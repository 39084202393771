import CHOrderEntryDE from 'src/CHOrderEntry/messages/de';
import receptionSurvey from 'src/i18n/de/receptionSurvey';
import subscriptionManagementDE from 'src/subscriptionProductManagement/messages/subscriptionManagement.de';

import address from './de/address';
import app from './de/app';
import consentManagement from './de/consentManagement';
import crossSellHints from './de/crossSellHints';
import customer from './de/customer';
import errors from './de/errors';
import inflightOrder from './de/inflightOrder';
import notepad from './de/notepad';
import notificationMessage from './de/notificationMessage';
import offers from './de/offers';
import order from './de/order';
import orderEntry from './de/orderEntry';
import orderHistory from './de/orderHistory';
import orderSource from './de/orderSource';
import orderType from './de/orderType';
import payment from './de/payment';
import privacyPolicy from './de/privacyPolicy';
import saleSource from './de/saleSource';
import solvencyCheck from './de/solvencyCheck';
import stockReminder from './de/stockReminder';
import subscription from './de/subscription';
import subscriptionHistory from './de/subscriptionHistory';
import voucher from './de/voucher';
import waitlist from './de/waitlist';


export default {
  ...app,
  ...errors,
  ...address,
  ...customer,
  ...order,
  ...payment,
  ...saleSource,
  ...orderEntry,
  ...orderHistory,
  ...offers,
  ...voucher,
  ...solvencyCheck,
  ...notificationMessage,
  ...stockReminder,
  ...consentManagement,
  ...privacyPolicy,
  ...crossSellHints,
  ...inflightOrder,
  ...notepad,
  ...waitlist,
  ...receptionSurvey,
  ...subscriptionHistory,
  ...subscription,
  ...orderType,
  ...orderSource,
  ...subscriptionManagementDE,
  ...CHOrderEntryDE,

  'root.title': 'Willkommen im Back-Office von HSE24',
  'root.description': 'Gehen Sie zur Route %orders/:id%, um eine Bestellung anzuzeigen',
  'root.orderId': 'Auftragsnummer',
  'root.errors.invalidOrderID': 'Ungültige Bestellnummer',
  'auth.error':
    'Ein unerwarteter Fehler ist aufgetreten. Bitte wenden Sie sich an ecom_team_order@hse24.de',
  'generic.error': 'Etwas ist schief gelaufen. Bitte versuche es erneut.',
  'editable.empty': 'nicht gesetzt',
  'orderDisplay.orderId': 'Auftrag:',
  'orderDisplay.exportedTo': 'Exportiert nach',
  'orderDisplay.notFoundError': 'Auftrag nicht gefunden!',
  'orderDisplay.genericError': 'Fehler beim Laden des Auftrags. Bitte versuchen Sie es später erneut.',
  'orderDisplay.priceDetails': 'Preisinformationen',
  'orderDisplay.totalPrice': 'Gesamtpreis',
  'orderDisplay.shippingDetails': 'Lieferinformationen',
  'orderDisplay.deliveryFees': 'Versandkosten',
  'orderDisplay.deliveryMode': 'Lieferart',
  'orderDisplay.itemSKU': 'SKU',
  'orderDisplay.itemQuantity': 'Menge',
  'orderDisplay.itemPrice': 'Preis',
  'orderDisplay.itemType': 'Typ',
  'orderDisplay.takeBackEnabled': 'Alt-Elektrogeräteabholung',
  'orderDisplay.customerDetails': 'Kundendaten',
  'orderDisplay.customerDetails.dplValue': 'DPL Wert',
  'orderDisplay.customerDetails.crmValue': 'CRM Wert',
  'orderDisplay.customerDetails.crmToolTip': 'CRM Wert nach DPL Wert übertragen',
  'orderDisplay.customerConsentDetails': 'Details zur Einwilligung des Kunden',
  'orderDisplay.customerPrivacyPolicyDetails': 'Details zur Datenschutzerklärung',
  'orderDisplay.customer.skipDoubleCheck': 'Doppelkontrolle überspringen',
  'orderDisplay.deliveryAddress': 'Lieferadresse',
  'orderDisplay.customer.salutation': 'Anrede',
  'orderDisplay.customer.salutation.mr': 'Herr',
  'orderDisplay.customer.salutation.ms': 'Frau',
  'orderDisplay.customer.salutation.company': 'Firma',
  'orderDisplay.customer.salutation.family': 'Familie',
  'orderDisplay.customer.email': 'E-Mail',
  'orderDisplay.customer.fullName': 'Name',
  'orderDisplay.customer.firstName': 'Vorname',
  'orderDisplay.customer.careOf': 'C/O Addresse',
  'orderDisplay.customer.customerNumber': 'Kundennummer',
  'orderDisplay.customer.lastName': 'Nachname',
  'orderDisplay.customer.customerName': 'Kundenname',
  'orderDisplay.customer.emailAddress': 'E-Mail-Adresse',
  'orderDisplay.customer.dateOfBirth': 'Geburtsdatum',
  'orderDisplay.customer.phone': 'Telefon',
  'orderDisplay.customer.street': 'Straße',
  'orderDisplay.customer.postNumber': 'Post Nummer',
  'orderDisplay.customer.addressAddition': 'HNR Zusatz',
  'orderDisplay.customer.empty': 'Kein Wert',
  'orderDisplay.customer.streetNumber': 'Hausnummer',
  'orderDisplay.customer.packstation': 'Packstation Nummer',
  'orderDisplay.customer.zipcode': 'Postleitzahl',
  'orderDisplay.customer.city': 'Stadt',
  'orderDisplay.customer.country': 'Land',
  'orderDisplay.paymentDetails': 'Bezahlinformationen',
  'orderDisplay.paymentMethod': 'Bezahlart',
  'orderDisplay.paymentMethod.sofort': 'Sofortüberweisung',
  'orderDisplay.paymentMethod.invoice': 'Rechnung',
  'orderDisplay.paymentMethod.paypal': 'PayPal',
  'orderDisplay.paymentMethod.direct_debit': 'ELV (Elektronisches Lastschriftverfahren)',
  'orderDisplay.paymentMethod.cash_on_delivery': 'Nachnahme',
  'orderDisplay.paymentMethod.installments': 'Teilzahlung',
  'orderDisplay.paymentMethod.installments_factoring': 'Ratenkauf',
  'orderDisplay.paymentMethod.credit_card': 'Kreditkarte',
  'orderDisplay.payment.paypal.transactionId': 'PayPal Transaktions-ID',
  'orderDisplay.payment.creditCard.transactionId': 'Kreditkarte Transaktions-ID',
  'orderDisplay.payment.accountHolder': 'Kontoinhaber',
  'orderDisplay.payment.iban': 'IBAN',
  'orderDisplay.payment.installment': 'Rate',
  'orderDisplay.payment.installmentCount': 'Anzahl Raten',
  'orderDisplay.payment.finalInstallment': 'Finale Rate',
  'orderDisplay.payment.total': 'Gesamtbetrag',
  'orderDisplay.payment.dueDay': 'Tag der Fälligkeit',
  'orderDisplay.payment.CalculationId': 'Berechnungs-ID',
  'orderDisplay.payment.hint': '„Nennen Sie mir bitte den Namen Ihrer Bank.“ ({BANKNAME})',
  'orderDisplay.payment.notAllowed': 'Diese Zahlungsart ist nicht möglich.',
  'orderDisplay.payment.installmentFactoring.notAllowed': 'Zahlungsart nicht möglich. Fragen zur Ablehnung beantwortet UNZER/Payolution unter: datenschutz-vie@unzer.com oder per Brief an Unzer GmbH, c/o Payolution, Hanauer Landstraße 164, 60314 Frankfurt',
  'orderDisplay.payment.cachOnDeliveryNotAllowedWithoutBillingAddress': 'Die Zahlung per Nachnahme ist bei Versand an abweichende Lieferadresse nicht möglich. Bitte wählen Sie eine andere Zahlungsart',
  'orderDisplay.otherInformation': 'Andere Informationen',
  'orderDisplay.orderInformation': 'Auftragsinformation',
  'orderDisplay.voucherInformation': 'Gutschein informationen',
  'orderDisplay.voucherInformation.code': 'Gutschein-Code Rabatt',
  'orderDisplay.voucherInformation.valueVoucher.code': 'Gutschein-Code CRM',
  'orderDisplay.voucherInformation.valueVoucher.value': 'Gutscheinwert CRM',
  'orderDisplay.voucherInformation.value': 'Gutscheinwert Rabatt',
  'orderDisplay.voucherInformation.percentage': 'Rabatt',
  'orderDisplay.voucherInformation.freeShipping': 'Versandkostenfrei',
  'orderDisplay.order.createdAt': 'Bestellzeitpunkt',
  'orderDisplay.order.salesOffice': 'Vertriebsbüro',
  'orderDisplay.order.salesChannel': 'Verkaufskanal',
  'orderDisplay.order.orderType': 'Auftragstype',
  'orderDisplay.order.orderSource': 'Herkunft',
  'orderDisplay.order.rekla.section.label': 'Rekla-Auftrag',
  'orderDisplay.order.rekla.checkbox.label': 'Aktiv',
  'orderDisplay.order.rekla.basket.hint.with.number': 'BO-Auftragsnummer: {previousOrderId}',
  'orderDisplay.order.rekla.basket.hint.without.number': 'Rekla-Auftrag',
  'orderDisplay.order.rekla.basket.maxItems': 'Max. Warenkorbgröße für Rekla-Auftrag erreicht.',
  'orderDisplay.statusHistory.unavailable':
    'Der Statusverlauf ist für diese Bestellung nicht verfügbar.',
  'orderDisplay.statusHistory.title': 'Statusverlauf',
  'orderDisplay.statusHistory.agentNamePrefix': 'von',
  'orderDisplay.statusHistory.status.created': 'Erstellt',
  'orderDisplay.statusHistory.status.submitted': 'Gesendet',
  'orderDisplay.statusHistory.status.accepted': 'Akzeptiert',
  'orderDisplay.statusHistory.status.failed': 'Gescheitert',
  'orderDisplay.statusHistory.status.erp_export_in_progress': 'CRM Kundendaten akzeptiert',
  'orderDisplay.statusHistory.status.failed.universeCreditCheckFailed': 'Universum Bonitaet Check fehlgeschlagen',
  'orderDisplay.statusHistory.status.recreated_order': 'Nachbestellen',
  'orderDisplay.statusHistory.status.rejected': 'Abgewiesen',
  'orderDisplay.statusHistory.status.cancelled': 'Storniert',
  'orderDisplay.statusHistory.status.rejected.customerModificationRequired': 'Dublette vorhanden. Folgende Felder konnten nicht aktualisiert werden:',
  'orderDisplay.statusHistory.status.rejected.exceptionReason': 'Es ist ein Ausnahmefehler aufgetreten. Die technische Fehlerbeschreibung befindet sich im Anhang.',
  'orderDisplay.statusHistory.status.rejected.unexpectedResponseReason': 'Das SAP CRM hat eine unerwartete Antwort gesendet. Die technische Antwort befindet sich im Anhang.',
  'orderDisplay.statusHistory.status.rejected.CrmErrorCodeReason': 'SAP CRM hat einen Fehler zurückgeliefert',
  'orderDisplay.statusHistory.status.edited': 'Bearbeitet',
  'orderDisplay.statusHistory.status.cancelled.cancelledBy.AGENT': 'Diese Bestellung wurde von einem Service-Mitarbeiter storniert',
  'orderDisplay.statusHistory.status.cancelled.cancelledBy.SYSTEM.after36Hours': '{payment}-Bestellung wurde nach 36 Stunden automatisch storniert',
  'orderDisplay.statusHistory.status.cancelled.cancelledBy.SYSTEM': '{payment}-Bestellung wurde automatisch storniert',
  'orderDisplay.statusHistory.cancelOrder_modalTitle': 'Möchten Sie die Bestellung stornieren?',
  'orderDisplay.statusHistory.cancelOrder_modalDecline': 'Nein',
  'orderDisplay.statusHistory.original_order': 'Ursprüngliche Bestellung:',
  'orderDisplay.statusHistory.transferred': 'Übertragen:',
  'orderDisplay.orderHistory.cancelOrder_modalConfirm': 'Ja, stornieren',
  'orderDisplay.edit.error': 'Sie haben einen ungültigen Wert eingegeben',
  'orderDisplay.resubmit': 'Neu Abschicken',
  'orderDisplay.cancel': 'Bestellung stornieren',
  'orderDisplay.recreateOrder': 'Nachbestellung in WILIS',
  'orderDisplay.paypalOrder.before36Hours':
    'Paypal-Bestellungen können nur innerhalb von 36 Stunden bearbeitet oder erneut eingereicht werden (nach diesem Zeitraum werden sie automatisch storniert)',
  'orderDisplay.privacyPolicy.isRequested': 'wird erbeten',
  'orderDisplay.privacyPolicy.infoRead': 'info lesen',
  'orderDisplay.privacyPolicy.infoSend': 'info Senden',
  'orderDisplay.consent.exportDhlEmail': 'DHL-E-Mail exportieren',
  'orderDisplay.consent.emailNewsletter': 'E-Mail-Newsletter',
  'orderDisplay.consent.phoneMarketResearch': 'Telefon Marktforschung',
  'orderDisplay.consent.printedAdvertising': 'gedruckte Werbung',
  'orderDisplay.payment.installmentFactoring.notAllowed.country.AT': 'Ratenkauf in Österreich nicht möglich.',

  'notification.resubmitOrder.success': 'Der Auftrag wurde erneut abgeschickt.',
  'notification.edit.success': 'Der Auftrag wurde bearbeitet.',
  'notification.operation.failed': 'Vorgang fehlgeschlagen.',
  'notification.dismiss': 'Schließen',
  // Customer
  'customer.voucher': 'Gutschein',
  'customer.data': 'Kundendaten',
  'customer.deliveryAddress': 'Hinterlegte Lieferadressen',
  'customer.paymentMethods': 'Zahlungsarten',
  'customer.kdnr': 'Kundennummer',
  'customer.search.kdnr': 'Nach KDNR suchen',
  'customer.customerNumber': 'Kundennummer',
  'customer.search.error.customerNumber': 'Die Kundennummer ist erforderlich!',
  'customer.angemahnterBetrag': 'Angemahnter Betrag',
  'customer.mahndatum': 'Mahndatum',
  'customer.mahnstufe': 'Mahnstufe',
  'customer.vonMoglichenMst': 'von möglichen MST',
  'customer.tzAngemahnterBetrag': 'TZ Angemahnter Betrag',
  'customer.tzMahndatum': 'TZ Mahndatum',
  'customer.tzMahnstufe': 'TZ Mahnstufe',
  'customer.tzInkassoubergabe': 'TZ Inkassoübergabe',
  'customer.auftragsbeschrankung': 'Auftragsbeschränkung',
  'customer.rabattkennzeichen': 'Rabattkennzeichen',
  'customer.bemerkung': 'Bemerkung',
  'customer.fiDatenAktuell': 'FI-Daten aktuell',
  'customer.erfassungsdatumbv': 'Erfassungsdatum BV',
  'customer.bevorzugtezahlungsart': 'Bevorzugte Zahlungsart',
  'customer.ezmversand': 'EZM Versand',
  'customer.ezmstatus': 'EZM Status',
  'customer.inhber': 'Inhaber',
  'customer.banker': 'Bank',
  'customer.iban': 'IBAN',
  'customer.streetSuppl1': 'Straße',
  // Customer Delivery Address
  'customer.buisenesspartner': 'Geschäftspartner',
  'customer.addAddress': 'Neue Lieferadresse hinzufügen',
  'customer.editAddress': 'Lieferadresse bearbeiten',
  'customer.addAddress.error': 'Fehler beim Hinzufügen einer neuen Lieferadresse',
  'customer.editAddress.error': 'Fehler beim Bearbeiten der Lieferadresse',
  'customer.addAddress.success': 'Lieferadresse hinzugefügt',
  'customer.editAddress.success': 'Lieferadresse bearbeitet',
  'customer.addressSuggestions.error': 'Adressvorschläge konnten nicht abgerufen werden',
  'customer.emailkuntefr': 'E-Mail Kund Befrountry',
  'customer.Kommentar': 'Kommentar',
  'customer.standardUsage': 'Standard Usage',
  'customer.relationshCat': 'Titel',
  'customer.academicTitle': 'Akademischer Titel\n',
  'customer.lastName': 'Nachname',
  'customer.fullName': 'Name',
  'customer.firstName': 'Vorname',
  'customer.careOf': 'C/O Addresse (optional)',
  'customer.kndr': 'KNDR',
  'customer.salutation': 'Anrede',
  'customer.street': 'Straße',
  'customer.houseNumber': 'Nr.',
  'customer.street4': 'Street 4',
  'customer.postalCode': 'Postleitzahl',
  'customer.city': 'Stadt',
  'customer.ort': 'Ort',
  'customer.country': 'Land',
  'customer.country.DE': 'Deutschland',
  'customer.country.AT': 'Österreich',
  'customer.street2': 'Street 2',
  'customer.telephoneNo': 'Telefonnummer',
  'customer.telephoneNoOptional': 'Telefonnummer (optional)',
  'customer.telephoneNoNotOptional': 'Telefonnummer',
  'customer.phoneType': 'Telefon-Typ',
  'customer.notUsed': 'Nicht benutzt',
  'customer.azStradaGepruft': 'AZ-Strada geprüft',
  'customer.packstation': 'Packstation Nummer',
  'customer.packStationLabel': 'Nummer der Packstation',
  'customer.postNumber': 'Post Nummer',
  'customer.postNumberLabel': 'DHL Postnummer',
  'customer.billingAddress': 'Rechnungsadresse',
  'customer.emailAddress.optional': 'E-Mail-Adresse (optional)',
  'customer.emailAddress.no.optional': 'E-Mail-Adresse',
  'orderDisplay.customer.dateOfBirth.label': 'Geburtsdatum (tt.mm.jjjj)',
  // Add Edit Customer
  'customer.createEdit.submit': 'sichern',
  'customer.search': 'Suchen',
  'customer.createEdit.abort': 'Abbrechen',
  'customer.createEdit.phoneType': 'Telefon-Typ',
  'customer.createEdit.createTitle': 'Neukunde anlegen',
  'customer.createEdit.editTitle': 'Kunden Bearbeiten',
  'customer.createEdit.lastCustomers': 'Letzte Kunden',
  'customer.createEdit.createError': 'Kundenanlage aktuell nicht möglich, bitte probiere noch einmal.',
  'customer.createEdit.editError': 'Aktualisierung der Kundendaten nicht möglich, bitte probiere noch einmal.',
  'customer.createEdit.createSuccess': 'Kunde erfolgreich angelegt.',
  'customer.createEdit.editSuccess': 'Kundendaten werden erst mit Bestellabschluss aktualisiert',
  // Customer order history
  'customer.orderHistory': 'Bestellhistorie',
  'customer.history.date': 'Datum',
  'customer.history.orderNumber': 'Bestellnummer',
  'customer.history.paymentMethod': 'Bezahlverfahren',
  'customer.history.shipping': 'Versand',
  'customer.history.productId': 'Produkt ID',
  'customer.history.description': 'Beschreibung',
  'customer.history.address': 'Address',
  'customer.history.lieferzeit': 'Lieferzeit',
  'customer.history.deliveryType': 'Delivery Type',
  'customer.history.createdBy': 'Created By',
  'customer.history.createdOn': 'Created On',
  'customer.history.changedBy': 'Changed By',
  'customer.history.changedOn': 'Changed On',
  // CTA
  'customer.lastCustomer': 'Vorheriger Kunde',
  'customer.createCustomer': 'Neu anlegen',
  'customer.createAddress': 'Adresse erstellen',
  'customer.reset': 'zurücksetzen',
  'customer.editCustomer': 'Bearbeiten',
  'product.search.title': 'Produktsuche',
  'product.search.bpno': 'Nach Bestellnr. suchen',
  'product.search.mark': 'Nach Produkt, Marke suchen',
  'product.search.resetAll': 'Alle zurücksetzen',
  'product.search.articleName': 'Artikelname',
  'product.search.salesDriver': 'Angebot',
  'product.search.freeShipping': 'Kostenloser Versand',
  'product.freeshippinglabel': '(Servicegutschein)',
  'product.search.description': 'Langtext Suche',
  'product.search.type.single': 'Einzelartikel',
  'product.search.type.variant': 'Variante',
  'product.search.type.set': 'Set',
  'product.search.bnr': 'BNR',
  'product.search.error.bnr': 'Die Produktnummer muss aus mindestens 4 Zeichen bestehen!',
  'product.search.stock.AVAILABLE': 'Vorrätig',
  'product.search.stock.ALMOST_SOLD_OUT': 'Niedriger Bestand',
  'product.search.stock.SOLD_OUT': 'Nicht vorrätig',
  'product.productInformation': 'Details & Bilder:',
  'search': 'suchen',
  'reset': 'zurücksetzen',
  'noResuts': 'Kein Ergebnis gefunden',
  'product information': 'Produktinformationen',
  'take': 'Übernehmen',
  'view variants': 'Varianten anzeigen',
  'upsells': 'Passende Produkte dazu',
  'product added successfully': 'Produkt erfolgreich hinzugefügt',

  'main product': 'Hauptprodukt',
  'upsell products': 'Upsell-Produkte',
  'productDetails.close': 'Schließen',
  'productDetails.colorLabel': 'Farbe',
  'productDetails.description': 'Beschreibung',
  'productDetails.moreDetails': 'Maße & Details',
  'productDetails.infoAndDelivery': 'Produktinformation und Lieferumfang',
  'productDetails.readMore': 'Weniger lesen',
  'productDetails.readLess': 'Mehr lesen',
  'postpone': 'Aufschub',
  'add to order': 'Dem Auftrag hinzufügen',
  'abort': 'Abbrechen',
  'save': 'Hinzufügen',
  'validate': 'Bestätigen',
  //Product
  'product.bnr': 'Bestellnr.',
  'product.image': 'Bild',
  'product.description': 'Bezeichung',
  'product.salesDriver': 'Angebot',
  'product.freeshipping': 'Versand Gratis',
  'product.price': 'Einzelpreis',
  'product.articletype': 'Artikeltyp',
  'product.stock': 'Bestand',
  'product.variant': 'Variante',
  'product.upsells': 'Empfehlung',
  'product.waitlist': 'Warteliste',
  'product.name': 'Marke & Produkt',
  'product.wrongCountry': 'Dieses Produkt ist in {country} nicht verfügbar.',
  'product.wrongCountry.DE': 'Dieses Produkt ist in Deutschland nicht verfügbar.',
  'product.wrongCountry.AT': 'Dieses Produkt ist in Österreich nicht verfügbar.',
  'product.wrongCountry.CH': 'Dieses Produkt ist in Schweiz nicht verfügbar.',
  'COLOR': 'Farbe',
  'SIZE': 'Größe',
  'ALLOY': 'Legierung',
  'TASTE': 'Geschmacksrichtung',
  'MOTIV': 'Motiv',
  'AROMA': 'Aroma',
  'AMOUNT': 'Menge',
  'STONE': 'Stein',
  'OTHERS': 'Others',
  'freeShipping.VERARGERTERKD': 'VERÄRGERTER KD',
  'freeShipping.SYSTEMFEHLER': 'SYSTEMFEHLER',
  'freeShipping.UMTAUSCH': 'UMTAUSCH',
  'product.stockReminder.hint': '„Dieses Produkt ist leider schon ausverkauft. Möchten Sie per E-Mail informiert werden, sobald es wieder verfügbar ist? Sie können es dann zum heutigen Angebotspreis von {price} bestellen.“',
  'product.notSellable': 'Bitte Teamleiter*in informieren! Hauptprodukt ist nicht verkaufsfähig',
  'product.cantOrderSubscriptionProduct': 'Treue Abo Produkt! Bitte die korrekte BNR (Werbenummer) der Abo-Liste in KIK entnehmen.',

  //RejectionManagement
  'rejection.order.label': 'Bitte Auftragsnummer eingeben',
  'rejection.order.input': 'Auftragsnummer',
  'rejection.order.input.required': 'Erforderlich',
  'rejection.order.submit': 'Weiter',

  'customer.banking.details.notFound': 'Keine Bankverbindung begründet',
  'customer.upsells.notAllowed': 'Kein Upsell erwünscht',

  //Take to online shop modal
  'onlineshoppopup.title': 'Eingeschränkte Zahlungsarten',
  'onlineshoppopup.body': 'Nur eingeschränkte Zahlungsarten möglich. Kunden an Online-Shop verweisen.',
  'onlineshoppopup.hint': '„Ich kann derzeit keine Zahlungsarten auswählen. Bitte bestellen Sie die gewünschten Produkte im Online-Shop per PayPal.”',

  //ofer
  'offer.discarded': 'Bitte Teamleiter*in informieren! Produkt im TV ist nicht verkaufsfähig.',
  'offer.product.discarded': 'Produkt ist nicht verkaufsfähig.',
  'product.stock.fetch.error': 'konnte den Bestand nicht abrufen.',

  // header InfoPost
  'header.infoPost.title': 'Infopost',
  'header.infoPost.ringstemplate': 'Ringschablone',
  'header.infoPost.request': 'Anfordern',
  'header.infoPost.requestSent': 'Versand erfolgt',

  // header Lernstudio
  'header.learningStudio.title': 'Lernstudio',

  //Ringschablon pdp hint
  'ringstemplate.hint.message': 'Bestimmen Sie Ihre Ringgröße mit unserer Ringschablone.',
  'ringstemplate.hint.request': 'Kostenlos anfordern',
  'ringstemplate.hint.request.success': 'Versand erfolgt',
  'ringstemplate.hint.request.confirm': 'Bestätigen',
  'ringstemplate.hint.request.cancel': 'Abbruch',

  // schema letter preview
  'schemaLetter.preview.error': 'Die Schema-Briefvorschau konnte nicht geladen werden !',

  // Bot Request
  'botRequest.info.loading': 'Bot-Anforderungsinformationen werden geladen...',
  'botRequest.info.requestNumber': 'Code',
  'botRequest.info.externalCustomerId': 'Kundennummer',
  'botRequest.info.isAuthenticated': 'Authentifiziert',
  'botRequest.info.isAuthenticatedYes': 'ja',
  'botRequest.info.isAuthenticatedNo': 'nein',
  'botRequest.info.reason': 'Abwurfgrund',
  'botRequest.info.comment': 'Details'
} as {[key: string]: string};
