

export enum DeliveryTimeMessageType {
    fourteenDaysDelay ='fourteenDaysDelay',
    daysDeliveryTime = 'daysDeliveryTime',
    standardDeliveryTime = 'standardDeliveryTime'
}

export enum DeliveryType {
    consignment = 'CONSIGNMENT',
    thirdPartyDelivery = 'THIRD_PARTY_DELIVERY',
    telephoneNotification=  'TELEPHONE_NOTIFICATION'
  }

export enum ShippingType {
    dropShipment ='DROP_SHIPMENT',
    dropShipmentWithTelephoneNotification = 'DROP_SHIPMENT_WITH_TELEPHONE_NOTIFICATION',
    standard = 'STANDARD',
    unknown = 'UNKNOWN'
  }

export interface DeliveryTimeMessage {
    deliveryDays?: number;
    deliveryMinDays?: number;
    deliveryDate?: String;
    shippingType?: ShippingType;
    deliveryMessage?: String;
    deliveryType?: DeliveryType;
    type?: DeliveryTimeMessageType;
}

export type SkuDeliveryTime = { [sku: string]: DeliveryTimeMessage };
