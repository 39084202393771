import { RootStateType } from 'src/constants/types';
import { VoucherShortType } from 'src/types/voucher/VirtualVoucherType';
import { InvalidVoucherReason } from 'src/types/voucher/VoucherResponse';
import { createSelectors } from 'src/utils/state';


export const voucherSelector = createSelectors({
  getVoucher({ order: { voucher } }: RootStateType) {
    return {
      code: voucher.code,
      value: voucher.value,
      calculatedPercentageValue: voucher.calculatedPercentageValue,
      type: voucher.type,
      percentage: voucher.percentage,
      restrictions: voucher.restrictions,
      loading: voucher.loading,
      progressiveDiscountInfo: voucher.progressiveDiscountInfo,
    } as VoucherShortType;
  },
  getVoucherErrorDetail(state: RootStateType) {
    return state.order.voucher.error?.detail;
  },
  isVoucherUsed(state: RootStateType) {
    return state.order.voucher.error?.title?.startsWith(InvalidVoucherReason.VOUCHER_USED);
  },
  getRedeemedVoucherHistory(state: RootStateType) {
    return state.order.voucher.redeemedVouchers;
  },
  getFailedUsedVoucher(state: RootStateType) {
    return state.order.voucher.failedVoucherCode;
  },
  getViewFailedVoucher(state: RootStateType) {
    return state.order.voucher.viewFailedVoucher;
  },
  getShowDeletedVoucherHint(state: RootStateType) {
    return state.order.voucher.showDeletedVoucherHint;
  },
});
