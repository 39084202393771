import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  delay,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { setShouldMOveToOrderHistoryPage } from 'src/redux/customer/customerInfoSlice';
import {
  clearOrdersFetchFilters,
  fetchOrderHistoryResultsError,
  fetchOrderOverviewHistoryResultsInProgress,
  fetchOrderOverviewHistoryResultsSuccess,
  fetchOrdersOverviewHistory,
  OrderHistoryFilters,
  setOrdersFetchFilters,
  updateOffset,
} from 'src/redux/customer/orderHistorySlice';
import { orderHistorySelector } from 'src/redux/customer/selectors/orderHistorySelectors';
import { fetchProductStock } from 'src/redux/product/productStockSlice';
import { checkAndLoadCustomer } from 'src/sagas/utils/checkAndLoadCustomer';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { createOrdersHistoryFetchRequest, OrderStatusRequest } from 'src/types/orderhistory/OrdersOverviewHistoryRequest';
import { ORDERS_OVERVIEW_SIZE, OrdersOverviewHistoryResponse } from 'src/types/orderhistory/OrdersOverviewHistoryResponse';
import { getProductSkusFromOrderHistoryResponse } from 'src/utils/getters/getProductSkusFromOrderHistoryResponse';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* fetchOrderOverviewHistorySaga(action: PayloadAction) {
  try {
    const externalCustomerId = yield select(orderHistorySelector.getExternalCustomerId);
    const searchedLimelightOrderId: string | undefined = yield select(orderHistorySelector.getSearchedLimelightOrderId);

    const currentTotalFilteredOrders = yield select(orderHistorySelector.getTotalFilteredOrders);
    const offset = yield select(orderHistorySelector.getOffset);
    const filters: OrderHistoryFilters = yield select(orderHistorySelector.getOrdersHistoryFilters);

    yield put(fetchOrderOverviewHistoryResultsInProgress());
    yield delay(200);

    const apiOrderHistory = yield getContext(SagaContextItem.apiOrderHistory);

    const response: OrdersOverviewHistoryResponse = yield call(
      apiOrderHistory.getCustomerOrdersOverViewHistory,
      createOrdersHistoryFetchRequest(externalCustomerId, offset, ORDERS_OVERVIEW_SIZE, filters, currentTotalFilteredOrders, searchedLimelightOrderId),
    );

    let orders = response.orders;
    if(action.type === updateOffset.type) {
      const previousOrders = yield select(orderHistorySelector.getOrdersHistory);
      orders = previousOrders.concat(response.orders);
    }

    const [totalOrders, totalFilteredOrders] = (filters.status === undefined || filters.status === OrderStatusRequest.ALL) ?
      [response.total, response.total] : [response.total, orders.length];

    yield put(fetchOrderOverviewHistoryResultsSuccess({ ...response, orders, totalOrders, totalFilteredOrders }));

    const orderSkus = yield call(getProductSkusFromOrderHistoryResponse, response.orders);
    if (response.orders.length > 0 && orderSkus.length > 0) {
      yield put(fetchProductStock(orderSkus));
    }
    if(externalCustomerId){
      yield call(checkAndLoadCustomer, externalCustomerId);
    }
    yield put(setShouldMOveToOrderHistoryPage(false));
  } catch (err) {
    const errorCode = (err && err.response && err.response.status) || 400;
    yield call(
      logErrorAndShowNotification,
      'Oops... Error fetching orders overview history.',
      'Hoppla... Fehler beim Abrufen des Bestellübersichtsverlaufs',
      err,
    );

    yield put(fetchOrderHistoryResultsError(errorCode));
  }
}

export default function* fetchOrdersOverviewHistoryWatcher() {
  yield takeLatest(fetchOrdersOverviewHistory.type, fetchOrderOverviewHistorySaga);
  yield takeLatest(setOrdersFetchFilters.type, fetchOrderOverviewHistorySaga);
  yield takeLatest(clearOrdersFetchFilters.type, fetchOrderOverviewHistorySaga);
  yield takeLatest(updateOffset.type, fetchOrderOverviewHistorySaga);
}
