import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const waitlistHistorySelector = createSelectors({
  getWaitlistHistoryItems(state: RootStateType) {
    return state.customer.waitlistHistory.data;
  },
  getWaitlistHistoryLoadingStatus(state: RootStateType) {
    return state.customer.waitlistHistory.loading;
  },
  getExternalCustomerId(state: RootStateType){
    return state.customer.waitlistHistory.externalCustomerId;
  },
  getWaitlistHistoryFilters(state: RootStateType) {
    return state.customer.waitlistHistory.filters;
  },
  getReasonOfCancellation(state: RootStateType) {
    return state.customer.waitlistHistory.waitlistItemCancellationReason;
  },
  getWaitlistCancellationItemId(state: RootStateType) {
    return state.customer.waitlistHistory.waitlistItemId;
  },
  getInfiniteScrollState(state: RootStateType) {
    return state.customer.waitlistHistory.infiniteScrollState;
  }
});
