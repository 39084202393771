import _ from 'lodash';

import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const productStockSelector = createSelectors({
  getStocks(state: RootStateType) {
    return state.product.stocks.data;
  },
  getStocksLoadingSate(state: RootStateType) {
    return state.product.stocks.loading;
  },
  getStocksBySku(state: RootStateType) {
    return _(state.product.stocks.data)
      .keyBy('sku')
      .mapValues(stock => stock)
      .value();
  },
});
