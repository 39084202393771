import onNewSessionWatcher from './onNewSession';
import onNewSessionWithBasketWatcher from './onNewSessionWithBasket';
import onNewSessionWithCustomerWatcher from './onNewSessionWithCustomer';


export default [
  onNewSessionWatcher,
  onNewSessionWithCustomerWatcher,
  onNewSessionWithBasketWatcher,
];
