import {
  call,
  getContext,
  put,
} from 'redux-saga/effects';

import { ApiBasket } from 'src/api/apiBasket';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { getBasketId, removeBasketId } from 'src/utils/basketId';


export enum RemoveItemFromBasketStatus {
  SUCCESS,
  ITEM_NOT_FOUND,
  BASKET_NOT_FOUND,
  FAILED
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* removeItemFromBasket(itemId: string): any {
  try {
    const apiBasket: ApiBasket = yield getContext(SagaContextItem.apiBasket);
    const currentBasketId = getBasketId();

    if(!currentBasketId) {
      return RemoveItemFromBasketStatus.BASKET_NOT_FOUND;
    }

    yield call(apiBasket.deleteItemFromBasket, itemId, currentBasketId);
    return RemoveItemFromBasketStatus.SUCCESS;
  } catch (basketApiError) {
    const basketErrorResponse = basketApiError.response?.data;
    if(basketErrorResponse?.type === 'ProductNotFound') {
      // already removed so no need to show SnackNotification (ITEM_NOT_FOUND ~ SUCCESS)
      return RemoveItemFromBasketStatus.ITEM_NOT_FOUND;
    }

    const errorMessage  = basketErrorResponse?.detail ?? basketApiError?.message;
    yield put(showErrorNotification(errorMessage));

    if(basketErrorResponse?.type === 'BasketNotFound') {
      removeBasketId();
      return RemoveItemFromBasketStatus.BASKET_NOT_FOUND;
    }
    return RemoveItemFromBasketStatus.FAILED;
  }
}
