import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  addNewProductSuccess,
  cancelProductSuccess,
  changePaymentSuccess,
  changeServiceVoucherSuccess,
  redeemVoucherSuccess,
  removeProductSuccess,
  removeVoucherSuccess,
  setInstallmentFactoringPlans,
  setInstallmentPlans,
  switchDeliveryAddressSuccess,
  updateProductPriceDateSuccess,
  updateProductSuccess,
} from 'src/redux/editOrder/editOrderSlice';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';


export function* handleUpdatedEditedOfferSaga(action: PayloadAction<EditedOffer>) {
  try {
    const editedOffer = action.payload;
    const paymentMethod = editedOffer.payment?.method;

    if(paymentMethod === PaymentMethod.installmentsFactoring) {
      yield put(setInstallmentFactoringPlans(editedOffer.installmentPlans));
    } else if(paymentMethod === PaymentMethod.installments) {
      yield put(setInstallmentPlans(editedOffer.installmentPlans));
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not handle success event ${action.type}`, err }));
  }
}

export default function* handleUpdatedEditedOfferWatcher() {
  yield takeLatest(changeServiceVoucherSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(updateProductPriceDateSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(addNewProductSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(removeVoucherSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(redeemVoucherSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(changePaymentSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(removeProductSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(cancelProductSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(updateProductSuccess.type, handleUpdatedEditedOfferSaga);
  yield takeLatest(switchDeliveryAddressSuccess.type, handleUpdatedEditedOfferSaga);
}
