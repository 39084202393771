import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { decamelizeKeys } from 'humps';

import { localeHeaderInterceptor } from 'src/api/httpClient/interceptors/localeHeaderInterceptor';
import Config from 'src/config';
import { BasketIdResponse } from 'src/types/offer/BasketIdResponse';
import { BasketItemRequest, UpdateItemRequest } from 'src/types/offer/BasketItemRequest';
import { BasketResponse } from 'src/types/offer/BasketResponse';
import getBasketServiceBaseUrl from 'src/utils/getters/getBasketServiceBaseUrl';

import { generateQueryString } from '../utils/basketId';


const httpAgent = axios.defaults.httpAgent;
const httpsAgent = axios.defaults.httpsAgent;
const headers = { 'x-request-source': 'BACKOFFICE' }; // Used to enable stock-reservation

export interface ApiBasket {
  client: AxiosInstance;
  addItemToBasket: (basketItem: BasketItemRequest, basketId: string | null, salesOffice: string, salesChannel: string, isB2BOrder?: boolean) => Promise<BasketIdResponse>;
  updateItem: (itemId: string, basketId: string, updateItemRequest: UpdateItemRequest) => Promise<BasketIdResponse>;
  deleteItemFromBasket: (itemId: string, basketId: string) => Promise<BasketIdResponse>;
  deleteBasket: (basketId: string) => Promise<BasketResponse>;
  updateBasket: (basketId: string, salesOffice: string, salesChannel: string) => Promise<BasketIdResponse>;
}

export function getApiBasket(baseURL: string): ApiBasket {
  const basketClient: AxiosInstance = axios.create({
    baseURL: baseURL,
    httpAgent: httpAgent,
    httpsAgent: httpsAgent,
    proxy: false,
    headers: headers,
  });

  // check to bypass the pact failure
  basketClient.interceptors.request.use((config: AxiosRequestConfig) => {
    if(
      config.baseURL === Config.env.basketServiceAkamaiUrl.at ||
      config.baseURL === Config.env.basketServiceAkamaiUrl.de ||
      config.baseURL === Config.env.basketServiceAkamaiUrl.ch
    ){
      config.baseURL = getBasketServiceBaseUrl();
    }
    return config;
  });
  if (!Config.env.basketServiceAkamaiUrl.enabled) {
    basketClient.interceptors.request.use(localeHeaderInterceptor.onFulfilled);
  }

  const client = {
    client: basketClient,

    addItemToBasket: async function(basketItem: BasketItemRequest, basketId: string | null, salesOffice: string, salesChannel: string, isB2BOrder = false) {
      const queryString = generateQueryString(basketId, salesOffice, salesChannel, isB2BOrder);

      const response = await basketClient.post<BasketIdResponse>(
        `/basket/BASKET_ID/items${queryString}`.replace('/BASKET_ID', basketId ? `/${basketId}` : ''),
        decamelizeKeys(basketItem)
      );
      return response.data;
    },

    updateItem: async function (itemId: string, basketId: string, updateItemRequest: UpdateItemRequest) {
      return (await basketClient.patch<BasketIdResponse>(`/private/basket/${basketId}/items/${itemId}`, decamelizeKeys(updateItemRequest))).data;
    },

    deleteItemFromBasket: async function (itemId: string, basketId: string) {
      return (await basketClient.delete<BasketIdResponse>(`/basket/${basketId}/items/${itemId}`)).data;
    },

    deleteBasket: async function (basketId: string) {
      return (await basketClient.delete<BasketResponse>(`/basket/${basketId}`)).data;
    },

    updateBasket: async function(basketId: string, salesOffice: string, salesChannel: string) {
      return (await basketClient.patch<BasketIdResponse>(`/basket/${basketId}`, decamelizeKeys({
        salesOffice: salesOffice,
        salesChannel: salesChannel
      }))).data;
    }
  };

  return client;
}

