import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import flatten from 'lodash/flatten';

import { getAuthHeader, X_AUTHORITZATION_HEADER } from 'src/api/httpClient/interceptors/xAuthorizationHeaderInterceptor';
import { LOCALE_HEADER } from 'src/types/Locale';
import countryLocalStorage from 'src/utils/countryLocalStorage';
import { getLocaleFromCountryCode } from 'src/utils/getters/getLocaleFromCountryCode';

import { camelizeKeysTransformer } from './camelizeKeysTransformer';


export interface HttpClientOptions {
  baseUrl: string;
  transform?: {
    camelize: boolean;
  };
  headers?: Record<string, string>;
  interceptors?: {
    request?: RequestInterceptor[] | RequestInterceptor;
    response?: ResponseInterceptor[] | ResponseInterceptor;
  };
}

export interface ResponseInterceptor {
  onFulfilled?: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected?: (error: any) => any;
}

export interface RequestInterceptor {
  onFulfilled?: (config: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRejected?: (error: any) => any;
}


function getAxiosConfig(options: HttpClientOptions): AxiosRequestConfig {
  const axiosConfig: AxiosRequestConfig = {};

  axiosConfig.baseURL = options.baseUrl;

  if (options.transform?.camelize) {
    axiosConfig.transformResponse = camelizeKeysTransformer;
  }

  if (options.headers) {
    axiosConfig.headers = options.headers;
  }

  return axiosConfig;
}

export function getHttpClient(options: HttpClientOptions): AxiosInstance {
  const axiosInstance = axios.create(getAxiosConfig(options));

  if (options.interceptors?.request) {
    flatten([options.interceptors.request]).forEach(interceptor => {
      axiosInstance.interceptors.request.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  }

  if (options.interceptors?.response) {
    flatten([options.interceptors.response]).forEach(interceptor => {
      axiosInstance.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
    });
  }

  return axiosInstance;
}

export const appendLocaleAndXAuthHeaders = (config: AxiosRequestConfig) => {
  config.headers = {
    ...config.headers,
    [LOCALE_HEADER]: getLocaleFromCountryCode(countryLocalStorage.get()),
    [X_AUTHORITZATION_HEADER]: getAuthHeader(),
  };
  return config;
};
