import { AxiosInstance } from 'axios';
import { decamelizeKeys } from 'humps';

import { StocksRemindableResponse } from 'src/types/product/product';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';


export interface ApiStockHistory {
  client: AxiosInstance;
  getProductStocksRemindable: (baseProductsNumbers: string[]) => Promise<StocksRemindableResponse>;
}

export function getApiStockHistory(baseURL: string): ApiStockHistory {
  const stockHistoryClient = getHttpClient(
    {
      baseUrl: baseURL,
      transform: {
        camelize: true,
      },
      interceptors: {
        request: [localeHeaderInterceptor],
      },
    });

  return {
    client: stockHistoryClient,
    getProductStocksRemindable: async (baseProductsNumbers: string[]) => {
      const result = await stockHistoryClient.post('/stocks-remindable', decamelizeKeys({ baseProductsNumbers: baseProductsNumbers }));
      return result.data;
    },
  };
}
