import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { cancelProduct, cancelProductSuccess } from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';

import { setDeliveryTimesSaga } from './initEditOrder';


export function* cancelProductSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    const response: EditedOffer = yield call(apiEditOrder.cancelPosition, action.payload);

    const offer = yield call(setDeliveryTimesSaga, response);

    yield put(cancelProductSuccess(offer));
  } catch (error) {
    yield handleFailureState(error, 'Couldn\t cancel Product on edit order actionRequest: ',action.payload.actionRequest);
  }
}

export default function* cancelProductWatcher() {
  yield takeLatest(cancelProduct.type, cancelProductSaga);
}
