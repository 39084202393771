export default {
  'app.title': 'Digital Platform - Order Back Office',
  'app.headerSearch': 'Produkt, Marke, Kategorie, Bestellnr',
  'app.headerSearchButton': 'Suchen',
  'app.salesDrivers': 'Sales drivers',
  'app.freeshipping': 'Free shipping',
  'app.search': 'Search',
  'app.advancedSearch': 'Advanced Search',
  'app.email': 'E-Mail',
  'app.salutation.mr': 'Mr',
  'app.salutation.ms': 'Ms',
  'app.firstName': 'First Name',
  'app.lastName': 'Last Name',
  'app.dateOfBirth': 'Birthday',
  'app.phone': 'Phone Number',
  'app.street': 'Street',
  'app.streetNumber': 'Street Number',
  'app.zipcode': 'Zip Code',
  'app.postalCode': 'Postal Code',
  'app.city': 'city',
  'app.login': 'Einloggen',
  'app.errors.required': 'Erforderlich',
  'app.submit': 'sichern',
  'app.abort': 'abbrechen',
  'app.reset': 'Reset',
  'app.country': 'Country',
  'app.language': 'Language',
  'app.newSession': 'New Session',
  'app.newSessionwithCustomer': 'Reset customer',
  'app.close': 'Close',
  'app.logout': 'Logout',
  'app.unauthorized': 'You are not authorized to access this page',
  'app.tablePagination': 'rows per page',
  'app.agentSalutation': 'Willkommen, {fullName}!',
  'app.chooseApp': 'Bitte wähle eine Applikation.',
  'app.orderApp.headline': 'WILIS - Wir lieben Shopping!',
  'app.orderApp.cta': 'Bestellungen erfassen',
  'app.wilisForCH.headline': 'Shopping for Swiss!',
  'app.wilisForCH.cta': 'Bestellungen erfassen',
  'app.rejectionMgmt.headline': 'Abwürfe',
  'app.rejectionMgmt.cta': 'Abwürfe bearbeiten',
  'app.voucherMgmt.headline': 'Gutscheinverwaltung',
  'app.voucherMgmt.cta': 'Gutscheine erstellen',
  'app.SolvencyCheck.headline': 'Kundenauskunft Bonität',
  'app.SolvencyCheck.cta': 'Bonität prüfen',
  'app.name': 'Name',
  'app.loginTime': 'Login time',
  'app.subscriptionProductManagement.headline': 'Treue Abo',
  'app.subscriptionProductManagement.cta': 'Abonnementverwaltung',
};
