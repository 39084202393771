export interface AddressCheckErrorResponse {
  reason: AddressCheckFailureResult;
}

export enum AddressCheckFailureResult {
  SERVICE_DOWN,
  BAD_REQUEST,
  UNKNOWN_REASON
}

export const createAddressCheckErrorResponse = (responseCode?: number) => {
  switch (responseCode) {
  case 400 :
    return { reason: AddressCheckFailureResult.BAD_REQUEST };
  case 500 :
    return { reason: AddressCheckFailureResult.SERVICE_DOWN };
  default :
    return { reason: AddressCheckFailureResult.UNKNOWN_REASON };
  }
};
