export default {
  'month.january': 'Januar',
  'month.february': 'Februar',
  'month.march': 'März',
  'month.april': 'April',
  'month.may': 'Mai',
  'month.june': 'Juni',
  'month.july': 'Juli',
  'month.august': 'August',
  'month.september': 'September',
  'month.october': 'Oktober',
  'month.november': 'November',
  'month.december': 'Dezember',
};
