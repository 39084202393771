import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const offersSelectors = createSelectors({
  // top deals
  getMainDailyTopDeal(state: RootStateType) {
    return state.offers.topDeals.daily[0];
  },
  getDailyTopDeals(state: RootStateType) {
    return state.offers.topDeals.daily;
  },
  getMainMonthlyTopDeal(state: RootStateType) {
    return state.offers.topDeals.monthly[0];
  },
  getMonthlyTopDeals(state: RootStateType) {
    return state.offers.topDeals.monthly;
  },
  getMainWeeklyTopDeal(state: RootStateType) {
    return state.offers.topDeals.weekly[0];
  },
  getWeeklyTopDeals(state: RootStateType) {
    return state.offers.topDeals.weekly;
  },
  // tv offers
  getMainHse24Offer(state: RootStateType) {
    return state.offers.tvOffers.hse24[0];
  },
  getHse24Offers(state: RootStateType) {
    return state.offers.tvOffers.hse24;
  },
  getMainHse24ExtraOffer(state: RootStateType) {
    return state.offers.tvOffers.hse24_extra[0];
  },
  getHse24ExtraOffers(state: RootStateType) {
    return state.offers.tvOffers.hse24_extra;
  },
  getMainHse24TrendOffer(state: RootStateType) {
    return state.offers.tvOffers.hse24_trend[0];
  },
  getHse24TrendOffers(state: RootStateType) {
    return state.offers.tvOffers.hse24_trend;
  },
  allOfferItems(state: RootStateType) {
    return state.offers.allOfferItems;
  },
  getSelectOffersType(state: RootStateType) {
    return state.offers.offersType;
  },
  tvOffersLoading(state: RootStateType) {
    return state.offers.loading;
  },
});
