import { LocalStorageKeys } from 'src/constants/localStorageKeys';
import { resetLastPinChallengeCheck } from 'src/utils/lastPinChallengeCheck';

import Config from '../config';


function clearAuth() {
  localStorage.removeItem(LocalStorageKeys.IdToken);
  localStorage.removeItem(LocalStorageKeys.RefreshToken);
}

function clearNotes() {
  localStorage.removeItem(LocalStorageKeys.Notes);
}

/**
 * Redirects to Cognito Login Hosted-UI
 */
export function login() {
  clearAuth();
  clearNotes();

  // Save current route to localStorage, so that we can get back there after Cogito UI sends us back
  localStorage.setItem(LocalStorageKeys.PathBeforeRedirect, window.location.pathname);

  window.location.href =
    `${Config.env.cognito.url}` +
    `/login?response_type=code&client_id=${Config.env.cognito.clientId}` +
    `&redirect_uri=${Config.env.backofficeUrl}/oauth-sign-in`;
}

/**
 * Redirects to Cognito Logout Hosted-UI
 */
export function logout() {
  clearAuth();
  clearNotes();
  resetLastPinChallengeCheck();
  window.location.href =
    `${Config.env.cognito.url}` +
    `/logout?response_type=code&client_id=${Config.env.cognito.clientId}` +
    `&redirect_uri=${Config.env.backofficeUrl}/oauth-sign-in`;
}

/**
 * Redirects to Unauthorized page
 */
export function unauthorized() {
  window.location.href = '/unauthorized';
}
