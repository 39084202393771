export default {
  'consent.management.title': 'Einwilligungen',
  'consent.management.exportDhlEmail': 'E-Mail-Adr. für Bestellbestätigung und DHL',
  'consent.management.emailNewsletter': 'E-Mail Newsletter',
  'consent.management.phoneMarketResearch': 'Marktforschung',
  'consent.management.printedAdvertising': 'Gedruckte Werbung',
  'consent.management.yes': 'Erlaubt',
  'consent.management.no': 'Nicht erlaubt',
  'consent.management.save': 'Sichern',
  'consent.management.exportDhlEmail.text': '„Haben Sie auch eine E-Mail-Adresse an die wir und die DHL die Lieferbenachrichtigungen senden dürfen?“',
  'consent.management.emailNewsletter.text': '„Möchten Sie auch unseren E-Mail-Newsletter ausprobieren? Wenn Sie sich jetzt anmelden, dann erhalten Sie einen 10 Euro Gutschein für Ihren nächsten Einkauf.\n' +
    '(Probieren Sie ihn doch einfach mal aus. Der Newsletter ist kostenlos und Sie können ihn jederzeit abbestellen. Den Gutschein bekommen Sie in jedem Fall.)“',
  'consent.management.phoneMarketResearch.text': '„{salutation} {name},{br}' +
    'wir befragen gelegentlich Kunden telefonisch oder per E-Mail zu ihrer Meinung über HSE, unsere Produkte, Präsentationen und Serviceleistungen. Dürfen wir auch Sie dabei zukünftig berücksichtigen? Sie können Ihre Einwilligung auch jederzeit widerrufen.“',
  'consent.management.phoneMarketResearch.questions.title': 'Kunde hat Rückfragen',
  'consent.management.phoneMarketResearch.questions.text': '„Bei Rückfragen wenden Sie sich gerne an die Kollegen der Serviceline. Telefonisch unter 0800 23 414 74 (8-21 Uhr) oder per E-Mail service@hse.de / service@hse.at / service@hse24.ch  Ausführliche Informationen zum Datenschutz finden Sie auch in unserem Online-Shop oder im Videotext.“',
  'consent.management.phoneMarketResearch.dataProtection.title': 'Datenschutz-Auszug vorlesen',
  'consent.management.phoneMarketResearch.dataProtection.text': '„(i) Wenn Sie an einer Telefon- oder E-Mail Umfrage zu Marktforschungszwecke der Home Shopping Europe teilnehmen, verarbeiten wir Ihre Daten zum Zweck der Durchführung und Auswertung der Umfrage zur Verbesserung unserer Produkte und Services.{br}' +
    '(ii) Die verarbeiteten Daten sind der Namen, die Telefonnummer, die E-Mail-Adresse und die Antworten auf die Fragen im Rahmen der Umfrage der Teilnehmer der Umfrage.{br}' +
    '(iii) Rechtsgrundlage für die Verarbeitung ist Ihre Einwilligung (Artikel 6 Absatz 1 Buchstabe a DS-GVO). Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch Ihren Widerruf wird die Rechtmäßigkeit der Verarbeitung Ihrer personenbezogenen Daten bis zum Widerruf nicht berührt. Den Widerruf können Sie per E-Mail an service@hse.de oder durch eine Nachricht an die im Impressum angegebenen Kontaktdaten erklären.{br}' +
    '(iv) Die Daten werden aktiv vom Teilnehmer der Umfrage zur Verfügung gestellt.{br}' +
    '(v) Wir setzen Dienstleister im Wege der Auftragsverarbeitung bei der Erbringung von Leistungen ein, insbesondere für die Bereitstellung, Wartung und Pflege von IT-Systemen.{br}' +
    '(vi) Die Daten werden spätestens 1 Jahr nach Durchführung der Umfrage gelöscht.{br}' +
    '(vii) Ohne Angabe Ihrer Daten können Sie nicht an der Umfrage teilnehmen.{br}' +
    '(viii) Es findet keine automatisierte Entscheidungsfindung statt.“',
  'consent.management.text': '„Bevor wir zum Abschluss Ihrer Bestellung kommen, habe ich noch eine Frage zu Ihrem Kundenkonto.”',
  'consent.management.email': 'E-Mail-Adresse',
  'consent.management.email.error': 'Bitte E-Mail-Adresse prüfen',
  'consent.management.email.required': 'Bitte E-Mail-Adresse eingeben',
  'consent.management.phone': 'Telefonnummer',
  'consent.management.phone.text': '„Dürfen wir Ihre Telefonnummer für Rückfragen zur Bestellung hinterlegen?"',
  'consent.management.phone.error': 'Bitte Telefonnummer prüfen',
  'consent.management.phone.required': 'Bitte Telefonnummer eingeben',
  'consent.management.emailNewsletter.reward': '„Sie erhalten gleich eine E-Mail mit einem Anmelde-Link von uns. Bitte bestätigen Sie ihn innerhalb von 12 ' +
    'Stunden. Danach senden wir Ihnen den Einkaufsgutschein, den Sie sofort einlösen können.“',
  'consent.management.options.notQueried': 'Nicht abgefragt',
  'consent.management.options.permited': 'Erlaubt',
  'consent.management.options.notAllowed': 'Nicht erlaubt',
};
