export default {
  'subscriptionproductsreport.currentmonthfloorsales': 'Floor Sales aktueller Monat',
  'subscriptionproductsreport.previousmonthfloorsales': 'Floor Sales vergangener Monat',
  'subscriptionproductsreport.totalsubscription': 'Treue Abos gesamt',
  'subscriptionproductsreport.totalproducts': 'Treue Abo Produkte gesamt',
  'subscriptionproductsreport.activesubscription': 'aktive Treue-Abos',
  'subscriptionproductsreport.available': 'Ausreichend',
  'subscriptionproductsreport.product': 'Produkte',
  'subscriptionproductsreport.stock': 'Bestände',
  'subscriptionproductsreport.warning': 'Warnung(en)',
  'subscriptionproductsreport.review': 'Ansehen',
  'subscriptionproductsreport.backToOverview': 'Zurück zur Produkt-übersicht',
};
