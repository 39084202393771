import dayjs from 'dayjs';

import { CountryCode } from 'src/api/orderTypes';
import { DEFAULT_SALES_OFFICE } from 'src/config/salesChannels';
import { DEFAULT_DISPLAY_DATE_FORMAT } from 'src/constants/date';
import { CustomerInfoState } from 'src/redux/customer/customerInfoSlice';
import {
  BillingAddress,
  DeliveryAddress,
  DeliveryAddressType,
  PostalDeliveryAddress,
} from 'src/types/customer/address';
import { Customer } from 'src/types/customer/customer';
import { Salutation } from 'src/types/customer/Salutation';
import { GuestOrderRequest } from 'src/types/offer/GuestOrderRequest';
import { OrderItem } from 'src/types/offer/OrderItem';
import { Payment } from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { ReklaFlag } from 'src/types/offer/ReklaFlag';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { ServiceVoucher } from 'src/types/voucher/ServiceVoucher';
import { VoucherRequest } from 'src/types/voucher/VoucherRequest';
import fromDisplayFormatToISO8601 from 'src/utils/formatters/fromDisplayFormatToISO8601';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


export interface OrderParams {
  salesOffice: string;
  salesChannel: string;
  customerInfo: CustomerInfoState;
  payment: Payment;
  voucher?: VoucherRequest;
  serviceVoucher?: ServiceVoucher;
  orderItems: OrderItem[];
  country: CountryCode;
  freeShipping: boolean;
  basketId: string | null;
  sourceChannel: SourceChannels;
  preferredPaymentMethod?: PaymentMethod;
  recreatedOriginalOrderId?: string;
  deliveryAddress?: DeliveryAddress;
  reklaFlag?: ReklaFlag
}

export function mapBillingToDeliveryAddress(billingAddress: BillingAddress, customer: Customer): PostalDeliveryAddress {
  return {
    externalAddressId: customer.externalCustomerId,
    type: DeliveryAddressType.Postal,
    salutation: customer.salutation,
    firstName: customer.firstName,
    lastName: customer.lastName,
    careOf: undefined,
    street: billingAddress.street,
    streetNumber: billingAddress.streetNumber,
    addressAddition: trimStringOrNull(billingAddress.addressAddition),
    zipCode: billingAddress.zipCode,
    city: billingAddress.city,
    countryCode: billingAddress.countryCode,
  };
}

const buildOrderRequest = (order: OrderParams): [GuestOrderRequest | undefined, string] => {
  const customer = order.customerInfo.customerResponse;

  if (!customer) {
    return [undefined, 'order.invalid.customerData'];
  }
  if (!customer.dateOfBirth) {
    return [undefined, 'order.invalid.dateOfBirth'];
  }

  let dateOfBirth = customer.dateOfBirth;
  if (dayjs(dateOfBirth, DEFAULT_DISPLAY_DATE_FORMAT).isValid()) {
    dateOfBirth = fromDisplayFormatToISO8601(customer.dateOfBirth) || '';
  }

  if (!order?.payment?.method) {
    return [undefined, 'order.invalid.paymentMethod'];
  }

  if (!order.deliveryAddress) {
    return [undefined, 'order.invalid.billingAddress'];
  }

  if (order.orderItems.length === 0) {
    return [undefined, 'order.cart.isEmpty'];
  }

  const isExistingCustomer = !!customer.externalCustomerId;
  const billingAddress = customer.billingAddress!;
  const orderRequest: GuestOrderRequest = {
    salesOffice: order.salesOffice || DEFAULT_SALES_OFFICE,
    salesChannel: order.salesChannel,
    customer: {
      externalCustomerId: customer.externalCustomerId,
      dateOfBirth: dateOfBirth,
      salutation: customer.salutation || Salutation.miss,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: trimStringOrNull(customer.email),
      phone: customer.originalPhoneNumber || trimStringOrNull(customer.phoneNumber),
      careOf: trimStringOrNull(billingAddress.careOf),
      street: billingAddress.street,
      streetNumber: billingAddress.streetNumber,
      zipCode: billingAddress.zipCode,
      city: billingAddress.city,
      countryCode: order.country,
      addressAddition: trimStringOrNull(billingAddress.addressAddition),
      createAccount: !isExistingCustomer,
      consents: isExistingCustomer ? undefined : customer.consents,
      dataProtection: customer.dataProtection,
      skipDoubleCheck: customer.skipDoubleCheck,
      preferredPaymentMethod: order.preferredPaymentMethod,
      signupForNewsletter: customer.consents?.emailNewsletter,
      reception: customer.reception,
    },
    deliveryAddress: order.deliveryAddress,
    payment: order.payment,
    voucher: order.voucher,
    serviceVoucher: order.serviceVoucher,
    isShippingOverwritten: order.freeShipping,
    basketId: order.basketId || undefined,
    sourceChannel: order.sourceChannel,
    originalOrderId: order.recreatedOriginalOrderId,
    reklaFlag: order.reklaFlag
  };

  return [orderRequest, ''];
};

export default buildOrderRequest;
