import {
  call,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import {
  GetSuggestedCities,
  getSuggestedCities,
  setSuggestedCities
} from 'src/redux/customer/addressSuggestionsSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { AddressValidationResponse } from 'src/types/customer/AddressValidationResponse';
import serializeAddress from 'src/utils/mappers/serializeAddress';


export function* getSuggestedCitySaga(action: GetSuggestedCities) {
  try {
    const country = yield select(appSelectors.getCurrentCountry);
    const {
      street = '',
      streetNumber = '',
      zipCode,
    } = action.payload;
    const apiAddress = yield getContext(SagaContextItem.apiAddress);
    const addresses: AddressValidationResponse = yield call(apiAddress.validateAddress, serializeAddress({
      street,
      streetNumber,
      zipCode,
      city: '',
      countryCode: country,
    }));

    const completeAddresses = addresses.completeAddresses;
    const partialAddresses = addresses.partialAddresses;
    const suggestedCities = completeAddresses.map(it => (it.city)).concat(partialAddresses.map(it => (it.city)));
    yield put(setSuggestedCities(suggestedCities));

  } catch (err) {
    yield put(
      logErrorEvent({ message: `Could not fetch cities suggestions, error: ${err?.toString()}`, err }),
    );
    yield put(setSuggestedCities([])); 
  }
}

export default function* getSuggestedCityWatcher() {
  yield takeLatest(getSuggestedCities.type, getSuggestedCitySaga);
}