import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { changeServiceVoucher, changeServiceVoucherSuccess } from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';


export function* changeServiceVoucherSaga(
  action: PayloadAction<EditOrderActionRequestWithOrderId>,
) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    const response: EditedOffer = yield call(apiEditOrder.changeServiceVoucher, action.payload);
    yield put(changeServiceVoucherSuccess(response));
  } catch (error) {
    yield handleFailureState(error, 'Couldn\'t change Service Voucher on edit order actionRequest: ', action.payload.actionRequest);
  }
}

export default function* changeServiceVoucherWatcher() {
  yield takeLatest(changeServiceVoucher.type, changeServiceVoucherSaga);
}
