import { takeEvery, put } from 'redux-saga/effects';

import { showErrorNotification } from 'src/redux/app/appSlice';
import { getBasketError } from 'src/redux/order/orderEntrySlice';


export function *onGetBasketErrorSaga() {
  yield put(showErrorNotification('orderentry.basket.error'));
}

export default function *getBasketErrorWatcher() {
  yield takeEvery(getBasketError.type, onGetBasketErrorSaga);
}
