import { AxiosRequestConfig } from 'axios';

import { LOCALE_HEADER } from 'src/types/Locale';
import countryLocalStorage from 'src/utils/countryLocalStorage';
import { getLocaleFromCountryCode } from 'src/utils/getters/getLocaleFromCountryCode';

import { RequestInterceptor } from '../HttpClient';


export const localeHeaderInterceptor: RequestInterceptor = {
  onFulfilled(config: AxiosRequestConfig) {
    config.headers = {
      ...config.headers,
      [LOCALE_HEADER]: getLocaleFromCountryCode(countryLocalStorage.get()),
    };
    return config;
  },
};
