import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import {
  changePayment,
  changePaymentSuccess,
  setInstallmentFactoringConsent,
  setInstallmentFactoringPlans,
  setInstallmentPlans,
} from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';


export function* changePaymentSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    const response: EditedOffer = yield call(apiEditOrder.changePayment, action.payload);

    const installmentPlans = response.installmentPlans;

    if (response.payment?.method === PaymentMethod.installments) {
      yield put(setInstallmentPlans(installmentPlans));
    }
    else if(response.payment?.method === PaymentMethod.installmentsFactoring){
      yield put(setInstallmentFactoringPlans(installmentPlans));
      yield put(setInstallmentFactoringConsent(false));
    }

    yield put(changePaymentSuccess(response));
  } catch (error) {
    yield handleFailureState(error,'Couldn\'t change payment on edit order actionRequest: ',action.payload.actionRequest);
  }
}

export default function* changePaymentWatcher() {
  yield takeLatest(changePayment.type, changePaymentSaga);
}
