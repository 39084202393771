export default {
  'subscription.step.login' : 'Kundennummer eingeben',
  'subscription.step.birthday.check' : 'Authentifizierung',
  'subscription.step.login.text' : '„Nennen Sie mir bitte zuerst Ihre Kundennummer.”',
  'subscription.step.birthday.check.text' : '„Nennen Sie mir zum Datenabgleich bitte Ihr Geburtsdatum (Passwort).”',
  'subscription.back.login' : 'Zurück zur Kundennummer',
  'subscription.step.confirmed' : 'Treue Abo bestätigt',
  'subscription.confirmed.hint': '"Sie erhalten Ihre(n) Wunschartikel ab jetzt regelmäßig zum Vorteilspreis geliefert."',

  'subscription.title': 'Loyalty Subscription',
  'subscription.saveAndContinue': 'Save and continue',
  'subscription.edit.successNotificationHint': 'Subscription was successfully edited',
  'subscription.item.cancel.successNotificationHint': 'Subscription item was successfully cancelled',
  'subscription.editMultipleSubscriptionNumbers.successNotificationHint': 'Subscription Numbers were successfully edited',
  'subscription.quantity': 'Order quantity/unit price',

  // rotation options
  'subscription.rotation': 'Rotation',
  'subscription.rotation.ONE_MONTH': 'Monthly',
  'subscription.rotation.TWO_MONTHS': 'Every 2 months',
  'subscription.rotation.THREE_MONTHS': 'Every 3 months',
  'subscription.rotation.FOUR_MONTHS': 'Every 4 months',
  'subscription.rotation.FIVE_MONTHS': 'Every 5 months',
  'subscription.rotation.SIX_MONTHS': 'Every 6 months',
  'subscription.rotation.hint': 'Confirm the next delivery date with the customer',

  // start date options
  'subcscription.startDate.TODAY': 'Today',
  'subcscription.startDate.ONE_WEEK': 'In a week',
  'subcscription.startDate.TWO_WEEKS': 'In 2 weeks',
  'subcscription.startDate.THREE_WEEKS': 'In 3 weeks',
  'subcscription.startDate.ONE_MONTH': 'In 1 month',
  'subcscription.startDate.SIX_WEEKS': 'In 6 weeks',
  'subcscription.startDate.CUSTOM': 'Datum eingeben',

  'subcscription.nextOrderDate.TODAY': 'Today',
  'subcscription.nextOrderDate.ONE_WEEK': 'After a week',
  'subcscription.nextOrderDate.TWO_WEEKS': 'After 2 weeks',
  'subcscription.nextOrderDate.THREE_WEEKS': 'After 3 weeks',
  'subcscription.nextOrderDate.ONE_MONTH': 'After 1 month',
  'subcscription.nextOrderDate.SIX_WEEKS': 'After 6 weeks',
  'subcscription.nextOrderDate.SKIP_NEXT_DELIVERY': 'Nächste Lieferung aussetzen',
  'subcscription.nextOrderDate.CUSTOM': 'Datum eingeben',

  'subscription.variantHint': 'Did you know that this article is also available with a loyalty subscription? now e.g. B. Save {price} on monthly delivery.',

  'subscription.hint': 'Wussten Sie, dass es diesen Artikel auch im Treue-Abo gibt? Jetzt {price} sparen bei monatlicher Lieferung.',
  'subscription.title.withPrice':  'Treue-Abo {price} je Lieferung',
  'subscription.startIn': 'Start In',
  'subscription.source.channelId': 'Distribution channel',
  'subscription.source': 'Channel',
  'subscription.source.channel': 'Input channel',

  'subscription.paymentMethod': 'payment method',

  // subscription status hint
  'subscription.product.statusHint.RAN_OUT': 'No new subscriptions can be created for this loyalty subscription at this time.',
  'subscription.product.statusHint.STOPPED': 'Attention subscription paused. (The next possible delivery date can be found in the KIK Abo-News.)',
  'subscription.product.statusHint.DELETED': 'This item has been discontinued',
  'subscription.product.statusHint.ACTIVE_NO_STOCK': 'The loyalty subscription will be available in a few days. Delivery will take place as soon as possible.',
  'subscription.product.statusHint.ACTIVE_NO_STOCK.checkbox': 'I have informed the customer and I want to proceed',

};
