import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { AuthorizationCodeResponse } from 'src/api/authTypes';
import { LocalStorageKeys } from 'src/constants/localStorageKeys';
import {
  AppActionTypes,
  FetchOauthTokens,
  fetchOauthTokensFailed,
  fetchOauthTokensSuccess,
} from 'src/redux/app/appSlice';
import { loadAuthenticatedAgent } from 'src/sagas/auth/loadAuthenticatedAgent';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


export function* fetchOauthTokens(action: FetchOauthTokens) {
  try {
    const apiOrder = yield getContext(SagaContextItem.apiOrder);
    const tokenResponse: AuthorizationCodeResponse = yield call(apiOrder.getOauthTokens, action.payload);
    localStorage.setItem(LocalStorageKeys.IdToken, tokenResponse.idToken);
    localStorage.setItem(LocalStorageKeys.RefreshToken, tokenResponse.refreshToken);
    yield put(fetchOauthTokensSuccess(tokenResponse));
    yield call(loadAuthenticatedAgent);
  } catch (err) {
    localStorage.removeItem(LocalStorageKeys.IdToken);
    localStorage.removeItem(LocalStorageKeys.RefreshToken);
    yield put(fetchOauthTokensFailed());
  }
}

export default function* fetchOauthTokensWatcher() {
  yield takeLatest(AppActionTypes.FETCH_OAUTH_TOKENS, fetchOauthTokens);
}
