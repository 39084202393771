import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { redeemVoucher, redeemVoucherSuccess } from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';


export function* redeemVoucherSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    const response: EditedOffer = yield call(apiEditOrder.redeemVoucher, action.payload);
    yield put(redeemVoucherSuccess(response));
    return true;
  } catch (error) {
    yield handleFailureState(error, 'Couldn\'t redeem Voucher on edit order actionRequest: ', action.payload.actionRequest);
  }

  return false;
}

export default function* redeemVoucherWatcher() {
  yield takeLatest(redeemVoucher.type, redeemVoucherSaga);
}
