import jwtDecode from 'jwt-decode';
import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { LocalStorageKeys } from 'src/constants/localStorageKeys';
import { checkAuthToken, fetchOauthTokens } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { login } from 'src/routes/externalRedirects';


interface TokenDto {
  exp: number;
}

export function* validateAuthenticationToken() {
  const jwtToken = yield select(appSelectors.getJwt);
  try {
    const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);
    const jwtDetail = jwtDecode<TokenDto>(jwtToken);
    if (Date.now() >= jwtDetail.exp * 1000) {
      if (refreshToken) {
        yield put(fetchOauthTokens(refreshToken || ''));
      } else {
        login();
      }
    }
  } catch (error) {
    login();
  }
}

export default function* validateAuthenticationTokenWatcher() {
  yield takeLatest(checkAuthToken.type, validateAuthenticationToken);
}
