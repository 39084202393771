import { rest } from 'msw';

import Config from 'src/config';
import getFactFinderBaseURL from 'src/utils/getters/getFactFinderBaseURL';
import getFactFinderChannel from 'src/utils/getters/getFactFinderChannel';

import { dailyTopDeals } from './data/dailyTopDeals';
import { monthlyTopDeals } from './data/monthlyTopDeals';
import { getRandomMockedProductSKUs } from './data/products';
import { weeklyTopDeals } from './data/weeklyTopDeals';


export const handlers = [

  rest.get(`${getFactFinderBaseURL()}/search/${getFactFinderChannel()}`, async (req, res, ctx) => {
    const filters = req.url.searchParams.getAll('filter');
    let deals = {};
    if (filters.length === 0) {
      const originalResponse = await ctx.fetch(req);
      const data = await originalResponse.json();
      return res(
        ctx.status(200),
        ctx.set('Content-Type', 'application/json'),
        ctx.json(data),
      );
    }
    if (filters[0] === 'Salesdrivers:Angebot des Tages') {
      deals = dailyTopDeals;
    } else if (filters[0] === 'Salesdrivers:Joker der Woche') {
      deals = weeklyTopDeals;
    } else if (filters[0] === 'Salesdrivers:Produkt des Monats') {
      deals = monthlyTopDeals;
    }

    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json(deals),
    );
  }),
  rest.get(`${Config.env.offers}/:channel`, (req, res, ctx) => {

    const { channel } = req.params;
    return res(
      ctx.status(200),
      ctx.set('Content-Type', 'application/json'),
      ctx.json({
        product_nos: getRandomMockedProductSKUs(20),
        tv_channel: channel,
      }),
    );
  }),
];
