import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { logErrorEvent } from 'src/logging/loggingActions';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { salesOfficeSelector } from 'src/redux/order/selectors/salesOfficeSelectors';
import {
  CreateWaitlistItem,
  createWaitlistItem,
  createWaitlistItemFailed,
  CreateWaitlistItemState,
  createWaitlistItemSuccess,
} from 'src/redux/product/createWaitlistItemSlice';
import { createWaitlistItemSelector } from 'src/redux/product/selectors/createWaitlistItemSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer } from 'src/types/customer/customer';
import { buildWaitlistItemRequest } from 'src/utils/buildWaitlistItemRequest';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


const { getSalesChannel } = salesChannelSelector;
const { getSalesOffice } = salesOfficeSelector;

export function* createWaitlistItemSaga(action: CreateWaitlistItem) {
  const customerInfo = yield select(customerInfoSelectors.getCustomerInfo);
  const deliveryAddress = yield select(customerInfoSelectors.getSelectedDeliveryAddress);
  const customer: Customer = customerInfo.customerResponse;
  const createWaitlistItemParams: CreateWaitlistItemState = yield select(createWaitlistItemSelector.getParams);

  try {
    if (createWaitlistItemParams.lastLoading) {
      yield put(logErrorEvent({ message: 'sent same create waitlist item request multiple times :warning:' }));
    }
    const payment = createWaitlistItemParams.selectedPaymentMethod;
    const bankingDetails = yield select(orderEntrySelector.getCustomerBankingDetailsState);
    const salesChannel = yield select(getSalesChannel);
    const salesOffice = yield select(getSalesOffice);
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    if (payment !== undefined) {
      yield call(apiCallCenter.createWaitlistItem, buildWaitlistItemRequest(
        customerInfo,
        createWaitlistItemParams,
        payment,
        salesChannel,
        salesOffice,
        bankingDetails,
        action.payload,
        deliveryAddress,
      ));

      yield put(createWaitlistItemSuccess());
    } else {
      yield call(handelCreateWaitlistItemFailed,'payment is undefined in waitlist state',customer,createWaitlistItemParams);

    }
  } catch (err) {
    yield call(handelCreateWaitlistItemFailed,err,customer,createWaitlistItemParams);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handelCreateWaitlistItemFailed(err: any, customer: Customer, createWaitlistItemParams: CreateWaitlistItemState) {
  yield call(
    logErrorAndShowNotification,
    `Couldn't create waitlist item for customer ${customer.id}, createWaitlistItemParams: ${JSON.stringify(createWaitlistItemParams)}`,
    'Hoppla... Fehler beim Erstellen der Wartelistenreservierung',
    err,
  );
  yield put(createWaitlistItemFailed());
}


export default function* createWaitlistItemWatcher() {
  yield takeLatest(createWaitlistItem.type, createWaitlistItemSaga);
}
