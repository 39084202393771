import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { showSuccessNotification } from 'src/redux/app/appSlice';
import { submitVoucher, submitVoucherSuccess } from 'src/redux/order/voucherSlice';
import { resetServiceVoucher } from 'src/redux/serviceVoucher/serviceVoucherActions';

import { checkAndResetPayment, getOfferAndStoreBasket as getOfferSaga } from '../offer/getOffer';


export function* submitVoucherSaga() {
  try {
    const offer = yield call(getOfferSaga);
    const voucher = offer?.response?.offer?.voucher;

    if (voucher) {
      yield put(
        submitVoucherSuccess(voucher),
      );
      if (yield call(checkAndResetPayment)) {
        yield call(getOfferSaga);
      }
      yield put(showSuccessNotification('order.voucher.submited'));
    } else {
      yield put(resetServiceVoucher());
    }
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not redeem voucher', err }));
  }
}


export default function* voucherWatcher() {
  yield takeLatest(submitVoucher.type, submitVoucherSaga);
}
