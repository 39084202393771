import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { resetCustomer, deleteDeliveryAddress } from 'src/redux/customer/customerInfoSlice';
import { resetCustomerPinState } from 'src/redux/customer/CustomerPinSlice';
import { clearRedeemedVouchers } from 'src/redux/order/voucherSlice';
import { resetPayment } from 'src/redux/payment/paymentSlice';

import { getOfferIfOrderLineItemsExist } from '../offer/getOffer';


export function* resetCustomerSaga() {
  try {
    yield put(resetCustomerPinState());
    yield put(resetPayment());
    yield put(deleteDeliveryAddress());
    yield put(clearRedeemedVouchers());
    yield call(getOfferIfOrderLineItemsExist);
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not reset customer', err }));
  }
}

export default function* resetCustomerWatcher() {
  yield takeEvery(resetCustomer.type, resetCustomerSaga);
}
