import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';


export interface PreviewLetterPayload {
  fileName: string;
}

export interface PreviewLetterState {
  fileContent: Blob | undefined;
}

export const fetchPreviewLetter = createAction<PreviewLetterPayload>('previewLetter/FETCH_PREVIEW_LETTER');
export type PreviewLetterAction = ReturnType<typeof fetchPreviewLetter>

export const previewLetterStateInitialState: PreviewLetterState = {
  fileContent: undefined,
};

const previewLetterSlice = createSlice({
  name: 'previewLetter',
  initialState: previewLetterStateInitialState,
  reducers: {
    fetchPreviewLetterSuccess(state, action: PayloadAction<Blob>) {
      state.fileContent = action.payload;
    },
  },
});

export const { fetchPreviewLetterSuccess } = previewLetterSlice.actions;

export const previewLetterReducer = previewLetterSlice.reducer;


