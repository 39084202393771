import { put, select } from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { appSelectors } from 'src/redux/app/selectors/appSelector';


export function* logRequestTime(functionName: string, requestStartTime: Date) {
  const agentUsername = yield select(appSelectors.getAgentUsername);

  const now = new Date();
  const diff = now.getTime() - requestStartTime.getTime();

  if(diff > 8000) {
    yield put(logEvent(
      {
        level: LOG_LEVEL.INFO,
        message: `${functionName} execution time`,
        args: {
          requestStartTime: requestStartTime,
          requestEndTime: now,
          userAgentName: agentUsername,
        },
      },
    ));
  }
}
