import { Customer } from 'src/types/customer/customer';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';


export function shouldStorePreferredPaymentMethod(customer: Customer) {
  if (!customer.preferredPaymentMethod) {
    return false;
  }

  if ([PaymentMethod.paypal, PaymentMethod.creditCard].includes(customer.preferredPaymentMethod)) {
    return false;
  }

  if (
    [
      PaymentMethod.installments,
      PaymentMethod.installmentsFactoring,
      PaymentMethod.directDebit,
    ].includes(customer.preferredPaymentMethod)
  ) {
    return !!customer.bankingDetails;
  }

  return true;
}
