export default {

  // Cancellation modal

  'subscription.cancellation.title': 'Treue Abo kündigen',
  'subscription.cancellation.selectReason': 'Einen Stornogrund auswählen',
  'subscription.cancellation.question': 'Warum möchten Sie das Abonnement kündigen?',
  'subscription.cancellation.submitCancellation': 'Abonnement stornieren',

  'subscription.cancellation.reason.ANIMAL_DIED': 'Tier verstorben',
  'subscription.cancellation.reason.INCOMPATIBILITY': 'Unverträglichkeit',
  'subscription.cancellation.reason.COLLECTION': 'Inkasso',
  'subscription.cancellation.reason.QUANTITY_BECAME_TOO_MUCH': 'Menge zu viel geworden',
  'subscription.cancellation.reason.CUSTOMER_DIDNT_WANT_SUBSCRIPTION': 'Kunde wollte gar kein Abo',
  'subscription.cancellation.reason.PRODUCT_REGULARLY_OFFERED_CHEAPER': 'Produkt regulär günstiger angeboten',
  'subscription.cancellation.reason.NO_MORE_NEED': 'kein Bedarf mehr',
  'subscription.cancellation.reason.NO_INDICATION_OF_REASON': 'keine Angabe von Gründen',
  'subscription.cancellation.reason.DOUBLE_SUBSCRIPTION': 'Doppeltes Abo',
  'subscription.cancellation.reason.WRONG_SUBSCRIPTION': 'Falscher Abo Artikel',
  'subscription.cancellation.reason.ORDER_RESTRICTION': 'Auftragsbeschränkung',
  'subscription.cancellation.reason.NO_LONGER_ON_OFFER': 'nicht mehr im Angebot',
  'subscription.cancellation.reason.HSE_DECISION': 'HSE Entscheidung',
  'subscription.cancellation.reason.SUBSCRIPTION_EXPIRED': 'Abo abgelaufen',
  'subscription.cancellation.reason.VARIANT_CHANGE': 'Variantenwechsel',
  'subscription.cancellation.reason.PRICE_INCREASE': 'Preiserhöhung',

  //Subscription history
  'subscription.history.title': 'Treue-Abo',
  'subscription.history.header.rejection.reason': 'Absagegrund',
  'subscription.history.header.item.description': 'Artikelbezeichnung',
  'subscription.history.header.price': 'Einzelpreis',
  'subscription.history.header.next_delivery': 'Nächste Lieferung',
  'subscription.history.header.product_id': 'Liefer BNR',
  'subscription.history.header.contract_no': 'Vertrags-Nr.',
  'subscription.history': 'Historie',
  'subscription.history.edit': 'Bearbeiten',
  'subscription.history.cancel': 'Stornieren',

  'subscription.history.status.ACTIVE': 'Aktiv',
  'subscription.history.status.CANCELED': 'Gekündigt',
  'subscription.history.status.WARNING': 'Warnung',

  'subscription.history.delivery.failed': 'Lieferung der Bestellung fehlgeschlagen',

  //edit subscription
  'subscription.history.confirmationTitle': 'Bestätigung Treue-Abo',
  'subscription.history.rejection.reason': 'Fehlermeldung',

  'subscription.history.orderNumber': 'Auftragsnummer',
  'subscription.history.orderQuantity': 'Bestellmenge',
  'subscription.history.unitPrice': 'Einzelpreis',
  'subscription.history.nextOrder': 'Termin nächste',
  'subscription.history.rotation': 'Turnus',
  'subscription.history.startIn': 'Termin nächste / vorh. Anlage',
};
