import googleTagManager from '@redux-beacon/google-tag-manager';
import { createMiddleware, EventDefinition } from 'redux-beacon';

import { AppActionTypes } from 'src/redux/app/appSlice';
import { fetchCustomer } from 'src/redux/customer/customerInfoSlice';
import { newOrderSuccess } from 'src/redux/order/newOrderSlice';
import {
  trackOrderEvent as tranckOrderEventAction,
  changeQuantity,
  addOrderItemBySku,
  deleteOrderItem,
  trackRemoveProductFromCartEvent,
  trackAddProductToCartEvent,
} from 'src/redux/order/orderEntrySlice';
import { trackWaitListImpression, trackWaitListReservation } from 'src/redux/product/createWaitlistItemSlice';
import {
  trackProductImpressionsEvent as trackProductImpressionsEventAction,
  trackProductDetailsViews,
  trackProductClick,
  trackRowClick,
  trackOfferProductImpression,
  trackProductRowImpression,
} from 'src/redux/product/productDetailsSlice';
import { fetchProductSearchResults } from 'src/redux/product/productSearchSlice';
import { trackStockReminderPopupImpression, trackStockReminderSetReminder } from 'src/redux/product/stockReminderSlice';

import {
  orderConfirmationEvent,
  changeQuantityEvent,
  addOrderItemToBasketEvent,
  deleteOrderItemFromBasketEvent,
  trackCustomerSearch,
  trackProductSearch,
  trackOrderEvent,
  trackUserLogins,
  trackUserSessions,
  trackProductClickEvent,
  trackRowClickEvent,
  trackRemoveProductFromCart,
  trackAddProductToCart,
  trackProductImpressionsEvent,
  trackProductDetailsViewsEvent,
  trackOfferProductImpressionEvent,
  trackProductRowImpressionEvent,
  trackWaitListImpressionEvent,
  trackWaitListReservationEvent,
  trackStockReminderPopupImpressionEvent,
  trackStockReminderSetReminderEvent,
  trackUserFailureLogins
} from './events';


export const eventsMap: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: EventDefinition<any, any, any>;
} = {
  [newOrderSuccess.type]: orderConfirmationEvent,
  [changeQuantity.type]: changeQuantityEvent,
  [addOrderItemBySku.type]: addOrderItemToBasketEvent,
  [deleteOrderItem.type]: deleteOrderItemFromBasketEvent,
  [fetchCustomer.type]: trackCustomerSearch,
  [fetchProductSearchResults.type]: trackProductSearch,
  [tranckOrderEventAction.type]: trackOrderEvent,
  [AppActionTypes.TRACK_USER_LOGIN_EVENT]: trackUserLogins,
  [AppActionTypes.TRACK_USER_FAILURE_LOGINS_EVENT]: trackUserFailureLogins,
  [AppActionTypes.TRACK_USER_SESSION_EVENT]: trackUserSessions,
  [trackProductClick.type]: trackProductClickEvent,
  [trackRowClick.type]: trackRowClickEvent,
  [trackRemoveProductFromCartEvent.type]: trackRemoveProductFromCart,
  [trackAddProductToCartEvent.type]: trackAddProductToCart,
  [trackProductImpressionsEventAction.type]: trackProductImpressionsEvent,
  [trackProductDetailsViews.type]: trackProductDetailsViewsEvent,
  [trackOfferProductImpression.type]: trackOfferProductImpressionEvent,
  [trackProductRowImpression.type]: trackProductRowImpressionEvent,
  [trackWaitListImpression.type]: trackWaitListImpressionEvent,
  [trackWaitListReservation.type]: trackWaitListReservationEvent,
  [trackStockReminderPopupImpression.type]: trackStockReminderPopupImpressionEvent,
  [trackStockReminderSetReminder.type]: trackStockReminderSetReminderEvent,
};

const createReduxBeaconMiddleware = () => createMiddleware(eventsMap, googleTagManager());

export default createReduxBeaconMiddleware;
