import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  DEFAULT_SERVICES_DATE_FORMAT,
  DISPLAY_DATETIME_FORMAT,
} from 'src/constants/date';


dayjs.extend(customParseFormat);

export function fromDisplayFormatToISO8601(dateOfBirthInGermanFormat: string): string | undefined {
  const dateInGermanFormat = dayjs(dateOfBirthInGermanFormat, DEFAULT_DISPLAY_DATE_FORMAT, true);
  if (!dateInGermanFormat.isValid()) {
    return undefined;
  }

  return dateInGermanFormat.format(DEFAULT_SERVICES_DATE_FORMAT);
}

export function fromDisplayFormatToISO8601WithTime(dateOfBirthInGermanFormat: string): string | undefined {
  const dateInGermanFormat = dayjs(dateOfBirthInGermanFormat, DEFAULT_DISPLAY_DATE_FORMAT, true);
  if (!dateInGermanFormat.isValid()) {
    return undefined;
  }
  return dateInGermanFormat.format(DISPLAY_DATETIME_FORMAT);
}

export default fromDisplayFormatToISO8601;
