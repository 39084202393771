import { InflightOrderCustomer } from 'src/types/inflightOrder/InflightOrderCustomer';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';


export interface InflightOrderRequest {
    basketId?: string;
    baseProductNo?: string;
    customer?: InflightOrderCustomer;
    voucherCode?: string;
    paymentMethod?: PaymentMethod;
    step?: Steps;
}

export enum Steps {
    INVALID_BASE_PRODUCT_NUMBER_SELECTION = 'INVALID_BASE_PRODUCT_NUMBER_SELECTION',
    INVALID_PRODUCT_VARIANT_SELECTION = 'INVALID_PRODUCT_VARIANT_SELECTION',
    INVALID_CROSS_SELLING = 'INVALID_CROSS_SELLING',
    INVALID_AUTHENTICATION = 'INVALID_AUTHENTICATION',
    INVALID_VOUCHER_SELECTION = 'INVALID_VOUCHER_SELECTION',
    INVALID_ADDRESS_SELECTION = 'INVALID_ADDRESS_SELECTION',
    INVALID_PAYMENT_METHOD_SELECTION = 'INVALID_PAYMENT_METHOD_SELECTION',
    INVALID_ORDER = 'INVALID_ORDER',
    WEEE_TAKEBACK_HANDOVER = 'WEEE_TAKEBACK_HANDOVER',
    BASKET_NOT_FOUND = 'BASKET_NOT_FOUND'
}
