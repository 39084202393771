import { AxiosInstance } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { Endpoints } from 'src/constants/endpoints';
import { RedeemedVoucherResponse } from 'src/types/voucher/RedeemedVoucherResponse';
import { VerifyVoucherRequest } from 'src/types/voucher/VoucherRequest';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


export interface ApiVoucher {
  client: AxiosInstance;
  verifyVoucherRedemption: (voucherRequest: VerifyVoucherRequest) => Promise<string>
  getRedeemedVouchersHistory: (externalCustomerId: string) => Promise<RedeemedVoucherResponse>
}

export function getApiVoucher(baseURL: string): ApiVoucher {
  const voucherClient = getHttpClient(
    {
      baseUrl: baseURL,
      headers: { 'Content-Type': 'application/json' },
      interceptors: {
        response: refreshTokenInterceptor,
        request: [
          localeHeaderInterceptor,
          xAuthorizationHeaderInterceptor,
        ],
      },
    });


  return {
    client: voucherClient,
    verifyVoucherRedemption: async (voucherRequest: VerifyVoucherRequest) => {
      const result = await voucherClient.post(`${Endpoints.verifyVoucher}`, decamelizeKeys(voucherRequest));
      return result.data as string;
    },
    getRedeemedVouchersHistory: async (externalCustomerId: string) => {
      const result = await voucherClient.get<RedeemedVoucherResponse>(`${Endpoints.redeemedVouchersHistory}/${externalCustomerId}`);
      return camelizeKeys(result.data) as RedeemedVoucherResponse;
    },
  };
}
