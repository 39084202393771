import { takeEvery, put } from 'redux-saga/effects';

import { openModal, closeModal } from 'src/redux/app/appSlice';
import { productSearchOpened, ProductSearchOpened } from 'src/redux/product/productSearchSlice';
import { Modals } from 'src/types/Modals';


export function* productSearchOpenedSaga(action: ProductSearchOpened) {
  const open = action.payload.open;
  if(open) {
    yield put(openModal(Modals.productSearch));
  }
  else {
    yield put(closeModal(Modals.productSearch));
  }
}

export default function* productSearchOpenedWatcher() {
  yield takeEvery(productSearchOpened, productSearchOpenedSaga);
}
