import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  BankingDetails,
  BankingDetailsState,
  BankingInfo,
  InstallmentPlan,
  Payment,
} from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { PaymentOption } from 'src/types/offer/PaymentOption';


export const DEFAULT_PAYMENTS = [
  { enabled: true, method: PaymentMethod.invoice },
  { enabled: true, method: PaymentMethod.directDebit },
  { enabled: true, method: PaymentMethod.cashOnDelivery },
  { enabled: false, method: PaymentMethod.installments },
  { enabled: false, method: PaymentMethod.installmentsFactoring },
];

export const DEFAULT_CH_PAYMENT_OPTIONS: PaymentOption[] = [
  { method: PaymentMethod.invoice, enabled: true }
];

export enum InstallmentModalSteps {
  CHOOSE_PLAN = 'CHOOSE_PLAN',
  ENTER_BANKING_INFO = 'ENTER_BANKING_INFO',
}

export enum InstallmentFactoringModalSteps {
  CHOOSE_PLAN = 'CHOOSE_PLAN',
  ENTER_BANKING_INFO = 'ENTER_BANKING_INFO',
  ACCEPT_INSTALLMENT_FACTORING_PAYMENT_CONDITIONS = 'ACCEPT_INSTALLMENT_FACTORING_PAYMENT_CONDITIONS'
}

export enum InstallmentFactoringModalMode {
  NORMAL_MODE = 'NORMAL_MODE',
  EDIT_MODE = 'EDIT_MODE'
}

export interface InstallmentsModalState {
  installmentPlans?: InstallmentPlan[];
  currentInstallmentPlan?: InstallmentPlan;
  dueDay: number;
  modalStep: InstallmentModalSteps;
  changedDueDay?: number
}

export interface InstallmentsFactoringModalState {
  installmentPlans?: InstallmentPlan[];
  currentInstallmentPlan?: InstallmentPlan;
  modalStep: InstallmentFactoringModalSteps;
  calculationId?: string;
  channel?: string;
  conditionsConsent?: boolean;
  installmentFactoringModalMode: InstallmentFactoringModalMode;
}

export interface FactoringInstallmentsModalState {
  installmentPlans?: InstallmentPlan[];
  currentInstallmentPlan?: InstallmentPlan;
  modalStep: InstallmentFactoringModalSteps;
  calculationId?: string;
  channel?: string;
  conditionsConsent: boolean;
  installmentFactoringModalMode: InstallmentFactoringModalMode;
}

export interface PaymentState {
  error: string | null;
  loading: boolean;
  selectedPaymentMethod?: PaymentMethod;
  availablePaymentMethods: PaymentOption[];
  bankingInfo: BankingInfo;
  prevBankingInfo?: BankingInfo;
  loadingPrefilledIban: boolean;
  installments: InstallmentsModalState;
  installmentsFactoring: InstallmentsFactoringModalState;
  preferredPaymentMethod?: PaymentMethod;
  preferredPaymentLoading?: boolean;
  warnings: PaymentWarningMap;
  reklaZeroPercentFinancing?: boolean;
}

export interface CustomerBankingDetailsState {
  iban?: string,
  accountHolder: string,
  bankName: string,
  usesPrefilledIban: boolean,
  isExisted: boolean,
  ibanValid: boolean,
  firstTime?: boolean,
  ibanError?: string,
}

interface PaymentWarning {
  method: PaymentMethod,
  warning?: string
}

interface PaymentWarningMap {
  [method: string]: string | undefined;
}

export const paymentInitialState: PaymentState = {
  error: null,
  loading: false,
  availablePaymentMethods: [],
  bankingInfo: {
    iban: '',
    accountHolder: '',
    bankName: '',
    ibanValid: false,
    firstTime: true,
    usesPrefilledIban: BankingDetailsState.initial,
  },
  loadingPrefilledIban: false,
  installments: {
    dueDay: 1,
    modalStep: InstallmentModalSteps.CHOOSE_PLAN,
  },
  installmentsFactoring: {
    modalStep: InstallmentFactoringModalSteps.CHOOSE_PLAN,
    conditionsConsent: false,
    installmentFactoringModalMode: InstallmentFactoringModalMode.NORMAL_MODE,
  },
  reklaZeroPercentFinancing: false,
  warnings: {},
};

export type AvailabalePaymentMethodPayload = {
  payments: PaymentOption[],
  skipGetOffer?: boolean
}

export const setAvailablePaymentMethods = createAction(
  'payment/setAvailablePaymentMethods',
  (payments: PaymentOption[], skipGetOffer?: boolean) => ({ payload: { payments, skipGetOffer } }),
);

export const onClosingInstallmentFactoringModal = createAction('payment/onClosingInstallmentFactoringModal');

const paymentSlice = createSlice({
  name: 'payment',
  reducers: {
    changeIBAN(state, { payload }: PayloadAction<string>) {
      state.bankingInfo.iban = payload;
      state.bankingInfo.bankName = '';
      state.bankingInfo.ibanValid = false;
    },
    changeAccountHolder(state, { payload }: PayloadAction<string>) {
      state.bankingInfo.accountHolder = payload;
    },
    validateIBAN(state) {
      return state;
    },
    validateIBANInProgress(state) {
      state.loading = true;
    },
    validateIBANSuccess(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.bankingInfo.bankName = payload;
      state.bankingInfo.ibanValid = true;
    },
    validateIBANFailure(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.error = payload;
    },
    getBankingDetailsInProgress(state) {
      state.loadingPrefilledIban = true;
    },
    getBankingDetailsSuccess(state, { payload }: PayloadAction<BankingDetails>) {
      state.loadingPrefilledIban = false;
      state.bankingInfo.iban = payload.bankingDetails.maskedIban;
      state.bankingInfo.bankName = payload.bankingDetails.bankName;
      state.bankingInfo.accountHolder = payload.bankingDetails.accountHolder;
      state.bankingInfo.ibanValid = true;
      state.bankingInfo.firstTime = false;
      state.bankingInfo.usesPrefilledIban = BankingDetailsState.prefilled;
    },
    getBankingDetailsFailure(state) {
      state.loadingPrefilledIban = false;
      state.bankingInfo.usesPrefilledIban = BankingDetailsState.noBankingDetails;
    },
    setSelectedPaymentMethod(state, { payload }: PayloadAction<PaymentMethod | undefined>) {
      state.selectedPaymentMethod = payload;
      state.warnings = {};
    },
    storeSelectedPaymentMethod(state, { payload }: PayloadAction<PaymentMethod | undefined>) {
      state.selectedPaymentMethod = payload;
    },
    submitBankInfo(state, { payload }: PayloadAction<string>) {
      state.selectedPaymentMethod = PaymentMethod.directDebit;
      state.bankingInfo.accountHolder = payload;
    },
    resetPayment() {
      return paymentInitialState;
    },
    resetChEntryPayments(state) {
      state.availablePaymentMethods = DEFAULT_CH_PAYMENT_OPTIONS;
      state.selectedPaymentMethod = PaymentMethod.invoice;
    },
    resetSelectedPaymentMethod(state) {
      state.selectedPaymentMethod = undefined;
    },
    unselectInstallmentsPaymentMethod(state) {
      if (state.selectedPaymentMethod === PaymentMethod.installments) {
        state.selectedPaymentMethod = undefined;
      }
    },
    resetBankInfo(state) {
      if (state.prevBankingInfo) {
        state.bankingInfo = state.prevBankingInfo;
      } else {
        state.bankingInfo = paymentInitialState.bankingInfo;
      }
      state.preferredPaymentLoading = false;
    },
    saveBankInfo(state) {
      state.prevBankingInfo = state.bankingInfo;
    },
    changePrefilledBankingDetails(state) {
      state.bankingInfo.usesPrefilledIban = BankingDetailsState.prefillChanged;
    },
    getInstallmentPlans(state) {
      state.installments = paymentInitialState.installments;
      state.loading = true;
    },
    getInstallmentPlansSuccess(state, { payload }: PayloadAction<InstallmentPlan[]>) {
      state.installments.installmentPlans = payload;
      state.loading = false;
    },
    getInstallmentPlansFailure(state) {
      state.loading = false;
      state.installments.currentInstallmentPlan = undefined;
      state.installments.installmentPlans = undefined;
      state.selectedPaymentMethod = undefined;
    },
    changeInstallmentPlan(state, { payload }: PayloadAction<InstallmentPlan>) {
      state.installments.currentInstallmentPlan = payload;
    },
    submitInstallmentPlan(state) {
      return state;
    },
    setAndSubmitInstallmentFactoringPlan(state, { payload }: PayloadAction<InstallmentPlan>) {
      state.installmentsFactoring.currentInstallmentPlan = payload;
      state.installmentsFactoring.modalStep = InstallmentFactoringModalSteps.ENTER_BANKING_INFO;
    },
    changeInstallmentModalStep(state, { payload }: PayloadAction<InstallmentModalSteps>) {
      state.installments.modalStep = payload;
    },
    changeInstallmentDueDay(state, { payload }: PayloadAction<number>) {
      state.installments.dueDay = payload;
    },
    submitInstallmentBankInfo(state) {
      state.bankingInfo.usesPrefilledIban = BankingDetailsState.prefilled;
      state.selectedPaymentMethod = PaymentMethod.installments;
    },
    resetInstallmentPayment(state) {
      state.installments = paymentInitialState.installments;
    },
    setChangedDuaDay(state, { payload }: PayloadAction<number | undefined>) {
      state.installments.changedDueDay = payload;
    },
    setPreferredPaymentStatus(state, { payload }: PayloadAction<boolean>) {
      state.preferredPaymentLoading = payload;
    },
    submitPreferredPaymentBankInfo(state, { payload }: PayloadAction<string>) {
      state.preferredPaymentMethod = PaymentMethod.directDebit;
      state.bankingInfo.accountHolder = payload;
      state.preferredPaymentLoading = false;
    },
    savePaymentData(state, { payload }: PayloadAction<Payment>) {
      state.selectedPaymentMethod = payload.method;
    },
    storeAvailablePaymentMethods(state, { payload }: PayloadAction<PaymentOption[]>) {
      state.availablePaymentMethods = payload;
    },
    unselectInstallmentsFactoringPaymentMethod(state) {
      if (state.selectedPaymentMethod === PaymentMethod.installmentsFactoring) {
        state.selectedPaymentMethod = undefined;
      }
    },
    getInstallmentFactoringPlans(state) {
      state.installmentsFactoring = paymentInitialState.installmentsFactoring;
      state.loading = true;
    },
    getInstallmentFactoringPlansSuccess(state, { payload }: PayloadAction<InstallmentPlan[]>) {
      state.installmentsFactoring.installmentPlans = payload;
      state.loading = false;
    },
    getInstallmentFactoringPlansFailure(state) {
      state.loading = false;
      state.installmentsFactoring = paymentInitialState.installmentsFactoring;
      state.selectedPaymentMethod = undefined;
    },
    submitInstallmentFactoringPlan(state) {
      return state;
    },
    changeInstallmentFactoringModalStep(state, { payload }: PayloadAction<InstallmentFactoringModalSteps>) {
      state.installmentsFactoring.modalStep = payload;
    },
    changeInstallmentFactoringCalculationId(state, { payload }: PayloadAction<string | undefined>) {
      state.installmentsFactoring.calculationId = payload;
    },
    changeInstallmentFactoringChannel(state, { payload }: PayloadAction<string | undefined>) {
      state.installmentsFactoring.channel = payload;
    },
    submitInstallmentFactoringBankInfo(state) {
      state.bankingInfo.usesPrefilledIban = BankingDetailsState.prefilled;
      state.selectedPaymentMethod = PaymentMethod.installmentsFactoring;
    },
    resetInstallmentFactoringPayment(state) {
      state.installmentsFactoring = paymentInitialState.installmentsFactoring;
    },
    setInstallmentFactoringConsent(state, { payload }: PayloadAction<boolean>) {
      state.installmentsFactoring.conditionsConsent = payload;
    },
    addPaymentWarnings(state, { payload }: PayloadAction<PaymentWarning>) {
      state.warnings[payload.method.toString()] = payload.warning;
    },
    changeInstallmentFactoringModalMode(state, { payload }: PayloadAction<InstallmentFactoringModalMode>) {
      state.installmentsFactoring.installmentFactoringModalMode = payload;
    },
    cancelInstallmentFactoringPaymentForm(state) {
      state.installmentsFactoring = paymentInitialState.installmentsFactoring;

      if (state.prevBankingInfo) {
        state.bankingInfo = state.prevBankingInfo;
      } else {
        state.bankingInfo = paymentInitialState.bankingInfo;
      }
      state.preferredPaymentLoading = false;
      state.selectedPaymentMethod = undefined;
      if (state.installmentsFactoring.calculationId !== undefined) {
        state.installmentsFactoring.calculationId = undefined;
      }
      if (state.installmentsFactoring.channel !== undefined) {
        state.installmentsFactoring.channel = undefined;
      }
    },
    toggleReklaZeroPercentFinancingFlag(state, { payload }: PayloadAction<boolean>) {
      state.reklaZeroPercentFinancing = payload;
    }
  },
  extraReducers: builder => {
    builder .addCase( setAvailablePaymentMethods,(state, { payload: { payments } }: PayloadAction<{ payments: PaymentOption[] }>) => {
      state.availablePaymentMethods = payments;
    });
  },
  initialState: paymentInitialState,
});

export const {
  changeIBAN,
  changeAccountHolder,
  validateIBAN,
  validateIBANInProgress,
  validateIBANSuccess,
  validateIBANFailure,
  getBankingDetailsInProgress,
  getBankingDetailsSuccess,
  getBankingDetailsFailure,
  setSelectedPaymentMethod,
  submitBankInfo,
  resetPayment,
  resetSelectedPaymentMethod,
  storeSelectedPaymentMethod,
  resetBankInfo,
  saveBankInfo,
  changePrefilledBankingDetails,
  getInstallmentPlans,
  getInstallmentPlansSuccess,
  getInstallmentPlansFailure,
  changeInstallmentPlan,
  submitInstallmentPlan,
  setAndSubmitInstallmentFactoringPlan,
  changeInstallmentModalStep,
  changeInstallmentDueDay,
  submitInstallmentBankInfo,
  resetInstallmentPayment,
  setChangedDuaDay,
  submitPreferredPaymentBankInfo,
  setPreferredPaymentStatus,
  unselectInstallmentsPaymentMethod,
  savePaymentData,
  storeAvailablePaymentMethods,
  unselectInstallmentsFactoringPaymentMethod,
  getInstallmentFactoringPlans,
  getInstallmentFactoringPlansSuccess,
  getInstallmentFactoringPlansFailure,
  submitInstallmentFactoringPlan,
  changeInstallmentFactoringModalStep,
  changeInstallmentFactoringCalculationId,
  changeInstallmentFactoringChannel,
  submitInstallmentFactoringBankInfo,
  resetInstallmentFactoringPayment,
  setInstallmentFactoringConsent,
  addPaymentWarnings,
  changeInstallmentFactoringModalMode,
  cancelInstallmentFactoringPaymentForm,
  resetChEntryPayments,
  toggleReklaZeroPercentFinancingFlag
} = paymentSlice.actions;

export default paymentSlice.reducer;
