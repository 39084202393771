// HARDCODED NORMAL PRODUCTS-SUBSCRIPTIONS_NUMBERS (list not complete, we'll receive complete list later...)
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';


export const productsAndTheirSubscriptions: {[key: string]: string} = {
  '356379':'281524',
  '391258':'334483',
  '434958':'393043',
  '446619':'367689',
  '415361':'334482',
  '435377':'350601',
  '327841':'346862',
  '435105':'346863',
  '378879':'367681',
  '367683':'375985',
  '400479':'463074',
  '435304':'463074',
  '356877':'382974',
  '381313':'410551',
  '417610':'425710',
  '435575':'444444',
  '367133':'390745',
  '225313':'290953',
  '258778':'291051',
  '225315':'291052',
  '452881':'415887',
  '450263':'439993',
  '345680':'442877',
  '367299':'442879',
  '448734':'315255',
  '430257001':'315256',
  '430257006':'444445',
  '430257003':'315258',
  '454289':'314128',
  '339553':'442878',
  '336999':'442878',
  '455597':'314128',
  '454594':'314128',
  '462903':'314128',
  '463859':'314128',
  '460871':'314128',
  '462903001':	'314128',
  '462903002':	'397925',
  '462903003':	'397925',
  '462903004':	'397925',

  '463859001':	'314128',
  '463859002':	'397925',

  '460871001':	'314128',
  '460871002':	'314128',
  
  '465221': '442878',
  '465263': '442877'
  // need to check the following cases, because they are two possible subscriptions for same product
  // '455597':'397925',
  // '454594':'397925',
};

export const productSubscriptionsNumbers = Object.values(productsAndTheirSubscriptions);

export const subscriptionsDeliveryProducts = [
  '264277',
  '264278',
  '264279',
  '393451',
  '462811',
  '462810',
  '471968',
  '415373',
  '435486',
  '365137',
  '471931',
  '391722',
  '462829',
  '462825',
  '409374',
  '471966',
  '471966',
  '380213',
  '423653',
  '472000',
  '444760',
  '382988',
  '290950',
  '290952',
  '290951',
  '453378',
  '453379',
  '439614',
  '465294',
  '465231',
  '442632',
  '449590',
  '439730',
];

export const isSubscriptionsDeliveryProductByBpno = (bpno: string) => !!subscriptionsDeliveryProducts.find(it => toBaseProductNumber(bpno) === it);
