import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  delay,
  getContext,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { showErrorNotification, showSuccessNotification } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import {
  addNewProduct,
  addNewProductSuccess,
  cancelProductSuccess,
  updatePayment,
  editOrderFailure,
  redeemVoucherSuccess,
  removeProductSuccess,
  removeVoucherSuccess,
  resetPayment,
  setNewItems,
  updateProductSuccess,
  updateInstallmentPlan,
  updateProductPriceDateSuccess,
  updateInstallmentFactoringPlan,
} from 'src/redux/editOrder/editOrderSlice';
import { deleteBasketItemStatus, updateBasketItemStatus } from 'src/redux/order/orderEntrySlice';
import { fetchProduct } from 'src/redux/product/productDetailsSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { OfferItem } from 'src/types/offer/Offer';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId, EditOrderAddActionRequest } from 'src/types/orderhistory/EditOrderActionRequest';
import Log from 'src/utils/log';

import { setDeliveryTimesSaga } from './initEditOrder';
import { fetchUpsellsSaga } from '../orderEntry/fetchUpsells';


const { getOpenedModals } = appSelectors;

export function* addNewProductSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  const actionRequest = action.payload.actionRequest as EditOrderAddActionRequest;
  const uniqueID = actionRequest.id;

  try {

    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);

    yield put(updateBasketItemStatus({ id: uniqueID, status: 'in_progress' }));

    const response: EditedOffer = yield call(apiEditOrder.addPosition, action.payload);

    const offer = yield call(setDeliveryTimesSaga, response);

    yield put(updateBasketItemStatus({ id: uniqueID, status: 'success' }));
    yield delay(250);
    yield put(deleteBasketItemStatus(uniqueID));

    yield put(addNewProductSuccess(offer));
    yield put(showSuccessNotification('order.edit.productAdded'));

  } catch (error) {
    if (!!error.response?.data?.offer || error.response?.data?.title === 'RESERVATION_FAILURE') {
      const openedModals = yield select(getOpenedModals);
      const modalAlreadyOpened = openedModals.includes(Modals.productDetails) || openedModals.includes(Modals.productSearch);
      if (!modalAlreadyOpened) {
        yield put(fetchProduct({ sku: (action.payload.actionRequest as EditOrderAddActionRequest).sku }));
      }
    }
    yield put(editOrderFailure({
      title: error.response?.data?.title || '',
      detail: error.response?.data?.detail || '',
    }));

    yield put(updateBasketItemStatus({ id: uniqueID, status: 'error' }));
    yield delay(500);
    yield put(deleteBasketItemStatus(uniqueID));

    yield put(showErrorNotification(error.response?.data?.detail));
    yield handleFailureState(
      error,
      `Couldn't add item with sku ${actionRequest.sku} to basket and get offer on edit order actionRequest: `,
      actionRequest,
    );
  }
}

export function* getOfferNewItemsSaga(action: PayloadAction<EditedOffer>) {
  try {
    const { items = [] }: EditedOffer = action.payload;
    const newItems: OfferItem[] = [];
    const oldItems: OfferItem[] = [];

    items.map((item) => !item.originalQuantity ? newItems.push(item) : oldItems.push(item));
    yield put(setNewItems({ newItems, oldItems }));

  } catch (error) {
    yield call(Log.error, error);
  }
}

export function* totalPriceChangedSaga(action: PayloadAction<EditedOffer>) {
  try {
    const { totalPrice, payment }: EditedOffer = action.payload;

    if (totalPrice < 45 && payment?.method === PaymentMethod.installments) {
      yield put(updatePayment());
      yield put(resetPayment());
    } else if (payment?.method === PaymentMethod.installments) {
      yield put(updatePayment());
      yield put(updateInstallmentPlan(true));

    }

    if (totalPrice < 45 && payment?.method === PaymentMethod.installmentsFactoring) {
      yield put(updatePayment());
      yield put(resetPayment());
    } else if (payment?.method === PaymentMethod.installmentsFactoring) {
      yield put(updatePayment());
      yield put(updateInstallmentFactoringPlan(true));
    }

  } catch (error) {
    yield call(Log.error, error);
  }
}

export default function* addNewProductWatcher() {
  yield takeLatest(addNewProduct.type, addNewProductSaga);
  yield takeLatest(addNewProductSuccess.type, getOfferNewItemsSaga);
  yield takeLatest(updateProductSuccess.type, getOfferNewItemsSaga);
  yield takeLatest(cancelProductSuccess.type, getOfferNewItemsSaga);
  yield takeLatest(removeProductSuccess.type, getOfferNewItemsSaga);
  yield takeLatest(addNewProductSuccess.type, totalPriceChangedSaga);
  yield takeLatest(updateProductSuccess.type, totalPriceChangedSaga);
  yield takeLatest(cancelProductSuccess.type, totalPriceChangedSaga);
  yield takeLatest(removeProductSuccess.type, totalPriceChangedSaga);
  yield takeLatest(removeVoucherSuccess.type, totalPriceChangedSaga);
  yield takeLatest(redeemVoucherSuccess.type, totalPriceChangedSaga);
  yield takeLatest(updateProductPriceDateSuccess.type, getOfferNewItemsSaga);
  yield takeEvery(addNewProduct.type, fetchUpsellsSaga);
}
