export enum PaymentMethod {
  invoice = 'INVOICE',
  paypal = 'PAYPAL',
  directDebit = 'DIRECT_DEBIT',
  installments = 'INSTALLMENTS',
  installmentsFactoring = 'INSTALLMENTS_FACTORING',
  cashOnDelivery = 'CASH_ON_DELIVERY',
  creditCard = 'CREDIT_CARD',
  sofort = 'SOFORT',
}
