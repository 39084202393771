import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiSubscriptionsManagement } from 'src/api/apiSubscriptionsManagement';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { subscriptionManagementSelectors } from 'src/subscriptionProductManagement/redux/selectors/subscriptionManagementSelectors';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import {
  fetchSubscriptionChangesHistory,
  fetchSubscriptionChangesHistoryError,
  fetchSubscriptionChangesHistorySuccess,
  sortSubscriptionChangesHistory,
  SubscriptionChangesHistoryState,
} from '../redux/subscriptionManagementSlice';
import { createSubscriptionChangesHistoryRequest, DEFAULT_SUBSCRIPTION_CHANGES_HISTORY_SIZE } from '../types/request/SubscriptionChangesHistoryRequest';
import { SubscriptionChangesHistoryResponse } from '../types/response/SubscriptionChangesHistoryResponse';


export function* fetchSubscriptionChangesHistorySaga() {
  try {
    const subscriptionId: string = yield select(subscriptionManagementSelectors.getCurrentSubscriptionId);
    const { offset, sortingMode }: SubscriptionChangesHistoryState = yield select(
      subscriptionManagementSelectors.getCurrentSubscriptionChangesHistoryState,
    );
    const apiSubscriptionsManagement: ApiSubscriptionsManagement = yield getContext(
      SagaContextItem.apiSubscriptionsManagement,
    );

    const request = createSubscriptionChangesHistoryRequest(subscriptionId, offset, sortingMode);

    const changesHistory: SubscriptionChangesHistoryResponse = yield call(
      apiSubscriptionsManagement.getSubscriptionChangesHistory,
      request,
    );
    const { changes } = yield select(subscriptionManagementSelectors.getCurrentSubscriptionChangesHistoryState);

    yield put(
      fetchSubscriptionChangesHistorySuccess({
        response: {
          page: changesHistory.page,
          size: changesHistory.size,
          changes: changes.concat(changesHistory.changes),
        },
        hasMore: changesHistory.changes.length === DEFAULT_SUBSCRIPTION_CHANGES_HISTORY_SIZE,
      }),
    );
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'Oops... Error fetching subscription history.',
      'Hoppla... Fehler beim Abrufen des Abonnementverlaufs',
      err,
    );
    yield put(fetchSubscriptionChangesHistoryError());
  }
}

export function* fetchSubscriptionChangesHistoryWatcher() {
  yield takeLatest(fetchSubscriptionChangesHistory.type, fetchSubscriptionChangesHistorySaga);
  yield takeLatest(sortSubscriptionChangesHistory.type, fetchSubscriptionChangesHistorySaga);
}
