import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import dayjs from 'src/lib/datetime';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import {
  ChangePriceDateAction, 
  deleteBasketSuccess, 
  updateBasketItemStatus, 
  deleteBasketItemStatus, 
  changePriceDate,
} from 'src/redux/order/orderEntrySlice';
import { onGetBasketSaga } from 'src/sagas/orderEntry/onGetBasket';
import { showNotificationAndLogError } from 'src/sagas/orderEntry/showNotificationAndLogError';
import { updateBasketItem, UpdateBasketItemStatus } from 'src/sagas/orderEntry/updateBasketItemService';
import { getBasketId } from 'src/utils/basketId';


export function *onPriceDateChangeSaga(action: ChangePriceDateAction) {
  try {
    yield put(updateBasketItemStatus({ id: action.payload.id, status: 'in_progress' }));

    const date = dayjs(action.payload.priceDate).format('YYYY-MM-DDTHH:mm:ssZ');

    const updateItemStatus = yield call(updateBasketItem, action.payload.id, { priceDate: date });

    if(updateItemStatus === UpdateBasketItemStatus.BASKET_NOT_FOUND) {
      yield put(deleteBasketSuccess());
      return;
    }

    if(updateItemStatus !== UpdateBasketItemStatus.INVALID_PRICE_DATE) {
      yield call(onGetBasketSaga);
    }
  } catch (error) {
    yield put(
      logEvent({
        level: LOG_LEVEL.WARN,
        message: `Couldn't update item price date (itemId=${action.payload.id}, date=${action.payload.priceDate}, basketId=${getBasketId()})`,
        err: error,
      }),
    );

    yield call(showNotificationAndLogError, error);
  } finally {
    yield put(deleteBasketItemStatus(action.payload.id));
  }
}

export default function *onPriceDateChangeWatcher() {
  yield takeEvery(changePriceDate.type, onPriceDateChangeSaga);
}
