import { createReducer } from '@reduxjs/toolkit';

import {
  changeServiceVoucher,
  resetServiceVoucher,
  setServiceVoucher,
} from './serviceVoucherActions';
import { serviceVoucherInitialState } from './serviceVoucherState';


const serviceVoucherReducer = createReducer(serviceVoucherInitialState, {
  [setServiceVoucher.type]: (state, { payload }) => {
    state.code = payload;
  },
  [changeServiceVoucher.type]: (state, { payload }) => {
    state.code = payload;
  },
  [resetServiceVoucher.type]: () => serviceVoucherInitialState,
});

export default serviceVoucherReducer;
