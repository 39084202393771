import { AxiosInstance } from 'axios';
import { camelizeKeys } from 'humps';

import { Endpoints } from 'src/constants/endpoints';
import { VoucherSuggestionsResponse } from 'src/types/voucher/SuggestedVoucherResponse';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


export interface SuggestedVouchersQueryParam {
  countryValidity?: string;
  salesChannel?: string;
  externalCustomerId?: string;
}

export interface ApiVirtualVoucher {
  client: AxiosInstance;
  getSuggestedVouchers: (suggestedVouchersQueryParam?: SuggestedVouchersQueryParam) => Promise<VoucherSuggestionsResponse>;
}

export function getApiVirtualVoucher(baseURL: string): ApiVirtualVoucher {
  const voucherClient = getHttpClient(
    {
      baseUrl: baseURL,
      headers: { 'Content-Type': 'application/json' },
      interceptors: {
        response: refreshTokenInterceptor,
        request: [
          localeHeaderInterceptor,
          xAuthorizationHeaderInterceptor,
        ],
      },
    });


  return {
    client: voucherClient,
    getSuggestedVouchers: async (suggestedVouchersQueryParam: SuggestedVouchersQueryParam = {}) => {

      const params = { ...suggestedVouchersQueryParam };

      const result = await voucherClient.get<VoucherSuggestionsResponse>(
        `${Endpoints.suggestedVouchers}`, { params });
      return camelizeKeys(result.data) as VoucherSuggestionsResponse;
    },
  };
}
