import { push } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { PrepareReklaOrder, prepareReklaOrder } from 'src/redux/order/orderEntrySlice';
import routes from 'src/routes/routes';
import { saveReklaOrderInfoInSession } from 'src/utils/reklaOrderUtils';


export function* prepareReklaOrderSaga({ payload }: PrepareReklaOrder) {
  try {
    saveReklaOrderInfoInSession(payload);
    yield put(push(routes.orderApp.path));
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not prepare the creation of rekla order ' }));
  }
}


export default function* prepareReklaOrderWatcher() {
  yield takeLatest(prepareReklaOrder.type, prepareReklaOrderSaga);
}
