import { decamelizeKeys } from 'humps';

import { Endpoints } from 'src/constants/endpoints';
import { CreateOrderResultResponse } from 'src/types/offer/CreateOrderResultResponse';
import { GuestOrderRequest } from 'src/types/offer/GuestOrderRequest';

import { appendLocaleAndXAuthHeaders, getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


export interface ApiGuestOrder {
  createGuestOrder: (order: GuestOrderRequest) => Promise<CreateOrderResultResponse>;
  createLoggedInOrder: (customerId: string, order: GuestOrderRequest) => Promise<CreateOrderResultResponse>;
}

export function getApiGuestOrder(baseURL: string): ApiGuestOrder {
  const guestOrderClient = getHttpClient({
    baseUrl: baseURL,
    transform: {
      camelize: true,
    },
    interceptors: {
      response: refreshTokenInterceptor,
      request: [
        localeHeaderInterceptor,
        xAuthorizationHeaderInterceptor,
      ],
    },
  });

  const loggedInClient = getHttpClient({
    baseUrl: baseURL,
    transform: {
      camelize: true,
    },
    interceptors: {
      response: refreshTokenInterceptor,
      request: [
        localeHeaderInterceptor,
        xAuthorizationHeaderInterceptor,
      ],
    },
  });

  loggedInClient.interceptors.request.use(appendLocaleAndXAuthHeaders);
  guestOrderClient.interceptors.request.use(appendLocaleAndXAuthHeaders);

  return {
    createLoggedInOrder: async function (customerId: string, order: GuestOrderRequest) {
      const response = await loggedInClient.post<CreateOrderResultResponse>(
        `/backoffice-orders/${customerId}`,
        decamelizeKeys(order),
      );
      return response.data;
    },
    createGuestOrder: async (order: GuestOrderRequest) => {
      const response = await guestOrderClient.post<CreateOrderResultResponse>(Endpoints.guestOrder, decamelizeKeys(order));
      return response.data;
    },
  };
}

