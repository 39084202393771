import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const recreateOrderSelectors = createSelectors({
  getRecreatedOriginalOrder(state: RootStateType) {
    return state.recreateOrder.originalOrder?.id;
  },
  getRecreateOrderIdSearchValue(state: RootStateType) {
    return state.recreateOrder?.recreateOrderIdSearchValue;
  },
});
