import { 
  call,
  put,
  select
} from 'redux-saga/effects';

import { fetchCustomerSuccess } from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { checkAndLoadLocalCustomerInfo } from 'src/sagas/customer/fetchCustomer';
import { findCustomerByExternalId } from 'src/sagas/customer/fetchCustomerByNumberSaga';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';


export function* checkAndLoadCustomer(externalCustomerId: string) {
  try {
    const currentCustomer = yield select(customerInfoSelectors.getCustomer);
    if (!currentCustomer) {
      const customerResponse = yield call(findCustomerByExternalId, externalCustomerId);
      const filledCustomer = yield call(checkAndLoadLocalCustomerInfo, customerResponse);
      yield put(fetchCustomerSuccess(reformatBirthdayInResponse(filledCustomer)));
    }
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      `Couldn't load customer ${externalCustomerId}`,
      'Hoppla... Kundeninformationen konnten nicht geladen werden.',
      err
    );
  }
}
