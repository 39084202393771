import { CountryCode } from 'src/api/orderTypes';
import { Locale } from 'src/types/Locale';


export const getLocaleFromCountryCode = (country?: CountryCode): Locale => {
  if (country === CountryCode.at) {
    return Locale.de_AT;
  }

  if (country === CountryCode.ch) {
    return Locale.de_CH;
  }

  return Locale.de_DE;
};
