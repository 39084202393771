import { Currency } from 'src/types/product/Currency';
import { Product } from 'src/types/product/product';


export const mapProductsToOfferProducts = (offers: Product[]) => {
  return offers.map(({ masterValues, variantValues }: Product) => ({
    tag: masterValues.salesdrivers && masterValues.salesdrivers[0],
    baseProductNo: masterValues.masterProductNumber,
    brand: masterValues.brand,
    name: masterValues.name,
    imageUri: variantValues[0]?.imageURL,
    price: {
      currencyCode: Currency.euro,
      value: variantValues[0]?.price,
      reference: {
        savingPercent: variantValues[0]?.referencePriceSavingPercent,
        value: variantValues[0]?.referencePrice,
      },
    },
    freeShipping: masterValues.freeShipping,
  }));
};
