import { Location } from 'history';
import jwtDecode from 'jwt-decode';
import {
  ComponentClass,
  FC,
  useEffect,
} from 'react';
import { LoadableComponent } from 'react-loadable';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';

import { LocalStorageKeys } from 'src/constants/localStorageKeys';
import { fetchOauthTokens, loadAuthenticatedAgentInfo } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { login, unauthorized } from 'src/routes/externalRedirects';


interface Props {
  path: string;
  location: Location;
  exact?: boolean;
  authenticated: boolean;
  component: ComponentClass | (FC & LoadableComponent);
  roleAuthorized: boolean;
}

interface TokenDto {
  exp: number;
}

const ProtectedRoute: FC<Props> = (props: Props) => {
  const jwtString = localStorage.getItem(LocalStorageKeys.IdToken);
  const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);
  const agentUsername = useSelector(appSelectors.getAgentInfo)?.username;
  const dispatch = useDispatch();

  useEffect(() => {
    if(!agentUsername) {
      dispatch(loadAuthenticatedAgentInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!props.authenticated) {
    login();
  }

  if (!props.roleAuthorized) {
    unauthorized();
  }

  try {
    const jwtDetail = jwtDecode<TokenDto>(jwtString || '');
    if (Date.now() >= jwtDetail.exp * 1000) {
      if (refreshToken) {
        dispatch(fetchOauthTokens(refreshToken || ''));
      } else {
        login();
      }
    }
  } catch (error) {
    login();
  }

  return <Route exact={props.exact} path={props.path} component={props.component} />;
};

export default ProtectedRoute;
