import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { resetVoucher } from 'src/redux/order/voucherSlice';

import { checkAndResetPayment, getOfferIfOrderLineItemsExist } from '../offer/getOffer';


export function* resetVoucherSaga() {
  try {
    yield call(checkAndResetPayment);
    yield call(getOfferIfOrderLineItemsExist);
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not reset voucher', err }));
  }
}

export default function* resetVoucherWatcher() {
  yield takeLatest(resetVoucher.type, resetVoucherSaga);
}
