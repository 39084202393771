import {
  ErpProductStatus,
  ProductDetails,
  ProductStatus,
} from 'src/types/product/product';


export function isSellable(product: ProductDetails, sku?: string): boolean {
  const productHasErpStatus = doesProductHaveErpStatus(product);

  const productStatus = product?.status;
  const isAvailable = productHasErpStatus ? true : (productStatus !== ProductStatus.Discarded && productStatus !== ProductStatus.Archived);

  return isAvailable && (sku === undefined || isVariantSellable(product, sku));
}

function isVariantSellable(product: ProductDetails, sku: string) {
  const variant = product?.variants.find(i => i.sku === sku);
  return isVariantStatusSellable(variant?.status, variant?.erpStatus);
}

export function isVariantStatusSellable(productStatus?: ProductStatus, erpStatus?: ErpProductStatus) {
  if(erpStatus !== undefined) {
    return erpStatus === ErpProductStatus.SELLABLE;
  }

  return productStatus !== ProductStatus.Discarded && productStatus !== ProductStatus.Archived;
}

export function isProductAndVariantStatusSellable(productStatus?: ProductStatus, variantStatus?: ProductStatus, erpStatus?: ErpProductStatus) {
  if(erpStatus !== undefined) {
    return erpStatus === ErpProductStatus.SELLABLE;
  }

  return productStatus !== ProductStatus.Discarded && productStatus !== ProductStatus.Archived &&
    variantStatus !== ProductStatus.Discarded && variantStatus !== ProductStatus.Archived;
}

function doesProductHaveErpStatus(product: ProductDetails) {
  return product?.variants?.find(value => value.erpStatus !== undefined) !== undefined;
}

function discardedOffers(offers: ProductDetails[]): ProductDetails[] {
  return offers.filter((offer) => (
    !isSellable(offer)
  ));
}

export default discardedOffers;
