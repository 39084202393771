import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { closeModal, showErrorNotification } from 'src/redux/app/appSlice';
import {
  checkCustomerPinAction,
  CheckCustomerPinActionType,
  checkCustomerPinFailed,
  checkCustomerPinSuccessful,
  checkCustomerPinUnsuccessful,
  PinChallengeVerificationResult,
  PinVerificationResultDetail,
  setCurrentPinValue,
  setShouldClearPinInputStatus,
} from 'src/redux/customer/CustomerPinSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { setLastPinChallengeCheck } from 'src/utils/lastPinChallengeCheck';


export const finalFailedAttemptErrorKey = '/dpl/cs/errors/final_failed_attempt';
export const notTheFinalFailedAttemptErrorKey = '/dpl/cs/errors/first_failed_attempts';

export function* checkCustomerPinValiditySaga(action: CheckCustomerPinActionType) {
  try {
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    yield call(apiCustomer.verifyPinChallenge, action.payload);
    const pinDetail: PinVerificationResultDetail = {
      value: action.payload.pin,
      challengeResult: PinChallengeVerificationResult.SUCCESS,
    };
    yield put(checkCustomerPinSuccessful(pinDetail));
    setLastPinChallengeCheck(action.payload.externalCustomerId);
    yield put(closeModal(Modals.pinChallengeModal));
  } catch (err) {
    const errorKey = err.response?.data?.key;

    if (errorKey === notTheFinalFailedAttemptErrorKey) {
      const pinVerificationResultDetail: PinVerificationResultDetail = {
        value: action.payload.pin,
        challengeResult: PinChallengeVerificationResult.FAILED,
      };
      yield put(checkCustomerPinUnsuccessful({ result: pinVerificationResultDetail, attemptsCount: err.response?.data?.attemptsCount + 1 }));
      yield put(setCurrentPinValue(pinVerificationResultDetail));

    } else if (errorKey === finalFailedAttemptErrorKey) {
      const pinVerificationResultDetail: PinVerificationResultDetail = {
        value: action.payload.pin,
        challengeResult: PinChallengeVerificationResult.FAILED_WITH_MAX_ATTEMPTS_REACHED,
      };
      yield put(checkCustomerPinUnsuccessful({ result: pinVerificationResultDetail }));
      yield put(setCurrentPinValue(pinVerificationResultDetail));
    } else {

      yield put(checkCustomerPinFailed());
      yield put(
        logEvent({
          level: LOG_LEVEL.ERROR,
          message: `Couldn't check customer pin for customer with id ${action.payload.externalCustomerId})`,
          err: err,
        }),
      );

      yield put(showErrorNotification('customer.pin_verification.error_request'));
    }
  } finally {
    yield put(setShouldClearPinInputStatus(true));
  }

}

export default function* checkCustomerPinValidityWatcher() {
  yield takeLatest(checkCustomerPinAction.type, checkCustomerPinValiditySaga);
}
