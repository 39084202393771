import axios, { AxiosInstance } from 'axios';

import { NotificationsResponse } from 'src/types/NotificationResponse';


export interface ApiNotifications {
  client: AxiosInstance;
  getNotifications: (fetchPreview?: boolean) => Promise<NotificationsResponse | undefined>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getApiNotifications(entityId: string, contentfulConfig: any): ApiNotifications {
  const notificationsClient = axios;

  return {
    client: notificationsClient,
    getNotifications: async (fetchPreview?: boolean): Promise<NotificationsResponse | undefined> => {
      const graphQLQuery = `{
      wilisPage(id: "${entityId}", preview: ${fetchPreview}) {
        title
        notificationsCollection {
          items {
            publishedFrom
            publishedTo
            type
            detail
            sys {
              id
            }
          }
        }
      }
    }`;

      const token = fetchPreview ? contentfulConfig.previewToken : contentfulConfig.deliveryToken;

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };

      const response = await notificationsClient.get(
        `https://graphql.contentful.com/content/v1/spaces/${contentfulConfig.spaceId}/environments/${contentfulConfig.environment}`,
        {
          headers,
          params: {
            query: graphQLQuery,
          },
        },
      );

      if (!response) {
        return;
      }

      const { data: { data = {} } = {} } = response;

      return {
        title: data.wilisPage.title,
        notifications: data.wilisPage.notificationsCollection.items,
      };
    },
  };
}
