import {
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';

import {
  SelectOffersType,
  selectOffersType,
  setAllOffers,
} from 'src/redux/offers/offersActions';
import { offersSelectors } from 'src/redux/offers/selectors/offersSelectors';
import { OfferProductInterface } from 'src/types/product/product';
import Log from 'src/utils/log';


export function* selectOffersTypeSaga(action: SelectOffersType) {
  try {
    const categories = action.payload.split('.');
    const type = categories[1] ?? '';

    let products: OfferProductInterface[] = [];
    if(categories[0] === 'topDeals') {
      switch (type) {
      case 'daily': {
        products = yield select(offersSelectors.getDailyTopDeals);
        break;
      }
      case 'weekly': {
        products = yield select(offersSelectors.getWeeklyTopDeals);
        break;
      }
      case 'monthly': {
        products = yield select(offersSelectors.getMonthlyTopDeals);
        break;
      }
      default: {
        break;
      }
      }
    }
    else if(categories[0] === 'tvOffers') {
      switch (type) {
      case 'hse24': {
        products = yield select(offersSelectors.getHse24Offers);
        break;
      }
      case 'hse24_extra': {
        products = yield select(offersSelectors.getHse24ExtraOffers);
        break;
      }
      case 'hse24_trend': {
        products = yield select(offersSelectors.getHse24TrendOffers);
        break;
      }
      default: {
        break;
      }
      }
    }
    yield put(setAllOffers(products));
  } catch (err) {
    yield call(Log.error, err);
  }
}

export default function* selectOffersTypeWatcher() {
  yield takeEvery(selectOffersType.type, selectOffersTypeSaga);
}
