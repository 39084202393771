import { createSelector } from '@reduxjs/toolkit';

import { RootStateType } from 'src/constants/types';
import { addressToString } from 'src/utils/formatters/formatAddressToString';
import { createSelectors } from 'src/utils/state';


export const editSubscriptionSelector = createSelectors({
  getLoadingState(state: RootStateType) {
    return state.customer.editSubscription.loading || state.orderPreCheck.loading;
  },
  getOriginalSubscription(state: RootStateType) {
    return state.customer.editSubscription.originalSubscription;
  },
  getEditedSubscription(state: RootStateType) {
    return state.customer.editSubscription.editedSubscription;
  },
  getIsEditSubscriptionDateNotValid(state: RootStateType) {
    return state.customer.editSubscription.isEditSubscriptionDateNotValid;
  },
  hasChangedDeliveryAddress(state: RootStateType) {
    const details = state.customer.editSubscription.editedSubscription?.details;
    const editedSubscriptionDeliveryAddress = details?.deliveryAddress && addressToString(details.deliveryAddress);
    const originalSubscriptionDeliveryAddress = state.customer.editSubscription.originalSubscription?.details.deliveryAddress;
    return editedSubscriptionDeliveryAddress !== originalSubscriptionDeliveryAddress;
  },
  hasChangedPaymentMethod(state: RootStateType) {
    const details = state.customer.editSubscription.editedSubscription?.details;
    const editedSubscriptionPaymentMethod = details?.payment.method;
    const originalSubscriptionPaymentMethod = state.customer.editSubscription.originalSubscription?.details.payment.method;
    return editedSubscriptionPaymentMethod !== originalSubscriptionPaymentMethod;
  }
});

export const getSaveEditedSubscriptionErrorMessages = createSelector(
  (state: RootStateType) => state.customer.editSubscription.editedSubscription?.details,
  (state: RootStateType) => state.orderPreCheck.result,
  editSubscriptionSelector.hasChangedDeliveryAddress,
  editSubscriptionSelector.hasChangedPaymentMethod,
  (
    editSubscriptionDetails,
    orderPreCheckResult,
    hasChangedDeliveryAddress, 
    hasChangedPaymentMethod
  ) => { 
    const { deliveryAddress, paymentOptions } = orderPreCheckResult || {};
    const messagesIds = [];
    const paymentMethod = editSubscriptionDetails?.payment?.method;
    if (hasChangedPaymentMethod && !(paymentMethod && paymentOptions?.find(p => p.method === paymentMethod)?.enabled)) {
      messagesIds.push('order.chosePaymentMethod');
    }
    if (hasChangedDeliveryAddress && (!(editSubscriptionDetails?.deliveryAddress && addressToString(editSubscriptionDetails.deliveryAddress)) || !deliveryAddress?.isValid)) {
      messagesIds.push('order.choseDeliveryAddress');
    }
    return messagesIds;
  }
);