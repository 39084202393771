import { RootStateType } from 'src/constants/types';


export function createSelectors<
  U extends {
    [key: string]: (state: RootStateType) => unknown;
  }
>(selectors: U & ThisType<undefined>): U {
  return selectors;
}
