import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { editSubscriptionHistoryItemSuccess } from 'src/redux/customer/subscriptionHistorySlice';
import { SubscriptionItem } from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionProductResponse } from 'src/types/subscription/SubscriptionsOverviewResponse';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import { subscriptionManagementSelectors } from '../redux/selectors/subscriptionManagementSelectors';
import {
  CurrentSubscriptionOverviewViewPayload,
  fetchSubscriptionChangesHistory,
  setCurrentSubscriptionDetails,
  SubscriptionOverviewView,
} from '../redux/subscriptionManagementSlice';


export function* refreshCurrentSubscriptionSaga(action: PayloadAction<SubscriptionItem>) {
  const currentSubscription: SubscriptionProductResponse | undefined = yield select(subscriptionManagementSelectors.getCurrentSubscriptionDetails);
  try {
    if(!currentSubscription) {
      return;
    }

    const refreshedSubscription: CurrentSubscriptionOverviewViewPayload = {
      subscription: { ...currentSubscription, ...action.payload },
      view: SubscriptionOverviewView.ABO_DETAILS,
    };
    yield put(setCurrentSubscriptionDetails(refreshedSubscription));
    yield put(fetchSubscriptionChangesHistory());
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      `Couldn't refresh current subscription ${currentSubscription?.contractNumber}`,
      'Das aktuelle Abonnement konnte in der Benutzeroberfläche nicht aktualisiert werden. Bitte aktualisieren Sie die Seite',
      err,
    );
  }
}

export default function* refreshCurrentSubscriptionWatcher() {
  yield takeLatest(editSubscriptionHistoryItemSuccess.type, refreshCurrentSubscriptionSaga);
}
