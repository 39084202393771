import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const orderHistorySelector = createSelectors({
  getExternalCustomerId(state: RootStateType) {
    return state.customer.orderHistory.externalCustomerId;
  },
  getOrdersHistory(state: RootStateType) {
    return state.customer.orderHistory.data ?? [];
  },
  getOrdersHistoryFilters(state: RootStateType) {
    return state.customer.orderHistory.filters;
  },
  getOrdersHistoryLoadingStatus(state: RootStateType) {
    return state.customer.orderHistory.loading;
  },
  getOrdersOverviewHistoryLoadingStatus(state: RootStateType) {
    return state.customer.orderHistory.isLoadingMore;
  },
  getOffset (state: RootStateType) {
    return state.customer.orderHistory.offset;
  },
  getTotalOrders (state: RootStateType) {
    return state.customer.orderHistory.totalOrders;
  },
  getTotalFilteredOrders (state: RootStateType) {
    return state.customer.orderHistory.totalFilteredOrders;
  },
  getCancelledOrders (state: RootStateType) {
    return state.customer.orderHistory.cancelledOrders ?? [];
  },
  getSearchedLimelightOrderId({ customer }: RootStateType) {
    return customer.info.shouldMoveToOrderHistoryPage ? customer.orderHistory.searchedLimelightOrderId : undefined;
  }
});
