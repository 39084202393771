// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { put } from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { showErrorNotification, showStackedNotification } from 'src/redux/app/appSlice';
import { editOrderFailure } from 'src/redux/editOrder/editOrderSlice';
import { OrderErrorResponseTypes } from 'src/types/offer/OrderErrorResponseTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleFailureState(error: any, message: string, payload: any) {
  yield put(editOrderFailure({
    title: error.response?.data?.title || 'errors.general',
    detail: error.response?.data?.detail || 'errors.general',
  }));
  if (error.response?.data?.errorType === OrderErrorResponseTypes.voucherError && error.response?.data?.invalidVouchers) {
    const voucherErrors = error.response?.data?.invalidVouchers;
    yield put(showStackedNotification(voucherErrors?.map((it: { details: string | undefined; }) => it.details)));
  } else {
    yield put(showErrorNotification(error.response?.data?.detail));
  }
  
  const preparedMessage = message ? `${message} ${payload !== undefined ? JSON.stringify(payload) : ''}` : error.response?.data?.detail;
  yield put(
    logEvent({
      level: LOG_LEVEL.WARN,
      message: preparedMessage,
      err: error,
    }),
  );
}
