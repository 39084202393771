import Loadable from 'react-loadable';

import { Routes } from 'src/routes/routes';


const EditedOrderHistoryView = Loadable({
  loader: () => import('src/editedorder/container/EditedOrderHistoryContainer'),
  loading: () => <p>Loading...</p>,
});

const editedOrdersHistoryRoutes: Routes = {
  EditedOrder : {
    component: EditedOrderHistoryView,
    protected: true,
    exact: true,
    path: '/edited-order/history/:orderId',
  },
};

export default editedOrdersHistoryRoutes;
