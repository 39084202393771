import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { CountryCode } from 'src/api/orderTypes';
import { removeOrderIdFromSession } from 'src/OrderDisplay/utils/recreateOrderUtils';
import {
  newSession,
  setCountry,
  trackUserSessionEvent,
} from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { resetCustomer } from 'src/redux/customer/customerInfoSlice';
import { fetchOffers_TopDeals, resetAllOffers } from 'src/redux/offers/offersActions';
import { resetNewOrder } from 'src/redux/order/newOrderSlice';
import { deleteBasketSuccess, toggleReklaFlag } from 'src/redux/order/orderEntrySlice';
import { resetSalesChannel } from 'src/redux/order/salesChannelSlice';
import { resetSalesOffice } from 'src/redux/order/salesOfficeSlice';
import { resetSourceChannel } from 'src/redux/order/sourceChannelSlice';
import { resetVoucher } from 'src/redux/order/voucherSlice';
import { resetChEntryPayments } from 'src/redux/payment/paymentSlice';
import { resetProductStock } from 'src/redux/product/productStockSlice';
import { clearUpsells } from 'src/redux/product/productUpsellsSlice';
import { resetOriginalOrder } from 'src/redux/recreateOrder/recreateOrderSlice';
import { resetServiceVoucher } from 'src/redux/serviceVoucher/serviceVoucherActions';
import { deleteCurrentBasket } from 'src/sagas/orderEntry/deleteCurrentBasket';
import { removeUpgradesCrossSellsFromSession } from 'src/sagas/orderEntry/orderEntryUtils';
import inferCountryFromAgentUsername from 'src/sagas/utils/inferCountryFromAgentUsername';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import { removeReklaOrderInfoFromSession } from 'src/utils/reklaOrderUtils';


export function* onDefaultNewSessionSaga() {
  const agentUsername = yield select(appSelectors.getAgentUsername);
  removeOrderIdFromSession();
  removeUpgradesCrossSellsFromSession();
  yield put(resetOriginalOrder());
  yield call(deleteCurrentBasket);
  yield put(deleteBasketSuccess());
  yield put(resetNewOrder());
  yield put(resetCustomer());
  yield put(resetProductStock());
  yield put(clearUpsells());
  yield put(resetVoucher());
  yield put(fetchOffers_TopDeals());
  yield put(resetAllOffers());
  yield put(resetServiceVoucher());
  yield put(resetSalesChannel());
  yield put(resetSalesOffice());
  window.sessionStorage.removeItem('customerId');
  yield put(resetSourceChannel());
  yield put(setCountry(inferCountryFromAgentUsername(agentUsername)));
  yield put(toggleReklaFlag(false));
  if (agentUsername) {
    yield put(trackUserSessionEvent(agentUsername));
  }
}

export function* onCHNewSessionSaga() {
  yield call(deleteCurrentBasket);
  yield put(deleteBasketSuccess());
  yield put(resetChEntryPayments());
}
export function* onNewSessionSaga() {
  try {
    const country: CountryCode = yield select(appSelectors.getCountry);
    yield call(country === CountryCode.ch ? onCHNewSessionSaga : onDefaultNewSessionSaga);
    removeReklaOrderInfoFromSession();
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'Hoppla... Neue Sitzung konnte nicht geöffnet werden, Bitte versuchen Sie es erneut',
      'could finish NewSession Saga successfully something happen wrong',
      err,
    );
  }
}

export default function* onNewSessionWatcher() {
  yield takeEvery(newSession.type, onNewSessionSaga);
}
