import { DeliveryAddress } from 'src/types/customer/address';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { ProductName } from 'src/types/product/product';
import { SubscriptionProductCustomer } from 'src/types/subscription/SubscriptionProductCustomer';
import { WaitlistItemPayment } from 'src/types/waitlist/CreateWaitlistItemRequest';


export interface SubscriptionRequest {
  customer: SubscriptionProductCustomer;
  product: SubscriptionProduct;
  payment: WaitlistItemPayment;
  deliveryAddress: DeliveryAddress;
  salesChannel: string;
  salesOffice: string;
  sourceChannel: SourceChannels;
  rotation: SubscriptionRotation;
  aboNumber: string;
  nextOrderDate: string;
}

export interface SubscriptionProduct {
  sku: string;
  quantity: number;
  name: ProductName;
}

export enum SubscriptionRotation {
  ONE_MONTH= 'ONE_MONTH',
  TWO_MONTHS= 'TWO_MONTHS',
  THREE_MONTHS= 'THREE_MONTHS',
  FOUR_MONTHS= 'FOUR_MONTHS',
  FIVE_MONTHS= 'FIVE_MONTHS',
  SIX_MONTHS= 'SIX_MONTHS'
}

export enum SubscriptionStartIn {
  TODAY = 'TODAY',
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  SKIP_NEXT_DELIVERY = 'SKIP_NEXT_DELIVERY',
  CUSTOM = 'CUSTOM',
}
