import { AxiosResponse } from 'axios';

import { ResponseInterceptor } from '../HttpClient';


const X_CLIENT_VERSION_HEADER = 'x-client-version';

const CURRENT_CLIENT_VERSION = 'clientVersion';

export const versionHeaderInterceptor: ResponseInterceptor = {
  onFulfilled: (response: AxiosResponse): AxiosResponse => {
    const { headers } = response;
    const existingClientVersion = localStorage.getItem(CURRENT_CLIENT_VERSION);
    if (headers && headers[X_CLIENT_VERSION_HEADER] && headers[X_CLIENT_VERSION_HEADER] !== existingClientVersion) {
      localStorage.setItem(CURRENT_CLIENT_VERSION, headers[X_CLIENT_VERSION_HEADER]);
      if (existingClientVersion) {
        setTimeout(() => {
          location.reload();
        }, 2500);
      }
    }
    return response;
  },
};
  