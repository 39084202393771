import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { removeOrderIdFromSession } from 'src/OrderDisplay/utils/recreateOrderUtils';
import { newSessionWithCustomer } from 'src/redux/app/appSlice';
import { setPreviouslyUsedBankAccount } from 'src/redux/customer/customerInfoSlice';
import { fetchOffers_TopDeals, resetAllOffers } from 'src/redux/offers/offersActions';
import { resetNewOrder } from 'src/redux/order/newOrderSlice';
import { deleteBasketSuccess, toggleReklaFlag } from 'src/redux/order/orderEntrySlice';
import { resetSalesChannel } from 'src/redux/order/salesChannelSlice';
import { resetSalesOffice } from 'src/redux/order/salesOfficeSlice';
import { resetSourceChannel } from 'src/redux/order/sourceChannelSlice';
import { resetVoucher } from 'src/redux/order/voucherSlice';
import { paymentSelector } from 'src/redux/payment/selectors/paymentSelectors';
import { resetProductStock } from 'src/redux/product/productStockSlice';
import { clearUpsells } from 'src/redux/product/productUpsellsSlice';
import { resetOriginalOrder } from 'src/redux/recreateOrder/recreateOrderSlice';
import { resetServiceVoucher } from 'src/redux/serviceVoucher/serviceVoucherActions';
import { deleteCurrentBasket } from 'src/sagas/orderEntry/deleteCurrentBasket';
import { removeUpgradesCrossSellsFromSession } from 'src/sagas/orderEntry/orderEntryUtils';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


const { getSelectedPaymentMethod } = paymentSelector;

export function* onNewSessionWithCustomerSaga() {
  try {
    removeOrderIdFromSession();
    removeUpgradesCrossSellsFromSession();
    yield put(resetOriginalOrder());
    yield call(deleteCurrentBasket);
    yield put(deleteBasketSuccess());
    yield put(resetNewOrder());
    yield put(resetProductStock());
    yield put(clearUpsells());
    yield put(resetVoucher());
    yield put(fetchOffers_TopDeals());
    yield put(resetAllOffers());
    window.sessionStorage.removeItem('customerId');
    yield put(resetServiceVoucher());
    yield put(resetSalesChannel());
    yield put(resetSalesOffice());
    yield put(resetSourceChannel());
    yield put(toggleReklaFlag(false));
    const selectedPaymentMethod: PaymentMethod | undefined = yield select(getSelectedPaymentMethod);
    if(selectedPaymentMethod && [PaymentMethod.directDebit, PaymentMethod.installments, PaymentMethod.installmentsFactoring].includes(selectedPaymentMethod)) {
      yield put(setPreviouslyUsedBankAccount());
    }
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'Hoppla... Neue Sitzung konnte nicht geöffnet werden, Bitte versuchen Sie es erneut',
      'Could not start new session with customer',
      err,
    );
  }
}

export default function* onNewSessionWithCustomerWatcher() {
  yield takeEvery(newSessionWithCustomer.type, onNewSessionWithCustomerSaga);
}
