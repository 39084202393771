export default {
  'orderSource.SHOP': 'Shop',
  'orderSource.SHOP_AUTOMATIC' : 'Automatisch (Shop)',
  'orderSource.BACKOFFICE': 'Wilis',
  'orderSource.MOBILE': 'App',
  'orderSource.NEXT_APP' : 'HSE Hello',
  'orderSource.PHONE_BOT': 'Phonebot',
  'orderSource.WAITLIST_SERVICE': 'Warteliste',
  'orderSource.SUBSCRIPTION_SERVICE' : 'Abo',
};
