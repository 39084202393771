import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiOrderHistory } from 'src/api/apiOrderHistory';
import { logErrorEvent } from 'src/logging/loggingActions';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { fetchCustomer, setShouldMOveToOrderHistoryPage } from 'src/redux/customer/customerInfoSlice';
import { fetchByLimelightOrderId, setSearchedLimelightOrderId } from 'src/redux/customer/orderHistorySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { SearchOrderResult } from 'src/types/orderhistory/OrderHistoryResponse';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';


export function* fetchOrderInfoByLimelightOrderIdSaga({ payload }: PayloadAction<string>) {
  try {
    const api: ApiOrderHistory = yield getContext(SagaContextItem.apiOrderHistory);
    const { externalCustomerId }: SearchOrderResult = yield call(
      api.getExternalCustomerIdByLimelightOrderId,
      payload,
    );
    yield put(setShouldMOveToOrderHistoryPage(true));
    yield put(setSearchedLimelightOrderId(payload));
    yield put(fetchCustomer(externalCustomerId));
  } catch (err) {
    yield put(setShouldMOveToOrderHistoryPage(false));
    if (isNotFoundStatus(err)) {
      yield put(
        logErrorEvent({ message: `Failed to external_customer_id for order ${payload}`, err }),
      );
    }
    yield put(showErrorNotification('orderHistory.fetchByLimelightId.failed'));
  }
}

export function* fetchOrderInfoByLimelightOrderIdWatcher() {
  yield takeLatest(fetchByLimelightOrderId.type, fetchOrderInfoByLimelightOrderIdSaga);
}
