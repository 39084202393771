import {
  createAction,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { BotRequestInfo } from 'src/types/bot/BotRequest';


export interface BotRequestState {
  loading: boolean;
  botRequestInfo: BotRequestInfo | undefined;
  error: string | undefined;
}

export const botRequestInitialState: BotRequestState = {
  loading: false,
  botRequestInfo: undefined,
  error: undefined
};

export const fetchBotRequest = createAction<string>('bot/fetchBotRequest');

const botRequestSlice = createSlice({
  name: 'botRequest',
  initialState: botRequestInitialState,
  reducers: {
    fetchBotRequestInProgress(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchBotRequestSuccess(state, { payload }: PayloadAction<BotRequestInfo>) {
      state.loading = false;
      state.error = undefined;
      state.botRequestInfo = payload;
    },
    fetchBotRequestError(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.error = payload;
      state.botRequestInfo = undefined;
    }
  },
  extraReducers: builder => {
    builder
      .addCase( fetchBotRequest, state => {
        return state;
      });
  },
},
);

export const {
  fetchBotRequestInProgress,
  fetchBotRequestSuccess,
  fetchBotRequestError,
} = botRequestSlice.actions;

export default botRequestSlice.reducer;
