import { RedeemedVoucherResponse } from 'src/types/voucher/RedeemedVoucherResponse';


export function  getFailedVoucherPosition(
  voucherCode?: string,
  vouchers?: RedeemedVoucherResponse[],
): number {
  const index = vouchers?.findIndex(voucher => voucher.code === voucherCode);
  return index !== undefined ? Math.floor(index / 4 + 1) : -1;
}

export function cleanFailedVoucherCode(voucherCode?: string): string | undefined {
  if (!voucherCode) {
    return ;
  }
  const cleanVoucherCode = voucherCode[0] === '$' ? voucherCode.substring(1) : voucherCode;
  if (cleanVoucherCode.includes('%')) {
    return cleanVoucherCode.split('%')[0];
  } else if (cleanVoucherCode.includes('€')) {
    return cleanVoucherCode.split('€')[0];
  }
  return cleanVoucherCode;
}
