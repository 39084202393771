import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditedOrder } from 'src/editedorder/api/apiEditedOrder';
import {
  FetchEditedOrderHistory,
  fetchEditedOrderHistory,
  setEditedOrdersHistory,
} from 'src/editedorder/reducers/EditedOrderReducers';
import { EditedOrderHistoryResponse } from 'src/editedorder/types/EditedOrderHistoryResponse';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


export function* fetchEditedOrderHistorySaga(action: FetchEditedOrderHistory) {
  try {
    const apiEditedOrder: ApiEditedOrder = yield getContext(SagaContextItem.apiEditedOrder);

    const { editedOrderList }: EditedOrderHistoryResponse = yield call(apiEditedOrder.getHistoryByExternalOrderId, action.payload);

    yield put(setEditedOrdersHistory(editedOrderList));

  } catch (error) {
    yield handleFailureState(error, `Couldn't fetch Edited Order with id ${action.payload}`, undefined);
  }
}

export default function* fetchEditedOrderHistoryWatcher() {
  yield takeLatest(fetchEditedOrderHistory.type, fetchEditedOrderHistorySaga);
}
