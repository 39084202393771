const NotificationErrorIcon = () => {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2ZM12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3ZM9.85355 8.64645L12.5 11.2925L15.1464 8.64645C15.3267 8.4662 15.6103 8.45234 15.8064 8.60485L15.8536 8.64645C16.0338 8.82669 16.0477 9.1103 15.8951 9.30645L15.8536 9.35355L13.2075 12L15.8536 14.6464C16.0488 14.8417 16.0488 15.1583 15.8536 15.3536C15.6583 15.5488 15.3417 15.5488 15.1464 15.3536L12.5 12.7075L9.85355 15.3536C9.67331 15.5338 9.3897 15.5477 9.19355 15.3951L9.14645 15.3536C8.9662 15.1733 8.95234 14.8897 9.10485 14.6936L9.14645 14.6464L11.7925 12L9.14645 9.35355C8.95118 9.15829 8.95118 8.84171 9.14645 8.64645C9.34171 8.45118 9.65829 8.45118 9.85355 8.64645Z"
        fill="white" />
    </svg>);
};

export default NotificationErrorIcon;

