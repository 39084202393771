const NotificationCloseIcon = () => {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.85355 3.64645L12.5 11.2925L20.1464 3.64645C20.3267 3.4662 20.6726 3.48697 20.8536 3.64645C21.0345 3.80593 21.0477 4.1103 20.8951 4.30645L20.8536 4.35355L13.2075 12L20.8536 19.6464C21.0488 19.8417 21.0488 20.1583 20.8536 20.3536C20.6583 20.5488 20.3417 20.5488 20.1464 20.3536L12.5 12.7075L4.85355 20.3536C4.67331 20.5338 4.3897 20.5477 4.19355 20.3951L4.14645 20.3536C3.9662 20.1733 3.95234 19.8897 4.10485 19.6936L4.14645 19.6464L11.7925 12L4.14645 4.35355C3.95118 4.15829 3.95118 3.84171 4.14645 3.64645C4.34171 3.45118 4.65829 3.45118 4.85355 3.64645Z"
        fill="white" />
    </svg>);
};

export default NotificationCloseIcon;
