import onClosingInstallmentFactoringModalWatcher from 'src/sagas/payment/onClosingInstallmentFactoringModal';

import getInstallmentFactoringPlansWatcher from './getInstallmentFactoringPlans';
import getInstallmentPlansWatcher from './getInstallmentPlans';
import onSelectedPaymentMethodWatcher from './onSelectPaymentMethod';
import paymentStorageHandlerWatcher from './paymentStorageHandler';
import resetSelectedPaymentMethod from './resetSelectedPaymentMethod';
import validateIBAN from './validateIBAN';


export default [
  validateIBAN,
  resetSelectedPaymentMethod,
  onSelectedPaymentMethodWatcher,
  getInstallmentPlansWatcher,
  paymentStorageHandlerWatcher,
  getInstallmentFactoringPlansWatcher,
  onClosingInstallmentFactoringModalWatcher,
];
