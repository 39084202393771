export default {
  'orderentry.basket.error': 'Error getting basket items',
  'orderentry.orderEntry': 'Order entry',
  'orderentry.productId': 'Product number',
  'orderentry.quantity': 'Quantity',
  'orderentry.description': 'Description',
  'orderentry.price': 'Price',
  'orderentry.priceDate': 'Pos. Preisdatum',
  'orderentry.wrongDate': 'Bitte Datum prüfen',
  'orderentry.currency': 'Currency',
  'orderentry.totalPrice': 'Total price',
  'orderentry.upsells': 'Upsells',
  'orderentry.Strornogrund': 'Strornogrund',
  'orderentry.Preisdatum': 'Pos. Preisdatum',
  'orderentry.position.priceDate': 'Price date',
  'orderentry.transport': 'Transport',
  'orderentry.liefertage': 'Delivery day',
  'orderentry.Liefadresse': 'Delivery address',
  'orderentry.deliveryTime': 'Delivery Time',
  'fourteenDaysDelay': 'Spedition - Telefonisch Vereinbarung in <b>ca. 14 Tagen</b>',
  'daysDeliveryTime': 'Delivery within',
  'standardDeliveryTime': 'Delivery within 3-5 working days',
  'days': 'Days',
  'orderentry.productAdded.VERY_NICE_CHOICE': 'Very nice choice.',
  'orderentry.productAdded.GOOD_CHOICE': 'Good choice.',
  'orderentry.productAdded.VERY_POPULAR_TODAY': 'Very popular today.',
  'orderentry.productAdded.BESTSELLERS_WITH_US': 'Bestellers today with us.',
  'orderentry.productAdded.WONDERFUL_DECISION': 'Wonderful decision.',
  'orderentry.productAdded.EXCELLENT_CHOICE': 'You have made an excellent choice.',
  'orderentry.hideOffers': 'Hide offers',
  'orderentry.showOffers': 'Show offers',
  'orderentry.tv': 'TV',
  'orderentry.topDeals': 'Top Deals',
  'orderentry.addToBasket': 'In den Warenkorb',
  'orderentry.displayVariants': 'Weitere Varianten',
  'orderEntry.inputSearch': 'Bestellnr. eingeben, Enter drücken',
  'orderEntry.recreatedOrderHint' : 'Original order: {orderId}',
  'orderEntry.recreatedOrderPlaceholder' : 'Original order',
  'orderentry.crossSells': 'Produktcrosssells',
  'orderentry.adtCrossSells': 'Zeitcrosssells',
  'orderentry.productNotSellable': 'Product is not salable',
  'orderentry.freeShipping.FOLLOWUP_ORDER': 'Folgeauftrag',
  'orderentry.freeShipping.FREE_SHIPPING_ARTICLE': 'Produkt',
  'orderentry.freeShipping.HAPPY_HOUR': 'Aktion',
  'orderentry.freeShipping.SERVICE_VOUCHER': 'Service Gutschein',
  'orderentry.freeShipping.FREE_SHIPPING_CAMPAIGN': 'Kostenlose Versandaktion',
  'orderentry.freeShipping.FREE_SHIPPING_VOUCHER': 'Free shipping voucher',
  'orderentry.phoneBot.enterCode': 'Phonebot-Code eingeben, Enter drücken',
  'orderHistory.fetchByLimelightId.failed': 'Failed to fetch order',
  'orderentry.basket.empty': 'Your Cart is currently empty',
  'orderentry.basket.emptyText': 'Use the search bar to find what you are looking for and add it to your shopping cart.',
  'orderEntry.sapNumberSearch.placeholder': 'SAP Order Number',
  'orderEntry.sapNumberSearch.copy': 'Copy',
  'orderEntry.availability.nonSocialLiveItem' : 'Product is not an HSE Hello show product. Please remove from shopping cart.',
};
