import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DeliveryAddress, DeliveryAddressType } from 'src/types/customer/address';
import {
  Customer,
  CustomerConsentBooleanFlags,
  DataProtectionBooleanFlags,
} from 'src/types/customer/customer';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';
import { ReceptionProvider } from 'src/types/customer/ReceptionSurveyQuestionsData';
import { InfoPost } from 'src/types/offer/InfoPostRequests';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { PaymentOption } from 'src/types/offer/PaymentOption';
import getOrderedCustomerPhoneNumber from 'src/utils/getters/getOrdredCustomerPhoneNumber';


export interface CustomerInfoState {
  loading: boolean;
  customerResponse?: Customer;
  selectedDeliveryAddress?: DeliveryAddress;
  additionalDeliveryAddress?: DeliveryAddress;
  billingAsDeliveryAddress: boolean;
  error?: boolean;
  errorCode?: number;
  customerNumberField: string;
  customerSearchData?: Customer;
  billingAddressValidated?: boolean;
  deliveryAddressValidated?: boolean;
  customerOrderRestriction?: OrderRestriction;
  availablePaymentMethods?: PaymentOption[]; // TODO: cleanup; it's useless
  birthDayConfirmed?: boolean;
  isCustomerDataChanged: boolean;
  selectedLocalCustomerId?: string;
  blockedCustomer?: Customer;
  previouslyUsedBankAccount?: boolean;
  blockedDeliveryAddresses: string[];
  infoPost: InfoPost;
  selectedPreferredPayment?: PaymentMethod;
  shouldMoveToOrderHistoryPage?: boolean;
}

export const customerInfoInitialState: CustomerInfoState = {
  loading: false,
  customerNumberField: '',
  billingAsDeliveryAddress: false,
  isCustomerDataChanged: false,
  blockedDeliveryAddresses: [],
  infoPost: {
    ringsTemplate: { loading: false, letterSent: false },
  },
};

export enum CustomerInfoActionTypes {
  FETCH_CUSTOMER = 'customer/FETCH_CUSTOMER',
  FETCH_CUSTOMER_SUCCESS = 'customer/FETCH_CUSTOMER_SUCCESS',
  ADD_DELIVERY_ADDRESS = 'customer/ADD_DELIVERY_ADDRESS',
  EDIT_DELIVERY_ADDRESS = 'customer/EDIT_DELIVERY_ADDRESS',
  CREATE_CUSTOMER = 'customer/CRETAE_CUSTOMER',
  EDIT_CUSTOMER = 'customer/EDIT_CUSTOMER',
  SEND_RINGTEMPLATE_LETTER = 'customer/SEND_RINGTEMPLATE_LETTER',
}

export type AddDeliveryAddress = {
  type: CustomerInfoActionTypes.ADD_DELIVERY_ADDRESS;
  payload: DeliveryAddress;
};
export type CreateCustomerResponse = {
  type: CustomerInfoActionTypes.CREATE_CUSTOMER;
  payload: Customer;
};
export type EditCustomerResponse = {
  type: CustomerInfoActionTypes.EDIT_CUSTOMER;
  payload: Customer;
};
export type EditDeliveryAddress = {
  type: CustomerInfoActionTypes.EDIT_DELIVERY_ADDRESS;
  payload: DeliveryAddress;
};
export type FetchCustomerSuccess = {
  type: CustomerInfoActionTypes.FETCH_CUSTOMER_SUCCESS;
  payload: Customer;
};

export interface UpdateCustomerPreferencesAddresses {
  preferredAddressId: string;
  revertAddressId?: string;
  isNewAddress: boolean;
}

export const fetchCustomer = createAction<string>(CustomerInfoActionTypes.FETCH_CUSTOMER);
export const fetchCustomerForWaitlist = createAction<string>('customer/fetchCustomerForWaitlist');
export const updateCustomerPreferencesAddress = createAction<UpdateCustomerPreferencesAddresses>('customer/UPDATE_CUSTOMER_PREFERENCES_ADDRESS');
export const createCustomer = createAction<Customer>(CustomerInfoActionTypes.CREATE_CUSTOMER);
export const editCustomer = createAction<Customer>(CustomerInfoActionTypes.EDIT_CUSTOMER);
export const editDeliveryAddress = createAction<DeliveryAddress>(CustomerInfoActionTypes.EDIT_DELIVERY_ADDRESS);
export const addDeliveryAddress = createAction<Partial<DeliveryAddress>>(CustomerInfoActionTypes.ADD_DELIVERY_ADDRESS);
export const checkCustomerEmail = createAction<Partial<Customer>>('customer/checkCustomerEmail');
export const checkCustomerByNameAndBirthday = createAction<Customer>('customer/checkCustomerByNameAndBirthday');
export const setCustomerCreateFormValues = createAction<Partial<Customer>>('customer/setCustomerCreateFormValues');
export const createCustomerWithDuplicatedCheck = createAction<Customer>('customer/createCustomerWithDuplicatedCheck');
export const addCustomerConsent = createAction<CustomerConsentBooleanFlags>('customer/ADD_CUSTOMER_CONSENT');
export const addCustomerPrivacy = createAction<DataProtectionBooleanFlags>('customer/ADD_CUSTOMER_PRIVACY');
export const addCustomerReceptionProvider =createAction<ReceptionProvider>('customer/ADD_CUSTOMER_RECEPTION_PROVIDER');
export const loadCustomerFromLocalHistory =createAction<string>('customer/LOAD_CUSTOMER_FROM_LOCAL_HISTORY');
export const sendRingsTemplateLetter = createAction('customer/SEND_RINGTEMPLATE_LETTER');

export type FetchCustomerResponse = ReturnType<typeof fetchCustomer>
export type AddCustomerConsent = ReturnType<typeof addCustomerConsent>;
export type CheckCustomerEmail = ReturnType<typeof checkCustomerEmail>;
export type CheckCustomerByNameAndBirthday = ReturnType<typeof checkCustomerByNameAndBirthday>;
export type AddCustomerPrivacy = ReturnType<typeof addCustomerPrivacy>;
export type CreateCustomerWithDuplicatedCheck = ReturnType<typeof createCustomerWithDuplicatedCheck>
export type AddCustomerReceptionProvider = ReturnType<typeof addCustomerReceptionProvider>


const customerInfoSlice = createSlice({
  name: 'customerInfo',
  initialState: customerInfoInitialState,
  reducers: {
    fetchCustomerInProgress(state) {
      state.loading = true;
    },
    fetchCustomerSuccess(state, { payload }: PayloadAction<Customer>) {
      state.loading = false;
      state.customerResponse = {
        ...payload,
        phoneNumber: getOrderedCustomerPhoneNumber(payload.phoneNumbers).phoneNumber,
      };
    },
    fetchCustomerFinished(state) {
      state.loading = false;
    },
    customerError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    resetCustomer() {
      return customerInfoInitialState;
    },
    logoutCustomer() {
      return customerInfoInitialState;
    },
    setBillingAddressValidated(state, { payload }: PayloadAction<boolean>) {
      state.billingAddressValidated = payload;
    },
    setDeliveryAddressValidated(state, { payload }: PayloadAction<boolean>) {
      state.deliveryAddressValidated = payload;
    },
    setCustomerPhoneNumber(state, { payload }: PayloadAction<string>) {
      if (state.customerResponse) {
        state.customerResponse = {
          ...state.customerResponse,
          phoneNumber: payload,
        };
      }
    },
    setCustomerOrderRestriction(state, { payload }: PayloadAction<OrderRestriction>) {
      state.customerOrderRestriction = payload;
    },
    resetCustomerOrderRestriction(state) {
      state.customerOrderRestriction = undefined;
    },
    addCustomerConsentSuccess(state, { payload }: PayloadAction<CustomerConsentBooleanFlags>) {
      if (state.customerResponse) {
        state.loading = false;
        state.customerResponse = {
          ...state.customerResponse,
          consents: payload,
        };
      }
    },
    setCustomerEmail(state, { payload }: PayloadAction<string>) {
      if (state.customerResponse) {
        state.customerResponse = {
          ...state.customerResponse,
          email: payload,
        };
      }
    },
    addCustomerPrivacySuccess(state, { payload }: PayloadAction<DataProtectionBooleanFlags>) {
      if (state.customerResponse) {
        state.loading = false;
        state.customerResponse = {
          ...state.customerResponse,
          dataProtection: payload,
        };
      }
    },
    birthDayCheckConfirmed(state) {
      state.birthDayConfirmed = true;
    },
    setBirthDayCheckConfirmed(state) {
      state.birthDayConfirmed = true;
    },
    addDeliveryAddressSuccess(state, { payload }: PayloadAction<DeliveryAddress | undefined>) {
      state.loading = false;
      state.additionalDeliveryAddress = payload;
      if(payload) {
        if(payload.isDefault) {
          state.customerResponse?.deliveryAddresses?.forEach(address => {
            address.isDefault = false;
          });
        }
        state.customerResponse?.deliveryAddresses.push(payload);
      }
    },
    removeNewAddress(state) {
      if(state.customerResponse?.deliveryAddresses) {
        state.customerResponse.deliveryAddresses = state.customerResponse.deliveryAddresses.filter(address => 
          (address.type === DeliveryAddressType.Postal && address.fromInvoiceAddress === true) || address.externalAddressId
        );
      }
    },
    editDeliveryAddressSuccess(state, { payload }: PayloadAction<DeliveryAddress | undefined>) {
      state.loading = false;
      state.additionalDeliveryAddress = payload;
      if(payload) {
        state.customerResponse!.deliveryAddresses = state.customerResponse!.deliveryAddresses.filter(a => a.id !== payload.id);
        
        if(payload.isDefault) {
          state.customerResponse?.deliveryAddresses?.forEach(address => {
            address.isDefault = false;
          });
        }
        
        state.customerResponse!.deliveryAddresses.push(payload);
      }
    },
    deleteDeliveryAddress(state) {
      state.loading = false;
      state.additionalDeliveryAddress = undefined;
    },
    setSelectedDeliveryAddress(state, { payload }: PayloadAction<DeliveryAddress>) {
      state.billingAsDeliveryAddress = false;
      state.selectedDeliveryAddress = payload;
      state.isCustomerDataChanged = true;
    },
    storeSelectedDeliveryAddressOrUseBilling(state, { payload }: PayloadAction<DeliveryAddress | undefined>) {
      state.selectedDeliveryAddress = payload;
      state.billingAsDeliveryAddress = payload === undefined;
      state.isCustomerDataChanged = payload !== undefined;
    },
    setPreferredAddress(state, { payload: addressId }: PayloadAction<string | undefined>) {
      state.customerResponse?.deliveryAddresses?.forEach(address => {
        address.isDefault = address.id === addressId;
      });
    },
    setCustomerNumberField(state, { payload }: PayloadAction<string>) {
      state.customerNumberField = payload;
    },
    createCustomerError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    createCustomerSuccess(state, { payload }: PayloadAction<Customer>) {
      state.loading = false;
      state.customerResponse = payload;
      state.billingAsDeliveryAddress = true;
    },
    editCustomerError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    editCustomerSuccess(state, { payload }: PayloadAction<Customer>) {
      state.loading = false;
      state.customerResponse = {
        ...state.customerResponse,
        ...payload,
      };
    },
    setCustomerSearchData(state, { payload }: PayloadAction<Customer>) {
      state.customerSearchData = payload;
    },
    resetCustomerSearchData(state) {
      state.customerSearchData = undefined;
    },
    resetCustomerSearch(state) {
      state.customerSearchData = undefined;
      state.error = false;
      state.errorCode = undefined;
    },
    setSelectedCustomerLocalId(state, { payload }: PayloadAction<string | undefined>) {
      state.selectedLocalCustomerId = payload;
    },
    setBlockedCustomer(state, { payload }: PayloadAction<Customer>) {
      state.blockedCustomer = payload;
    },
    addCustomerReceptionProviderSuccess(state, { payload }: PayloadAction<ReceptionProvider | undefined>) {
      state.loading = false;
      if (state.customerResponse) {
        state.customerResponse.reception = payload;
      }
    },
    cleanCustomerReceptionProvider(state){
      state.loading = false;
      if (state.customerResponse) {
        state.customerResponse.reception = undefined;
      }
    },
    setPreviouslyUsedBankAccount(state) {
      state.previouslyUsedBankAccount = true;
    },
    addToBlockedDeliveryAddresses(state, { payload }: PayloadAction<string>) {
      state.blockedDeliveryAddresses?.push(payload);
    },
    sendingRingsTemplateLetterInProgress(state) {
      state.infoPost.ringsTemplate.loading = true;
    },
    sendingRingsTemplateLetterSuccessful(state) {
      state.infoPost.ringsTemplate.loading = false;
      state.infoPost.ringsTemplate.letterSent = true;
    },
    sendingRingsTemplateLetterFinished(state) {
      state.infoPost.ringsTemplate.loading = false;
    },
    setSelectedPreferredPayment(state, { payload }: PayloadAction<PaymentMethod | undefined>){
      state.selectedPreferredPayment = payload;
    },
    setShouldMOveToOrderHistoryPage(state, { payload }: PayloadAction<boolean | undefined>){
      state.shouldMoveToOrderHistoryPage = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase( fetchCustomer,state => {
        return state;
      })
      .addCase( createCustomer,state => {
        return state;
      })
      .addCase( editCustomer,state => {
        return state;
      })
      .addCase( editDeliveryAddress,state => {
        state.loading = true;
      })
      .addCase( addDeliveryAddress,state => {
        state.loading = true;
      })
      .addCase( checkCustomerEmail,state => {
        return state;
      })
      .addCase( checkCustomerByNameAndBirthday,state => {
        return state;
      })
      .addCase( setCustomerCreateFormValues,state => {
        return state;
      })
      .addCase( createCustomerWithDuplicatedCheck,state => {
        return state;
      })
      .addCase( addCustomerConsent,state => {
        state.loading = true;
      })
      .addCase( addCustomerPrivacy,state => {
        state.loading = true;
      })
      .addCase( addCustomerReceptionProvider,state =>{
        state.loading = true;
      })
      .addCase( updateCustomerPreferencesAddress,state =>{
        state.loading = true;
      });
  },
},
);

export const {
  fetchCustomerInProgress,
  fetchCustomerSuccess,
  customerError,
  resetCustomer,
  resetCustomerSearch,
  setBillingAddressValidated,
  setDeliveryAddressValidated,
  setCustomerPhoneNumber,
  setCustomerOrderRestriction,
  resetCustomerOrderRestriction,
  addCustomerConsentSuccess,
  setCustomerEmail,
  addCustomerPrivacySuccess,
  birthDayCheckConfirmed,
  setBirthDayCheckConfirmed,
  addDeliveryAddressSuccess,
  editDeliveryAddressSuccess,
  deleteDeliveryAddress,
  setSelectedDeliveryAddress,
  setPreferredAddress,
  storeSelectedDeliveryAddressOrUseBilling,
  setCustomerNumberField,
  createCustomerError,
  createCustomerSuccess,
  editCustomerError,
  editCustomerSuccess,
  setCustomerSearchData,
  resetCustomerSearchData,
  setSelectedCustomerLocalId,
  fetchCustomerFinished,
  setBlockedCustomer,
  addCustomerReceptionProviderSuccess,
  setPreviouslyUsedBankAccount,
  addToBlockedDeliveryAddresses,
  sendingRingsTemplateLetterInProgress,
  sendingRingsTemplateLetterSuccessful,
  sendingRingsTemplateLetterFinished,
  cleanCustomerReceptionProvider,
  logoutCustomer,
  setSelectedPreferredPayment,
  setShouldMOveToOrderHistoryPage,
  removeNewAddress,
} = customerInfoSlice.actions;

export default customerInfoSlice.reducer;
