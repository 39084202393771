import { createAction } from '@reduxjs/toolkit';

import { OfferProductInterface } from 'src/types/product/product';

import { TvOffers, TopDeals } from './offersState';


export const fetchOffers_TopDeals = createAction('offers/fetchOffers_TopDeals');
export const fetchOffers = createAction('offers/fetchOffers');
export const fetchOffersSuccess = createAction<TvOffers>('offers/fetchOffersSuccess');
export const fetchTopDeals = createAction('offers/fetchTopDeals');
export const fetchTopDealsSuccess = createAction<TopDeals>('offers/fetchTopDealsSuccess');
export const resetOffers = createAction('offers/resetOffers');
export const setShowOffers = createAction<boolean>('offers/setShowOffers');
export const setAllOffers = createAction<OfferProductInterface[]>('offers/setAllOffers');
export const selectOffersType = createAction<string>('offers/selectOffersType');
export const resetAllOffers = createAction('offers/resetAllOffers');
export const fetchOffersAndTopDealsLoadingDone = createAction('offers/fetchOffersAndTopDealsLoadingDone');


export type FetchOffersSuccess = ReturnType<typeof fetchOffersSuccess>
export type FetchTopDealsSuccess = ReturnType<typeof fetchTopDealsSuccess>
export type SelectOffersType = ReturnType<typeof selectOffersType>
