import dayjs from 'dayjs';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { notificationsTickerActions, getNotifications } from 'src/redux/app/notificationsTickerSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Notification } from 'src/types/NotificationResponse';


export const getValidNotifications = (notifications: Notification[]) => {
  return notifications.filter(notification => !!notification
    && dayjs(notification.publishedFrom).isBefore(dayjs())
    && dayjs(notification.publishedTo).isAfter(dayjs()));
};

export const getNewNotifications = (notifications: Notification[]) => {
  const closedNotifications = localStorage.getItem('closedNotifications');
  return notifications.filter(item => {
    return !(closedNotifications?.includes(item.sys.id));
  });
};

export function* getNotificationsSaga() {
  try {
    notificationsTickerActions.getNotificationsInProgress();

    const apiNotifications = yield getContext(SagaContextItem.apiNotifications);
    const wilisPage = yield call(apiNotifications.getNotifications, false);

    const validNotifications = getValidNotifications(wilisPage.notifications)?.sort(
      (a: Notification, b: Notification) => a.publishedFrom < b.publishedFrom ? 1 : -1,
    );

    return yield put(
      notificationsTickerActions.getNotificationsSuccess({
        ...wilisPage,
        notifications: validNotifications,
        newNotifications: getNewNotifications(validNotifications),
      }),
    );
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not get notifications from contentful', err }));
    return yield put(notificationsTickerActions.getNotificationsFailure(err.statusCode));
  }
}

export function* watchGetNotificationsSaga() {
  yield takeLatest(getNotifications.type, getNotificationsSaga);
}
