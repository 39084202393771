import Loadable from 'react-loadable';

import LoadingSpinner from 'src/components/shared/LoadingSpinner/LoadingSpinner';
import { Routes } from 'src/routes/routes';


const CHOrderEntryLoadable = Loadable({
  loader: () => import('src/CHOrderEntry/container/CHOrderEntryView'),
  loading: () => <LoadingSpinner visible />,
});


export default {
  wilisForCH : {
    component: CHOrderEntryLoadable,
    protected: true,
    exact: true,
    path: '/chorderentry'
  }
} as Routes;