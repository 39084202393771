import {
  call,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import { ApiOrderHistory } from 'src/api/apiOrderHistory';
import { setChOrderSearchRequest, setChOrderSearchResult } from 'src/CHOrderEntry/redux/chOrderEntrySlice';
import chOrderEntrySelectors from 'src/CHOrderEntry/redux/selectors/chOrderEntrySelectors';
import { handleFailureState } from 'src/CHOrderEntry/sagas/handelFailureState';
import { openModal } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { OrdersOverviewHistoryResponse } from 'src/types/orderhistory/OrdersOverviewHistoryResponse';


export function* showCHOrderDetailsSaga() {
  const { searchRequestValue } = yield select(chOrderEntrySelectors.getOrderHistoryState);

  try {
    const apiOrderHistory: ApiOrderHistory = yield getContext(SagaContextItem.apiOrderHistory);

    const orderHistoryResponse: OrdersOverviewHistoryResponse = yield call(apiOrderHistory.getCustomerOrdersOverViewHistory, searchRequestValue);
    yield put(setChOrderSearchResult(orderHistoryResponse.orders[0]));
    yield put(openModal(Modals.chOrderHistoryModal));
  } catch (error) {
    yield handleFailureState(error, `Couldn't fetch ch Order with id ${searchRequestValue}`, undefined);
  }
}


export default function* showCHOrderDetailsWatcher() {
  yield takeLatest(setChOrderSearchRequest.type, showCHOrderDetailsSaga);
}
