import {
  call,
  getContext,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { showErrorNotification, showSuccessNotification } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { subscriptionManagementSelectors } from 'src/subscriptionProductManagement/redux/selectors/subscriptionManagementSelectors';
import {
  CancellationSubscriptionItemRequest,
  createSubscriptionStatus,
  SubscriptionCancellationFilters,
  SubscriptionItemStatusType
} from 'src/types/subscription/SubscriptionHistoryResponse';

import {
  cancelSubscription,
  resetCancelSubscriptionLoadingStatus,
  CancelSubscriptionAction,
  setSubscriptionStatus,
  CancelSubscriptionMode,
} from '../redux/subscriptionManagementSlice';


export function* cancelSubscriptionSaga({ payload: { subscriptions, reason } }: CancelSubscriptionAction) {
  try {
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);
    const cancelationMode = yield select(subscriptionManagementSelectors.getCancelSubscriptionMode);

    const selectAll: boolean = yield select(subscriptionManagementSelectors.areAllSubscriptionsSelected);
    let filters: SubscriptionCancellationFilters | undefined = undefined;

    if (selectAll && cancelationMode === CancelSubscriptionMode.MULTIPLE_CANCELATION ) {
      const subcsriptionFilter = yield select(subscriptionManagementSelectors.getSubscriptionsOverviewFetchFilters);
      const subscriptionsTotal = yield select(subscriptionManagementSelectors.gettotalSubscriptions);
      filters = {
        ...subcsriptionFilter,
        subscriptionsTotalToCancel: subscriptionsTotal
      };
    }

    const requestBody: CancellationSubscriptionItemRequest = {
      subscriptions: subscriptions,
      reason: reason,
      selectAll: selectAll,
      filters: filters

    };

    yield call(apiCallCenter.cancelSubscriptions, requestBody);

    const agentUsername: string = yield select(appSelectors.getAgentUsername);
    const newStatus = createSubscriptionStatus(
      SubscriptionItemStatusType.CANCELED,
      agentUsername,
      reason,
    );

    yield put(setSubscriptionStatus({ subscriptionsItemsIds:subscriptions, newStatus }));
    yield put(showSuccessNotification('subscriptionProductManagement.subscriptionCanceled'));
  } catch (error) {
    yield put(logEvent({
      level: LOG_LEVEL.WARN,
      message: `Couldn't cancel subscriptions: ${subscriptions}, ${reason.toString()}`,
      err: error,
    }));
    yield put(showErrorNotification());
  } finally {
    yield put(resetCancelSubscriptionLoadingStatus());
  }
}

export default function* cancelSubscriptionWatcher() {
  yield takeEvery(cancelSubscription.type, cancelSubscriptionSaga);
}
