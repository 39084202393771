import {
  call,
  getContext,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { ApiBasket } from 'src/api/apiBasket';
import { logErrorEvent } from 'src/logging/loggingActions';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { changeSalesOffice } from 'src/redux/order/salesOfficeSlice';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { getBasketId } from 'src/utils/basketId';


export function* updateBasketSaga() {
  try {
    const apiBasket: ApiBasket = yield getContext(SagaContextItem.apiBasket);
    const currentBasketId = getBasketId();
    const salesOffice = yield select(orderEntrySelector.getOrderSalesOffice);
    const salesChannel = yield select(salesChannelSelector.getSalesChannel);

    if (!currentBasketId) {
      return;
    }

    yield call(apiBasket.updateBasket, currentBasketId, salesOffice, salesChannel);
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not update sales office and sales channel', err }));
  }
}

export default function* updateBasketWatcher() {
  yield takeEvery(changeSalesOffice.type, updateBasketSaga);
}
