import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { camelizeKeys } from 'humps';

import Config from 'src/config';
import { CampaignSalesChannel, ProductDetails } from 'src/types/product/product';
import { StockResponse } from 'src/types/product/StockResponse';
import getPDSBaseURL from 'src/utils/getters/getPDSBaseURL';
import filterBaseProductNumbers from 'src/utils/mappers/filterBaseProductNumbers';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';


const httpAgent = axios.defaults.httpAgent;
const httpsAgent = axios.defaults.httpsAgent;

export interface ApiProduct {
  client: AxiosInstance;
  getProduct: (sku: string, campaignSalesChannel?: CampaignSalesChannel) => Promise<ProductDetails>;
  getProductsStock: (baseProductNos: string[]) => Promise<StockResponse>;
  getProducts: (skus: string[], campaignSalesChannel?: CampaignSalesChannel) => Promise<ProductDetails[]>;
}

export function getApiProduct(baseURL: string,proxyBaseURL: string): ApiProduct {
  const productClientProxy: AxiosInstance = getHttpClient({
    baseUrl: proxyBaseURL,
    interceptors: {
      request: localeHeaderInterceptor,
    },
  });

  const productClient: AxiosInstance = axios.create({
    baseURL: baseURL,
    httpAgent: httpAgent,
    httpsAgent: httpsAgent,
    proxy: false,
    headers: {
      'x-client-id': 'wilis-view',
    },
  });

  // check to bypass the pact failure
  productClient.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.baseURL === Config.env.productServiceUrl.at || config.baseURL === Config.env.productServiceUrl.de || config.baseURL === Config.env.productServiceUrl.ch) {
      config.baseURL = getPDSBaseURL();
    }
    return config;
  });

  const clientObject = {
    client: productClient,
    getProduct: async (sku: string, campaignSalesChannel?: CampaignSalesChannel) => {
      const salesChannelClause = campaignSalesChannel ? `&campaigns=true&sales_channels=${campaignSalesChannel}` : '';
      const response = await productClient.get(`/products/${sku}?include_additional_info=true${salesChannelClause}`);
      return camelizeKeys(response.data as object) as ProductDetails;
    },
    getProductsStock: async (baseProductNos: string[]) => {
      const baseProductNumbers = filterBaseProductNumbers(baseProductNos);
      const response = await productClientProxy.get(`/stock?base_product_no=${baseProductNumbers.join(',')}`);
      return camelizeKeys(response.data as object) as StockResponse;
    },
    getProducts: async (skus: string[], campaignSalesChannel?: CampaignSalesChannel) => {
      const salesChannelClause = campaignSalesChannel ? `&campaigns=true&sales_channels=${campaignSalesChannel}` : '';
      const response = await productClient.get(`/products?include_additional_info=true&base_product_no=${skus.join()}${salesChannelClause}`);
      return camelizeKeys(response.data.products as object[]) as ProductDetails[];
    },
  };

  return clientObject;
}
