import { CountryCode } from 'src/api/orderTypes';
import Config from 'src/config';
import countryLocalStorage from 'src/utils/countryLocalStorage';


const getFactFinderBaseURL = (): string => {
  switch (countryLocalStorage.get()) {
  case (CountryCode.at): return Config.env.factFinderApiUrl.at;
  case (CountryCode.ch): return Config.env.factFinderApiUrl.ch;
  default: return Config.env.factFinderApiUrl.de;
  }
};

export default getFactFinderBaseURL;
