
export enum OrderErrorResponseTypes {
  voucherError = 'VOUCHER_ERROR',
  emptyBasket = 'EMPTY_BASKET',
  paymentError = 'PAYMENT_ERROR',
  invalidBasketItem = 'INVALID_BASKET_ITEM',
  unexpectedError = 'UNEXPECTED_ERROR',
  timeoutError = 'TIMEOUT_ERROR',
  addressCategory1Blocked = 'ORDER_WITH_ADDRESS_CATEGORY1_NOT_ALLOWED',
}
