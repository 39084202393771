import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const createSubscriptionSelector = createSelectors({
  getParams(state: RootStateType) {
    return state.product.createSubscription;
  },
  getLoadingState(state: RootStateType) {
    return state.product.createSubscription.loading || state.orderPreCheck.loading;
  },
  getSelectedPayment(state: RootStateType) {
    return state.product.createSubscription?.selectedPaymentMethod;
  },
  getSourceChannel(state: RootStateType) {
    return state.product.createSubscription?.sourceChannel;
  },
  getSalesOffice(state: RootStateType) {
    return state.product.createSubscription?.salesOffice;
  },
  getSalesChannel(state: RootStateType) {
    return state.product.createSubscription?.salesChannel;
  },
  getErrorMessages(state: RootStateType) {
    const errorMessages = [];
    const emailIsMissing = !state.customer.info.customerResponse?.email && state.product.createSubscription?.salesChannel === '04';
    if (emailIsMissing) {
      errorMessages.push('order.CustomerWithoutEmail');
    }
    return errorMessages;
  }
});
