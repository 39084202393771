import {
  call,
  put,
  takeLatest,
  getContext,
} from 'redux-saga/effects';

import {
  fetchOrder,
  fetchOrderInProgress,
  fetchOrderSuccess,
  fetchOrderError,
  FetchOrder,
} from 'src/OrderDisplay/redux/orderDisplaySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


export function* fetchOrderSaga(action: FetchOrder) {
  try {
    const apiOrder = yield getContext(SagaContextItem.apiOrder);
    yield put(fetchOrderInProgress());
    const orderResponse = yield call(apiOrder.getOrder, action.payload);
    yield put(fetchOrderSuccess(orderResponse));
  } catch (err) {
    const errorCode = (err && err.response && err.response.status) || 400;
    yield put(fetchOrderError(errorCode));
  }
}

export default function* fetchOrderWatcher() {
  yield takeLatest(fetchOrder.type, fetchOrderSaga);
}
