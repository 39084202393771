import { RouterState } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';

import NotificationErrorIcon from 'src/components/shared/Icons/NotificationErrorIcon';
import NotificationSuccessIcon from 'src/components/shared/Icons/NotificationSuccessIcon';
import ProtectedRoute from 'src/components/shared/ProtectedRoute/ProtectedRoute';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import routes from 'src/routes/routes';
import { getCognitoRolesFromJWT } from 'src/utils/JWTParser';
import { authorizedRole } from 'src/utils/validators/isAuthorized';

import LanguageProvider from '../LanguageProvider/LanguageProvider';
import NotificationProvider from '../NotificationProvider/NotificationProvider';

import styles from './App.module.scss';


const determineRoute = (route: string, cognitoRoles: string[], router: RouterState) => {
  const selectedRoute = routes[route];

  if (selectedRoute.protected) {

    return (
      <ProtectedRoute
        key={selectedRoute.path}
        exact={selectedRoute.exact}
        path={selectedRoute.path}
        location={router.location}
        authenticated={cognitoRoles.length >= 1}
        component={selectedRoute.component}
        roleAuthorized={authorizedRole(route, cognitoRoles)}
      />
    );
  }
  return <Route {...selectedRoute} key={selectedRoute.path} />;
};

const App = () => {
  const idToken = useSelector(appSelectors.getIdToken);
  const router = useSelector(appSelectors.getRouter);
  const cognitoRoles = getCognitoRolesFromJWT(idToken ?? '');
  return (
    <LanguageProvider>
      <SnackbarProvider preventDuplicate maxSnack={5}
        classes={{
          variantSuccess: styles.success,
          variantError: styles.error,
        }}
        iconVariant={{
          success: <NotificationSuccessIcon />,
          error: <NotificationErrorIcon />,
        }}
      >
        <NotificationProvider>
          <Switch>
            {Object.keys(routes).map((route) => determineRoute(route, cognitoRoles, router!))}
          </Switch>
        </NotificationProvider>
      </SnackbarProvider>
    </LanguageProvider>
  );
};

export default App;
