import axios from 'axios';
import { decamelizeKeys } from 'humps';

import { CUSTOM_AUTH_HEADER, getAuthHeader } from 'src/api/httpClient/interceptors/xAuthorizationHeaderInterceptor';
import Config from 'src/config';


const APP_ENVIRONMENT = window.hseEnv || process.env.REACT_APP_ENVIRONMENT;

export enum LOG_LEVEL {
  WARN = 'WARN',
  ERROR = 'ERROR',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
}

export interface ApiLogging {
  loggingService: (payload: object) => Promise<void>;
  getIP: () => Promise<string>
  pushToSlack: (requestBody: object) => Promise<string>
}

export function getApiLogging(baseURL: string): ApiLogging {

  const axiosClient = axios.create();
  const basicAxiosClient = axios.create();

  axiosClient.interceptors.request.use(
    async config => {
      return {
        ...config,
        headers: {
          [CUSTOM_AUTH_HEADER]: getAuthHeader(),
        },
      };
    },
    error => Promise.reject(error),
  );

  return {
    loggingService: async function (payload: object) {
      if (APP_ENVIRONMENT === 'local') {
        payload = { ...payload, ...{ environment: 'local' } };
      }

      const body = decamelizeKeys({
        projectId: 'wilis-view',
        payload,
      });

      axiosClient.post(baseURL, JSON.stringify(body));
    },

    pushToSlack: async function(requestBody: object) {
      try {
        await basicAxiosClient.post(Config.env.slackChannelAlarmsWebhook, JSON.stringify(requestBody));
        return 'OK';
      } catch (err) {
        return 'NO';
      }
    },

    getIP: async function () {
      const response = await axiosClient.get('https://api.ipify.org/?format=text');
      return response.data as string;
    },
  };
}
