import { BillingAddress, DeliveryAddress } from 'src/types/customer/address';
import { CustomerIndicators } from 'src/types/customer/CustomerIndicators';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';
import { PhoneNumber } from 'src/types/customer/phoneNumber';
import { ReceptionProvider } from 'src/types/customer/ReceptionSurveyQuestionsData';
import { Salutation } from 'src/types/customer/Salutation';
import { CustomerBankingDetailsResponse } from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { CustomerType } from 'src/types/voucher/EmployeeDiscount';


export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  salutation: Salutation | undefined;
  dateOfBirth?: string;
  email?: string;
  billingAddress?: BillingAddress;
  deliveryAddresses: DeliveryAddress[];
  phoneNumbers: PhoneNumber[];
  origin: string;
  externalCustomerId: string;
  orderRestriction?: OrderRestriction;
  indicators?: CustomerIndicators;
  preferredPaymentMethod?: PaymentMethod;
  phoneNumber?: string;
  comments?: string;
  type?: CustomerType;
  allowUpsells?: boolean;
  consents?: CustomerConsentBooleanFlags;
  dataProtection?: DataProtectionBooleanFlags;
  skipDoubleCheck?: boolean;
  marketResearchConsent?: MarketResearchConsent;
  dplMarketResearchConsent?: MarketResearchConsent;
  originalPhoneNumber?: string;
  bankingDetails?: CustomerBankingDetailsResponse;
  ignoreAddressCheck?: boolean;
  reception?: ReceptionProvider;
  emailMarketResearchConsent?: MarketResearchConsent;
  isHelloUser?: boolean;
  pinChallenge?: PhonePinChallenge;
  isCreateDeliveryAddressAllowed?: boolean;
}

export interface PhonePinChallenge {
  type: PinChallenge;
  resetRecently: boolean;
  restoredAt?: Date;
}

export enum PinChallenge {
  FIRST_THREE_DIGITS = 'FIRST_THREE_DIGITS',
  LAST_THREE_DIGITS = 'LAST_THREE_DIGITS',
}

export enum MarketResearchConsent {
  NOT_QUERIED = 'NOT_QUERIED',
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  UNKNOWN = 'UNKNOWN',
}

export interface DataProtectionBooleanFlags {
  isRequested?: boolean;
  infoRead?: boolean;
  infoSend?: boolean;
}

export interface CustomerConsentBooleanFlags {
  exportDhlEmail?: boolean;
  emailNewsletter?: boolean;
  phoneMarketResearch?: boolean;
  emailMarketResearch?: boolean;
  printedAdvertising?: boolean;
}

export type FormFieldError = {
  [path: string]: string;
};
