import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { CountryCode } from 'src/api/orderTypes';
import { DEFAULT_DISPLAY_DATE_FORMAT } from 'src/constants/date';
import { CustomerInfoState } from 'src/redux/customer/customerInfoSlice';
import { VoucherState } from 'src/redux/order/voucherSlice';
import { CustomerBankingDetailsState, PaymentState } from 'src/redux/payment/paymentSlice';
import { DeliveryAddress, DeliveryAddressType } from 'src/types/customer/address';
import { PostalAddress } from 'src/types/customer/AddressType';
import { Customer } from 'src/types/customer/customer';
import { Salutation } from 'src/types/customer/Salutation';
import { CustomerRequest } from 'src/types/offer/Offer';
import { OfferRequest } from 'src/types/offer/OfferRequest';
import { ReklaFlag } from 'src/types/offer/ReklaFlag';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { VoucherRequest } from 'src/types/voucher/VoucherRequest';
import fromDisplayFormatToISO8601 from 'src/utils/formatters/fromDisplayFormatToISO8601';
import stringify from 'src/utils/stringify';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';
import { validateCustomer } from 'src/utils/validators/customerSchema';

import { buildPayment } from './buildPayment';


export interface OfferParams {
  customer: {
    info: CustomerInfoState;
  }
  payment: PaymentState;
  voucher: VoucherState;
  freeShipping: boolean;
  salesChannel?: string;
  basketId?: string | null;
  bankingDetails?: CustomerBankingDetailsState;
  sourceChannel?: SourceChannels;
  countryCode: CountryCode;
  deliveryAddress?: DeliveryAddress;
  reklaFlag?: ReklaFlag;
}

interface BuildOfferRequestResult {
  result: OfferRequest;
  invalidDataReason?: string;
}

const buildOfferRequest = (state: OfferParams): BuildOfferRequestResult => {
  const customer = buildCustomer(state.customer.info, state.countryCode);

  return {
    result: {
      customer: customer.result,
      deliveryAddress: state.deliveryAddress,
      payment: buildPayment(state.payment, state.bankingDetails),
      voucher: buildVoucher(state.voucher, state.salesChannel),
      isShippingCostOverwritten: state.freeShipping,
      basketId: state.basketId || undefined,
      salesChannel: state.salesChannel,
      isCustomerDataChanged: state.customer.info.isCustomerDataChanged,
      sourceChannel: state.sourceChannel,
      reklaFlag: state.reklaFlag
    } as OfferRequest,
    invalidDataReason: customer.invalidDataReason,
  };
};

interface BuildCustomerRequestResult {
  result?: CustomerRequest;
  invalidDataReason?: string;
}

export function buildCustomer(state: CustomerInfoState, countryCode: CountryCode): BuildCustomerRequestResult {
  const customer = state.customerResponse;
  if (!customer) {
    return {};
  }

  let correctedInvalidDataReason = undefined;
  if(customer.billingAddress && !(customer.billingAddress.countryCode)) {
    correctedInvalidDataReason = `Correcting billing address (${stringify(customer.billingAddress)}) to `;
    customer.billingAddress.countryCode = countryCode;
    correctedInvalidDataReason = `${correctedInvalidDataReason} (${stringify(customer.billingAddress)})`;
  }
  const customerValidationResult = Object.keys(validateCustomer(customer, state.selectedDeliveryAddress));
  if (customerValidationResult.length) {
    return { invalidDataReason: `Wrong customer field: ${stringify(customerValidationResult)}` };
  }

  if (!customer.dateOfBirth) {
    return { invalidDataReason: 'Customer date of birth is empty' };
  }

  let dateOfBirth = customer.dateOfBirth;
  if (dayjs(dateOfBirth, DEFAULT_DISPLAY_DATE_FORMAT).isValid()) {
    dateOfBirth = fromDisplayFormatToISO8601(customer.dateOfBirth) || '';
  }

  const billingAddress = customer.billingAddress;
  if (!billingAddress) {
    return { invalidDataReason: 'Billing address is empty' };
  }

  return {
    invalidDataReason: correctedInvalidDataReason,
    result: {
      dateOfBirth: dateOfBirth,
      salutation: customer.salutation || Salutation.miss,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: trimStringOrNull(customer.email),
      phone: trimStringOrNull(customer.phoneNumber),
      street: billingAddress.street,
      streetNumber: billingAddress.streetNumber,
      zipCode: billingAddress.zipCode,
      city: billingAddress.city,
      countryCode: billingAddress.countryCode,
      addressAddition: trimStringOrNull(billingAddress.addressAddition),
      skipDoubleCheck: customer.skipDoubleCheck,
      externalCustomerId: trimStringOrNull(customer.externalCustomerId),
      dplCustomerId: trimStringOrNull(customer.id),
      careOf: trimStringOrNull(billingAddress.careOf),
    } as CustomerRequest,
  };
}

export function buildInvoiceAddressFromCustomer(customer?: Customer): PostalAddress | undefined {
  if(!customer) {
    return undefined;
  }

  const billingAddress = customer.billingAddress!;

  return {
    id: uuidv4(),
    externalAddressId: customer.externalCustomerId,
    type: DeliveryAddressType.Postal,
    firstName: customer.firstName,
    lastName: customer.lastName,
    salutation: customer.salutation!,
    addressAddition: billingAddress.addressAddition,
    city: billingAddress.city,
    countryCode: billingAddress.countryCode,
    careOf: billingAddress.careOf,
    street: billingAddress.street,
    streetNumber: billingAddress.streetNumber,
    zipCode: billingAddress.zipCode,
    fromInvoice: true
  };
}

const buildVoucher = (state: VoucherState, salesChannel?: string): VoucherRequest | undefined => {
  return state && state.code ? { code: state.code, salesChannel } : undefined;
};

export default buildOfferRequest;
