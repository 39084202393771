import { CustomerInfoState } from 'src/redux/customer/customerInfoSlice';
import { CustomerBankingDetailsState } from 'src/redux/payment/paymentSlice';
import { CreateSubscriptionState } from 'src/redux/product/createSubscriptionSlice';
import { DeliveryAddress } from 'src/types/customer/address';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { SubscriptionRequest } from 'src/types/subscription/SubscriptionRequest';
import formatDateToServicesFormat from 'src/utils/formatters/formatDateToServicesFormat';

import { buildSubscriptionProductCustomer } from './buildCustomerForSubscriptionAndWaitlist';


export function buildSubscriptionRequest(
  customer: CustomerInfoState,
  createSubscriptionParams: CreateSubscriptionState,
  payment: PaymentMethod,
  salesChannel: string,
  salesOffice: string,
  bankingDetails: CustomerBankingDetailsState,
  sourceChannel: SourceChannels,
  deliveryAddress: DeliveryAddress,
) {
  const additionalPaymentFields = payment === PaymentMethod.directDebit ? {
    iban: bankingDetails.iban,
    accountHolder: bankingDetails.accountHolder,
    usesPrefilledIban: bankingDetails.usesPrefilledIban,
  } : {};

  const product = createSubscriptionParams.product;

  return {
    customer: buildSubscriptionProductCustomer(customer),
    product: {
      sku: product?.subscriptionInfo?.deliveryProductNo,
      quantity: createSubscriptionParams.quantity,
      name: product?.name,
    },
    payment: { method: payment, ...additionalPaymentFields },
    deliveryAddress,
    salesChannel: salesChannel,
    salesOffice: salesOffice,
    sourceChannel: sourceChannel,
    rotation: createSubscriptionParams.rotation,
    aboNumber: product?.baseProductNo,
    nextOrderDate: formatDateToServicesFormat(createSubscriptionParams.nextOrderDate),
  } as SubscriptionRequest;
}
