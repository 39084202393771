import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import de from 'src/i18n/de';
import { closeModal, showErrorNotification } from 'src/redux/app/appSlice';
import { addOrderItemBySku } from 'src/redux/order/orderEntrySlice';
import {
  SubscribeStockReminder,
  subscribeStockReminder,
  subscribeStockReminderFailed,
  subscribeStockReminderSuccess,
  resetStockReminderState,
} from 'src/redux/product/stockReminderSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { StockReminderRequest } from 'src/types/stockReminder/StockReminderRequest';


type MessageCode = 409 | 429 | 'other';

export function* subscribeStockReminderSagas(action: SubscribeStockReminder) {
  try {
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);

    yield call(apiCustomer.postStockReminder, action.payload as StockReminderRequest);

    yield put(subscribeStockReminderSuccess());
  } catch (err) {
    const errorCode = err?.response?.status || 400;
    yield put(subscribeStockReminderFailed(errorCode));

    if (errorCode === 409) {
      yield put(addOrderItemBySku(action.payload.sku));
      yield put(resetStockReminderState());
      yield put(closeModal(Modals.productDetails));
    }

    const code: MessageCode = [409, 429].includes(errorCode) ? errorCode : 'other';
    const message = {
      409: de['stock.reminder.subscriptionFailed.409'],
      429: de['stock.reminder.subscriptionFailed.429'],
      'other': de['stock.reminder.subscriptionFailed.other'],
    }[code];

    yield put(showErrorNotification(message));
  }
}

export default function* subscribeStockReminderSagasWatcher() {
  yield takeLatest(subscribeStockReminder.type, subscribeStockReminderSagas);
}
