import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { newSessionWithBasket } from 'src/redux/app/appSlice';
import { deleteDeliveryAddress, fetchCustomer } from 'src/redux/customer/customerInfoSlice';
import { fetchOffers_TopDeals, resetAllOffers } from 'src/redux/offers/offersActions';
import { resetNewOrder } from 'src/redux/order/newOrderSlice';
import { deleteBasketSuccess, getBasket } from 'src/redux/order/orderEntrySlice';
import {
  clearRedeemedVouchers,
  handleVoucherSession,
  resetVoucherState
} from 'src/redux/order/voucherSlice';
import { resetPayment } from 'src/redux/payment/paymentSlice';
import { resetProductStock } from 'src/redux/product/productStockSlice';
import { clearUpsells } from 'src/redux/product/productUpsellsSlice';
import { resetOriginalOrder } from 'src/redux/recreateOrder/recreateOrderSlice';
import { resetServiceVoucher } from 'src/redux/serviceVoucher/serviceVoucherActions';
import { getBasketId } from 'src/utils/basketId';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import { getLoginToCustomer, removeLoginToCustomer } from 'src/utils/loginToCustomer';


export function* onNewSessionWithBasket() {
  try {
    const basketId = getBasketId();
    const customerId = window.sessionStorage.getItem('customerId');
    const customerToLoging = getLoginToCustomer();
    yield put(resetOriginalOrder());
    yield put(resetNewOrder());
    yield put(resetProductStock());
    yield put(clearUpsells());
    yield put(resetVoucherState());
    yield put(fetchOffers_TopDeals());
    yield put(resetAllOffers());
    yield put(resetServiceVoucher());
    yield put(resetPayment());
    yield put(deleteDeliveryAddress());
    yield put(clearRedeemedVouchers());
    yield put(handleVoucherSession());
    if (customerId) {
      yield put(fetchCustomer(customerId));
    } else if (customerToLoging) {
      yield put(fetchCustomer(customerToLoging));
      removeLoginToCustomer();
    } else if (basketId) {
      yield put(getBasket());
    }

    if (basketId === null) {
      yield put(deleteBasketSuccess());
    }

  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'Hoppla... Neue Sitzung konnte nicht geöffnet werden, Bitte versuchen Sie es erneut',
      'Could not start new session with basket',
      err,
    );
  }
}

export default function* onNewSessionWithBasketWatcher() {
  yield takeLatest(newSessionWithBasket.type, onNewSessionWithBasket);
}
