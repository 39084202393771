
const isPaymentError = (errorKey?: string) => errorKey?.includes('invalid_payment_method');

const isInstallmentsPaymentError = (errorKey?: string) => {
  return errorKey?.includes('order_amount_not_eligible_for_installments_payment') ||
    errorKey?.includes('invalid_installments_payment');
};

export {
  isPaymentError,
  isInstallmentsPaymentError,
};
