import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { showErrorNotification } from 'src/redux/app/appSlice';
import {
  requestNewPhonePinAction,
  RequestNewPhonePinActionType,
  requestNewPhonePinFailed,
  requestNewPhonePinSuccess,
} from 'src/redux/customer/CustomerPinSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


export const phonePinAlreadySentErrorKey = '/dpl/cs/errors/phone_pin_already_sent';

export function* requestNewCustomerPinSaga(action: RequestNewPhonePinActionType) {
  try {
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    yield call(apiCustomer.requestNewPhonePin, action.payload);
    yield put(requestNewPhonePinSuccess());
  } catch (err) {
    yield put(requestNewPhonePinFailed());
    const errorKey = err.response?.data?.key;
    if (errorKey === phonePinAlreadySentErrorKey) {
      yield put(showErrorNotification('customer.requestNewVerificationPin.alreadySent'));
    } else {
      yield put(
        logEvent({
          level: LOG_LEVEL.ERROR,
          message: `Couldn't request new customer pin for customer with id ${action.payload})`,
          err: err,
        }),
      );

      yield put(showErrorNotification('customer.requestNewVerificationPin.error_request'));
    }
  }
}

export default function* requestNewCustomerPinWatcher() {
  yield takeLatest(requestNewPhonePinAction.type, requestNewCustomerPinSaga);
}
