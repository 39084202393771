export default {
  'reception.survey.title': 'Empfangswegabfrage',
  'reception.survey.button.ok': 'OK',
  'reception.survey.button.ask_later': 'Später nachfragen',
  'reception.survey.question': 'Frage {number}',
  'reception.survey.question.1': '„Wie empfangen Sie HSE hauptsächlich?“',
  'reception.survey.question.2.cable': '„Empfangen Sie HSE über?“',
  'reception.survey.question.2.ip_tv': '„Über welchen Anbieter?“',
  'reception.survey.question.2.satellite_digital': '„Empfangen Sie HSE über Satellit digital in HD-Qualität?“',
  'reception.survey.question.3': '„Empfangen Sie HSE über Kabel digital in HD-Qualität?“',
  'reception.survey.answers.readout': 'Die folgenden Antworten bitte vorlesen',
  'reception.survey.answers.not_readout': 'Die folgenden Antworten bitte nicht vorlesen',
  'reception.survey.question.choices.satellite_digital': 'Satellit digital (mehr als 1000 Programme)',
  'reception.survey.question.choices.cable': 'Kabel',
  'reception.survey.question.choices.dvb_t2_hd': 'DVB-T2 HD',
  'reception.survey.question.choices.ip_tv': 'IP-TV',
  'reception.survey.question.choices.ip_tv.vodafone': 'Vodafone',
  'reception.survey.question.choices.ip_tv.magenta_tv': 'T-Home Entertain',
  'reception.survey.question.choices.satellite_digital.hd': 'ja (mit HD Logo oben links am TV)',
  'reception.survey.question.choices.satellite_digital.no_hd': 'nein (kein HD Logo)',
  'reception.survey.question.choices.cable.no_analog_cable': 'Kabel digital (ca 60-100 Programme ggf. mit HD)',
  'reception.survey.question.choices.cable.analog_cable': 'Kabel analog (ca 35-40 Programme)',
  'reception.survey.question.choices.internet': 'Internet',
  'reception.survey.question.choices.unknown': 'Kunde kann es nicht sagen / weiß es nicht',
};
