export interface SalesOffice {
  id: string;
  name: string;
}

export enum SourceChannels {
  ECOM = 'ECOM',
  IVR = 'IVR',
  MAIL = 'MAIL',
  OUTB = 'OUTB',
  SCHR = 'SCHR',
  TELE = 'TELE'
}
