export default {
  'offers.allOffers' : 'All offers',
  'offers.allBroadcasts' : 'All broadcasts',
  'offers.refreshOffers' : 'Update products',
  'topDeals.daily' : 'DAILY OFFERS',
  'topDeals.weekly' : 'WEEKLY OFFERS',
  'topDeals.monthly' : 'MONTHLY OFFERS',
  'tvOffers.hse24' : 'HSE',
  'tvOffers.hse24_extra' : 'HSE EXTRA',
  'tvOffers.hse24_trend' : 'HSE TREND',
};
