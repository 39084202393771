import { ComponentClass, FunctionComponent } from 'react';
import Loadable, { LoadableComponent } from 'react-loadable';

import CHOrderEntryRoutes from 'src/CHOrderEntry/container/CHOrderEntryRoutes';
import editedOrdersHistoryRoutes from 'src/editedorder/routes/routes';
import subscriptionProductManagementRoutes from 'src/subscriptionProductManagement/SubscriptionProductManagementRoutes';


const Loading = () => <p>Loading...</p>;

const Landing = Loadable({
  loader: () => import('src/containers/Landing/Landing'),
  loading: Loading,
});

const RejectionManagement = Loadable({
  loader: () => import('src/OrderDisplay/RejectionManagementView'),
  loading: Loading,
});

const Root = Loadable({
  loader: () => import('src/containers/Root/Root'),
  loading: Loading,
});

const OrderView = Loadable({
  loader: () => import('src/OrderDisplay/OrderView'),
  loading: Loading,
});

const OrderHistoryView = Loadable({
  loader: () => import('src/containers/OrderHistoryView/OrderHistoryView'),
  loading: Loading,
});

const OAuthSignIn = Loadable({
  loader: () => import('src/containers/OAuthSignIn/OAuthSignIn'),
  loading: Loading,
});

const UnAuthorized = Loadable({
  loader: () => import('src/components/UnauthorizedView/UnauthorizedView'),
  loading: Loading,
});

const EditOrderView = Loadable({
  loader: () => import('src/containers/EditOrderView/EditOrderView'),
  loading: Loading,
});

const WaitlistHistoryView = Loadable({
  loader: () => import('src/containers/WaitlistHistoryView/WaitlistHistoryView'),
  loading: Loading,
});

const CustomerVouchersView = Loadable({
  loader: () => import('src/containers/CustomerVouchersView/CustomerVouchersView'),
  loading: Loading,
});

const SubscriptionHistoryView = Loadable({
  loader: () => import('src/containers/SubscriptionHistoryView/SubscriptionHistoryView'),
  loading: Loading,
});

const PreviewLetterView = Loadable({
  loader: () => import('src/previewLetter/PreviewLetterView'),
  loading: Loading,
});

const BotRequestView = Loadable({
  loader: () => import('src/components/BotRequest/BotRequestView'),
  loading: Loading,
});

interface RouteDefinition {
  exact?: boolean;
  path: string;
  protected: boolean;
  component: ComponentClass | (FunctionComponent & LoadableComponent);
}

export interface Routes {
  [propName: string]: RouteDefinition;
}

const routes: Routes = {
  root: {
    component: Landing,
    exact: true,
    protected: true,
    path: '/',
  },
  orderApp: {
    component: Root,
    exact: true,
    protected: true,
    path: '/orderapp',
  },
  inflightOrderApp: {
    component: Root,
    exact: true,
    protected: true,
    path: '/inflightorder',
  },
  rejectionMgmt: {
    component: RejectionManagement,
    exact: true,
    protected: true,
    path: '/rejectionmgmt',
  },
  orderRejection: {
    component: OrderView,
    protected: true,
    path: '/orders/:id',
  },
  OrderHistory: {
    component: OrderHistoryView,
    protected: true,
    exact: true,
    path: '/orderhistory/:customerId',
  },
  oauthSignIn: {
    component: OAuthSignIn,
    exact: true,
    protected: false,
    path: '/oauth-sign-in',
  },
  unAuthorized: {
    component: UnAuthorized,
    exact: true,
    protected: false,
    path: '/unauthorized',
  },
  EditOrder: {
    component: EditOrderView,
    protected: true,
    exact: true,
    path: '/edit-order/:orderId',
  },
  WaitlistHistory: {
    component: WaitlistHistoryView,
    protected: true,
    exact: true,
    path: '/waitlisthistory/:customerId',
  },
  CustomerVouchers: {
    component: CustomerVouchersView,
    protected: true,
    exact: true,
    path: '/customervoucher/:customerId',
  },
  SubscriptionHistoryView: {
    component: SubscriptionHistoryView,
    protected: true,
    exact: true,
    path: '/subscriptionhistory/:externalCustomerId',
  },
  PreviewLetterView: {
    component: PreviewLetterView,
    protected: true,
    exact: true,
    path: '/letters/:fileName',
  },
  BotRequestView: {
    component: BotRequestView,
    protected: false,
    exact: true,
    path: '/bot-request',
  },
  ...subscriptionProductManagementRoutes,
  ...editedOrdersHistoryRoutes,
  ...CHOrderEntryRoutes
};

export default routes;
