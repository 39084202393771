import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateOrderResultResponse } from 'src/types/offer/CreateOrderResultResponse';
import { DeliveryTimeMessage } from 'src/types/product/DeliveryTime';


export interface NewOrderState {
    loading: boolean;
    lastLoading?: boolean;
    orderResponse?: CreateOrderResultResponse;
    orderDeliveryTimes: DeliveryTimeMessage[];
    error?: boolean;
    errorCode?: number;
}

export const newOrderInitialState: NewOrderState = {
  loading: false,
  orderDeliveryTimes: [],
};

const newOrderSlice = createSlice({
  name: 'newOrder',
  initialState: newOrderInitialState,
  reducers: {
    requestNewOrder(state) {
      state.lastLoading = state.loading;
      state.loading = true;
    },
    newOrderSuccess(state, { payload }: PayloadAction<CreateOrderResultResponse>) {
      state.loading = false;
      state.orderResponse = payload;
    },
    newOrderError(state, { payload }: PayloadAction<number | undefined>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    resetNewOrder() {
      return newOrderInitialState;
    },
    setOrderDeliveryTimes(state, { payload }: PayloadAction<DeliveryTimeMessage[]>) {
      state.orderDeliveryTimes = payload;
    },
  },
});

export const {
  requestNewOrder,
  newOrderSuccess,
  newOrderError,
  resetNewOrder,
  setOrderDeliveryTimes,
} = newOrderSlice.actions;

export default newOrderSlice.reducer;
