import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { InflightOrder } from 'src/types/inflightOrder/InflightOrder';
import { Steps } from 'src/types/inflightOrder/InflightOrderRequest';
import { ProductDetails } from 'src/types/product/product';


export interface InflightOrderError {
  title?: string;
  detail: string;
}

export interface InflightOrderState {
  loading: boolean;
  error?: InflightOrderError;
  inflightOrder?: InflightOrder;
  notification?: Steps;
  inflightOrderProduct?: ProductDetails;
}

export const inflightOrderInitialState: InflightOrderState = {
  loading: false,
};

export const fetchInflightOrder = createAction<number>('inflightOrder/fetchInflightOrder');
export const loadInflightOrder = createAction<InflightOrder>('inflightOrder/loadInflightOrder');

const inflightOrderSlice = createSlice({
  name: 'inflightOrder',
  initialState: inflightOrderInitialState,
  reducers: {
    fetchInflightOrderSuccess(state, { payload }: PayloadAction<InflightOrder>) {
      state.loading = false;
      state.inflightOrder = payload;
      state.notification = payload.order.step;
    },
    fetchInflightOrderProduct(state, { payload }: PayloadAction<ProductDetails>) {
      state.inflightOrderProduct = payload;
    },
    fetchInflightOrderFailure(state, { payload }: PayloadAction<InflightOrderError>) {
      state.loading = false;
      state.error = payload;
    },
    resetInflightOrder() {
      return inflightOrderInitialState;
    },
    resetLoadInflightOrder(state) {
      state.loading = false;
    },
  },
  extraReducers: builder =>  {
    builder
      .addCase( fetchInflightOrder,(state) => {
        state.loading = true;
      })
      .addCase( loadInflightOrder,(state) => {
        state.loading = true;
      });
  },
});

export const {
  fetchInflightOrderSuccess,
  fetchInflightOrderFailure,
  fetchInflightOrderProduct,
  resetInflightOrder,
  resetLoadInflightOrder,
} = inflightOrderSlice.actions;

export default inflightOrderSlice.reducer;
