import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { openModal } from 'src/redux/app/appSlice';
import { initializeCreateWaitlistItem } from 'src/redux/product/createWaitlistItemSlice';
import { Modals } from 'src/types/Modals';
import Log from 'src/utils/log';


export function* initializeCreateWaitlistItemSaga() {
  try {
    yield put(openModal(Modals.waitlist));
  } catch (err) {
    yield call(Log.error, err);
  }
}

export function* initializeCreateWaitlistItemWatcher() {
  yield takeLatest(initializeCreateWaitlistItem.type, initializeCreateWaitlistItemSaga);
}
