import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { DEFAULT_DISPLAY_DATE_FORMAT, DEFAULT_SERVICES_DATE_FORMAT } from 'src/constants/date';


dayjs.extend(customParseFormat);

function formatDateToServicesFormat(date: string | undefined): string | undefined {
  if (dayjs(date, DEFAULT_SERVICES_DATE_FORMAT, true).isValid()) {
    return date;
  }

  const birthday = dayjs(date, DEFAULT_DISPLAY_DATE_FORMAT, true).format(
    DEFAULT_SERVICES_DATE_FORMAT,
  );
  return birthday !== 'Invalid Date' ? birthday : undefined;
}

export default formatDateToServicesFormat;
