const getRandomProductAddedConfirmationMessage = (): string => {
  const strings: string[] = [
    'orderentry.productAdded.VERY_NICE_CHOICE',
    'orderentry.productAdded.GOOD_CHOICE',
    'orderentry.productAdded.VERY_POPULAR_TODAY',
    'orderentry.productAdded.BESTSELLERS_WITH_US',
    'orderentry.productAdded.WONDERFUL_DECISION',
    'orderentry.productAdded.EXCELLENT_CHOICE',
  ];
  const randomIndex: number = Math.floor(Math.random() * strings.length);
  return strings[randomIndex];
};

export default getRandomProductAddedConfirmationMessage;
