import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Order, StatusHistoryEntry } from 'src/api/orderTypes';
import { CustomerResponse } from 'src/types/customer/CustomerResponse';


export interface OrderDisplayState {
    loading: boolean;
    orderResponse?: Order;
    error?: boolean;
    errorCode?: number;
}

export const orderDisplayInitialState: OrderDisplayState = {
  loading: false,
};

export const resubmitOrder = createAction<Order>('orderDisplay/resubmitOrder');
export type ResubmitOrder = ReturnType<typeof resubmitOrder>;

export const openOrderInfo = createAction<string>('orderDisplay/openOrderInfo');
export type OpenOrderInfo = ReturnType<typeof openOrderInfo>;

export const fetchOrder = createAction<string>('orderDisplay/fetchOrder');
export type FetchOrder = ReturnType<typeof fetchOrder>;


export const cancelRejectedOrder = createAction<string>('orderDisplay/cancelRejectedOrder');
export type CancelRejectedOrder = ReturnType<typeof cancelRejectedOrder>;

const orderDisplaySlice = createSlice({
  name: 'orderDisplay',
  initialState: orderDisplayInitialState,
  reducers: {
    fetchOrderInProgress(state) {
      state.loading = true;
    },
    fetchOrderSuccess(state, { payload }: PayloadAction<Order>) {
      state.loading = false;
      state.orderResponse = payload;
    },
    fetchOrderError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    resubmitOrderSuccess(state, { payload }: PayloadAction<StatusHistoryEntry[]>) {
      state.loading = false;
            state.orderResponse!.statusHistory = payload;
    },
    resubmitOrderFailed(state) {
      state.loading = false;
    },
    updateOrder(state, { payload }: PayloadAction<Order>) {
      state.loading = true;
      state.orderResponse = payload;
    },
    updateOrderSuccess(state, { payload }: PayloadAction<Order>) {
      state.loading = false;
      state.orderResponse = payload;
    },
    updateOrderFailed(state) {
      state.loading = false;
      state.error = true;
    },
    cancelRejectedOrderSuccess(state, { payload }: PayloadAction<StatusHistoryEntry[]>) {
      state.loading = true;
            state.orderResponse!.statusHistory = payload;
    },
    cancelRejectedOrderFailed(state) {
      state.loading = false;
      state.error = true;
    },
    updateCustomer(state, { payload }: PayloadAction<CustomerResponse>) {
            state.orderResponse!.customer = payload;
    },
    prepareOrderRecreation(state) {
      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase( resubmitOrder,(state) => {
        state.loading = true;
      })
      .addCase( cancelRejectedOrder,(state) => {
        state.loading = true;
      });
  },
});

export const {
  fetchOrderInProgress,
  fetchOrderSuccess,
  fetchOrderError,
  resubmitOrderSuccess,
  resubmitOrderFailed,
  updateOrder,
  updateOrderSuccess,
  updateOrderFailed,
  cancelRejectedOrderSuccess,
  cancelRejectedOrderFailed,
  updateCustomer,
  prepareOrderRecreation,
} = orderDisplaySlice.actions;

export type UpdateOrder = ReturnType<typeof updateOrder>;

export default orderDisplaySlice.reducer;
