import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiLetterPreview } from 'src/api/apiLetterPreview';
import {
  fetchPreviewLetterSuccess,
  fetchPreviewLetter,
  PreviewLetterAction,
} from 'src/previewLetter/redux/proviewLetterSlice';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { 
  extractDetails,
  isNotFoundStatus,
  isForbiddenStatus
} from 'src/utils/errorStatusChecks';


export function *getPreviewLetterSaga(action: PreviewLetterAction){
  try {
    const apiPreviewLetter: ApiLetterPreview = yield getContext(SagaContextItem.apiPreviewLetter);

    const fileBlob = yield call(apiPreviewLetter.getPreviewLetter, action.payload);

    yield put(fetchPreviewLetterSuccess(fileBlob));
  } catch (error) {
    if(isNotFoundStatus(error)){
      yield put(showErrorNotification('Datei nicht gefunden!'));
    }if(isForbiddenStatus(error)){
      yield put(showErrorNotification('Vorschau nicht erlaubt!'));
    }else{
      yield put(showErrorNotification(extractDetails(error)));
    }
  }
}

export default function* letterPreviewWatcher() {
  yield takeLatest(fetchPreviewLetter.type, getPreviewLetterSaga);
}
