import { CountryCode } from 'src/api/orderTypes';
import countryLocalStorage from 'src/utils/countryLocalStorage';


const getBasketIdKey = (): string => {
  const countryCode = countryLocalStorage.get();
  return countryCode === CountryCode.ch ? 'basketId_CH' : 'basketId';
};

export const getBasketId = () => {

  return window.localStorage.getItem(getBasketIdKey());
};

export const addBasketId = (basketId: string) => {
  window.localStorage.setItem(getBasketIdKey(), basketId);
};

export const removeBasketId = () => {
  window.localStorage.removeItem(getBasketIdKey());
};

const DELETED_BASKETS_TOKEN = 'deleted_baskets';

export enum BasketDeletionSource {
  NEW_SESSION = 'NEW_SESSION',
  ORDER_CREATION = 'ORDER_CREATION',
}

const TRACK_BASKETS_MAX = 3;

const trackDeletedBasket = (source: BasketDeletionSource, id?: string) => {
  try {
    if(!id) {
      return;
    }
    const usedBaskets = localStorage.getItem(DELETED_BASKETS_TOKEN);
    localStorage.setItem(
      DELETED_BASKETS_TOKEN,
      (usedBaskets?.split(',')?.length ?? 0) > TRACK_BASKETS_MAX ? `${id}:${source}` : `${usedBaskets},${id}:${source}`
    );
  } catch (err) {
    return;
  }
};

export const trackNewSessionDeletedBasket = (id?: string) => trackDeletedBasket(BasketDeletionSource.NEW_SESSION, id);

export const trackCreatedOrderDeletedBasket = (id?: string) => trackDeletedBasket(BasketDeletionSource.ORDER_CREATION, id);

export const isBasketUsedPreviously = (id: string): BasketDeletionSource | undefined => {
  try {
    const deletedBasket = localStorage.getItem(DELETED_BASKETS_TOKEN)
      ?.split(',')
      ?.find(i => i.includes(id));

    return deletedBasket === `${id}:${BasketDeletionSource.ORDER_CREATION}` ? BasketDeletionSource.ORDER_CREATION :
      (deletedBasket === `${id}:${BasketDeletionSource.NEW_SESSION}` ? BasketDeletionSource.NEW_SESSION : undefined);
  } catch (err) {
    return undefined;
  }
};

export const generateQueryString = (basketId: string | null, salesOffice: string, salesChannel: string, isB2BOrder?: boolean): string => {
  const baseQueryString = `?sales_office=${salesOffice}&sales_channel=${salesChannel}`;

  return (!basketId && isB2BOrder) ? `${baseQueryString}&pregenerate_erp_number=true`
    : (!basketId) ? baseQueryString
      : (isB2BOrder) ? '?pregenerate_erp_number=true'
        : '';
};
