import { PhoneNumber, PhoneNumberType } from 'src/types/customer/phoneNumber';


const getOrdredCustomerPhoneNumber = (phoneNumbers: PhoneNumber[]) => {
  let customerPhoneNumber = '';

  const phoneNumbersHasAtype = (type: string) => {
    return phoneNumbers.some(val => val.phoneNumberType === type);
  };

  phoneNumbers.forEach(phone => {
    if (phone?.phoneNumberType === PhoneNumberType.PRIVATE) {
      customerPhoneNumber = phone.value;
    } else if (
      phone?.phoneNumberType === PhoneNumberType.MOBILE &&
      !phoneNumbersHasAtype(PhoneNumberType.PRIVATE)
    ) {
      customerPhoneNumber = phone.value;
    } else if (
      phone?.phoneNumberType === PhoneNumberType.BUSINESS &&
      !phoneNumbersHasAtype(PhoneNumberType.PRIVATE) &&
      !phoneNumbersHasAtype(PhoneNumberType.MOBILE)
    ) {
      customerPhoneNumber = phone.value;
    } else if (
      phone?.phoneNumberType === PhoneNumberType.FAX &&
      !phoneNumbersHasAtype(PhoneNumberType.PRIVATE) &&
      !phoneNumbersHasAtype(PhoneNumberType.MOBILE) &&
      !phoneNumbersHasAtype(PhoneNumberType.BUSINESS)
    ) {
      customerPhoneNumber = phone.value;
    }
  });

  const phoneNumber = customerPhoneNumber.
    replace(/[^0-9]+/ig, '')
    .replace(/^0+/, '')
  ;

  return {
    phoneNumber: phoneNumber ? '0' + phoneNumber : customerPhoneNumber,
    originalPhoneNumber: customerPhoneNumber,
  };
};

export default getOrdredCustomerPhoneNumber;
