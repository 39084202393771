import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import {
  call,
  put,
  takeEvery,
  getContext,
  takeLatest,
  take,
  select,
} from 'redux-saga/effects';

import { ApiOffers } from 'src/api/apiOffers';
import { ApiProduct } from 'src/api/apiProduct';
import { ProductSearchService } from 'src/api/ProductSearchService/ProductSearchService';
import {
  fetchTopDealsSuccess,
  fetchOffersSuccess,
  fetchOffers_TopDeals,
  setShowOffers,
  FetchOffersSuccess,
  FetchTopDealsSuccess,
  fetchOffersAndTopDealsLoadingDone,
} from 'src/redux/offers/offersActions';
import { TvOffers, TopDeals } from 'src/redux/offers/offersState';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { fetchProductStock, fetchProductStockSuccess } from 'src/redux/product/productStockSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { TvChannels } from 'src/types/offer/tv';
import {
  ChannelProducts,
  OfferProductInterface,
  ProductDetails,
} from 'src/types/product/product';
import { getCampaignChannelFromSalesChannel } from 'src/utils/formatters/freeShipping';
import { FilterType } from 'src/utils/getters/getFactFinderQuery';
import Log from 'src/utils/log';


export function* getOffersTopDeals() {
  try {
    const searchService: ProductSearchService = yield getContext(SagaContextItem.ProductSearchService);
    const apiOffers: ApiOffers = yield getContext(SagaContextItem.apiOffers);
    const apiProduct: ApiProduct = yield getContext(SagaContextItem.apiProduct);
    const salesChannel = yield select(salesChannelSelector.getSalesChannel);
    const campaignSalesChannel = getCampaignChannelFromSalesChannel(salesChannel);

    const channelProductHse24: ChannelProducts = yield call(apiOffers.getOffers, TvChannels.HSE24);
    const channelProductHse24Extra: ChannelProducts = yield call(apiOffers.getOffers, TvChannels.HSE24_EXTRA);
    const channelProductHse24Trend: ChannelProducts = yield call(apiOffers.getOffers, TvChannels.HSE24_TREND);

    const hse24Products: ProductDetails[] = channelProductHse24.productNos.length > 0 ?
      yield call(apiProduct.getProducts, channelProductHse24.productNos, campaignSalesChannel) : [];
    const hse24ExtraProducts: ProductDetails[] = channelProductHse24Extra.productNos.length > 0 ?
      yield call(apiProduct.getProducts, channelProductHse24Extra.productNos, campaignSalesChannel) : [];
    const hse24TrendProducts: ProductDetails[] = channelProductHse24Trend.productNos.length > 0 ?
      yield call(apiProduct.getProducts, channelProductHse24Trend.productNos, campaignSalesChannel) : [];

    const tvOffers: TvOffers = {
      hse24: hse24Products.map(product => merge(product, channelProductHse24.productNos.find(o => o === product.baseProductNo))),
      hse24_extra: hse24ExtraProducts.map(product => merge(product, channelProductHse24Extra.productNos.find(o => o === product.baseProductNo))),
      hse24_trend: hse24TrendProducts.map(product => merge(product, channelProductHse24Trend.productNos.find(o => o === product.baseProductNo))),
    };

    yield put(fetchOffersSuccess(tvOffers));

    const dailyTopDeals: OfferProductInterface[] = yield call(searchService.getTopDeals, FilterType.Day);
    const weeklyTopDeals: OfferProductInterface[] = yield call(searchService.getTopDeals, FilterType.Week);
    const monthlyTopDeals: OfferProductInterface[] = yield call(searchService.getTopDeals, FilterType.Month);

    const dailyTopDealsProducts: ProductDetails[] = dailyTopDeals.length > 0 ?
      yield call(apiProduct.getProducts, dailyTopDeals.map(offer => offer.baseProductNo), campaignSalesChannel) : [];
    const weeklyTopDealsProducts: ProductDetails[] = weeklyTopDeals.length > 0 ?
      yield  call(apiProduct.getProducts, weeklyTopDeals.map(offer => offer.baseProductNo), campaignSalesChannel) : [];
    const monthlyTopDealsProducts: ProductDetails[] = monthlyTopDeals.length > 0 ?
      yield  call(apiProduct.getProducts, monthlyTopDeals.map(offer => offer.baseProductNo), campaignSalesChannel) : [];

    const topDeals: TopDeals = {
      daily: dailyTopDealsProducts.map(p => {
        const dailyTopDeal = dailyTopDeals.find(d => d.baseProductNo === p.baseProductNo);
        const mergedProduct = merge(p, dailyTopDeal);

        return {
          ...mergedProduct,
          name: {
            long: dailyTopDeal?.name as string
          }
        };
      }),
      weekly: weeklyTopDeals.map(p => merge(p, weeklyTopDealsProducts.find(d => d.baseProductNo === p.baseProductNo))),
      monthly: monthlyTopDeals.map(p => merge(p, monthlyTopDealsProducts.find(d => d.baseProductNo === p.baseProductNo))),
    };

    yield put(fetchTopDealsSuccess(topDeals));
  } catch (err) {
    yield call(Log.error, err);
  } finally {
    yield put(fetchOffersAndTopDealsLoadingDone());
  }
}

export function* fetchOffersSuccessSaga(action: FetchOffersSuccess) {
  try {
    const {
      hse24: offersHSE24,
      hse24_extra: offersHSE24_EXTRA,
      hse24_trend: offersHSE24_TREND,
    } = action.payload;

    let products: ProductDetails[] = [...offersHSE24, ...offersHSE24_EXTRA, ...offersHSE24_TREND];
    products = uniqBy(products, p => p.baseProductNo);

    if (products.length > 0) {
      yield put(fetchProductStock(products.map((item: ProductDetails) => item.baseProductNo)));
    }

    yield take(fetchProductStockSuccess.type);

    yield put(setShowOffers(true));
  } catch (err) {
    yield call(Log.error, err);
  }
}

export function* fetchTopDealsSuccessSaga(action: FetchTopDealsSuccess) {

  try {

    const {
      daily: dailyTopDeals,
      weekly: weeklyTopDeals,
      monthly: monthlyTopDeals,
    } = action.payload;


    let products: ProductDetails[] = [...dailyTopDeals, ...weeklyTopDeals, ...monthlyTopDeals];
    products = uniqBy(products, p => p.baseProductNo);

    if (products.length > 0) {
      yield put(fetchProductStock(products.map((item: ProductDetails) => item.baseProductNo)));
    }


    yield put(setShowOffers(true));
  } catch (err) {
    yield call(Log.error, err);
  }
}

export default function* getOffers_TopDealsWatcher() {
  yield takeEvery(fetchOffers_TopDeals.type, getOffersTopDeals);
  yield takeLatest(fetchOffersSuccess.type, fetchOffersSuccessSaga);
  yield takeLatest(fetchTopDealsSuccess.type, fetchTopDealsSuccessSaga);
}
