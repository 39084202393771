import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  ItemResponse,
  Order,
  PaymentResponse,
} from 'src/api/orderTypes';
import { setCountryState } from 'src/redux/app/appSlice';
import { storeSelectedDeliveryAddressOrUseBilling } from 'src/redux/customer/customerInfoSlice';
import { setGlobalLoadingFlag } from 'src/redux/order/orderEntrySlice';
import { setSalesChannel } from 'src/redux/order/salesChannelSlice';
import { changeSalesOffice } from 'src/redux/order/salesOfficeSlice';
import { changeSourceChannel } from 'src/redux/order/sourceChannelSlice';
import { changeVoucher } from 'src/redux/order/voucherSlice';
import { storeSelectedPaymentMethod } from 'src/redux/payment/paymentSlice';
import {
  addOriginalOrderErrors,
  FailedRecreateOrderStep,
  fetchOriginalOrderSuccess,
  loadOriginalOrder,
  loadOriginalOrderSuccess,
  RecreateOrderError,
} from 'src/redux/recreateOrder/recreateOrderSlice';
import { setServiceVoucher } from 'src/redux/serviceVoucher/serviceVoucherActions';
import { processAndGetOffer } from 'src/sagas/offer/getOffer';
import {
  addItemToBasket,
  AddItemToBasketResult,
  AddItemToBasketStatus,
} from 'src/sagas/orderEntry/addItemToBasketService';
import { deleteCurrentBasket } from 'src/sagas/orderEntry/deleteCurrentBasket';
import { fillOriginalOrderCustomer } from 'src/sagas/recreateOrder/fillOriginalOrderCustomer';
import { handleLoadOriginalOrderFailureState } from 'src/sagas/recreateOrder/handelFailureState';
import { DeliveryAddressResponse } from 'src/types/customer/address';
import { BasketItemRequest } from 'src/types/offer/BasketItemRequest';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { ServiceVoucher } from 'src/types/voucher/ServiceVoucher';
import { VoucherOrderResponse } from 'src/types/voucher/VoucherOrderResponse';
import { extractDetails } from 'src/utils/errorStatusChecks';
import { addressToString } from 'src/utils/formatters/formatAddressToString';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';


export function* loadOriginalOrderSaga(action: PayloadAction<Order>) {
  try {
    const originalOrder: Order = action.payload;

    yield put(setGlobalLoadingFlag(true));

    yield call(fillOriginalOrderMetaData, originalOrder);

    yield call(fillOriginalOrderBasket, originalOrder.items);

    yield call(fillOriginalOrderCustomer, originalOrder.customer);

    yield call(fillOriginalDeliveryAddress, originalOrder.deliveryAddress);

    yield call(fillOriginalServiceVoucher, originalOrder.serviceVoucher);

    yield call(fillOriginalVoucher, originalOrder.voucher);

    yield call(fillOriginalPayment, originalOrder.payment);

    yield put(loadOriginalOrderSuccess());
    yield put(fetchOriginalOrderSuccess(originalOrder)); // TODO: make the processes synchronous for initial check when load the page
  } catch (err) {
    yield call(handleLoadOriginalOrderFailureState, err, action.payload);
  } finally {
    yield put(setGlobalLoadingFlag(false));
  }
}

function* fillOriginalOrderBasket(items: ItemResponse[]) {
  yield call(deleteCurrentBasket);
  for (const item of items) {
    const basketItemRequest: BasketItemRequest = {
      baseProductNo: item.baseProductNo ?? toBaseProductNumber(item.variant.sku),
      sku: item.variant.sku,
      quantity: item.quantity,
      crossSell: item.crossSell ? { ...item.crossSell, sourceType: 'CROSS_SELL' } : undefined,
      source: item.crossSell,
      priceDate: item.variant.price.date ?? item.createdAt,
    };
    const result: AddItemToBasketResult = yield call(addItemToBasket, basketItemRequest);

    if(result.status !== AddItemToBasketStatus.SUCCESS) {
      const error: RecreateOrderError = {
        step: FailedRecreateOrderStep.LOAD_BASKET,
        detail: `Artikel ${basketItemRequest.sku} konnte nicht zum Warenkorb hinzugefügt werden: ${result.details}`,
      };
      yield put(addOriginalOrderErrors(error));
    }
  }

  try {
    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.LOAD_BASKET,
      detail: `Angebot konnte nach Füllen des Warenkorbs nicht berechnet werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}

function* fillOriginalOrderMetaData(order: Order) {
  yield put(setCountryState(order.customer.countryCode));
  yield put(setSalesChannel(order.salesChannel ?? '04'));
  yield put(changeSalesOffice(order.salesOffice ?? '4001'));
  yield put(changeSourceChannel(order.sourceChannel ?? SourceChannels.ECOM));
}

function* fillOriginalVoucher(voucher?: VoucherOrderResponse) {
  const voucherCode = voucher?.code;
  if (voucherCode) {
    try {
      yield put(changeVoucher(voucherCode));
      yield call(processAndGetOffer);
    } catch (err) {
      const error: RecreateOrderError = {
        step: FailedRecreateOrderStep.REDEEM_VOUCHER,
        detail: `Gutschein ${voucherCode} konnte nicht eingelöst werden: ${extractDetails(err)}`,
      };
      yield put(addOriginalOrderErrors(error));
    }
  }
}

function* fillOriginalServiceVoucher(serviceVoucher?: ServiceVoucher) {
  if(!serviceVoucher) { return; }

  try {
    yield put(setServiceVoucher(serviceVoucher));
    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.SET_SERVICE_VOUCHER,
      detail: `Dienstgutschein konnte nicht auf ${serviceVoucher} gesetzt werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}

function* fillOriginalDeliveryAddress(address: DeliveryAddressResponse) {
  try {
    yield put(storeSelectedDeliveryAddressOrUseBilling(address));
    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.SET_DELIVERY_ADDRESS,
      detail: `Lieferadresse konnte nicht auf ${addressToString(address)} gesetzt werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}

function* fillOriginalPayment(payment: PaymentResponse) {
  try {
    yield put(storeSelectedPaymentMethod(payment.method));
    yield call(processAndGetOffer);
  } catch (err) {
    const error: RecreateOrderError = {
      step: FailedRecreateOrderStep.SET_PAYMENT,
      detail: `Zahlung konnte nicht auf ${payment.method} gesetzt werden: ${extractDetails(err)}`,
    };
    yield put(addOriginalOrderErrors(error));
  }
}

export default function* loadOriginalOrderWatcher() {
  yield takeLatest(loadOriginalOrder.type, loadOriginalOrderSaga);
}
