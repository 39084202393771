import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { DEFAULT_SERVICES_DATE_FORMAT, DEFAULT_TIMEZONE } from 'src/constants/date';
import { EditMultipleSubscriptionNumbersRequest } from 'src/types/subscription/EditMultipleSubscriptionNumbersRequest';
import {
  CancellationSubscriptionItemRequest,
  SubscriptionCancellationReason,
  SubscriptionItem,
  SubscriptionItemStatus,
  SubscriptionItemStatusType,
} from 'src/types/subscription/SubscriptionHistoryResponse';
import {
  KeywordType,
  SearchDateType,
  SubscriptionStatusRequest,
} from 'src/types/subscription/SubscriptionsOverviewRequest';
import { SubscriptionProductResponse } from 'src/types/subscription/SubscriptionsOverviewResponse';

import { SubscriptionChangesHistorySortingMode } from '../types/request/SubscriptionChangesHistoryRequest';
import { SubscriptionChangeItem, SubscriptionChangesHistoryResponse } from '../types/response/SubscriptionChangesHistoryResponse';
import {
  calculateNextDeliveryDate,
  SortBy,
  SortingMode
} from '../utils/subscriptionProductManagementUtils';


export interface SubscriptionsOverviewFetchFilters {
  keywords?: string;
  status: SubscriptionStatusRequest;
  searchFrom?: Date;
  searchTo?: Date;
  searchDateType?: SearchDateType;
  keywordType?: KeywordType;
  cancellationReason?: SubscriptionCancellationReason;
  excludeSubscriptionIds?: string[];
  sortBy?: SortBy;
  sortingMode?: SortingMode;
}

export interface FetchedSubscriptionsOverviewState {
  subscriptions: SubscriptionProductResponse[];
  page: number;
  size: number;
  totalSubscriptions: number;
  hasMore: boolean;
}
export enum EditDeliveryDateFilter {
  WEEK,
  MONTH,
  SKIP
}

export enum SubscriptionOverviewView  {
  ABO_OVERVIEW,
  ABO_DETAILS
}

export interface EditDeliveryDatesState {
  subscriptions: string[];
  isSingleEdit: boolean;
  filter?: EditDeliveryDateFilter;
  currentDate?: Date;
  newDate?: Date;
  isValidDate: boolean;
  note?: string;
}

export interface SubscriptionChangesHistoryState {
  changes: SubscriptionChangeItem[];
  sortingMode: SubscriptionChangesHistorySortingMode;
  loading: boolean;
  size: number;
  offset: number;
  hasMore: boolean;
}

export interface CancelSubscriptionState {
  cancelSubscriptionInProgress: boolean;
  subscriptionsItemsIds?: string[];
  subscriptionCancellationReason?: SubscriptionCancellationReason;
  mode?: CancelSubscriptionMode
}
export enum CancelSubscriptionMode {
  MULTIPLE_CANCELATION = 'MULTIPLE_CANCELATION',
  SINGLE_CANCELATION = 'SINGLE_CANCELATION'
}

export interface SubscriptionManagementState {
  loading: boolean;
  isLoadingMore: boolean;
  currentView: number;
  subscriptionOverviewView: SubscriptionOverviewView;
  data?: SubscriptionProductResponse[];
  error?: boolean;
  errorCode?: number;
  offset: number;
  totalSubscriptions: number;
  filters: SubscriptionsOverviewFetchFilters;
  editDeliveryDates: EditDeliveryDatesState,
  cancelSubscriptionState: CancelSubscriptionState
  hasMore: boolean;
  selectedSubscriptions: string[];
  excludedSubscriptions: string[];
  selectAll: boolean;
  subscriptionDetails?: SubscriptionProductResponse;
  changesHistory: SubscriptionChangesHistoryState;
  customEvent: CustomEventState;
  copyCustomerInfoLoading?: boolean;
  editMultipleSubscriptionNumbersLoading?: boolean;
}

export interface CurrentSubscriptionOverviewViewPayload {
  subscription?: SubscriptionProductResponse;
  view: SubscriptionOverviewView;
}

export interface CustomEventState {
  note?: string,
  loading: boolean,
}

export const editSubscriptionsDeliveryDatesInitialState: EditDeliveryDatesState = {
  subscriptions: [],
  isSingleEdit: true,
  filter: undefined,
  newDate: undefined,
  isValidDate: false,
};

export const fetchSubscriptionChangesHistoryInitalState: SubscriptionChangesHistoryState = {
  loading: false,
  offset: 1,
  size: 0,
  changes: [],
  sortingMode: SubscriptionChangesHistorySortingMode.OLD_FIRST,
  hasMore: false,
};

export const cancelSubscriptionInitialState: CancelSubscriptionState = {
  cancelSubscriptionInProgress: false,
  mode: CancelSubscriptionMode.SINGLE_CANCELATION
};

export const subscriptionManagementInitialState: SubscriptionManagementState = {
  currentView: 0,
  subscriptionOverviewView: SubscriptionOverviewView.ABO_OVERVIEW,
  loading: false,
  isLoadingMore: false,
  data: [],
  offset: 1,
  totalSubscriptions: 0,
  filters: {
    status: SubscriptionStatusRequest.ALL,
    keywordType: KeywordType.DELIVERY_PRODUCT_NO,
  },
  editDeliveryDates: editSubscriptionsDeliveryDatesInitialState,
  cancelSubscriptionState: cancelSubscriptionInitialState,
  hasMore: false,
  selectedSubscriptions: [],
  excludedSubscriptions: [],
  selectAll: false,
  changesHistory: fetchSubscriptionChangesHistoryInitalState,
  customEvent: {
    loading: false,
  },
  editMultipleSubscriptionNumbersLoading: false
};

interface SubscriptionsOverviewFetchFiltersPayload {
  keywords?: string;
  keywordType?: KeywordType;
  status?: SubscriptionStatusRequest;
  searchFrom?: Date;
  searchTo?: Date;
  searchDateType?: SearchDateType;
  cancellationReason?: SubscriptionCancellationReason | null;
}

interface PartialSubscriptionsFetchFiltersPayload {
  keywords?: string;
}

interface FetchSubscriptionChangesPayload {
  page?: number;
  sortingMode?: SubscriptionChangesHistorySortingMode;
}

export const fetchSubscriptionsOverview = createAction('subscriptionManagement/FETCH_SUBSCRIPTIONS_OVERVIEW');
export const copySubscriptionsCustomerInfo = createAction('subscriptionManagement/COPY_SUBSCRIPTIONS_CUSTOMERS_DATA');

export const editDeliveryDatesActionCreator = createAction<string[]>(
  'subscriptionManagement/EDIT_DELIVERY_DATES',
);
export const submitEditDeliveryDatesActionCreator = createAction('subscriptionManagement/SUBMIT_DELIVERY_DATES_REQUEST');

export const editMultipleSubscriptionNumbersAction = createAction<EditMultipleSubscriptionNumbersRequest>('subscriptionManagement/EDIT_MULTIPLE_SUBSCRIPTION_NUMBERS');
export type EditMultipleSubscriptionNumbersAction = ReturnType<typeof editMultipleSubscriptionNumbersAction>;

export const saveSubscriptionNote = createAction<string>('subscriptionManagement/SAVE_SUBSCRIPTION_NOTE');

export const cancelSubscription = createAction<CancellationSubscriptionItemRequest>('subscriptionManagement/CANCEL_SUBSCRIPTION');
export type CancelSubscriptionAction = ReturnType<typeof cancelSubscription>

export const fetchSubscriptionChangesHistory = createAction<FetchSubscriptionChangesPayload | undefined>('subscriptionManagement/FETCH_SUBSCRIPTION_CHANGES_HISTORY');
export const sortSubscriptionChangesHistory = createAction('subscriptionManagement/SORT_SUBSCRIPTION_CHANGES_HISTORY');

export const loadCurrentSubscriptionCustomer = createAction('subscriptionManagement/LOAD_SUBSCRIPTION_CUSTOMER');

export const saveSubscriptionCustomEvent = createAction<string>('subscriptionManagement/SAVE_SUBSCRIPTION_CUSTOM_EVENT');

const subscriptionManagementSlice = createSlice({
  name: 'subscriptionManagement',
  initialState: subscriptionManagementInitialState,
  reducers: {
    setCurrentView(state, { payload }: PayloadAction<number>) {
      state.currentView = payload;
    },
    setCurrentSubscriptionDetails(state, { payload }: PayloadAction<CurrentSubscriptionOverviewViewPayload>) {
      state.subscriptionOverviewView = payload.view;
      state.subscriptionDetails = payload.subscription;
      state.changesHistory = fetchSubscriptionChangesHistoryInitalState;
    },
    fetchSubscriptionsOverviewSuccess(
      state,
      { payload }: PayloadAction<FetchedSubscriptionsOverviewState>,
    ) {
      state.data = payload.subscriptions;
      state.offset++;
      state.totalSubscriptions = payload.totalSubscriptions;
      state.hasMore = payload.hasMore;
      if (state.selectAll) {
        state.selectedSubscriptions = payload.subscriptions
          .filter(it =>
            !state.excludedSubscriptions.find(sub => sub === it.id) &&
            it.status.type !== SubscriptionItemStatusType.CANCELED,
          ).map(it => it.id);
      }
    },
    fetchSubscriptionsOverviewError(state, { payload }: PayloadAction<number>) {
      state.error = true;
      state.errorCode = payload;
    },
    emptySubscriptionsOverviewResults(state) {
      state.loading = false;
      state.isLoadingMore = false;
      state.data = [];
      state.error = undefined;
      state.errorCode = undefined;
      state.offset = 1;
      state.totalSubscriptions = 0;
      state.subscriptionOverviewView = SubscriptionOverviewView.ABO_OVERVIEW;
    },
    clearSubscriptionsOverviewFetchFilters(state) {
      state.offset = 1;
      state.totalSubscriptions = 0;
      state.filters = {
        status: SubscriptionStatusRequest.ALL,
        sortingMode: undefined,
      };
      state.subscriptionOverviewView = SubscriptionOverviewView.ABO_OVERVIEW;
    },
    clearSubscriptionsSearchFilters(state) {
      state.filters.searchDateType = undefined;
      state.filters.searchFrom = undefined;
      state.filters.searchTo = undefined;
      state.offset = 1;
      state.totalSubscriptions = 0;
      state.loading = true;
      state.filters.cancellationReason = undefined;
    },
    clearSubscriptionsSearchKeywordsFilter(state) {
      state.filters.keywords = undefined;
      state.filters.keywordType = KeywordType.DELIVERY_PRODUCT_NO;
      state.offset = 1;
      state.totalSubscriptions = 0;
      state.loading = true;
      state.selectAll = false;
      state.selectedSubscriptions = [];
    },
    setSearchKeyword: (state, { payload }: PayloadAction<string | undefined>) => {
      state.filters.keywords = payload;
    },
    setSearchKeywordType: (state, { payload }: PayloadAction<KeywordType>) => {
      state.filters.keywordType = payload;
    },
    setSubscriptionsOverviewFetchFilters(
      state,
      { payload }: PayloadAction<SubscriptionsOverviewFetchFiltersPayload>,
    ) {
      state.offset = 1;
      state.totalSubscriptions = 0;
      state.filters = {
        keywords: payload.keywords ?? state.filters.keywords,
        keywordType: payload.keywordType ?? state.filters.keywordType,
        searchFrom: payload.searchFrom ?? state.filters.searchFrom,
        searchTo: payload.searchTo ?? state.filters.searchTo,
        status: payload.status ?? state.filters.status,
        searchDateType: payload.searchDateType ?? state.filters.searchDateType,
        cancellationReason: payload.cancellationReason === null ? undefined : (payload.cancellationReason ?? state.filters.cancellationReason),
      };
      state.selectedSubscriptions = [];
      state.loading = true;
      state.subscriptionOverviewView = SubscriptionOverviewView.ABO_OVERVIEW;
      state.selectAll = false;
    },
    viewSubscriptions: (state, { payload }: PayloadAction<PartialSubscriptionsFetchFiltersPayload>) => {
      state.currentView = 0;
      state.offset = 1;
      state.filters.keywords = payload.keywords;
      state.filters.keywordType = KeywordType.DELIVERY_PRODUCT_NO;
      state.selectedSubscriptions = [];
      state.loading = true;
      state.subscriptionOverviewView = SubscriptionOverviewView.ABO_OVERVIEW;
      state.totalSubscriptions = 0;
    },
    loadSingleSubscription(state, { payload }: PayloadAction<PartialSubscriptionsFetchFiltersPayload>) {
      state.offset = 0;
      state.loading = true;
      state.subscriptionOverviewView = SubscriptionOverviewView.ABO_OVERVIEW;
      state.filters = {
        ...state.filters,
        keywords: payload.keywords,
        keywordType: KeywordType.SUBSCRIPTION_NO,
      };
    },
    resetLoadingStatus(state) {
      state.loading = false;
      state.isLoadingMore = false;
    },
    toggleItemSelection(state, { payload }: PayloadAction<string>) {
      const item = state.selectedSubscriptions.find(it => it === payload);
      const isExcluded = !!state.excludedSubscriptions.find(it => it === payload);
      if (item) {
        state.selectedSubscriptions = state.editDeliveryDates.subscriptions = state.selectedSubscriptions.filter(it => it !== payload);
        if (state.selectAll && !isExcluded) {
          state.excludedSubscriptions.push(payload);
        }
      } else {
        state.excludedSubscriptions = state.excludedSubscriptions.filter(it => it !== payload);
        state.selectedSubscriptions.push(payload);
        state.editDeliveryDates.subscriptions.push(payload);
      }
    },
    toggleSelectAll(state) {
      if (state.selectedSubscriptions.length === 0) {
        state.selectedSubscriptions = state.editDeliveryDates.subscriptions =
          state.data
            ?.filter(it => it.status.type !== SubscriptionItemStatusType.CANCELED)
            .map(it => it.id) || [];
        state.selectAll = true;
      } else {
        state.selectedSubscriptions = [];
        state.editDeliveryDates.subscriptions = [];
        state.selectAll = false;
        state.excludedSubscriptions = [];
      }
    },
    setCancelSubscriptionsItemsIds(state, { payload }: PayloadAction<string[] | undefined>) {
      state.cancelSubscriptionState.subscriptionsItemsIds = payload;
    },
    setCancelSubscriptionReason(state, { payload }: PayloadAction<SubscriptionCancellationReason | undefined>) {
      state.cancelSubscriptionState.subscriptionCancellationReason = payload;
    },
    setSubscriptionStatus(state, { payload }: PayloadAction<{ subscriptionsItemsIds: string[], newStatus: SubscriptionItemStatus}>) {

      payload.subscriptionsItemsIds.forEach((subscriptionItemId)=>{
        if (Array.isArray(state.data) ) {
          const subscriptionIndex = state.data.findIndex(sub => sub.id === subscriptionItemId);
          if (subscriptionIndex !== -1) {
            state.data[subscriptionIndex].status = payload.newStatus;
          }
        }
      });
    },
    resetCancelSubscriptionLoadingStatus(state) {
      state.cancelSubscriptionState.cancelSubscriptionInProgress = false;
    },
    changeCancelSubscriptionMode(state, { payload }: PayloadAction<CancelSubscriptionMode>){
      state.cancelSubscriptionState.mode = payload;
    },
    editDeliveryDateSuccess: state => {
      const newDate = state.editDeliveryDates.newDate;
      state.data?.forEach(subscription => {
        if ( state.editDeliveryDates.subscriptions.includes(subscription.id) ) {
          subscription.aboInfo.nextOrderDate = dayjs(newDate).format(DEFAULT_SERVICES_DATE_FORMAT);
        }
      });

      state.editDeliveryDates = {
        ...state.editDeliveryDates,
        subscriptions: [],
        isSingleEdit: true,
        filter: EditDeliveryDateFilter.MONTH,
        isValidDate: false,
      };
      state.loading = false;
    },
    setDeliveryDateFilter: (state, { payload }: PayloadAction<EditDeliveryDateFilter>) => {
      state.editDeliveryDates.filter = payload;
      if ( state.editDeliveryDates.currentDate ) {
        const selectedSubscription = state.editDeliveryDates.subscriptions[0];
        const currentRotation = state.data?.find(sub => sub.id === selectedSubscription)?.aboInfo.rotation;
        state.editDeliveryDates.newDate = calculateNextDeliveryDate(payload, state.editDeliveryDates.currentDate, currentRotation);
      } else {
        state.editDeliveryDates.newDate = calculateNextDeliveryDate(payload, new Date());
      }
    },
    setDeliveryDateNote(state, { payload }: PayloadAction<string>) {
      state.editDeliveryDates.note = payload;
    },
    setCustomEventNote(state, { payload }: PayloadAction<string | undefined>) {
      state.customEvent.note = payload;
    },
    setNewDeliveryDate: (state, { payload }: PayloadAction<Date>) => {
      const today = dayjs().startOf('day').tz(DEFAULT_TIMEZONE);
      state.editDeliveryDates = {
        ...state.editDeliveryDates,
        newDate: payload,
        isValidDate: dayjs(payload).isAfter(today) || dayjs(payload).isSame(today),
        filter: undefined,
      };
    },
    setInvalidDate: (state) => {
      state.editDeliveryDates.isValidDate = false;
    },
    editDeliveryDates: (state, { payload }: PayloadAction<string[]>) => {
      if ( payload.length === 1 ) {
        // single subscription, next month from subscription next order date
        const subscription = state.data?.find(sub => sub.id === payload[0]);
        if ( subscription ) {
          state.editDeliveryDates.currentDate = dayjs(subscription.aboInfo.nextOrderDate).toDate();
          state.editDeliveryDates.newDate = undefined;
        }
      }
      state.editDeliveryDates = {
        ...state.editDeliveryDates,
        subscriptions: payload,
        isSingleEdit: payload.length <= 1,
        filter: undefined,
        isValidDate: true,
      };
    },
    clearEditDeliveryDateState(state) {
      state.editDeliveryDates = editSubscriptionsDeliveryDatesInitialState;
    },
    fetchSubscriptionChangesHistorySuccess(state, { payload }: PayloadAction<{
      response: SubscriptionChangesHistoryResponse,
      hasMore: boolean
    }>) {
      state.changesHistory.loading = false;
      state.changesHistory.offset++;
      state.changesHistory.size = payload.response.size;
      state.changesHistory.changes = payload.response.changes;
      state.changesHistory.hasMore = payload.hasMore;
    },
    fetchSubscriptionChangesHistoryError(state) {
      state.changesHistory.loading = false;
    },
    saveSubscriptionNoteSuccess(state, { payload }: PayloadAction<string>) {
      if(state.subscriptionDetails) {
        state.subscriptionDetails.note = payload;
      }
      state.data = state.data?.map(sub => sub.id === state.subscriptionDetails?.id ? { ...sub, note: payload }:sub);
    },
    editDeliveryDateFailed(state) {
      state.loading = false;
    },
    setSearchDateType(state, { payload }: PayloadAction<SearchDateType>) {
      state.filters.searchDateType = payload;
    },
    saveSubscriptionCustomEventSuccess(state) {
      state.customEvent.loading = false;
      state.customEvent.note = undefined;
    },
    saveSubscriptionCustomEventFailed(state) {
      state.customEvent.loading = false;
    },
    editSubscriptionSuccess: (state, { payload }: PayloadAction<SubscriptionItem> ) => {
      if ( state.data?.length ) {
        state.data = state.data.map(item => item.id === payload.id ? { ...item, ...payload } : item);
      }
    },
    setSortBy: (state, { payload }: PayloadAction<SortBy>) => {
      state.offset = 1;
      state.loading = true;
      state.filters.sortingMode =
        state.filters.sortingMode === SortingMode.DESC && state.filters.sortBy === payload ?
          SortingMode.ASC : SortingMode.DESC;
      state.filters.sortBy = payload;
    },
    copySubscriptionsCustomerDone: (state) => {
      state.copyCustomerInfoLoading = false;
    },
    editMultipleSubscriptionNumbersSuccess: (state, { payload }: PayloadAction<EditMultipleSubscriptionNumbersRequest>) => {
      const { oldDeliveryProductNumber, newDeliveryProductNumber, oldSubscriptionAboNumber, newSubscriptionAboNumber } = payload;
      const hasValidDeliveryProductNumber = !!newDeliveryProductNumber && oldDeliveryProductNumber !== newDeliveryProductNumber;
      const hasValidSubscriptionAboNumber = !!newSubscriptionAboNumber && oldSubscriptionAboNumber !== newSubscriptionAboNumber;

      if ( state.data?.length ) {
        state.data = state.data.map(item => {
          const newItem = { ...item };
          if(hasValidDeliveryProductNumber && item.details.product.sku === oldDeliveryProductNumber) {
            newItem.details.product.sku = newDeliveryProductNumber!;
          }
          if(hasValidSubscriptionAboNumber && item.aboInfo.aboNumber === oldSubscriptionAboNumber) {
            newItem.aboInfo.aboNumber = newSubscriptionAboNumber!;
          }
          return newItem;
        });
      }
    
      state.editMultipleSubscriptionNumbersLoading = false;
    },
    editMultipleSubscriptionNumbersFailed: (state) => {
      state.editMultipleSubscriptionNumbersLoading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSubscriptionsOverview, state => {
        state.loading = state.offset === 1;
        state.isLoadingMore = state.offset !== 1;
      })
      .addCase(copySubscriptionsCustomerInfo, state => {
        state.copyCustomerInfoLoading = true;
      })
      .addCase( cancelSubscription, state => {
        state.cancelSubscriptionState.cancelSubscriptionInProgress = true;
      })
      .addCase( fetchSubscriptionChangesHistory,(state, { payload }: PayloadAction<FetchSubscriptionChangesPayload | undefined>) => {
        state.changesHistory.loading = true;
        state.changesHistory.offset = payload?.page ?? state.changesHistory.offset;
        state.changesHistory.sortingMode = payload?.sortingMode ?? state.changesHistory.sortingMode;
        if (payload?.page) {
          state.changesHistory.changes = [];
        }
      })
      .addCase( sortSubscriptionChangesHistory,state => {
        state.changesHistory.offset = 1;
        state.changesHistory.changes = [];
        state.changesHistory.sortingMode = state.changesHistory.sortingMode === SubscriptionChangesHistorySortingMode.OLD_FIRST ?
          SubscriptionChangesHistorySortingMode.NEW_FIRST : SubscriptionChangesHistorySortingMode.OLD_FIRST;
        state.changesHistory.loading = true;
      })
      .addCase( submitEditDeliveryDatesActionCreator,state =>{
        state.loading = true;
      })
      .addCase( saveSubscriptionCustomEvent,state => {
        state.customEvent.loading = true;
      })
      .addCase( editMultipleSubscriptionNumbersAction,state =>{
        state.editMultipleSubscriptionNumbersLoading = true;
      });
  },
});

export const {
  setCurrentView,
  fetchSubscriptionsOverviewSuccess,
  fetchSubscriptionsOverviewError,
  emptySubscriptionsOverviewResults,
  clearSubscriptionsOverviewFetchFilters,
  setSubscriptionsOverviewFetchFilters,
  resetLoadingStatus,
  toggleItemSelection,
  toggleSelectAll,
  resetCancelSubscriptionLoadingStatus,
  editDeliveryDates,
  editDeliveryDateSuccess,
  setDeliveryDateFilter,
  setNewDeliveryDate,
  setInvalidDate,
  setDeliveryDateNote,
  clearEditDeliveryDateState,
  fetchSubscriptionChangesHistorySuccess,
  fetchSubscriptionChangesHistoryError,
  setCurrentSubscriptionDetails,
  setCancelSubscriptionsItemsIds,
  setCancelSubscriptionReason,
  setSubscriptionStatus,
  clearSubscriptionsSearchKeywordsFilter,
  saveSubscriptionNoteSuccess,
  clearSubscriptionsSearchFilters,
  editDeliveryDateFailed,
  setSearchKeyword,
  setSearchKeywordType,
  viewSubscriptions,
  loadSingleSubscription,
  setSearchDateType,
  setCustomEventNote,
  saveSubscriptionCustomEventSuccess,
  saveSubscriptionCustomEventFailed,
  editSubscriptionSuccess,
  setSortBy,
  copySubscriptionsCustomerDone,
  changeCancelSubscriptionMode,
  editMultipleSubscriptionNumbersSuccess,
  editMultipleSubscriptionNumbersFailed,
} = subscriptionManagementSlice.actions;

export const subscriptionManagementReducer = subscriptionManagementSlice.reducer;
