import { AxiosInstance } from 'axios';
import { decamelizeKeys } from 'humps';

import { CheckCustomerRequest } from 'src/types/customer/CheckCustomerRequest';
import { Customer } from 'src/types/customer/customer';
import { CustomerPreferencesRequest } from 'src/types/customer/CustomerPreferencesRequest';
import { SetGuestMarketResearchRequest, UpdateMarketResearchRequest } from 'src/types/customer/UpdateMarketResearchRequest';
import { SkipCustomerPinChallengeRequest, VerifyCustomerPinChallengeRequest } from 'src/types/customer/VerifyCustomerPinChallengeRequest';
import {
  BankingDetails,
  BankingDetailsRequest,
  IbanValidationResponse,
} from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { StockReminderRequest } from 'src/types/stockReminder/StockReminderRequest';
import { CreateWaitlistItemRequest } from 'src/types/waitlist/CreateWaitlistItemRequest';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { versionHeaderInterceptor } from './httpClient/interceptors/versionHeaderInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


export interface ApiCustomer {
  axiosInstance: AxiosInstance;
  findCustomerById: (id: string) => Promise<Customer>;
  getCustomers: (params: object) => Promise<Customer[]>;
  postStockReminder: (stockReminderRequest: StockReminderRequest) => Promise<void>;
  fetchCustomerByEmail: (email: string) => Promise<Customer>;
  fetchCustomersByNameAndDateOfBirth: (checkCustomerRequest: CheckCustomerRequest) => Promise<Customer[]>;
  updatePreferredPaymentMethod: (customerId: string, preferredPaymentMethod: PaymentMethod) => Promise<void>;
  createOrReplaceBankAccount: (customerId: string, bankingDetails: BankingDetailsRequest) => Promise<void>;
  updateMarketResearchConsent: (id: string, requestBody: UpdateMarketResearchRequest) => Promise<void>;
  setGuestMarketResearchConsent: (requestBody: SetGuestMarketResearchRequest) => Promise<void>;
  createWaitlistItem: (customerId: string, requestBody: CreateWaitlistItemRequest) => Promise<void>;
  updateCustomerPreferences: (curstomerId: string, requestBody: CustomerPreferencesRequest) => Promise<void>;
  verifyPinChallenge: (requestBody: VerifyCustomerPinChallengeRequest) => Promise<void>;
  skipPinChallenge: (requestBody: SkipCustomerPinChallengeRequest) => Promise<void>;
  requestNewPhonePin: (externalCustomerId: string) => Promise<void>;
  validateIBAN: (iban: string) => Promise<IbanValidationResponse>;
}

export function getCustomerApi(baseUrl: string): ApiCustomer {
  const axiosInstance = getHttpClient({
    baseUrl,
    transform: {
      camelize: true,
    },
    interceptors: {
      response: [
        refreshTokenInterceptor,
        versionHeaderInterceptor,
      ],
      request: [
        localeHeaderInterceptor,
        xAuthorizationHeaderInterceptor,
      ],
    },
  });

  return {
    axiosInstance: axiosInstance,
    findCustomerById: (id: string) => findCustomerById(baseUrl, axiosInstance, id),
    getCustomers: (params: object) => getCustomers(baseUrl, axiosInstance, params),
    postStockReminder: (stockReminderRequest: StockReminderRequest) => postStockReminder(baseUrl, stockReminderRequest, axiosInstance),
    fetchCustomerByEmail: (email: string) => fetchCustomerByEmail(baseUrl, email, axiosInstance),
    fetchCustomersByNameAndDateOfBirth: (checkCustomerRequest: CheckCustomerRequest) => fetchCustomerByNameAndBirthday(baseUrl, checkCustomerRequest, axiosInstance),
    updatePreferredPaymentMethod: (customerId: string, preferredPaymentMethod: PaymentMethod) => updatePreferredPaymentMethod(baseUrl, customerId, preferredPaymentMethod, axiosInstance),
    createOrReplaceBankAccount: (customerId: string, bankingDetails: BankingDetailsRequest) => createOrReplaceBankAccount(baseUrl, customerId, bankingDetails, axiosInstance),
    updateMarketResearchConsent: (id: string, requestBody: UpdateMarketResearchRequest) => updateMarketResearchConsent(baseUrl, axiosInstance, id, requestBody),
    setGuestMarketResearchConsent: (requestBody: SetGuestMarketResearchRequest) => setGuestMarketResearchConsent(baseUrl, axiosInstance, requestBody),
    createWaitlistItem: (customerId: string, requestBody: CreateWaitlistItemRequest) => createWaitlistItem(baseUrl, axiosInstance, customerId, requestBody),
    updateCustomerPreferences: (customerId: string, requestBody: CustomerPreferencesRequest) => updateCustomerPreferences(baseUrl, axiosInstance, customerId, requestBody),
    verifyPinChallenge: (requestBody: VerifyCustomerPinChallengeRequest) => verifyPinChallenge(baseUrl, axiosInstance, requestBody),
    skipPinChallenge: (requestBody: SkipCustomerPinChallengeRequest) => skipPinChallenge(baseUrl, axiosInstance, requestBody),
    requestNewPhonePin: (externalCustomerId: string) => requestNewPhonePin(baseUrl, axiosInstance, externalCustomerId),
    validateIBAN: (iban: string) => validateIBAN(axiosInstance, iban),
  };
}

export async function findCustomerById(baseUrl: string, axiosInstance: AxiosInstance, id: string): Promise<Customer> {
  const response = await axiosInstance.get<Customer>(`/private/customers?external_id=${id}`);
  return response.data;
}

export async function getCustomers(baseUrl: string, axiosInstance: AxiosInstance, params: object): Promise<Customer[]> {
  const response = await axiosInstance.post('/private/customer-search', params);
  return response.data;
}

export async function getBankingDetails(baseUrl: string, axiosInstance: AxiosInstance, id: string): Promise<BankingDetails> {
  const response = await axiosInstance.get(`/private/user/banking-details?customer_id=${id}`);
  return response.data;
}

export async function postStockReminder(baseUrl: string, stockReminderRequest: StockReminderRequest, axiosInstance: AxiosInstance): Promise<void> {
  const response = await axiosInstance.post('/private/stock-reminder', decamelizeKeys(stockReminderRequest));
  return response.data;
}

export async function fetchCustomerByEmail(baseUrl: string, email: string, axiosInstance: AxiosInstance): Promise<Customer> {
  const response = await axiosInstance.get<Customer>(`/private/fetch-customer-by-email?email=${email}`);
  return response.data;
}

export async function fetchCustomerByNameAndBirthday(baseUrl: string, checkCustomerRequest: CheckCustomerRequest, axiosInstance: AxiosInstance): Promise<Customer[]> {
  const response = await axiosInstance.post('/private/fetch-customers-by-name-dob', decamelizeKeys(checkCustomerRequest));
  return response.data;
}

export async function updatePreferredPaymentMethod(baseUrl: string, customerId: string, preferredPaymentMethod: PaymentMethod, axiosInstance: AxiosInstance): Promise<void> {
  const response = await axiosInstance.patch(`/private/preferred-payment/${customerId}`, decamelizeKeys({ preferredPaymentMethod }));
  return response.data;
}

export async function createOrReplaceBankAccount(baseUrl: string, customerId: string, bankingDetails: BankingDetailsRequest, axiosInstance: AxiosInstance): Promise<void> {
  const response = await axiosInstance.post(`/private/banking-details/${customerId}`, decamelizeKeys(bankingDetails));
  return response.data;
}

export async function updateMarketResearchConsent(baseUrl: string, axiosInstance: AxiosInstance, customerId: string, requestBody: UpdateMarketResearchRequest): Promise<void> {
  const response = await axiosInstance.post(`/private/update-market-research-consent/${customerId}`, decamelizeKeys(requestBody));
  return response.data;
}

export async function setGuestMarketResearchConsent(baseUrl: string, axiosInstance: AxiosInstance, requestBody: SetGuestMarketResearchRequest): Promise<void> {
  const response = await axiosInstance.post('/private/email-market-research-consent/guest', decamelizeKeys(requestBody));
  return response.data;
}

async function createWaitlistItem(baseUrl: string, axiosInstance: AxiosInstance, customerId: string, requestBody: CreateWaitlistItemRequest): Promise<void> {
  const response = await axiosInstance.post(`/private/waitlist-item/${customerId}`, decamelizeKeys(requestBody));
  return response.data;
}

export async function updateCustomerPreferences(baseUrl: string, axiosInstance: AxiosInstance, customerId: string, requestBody: CustomerPreferencesRequest): Promise<void> {
  const response = await axiosInstance.patch(`/private/update-customer-preferences/${customerId}`, decamelizeKeys(requestBody));
  return response.data;
}

export async function verifyPinChallenge(baseUrl: string, axiosInstance: AxiosInstance, requestBody: VerifyCustomerPinChallengeRequest): Promise<void> {
  await axiosInstance.post('/private/verify-pin-challenge', decamelizeKeys(requestBody));
}

export async function skipPinChallenge(baseUrl: string, axiosInstance: AxiosInstance, requestBody: SkipCustomerPinChallengeRequest): Promise<void> {
  await axiosInstance.post('/private/skip-pin-challenge', decamelizeKeys(requestBody));
}

export async function requestNewPhonePin(baseUrl: string, axiosInstance: AxiosInstance, externalCustomerId: string): Promise<void> {
  await axiosInstance.post(`/private/customers/${externalCustomerId}/phone-pin`);
}

export async function validateIBAN (axiosInstance: AxiosInstance, iban: string): Promise<IbanValidationResponse>  {
  const response = await axiosInstance.post('/api/iban/validation', { iban });
  return response.data;
}
