import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { logErrorEvent } from 'src/logging/loggingActions';
import { CustomerInfoState } from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { deleteOrderItem } from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { CustomerBankingDetailsState } from 'src/redux/payment/paymentSlice';
import {
  createSubscription,
  createSubscriptionFailed,
  CreateSubscriptionState,
  createSubscriptionSuccess,
} from 'src/redux/product/createSubscriptionSlice';
import { createSubscriptionSelector } from 'src/redux/product/selectors/createSubscriptionSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer } from 'src/types/customer/customer';
import { OrderItem } from 'src/types/offer/OrderItem';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { buildSubscriptionRequest } from 'src/utils/buildSubscriptionRequest';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import { onDeleteOrderItemSaga } from '../orderEntry/onDeleteOrderItem';


export function* createSubscriptionSaga() {
  const customerInfo: CustomerInfoState = yield select(customerInfoSelectors.getCustomerInfo);
  const customer: Customer = customerInfo.customerResponse!;
  const createSubscriptionParams: CreateSubscriptionState = yield select(createSubscriptionSelector.getParams);

  try {
    if (createSubscriptionParams.lastLoading) {
      yield put(logErrorEvent({ message: 'sent same create subscription request multiple times :warning:' }));
    }
    const payment: PaymentMethod | undefined = yield select(createSubscriptionSelector.getSelectedPayment);
    const deliveryAddress = yield select(customerInfoSelectors.getSelectedDeliveryAddress);
    const bankingDetails: CustomerBankingDetailsState = yield select(orderEntrySelector.getCustomerBankingDetailsState);
    const salesChannel: string = yield select(createSubscriptionSelector.getSalesChannel);
    const salesOffice: string = yield select(createSubscriptionSelector.getSalesOffice);
    const sourceChannel: SourceChannels = yield select(createSubscriptionSelector.getSourceChannel);
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);
    if (payment !== undefined) {
      yield call(apiCallCenter.createSubscription, buildSubscriptionRequest(
        customerInfo,
        createSubscriptionParams,
        payment,
        salesChannel,
        salesOffice,
        bankingDetails,
        sourceChannel,
        deliveryAddress,
      ));
      yield put(createSubscriptionSuccess());
      if(createSubscriptionParams.product?.baseProductNo){
        yield call(findAndDeleteSubscriptionRelatedOrderItem, createSubscriptionParams.product.baseProductNo);
      }
    } else {
      yield call(handelCreateSubscriptionItemFailed, 'payment is undefined in Subscription state', customer, createSubscriptionParams);
    }

  } catch (err) {
    yield call(handelCreateSubscriptionItemFailed, err, customer, createSubscriptionParams);
  }
}

export function* findAndDeleteSubscriptionRelatedOrderItem(subscriptionProductSku: string){
  try{
    const orderItems: OrderItem[] = yield select(orderEntrySelector.getOrderLineItems);
    const linkedProductdetails = yield select(orderEntrySelector.getSubscribableOrderItemsDetails);
    const itemRelatedToSubscription = orderItems.find(item => {
      const productDetails = linkedProductdetails[item.baseProductNo];
      if(productDetails){
        return productDetails.baseProductNo === subscriptionProductSku;
      }
      return false;
    });
    if (itemRelatedToSubscription){
      yield call(onDeleteOrderItemSaga, deleteOrderItem(itemRelatedToSubscription));
    }
  } catch (err) {
    yield put(
      logErrorEvent({
        message: 'could not remove subscription related item from the basket',
        err: err,
      })
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handelCreateSubscriptionItemFailed(err: any, customer: Customer, createSubscriptionParams: CreateSubscriptionState) {
  yield call(
    logErrorAndShowNotification,
    `Couldn't create subscription for customer ${customer.id}, createSubscriptionParams: ${JSON.stringify(createSubscriptionParams)}`,
    'Hoppla... Fehler beim Erstellen des Abonnements',
    err,
  );
  yield put(createSubscriptionFailed());
}

export default function* createSubscriptionWatcher() {
  yield takeLatest(createSubscription.type, createSubscriptionSaga);
}
