import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { showErrorNotification } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import {
  SearchAction,
  orderTableSearchFinished,
  searchError,
  setSearchTerm,
  search,
} from 'src/redux/order/orderEntrySlice';
import { fetchProduct } from 'src/redux/product/productDetailsSlice';
import {
  fetchProductSearchResults,
  productSearchOpened,
  emptyProductSearchResults,
} from 'src/redux/product/productSearchSlice';
import getProductBySku from 'src/sagas/product/productService';
import {
  isSubscriptionProduct,
  ProductDetails,
  SearchParams,
} from 'src/types/product/product';
import isSearchTermNotSku from 'src/utils/validators/isSearchTermNotSku';

import { onAddOrderItemBySkuSaga } from './onAddOrderItem';


const { getCurrentCountry } = appSelectors;

export function* onOptimizedSearchSaga(action: SearchAction) {
  const searchTerm = action?.payload?.searchTerm;
  try {
    yield put(setSearchTerm(''));
    yield put(emptyProductSearchResults());

    if (isSearchTermNotSku(searchTerm) || searchTerm.length < 6) {
      yield put(fetchProductSearchResults({ query: searchTerm } as SearchParams));
    } else {
      const mainProduct: ProductDetails = yield call(getProductBySku, searchTerm.replace(/ /g, ''));
      if (isSubscriptionProduct(mainProduct)) {
        yield put(fetchProduct({ sku: searchTerm.replace(/ /g, '') }));
      } else {
        const countryCode = yield select(getCurrentCountry);
        if (mainProduct.countryVisibilities?.find(country => country === countryCode) === undefined) {
          yield put(showErrorNotification(`product.wrongCountry.${countryCode}`));
          yield put(fetchProduct({ sku: searchTerm.replace(/ /g, '') }));
        } else {
          if (action.payload.showOnlyDetail) {
            yield put(fetchProduct({ sku: searchTerm.replace(/ /g, '') }));
          } else {
            yield call(onAddOrderItemBySkuSaga, searchTerm.replace(/ /g, ''));
          }
        }
      }
    }

    yield put(orderTableSearchFinished());
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(orderTableSearchFinished());
      yield put(productSearchOpened({ open: true, searchTerm: searchTerm }));
    } else {
      const errorMessage = (error.response ? error.response.data?.detail : error?.message) ?? 'errors.general';
      yield put(showErrorNotification(errorMessage));
      yield put(searchError(error));
    }
  }
}

export default function* onSearchWatcher() {
  yield takeLatest(search.type, onOptimizedSearchSaga);
}
