export const DEFAULT_SALES_CHANNEL = '01';
export const DEFAULT_SALES_OFFICE = '1001';
export const DEFAULT_ECOM_SALES_OFFICE = '4001';

export default [
  {
    id: '01',
    name: 'TV',
    salesOffices: [
      {
        id: '1001',
        name: 'HSE',
      },
      {
        id: '1002',
        name: 'Neue IVR',
      },
      {
        id: '1008',
        name: 'HSE Extra',
      },
      {
        id: '1009',
        name: 'HSE Trend',
      },
    ],
  },
  {
    id: '04',
    name: 'Internet',
    salesOffices: [
      {
        id: '4001',
        name: 'Internet',
      },
      {
        id: '4002',
        name: 'Internet Affiliate',
      },
      {
        id: '4003',
        name: 'Internet Suchmaschin',
      },
      {
        id: '4004',
        name: 'Smartphone Apps',
      },
      {
        id: '4005',
        name: 'Internet SEO',
      },
      {
        id: '4006',
        name: 'In.Aff.Klick',
      },
      {
        id: '4010',
        name: 'Kooperationen',
      },
      {
        id: '4011',
        name: 'Newsletter',
      },
      {
        id: '4007',
        name: 'In.Aff.Postview',
      },
      {
        id: '4008',
        name: 'In.Aff.Retargeting',
      },
      {
        id: '4009',
        name: 'Display',
      },
      {
        id: '4012',
        name: 'iPad App',
      },
      {
        id: '4013',
        name: 'HbbTV APS',
      },
      {
        id: '4014',
        name: 'Mobile Webseite',
      },
      {
        id: '4015',
        name: 'Internet Google Base',
      },
      {
        id: '4016',
        name: 'Google TV App',
      },
      {
        id: '4017',
        name: 'Samsung TV App',
      },
      {
        id: '4018',
        name: 'Social Media',
      },
      {
        id: '4019',
        name: 'ebay',
      },
      {
        id: '4020',
        name: 'Judith Williams Shop',
      },
      {
        id: '4021',
        name: 'Alfredo Pauly Shop',
      },
      {
        id: '4022',
        name: 'Ricarda M. Shop',
      },
      {
        id: '4023',
        name: 'Sarah Kern Shop',
      },
      {
        id: '4024',
        name: 'not tracked Sales DT',
      },
      {
        id: '4025',
        name: 'Freunde_werben',
      },
      {
        id: '4026',
        name: 'Koop2',
      },
      {
        id: '4027',
        name: 'Koop3',
      },
      {
        id: '4028',
        name: 'Koop4',
      },
      {
        id: '4029',
        name: 'Test1',
      },
      {
        id: '4030',
        name: 'Test2',
      },
      {
        id: '4031',
        name: 'FB_Redaktion',
      },
      {
        id: '4032',
        name: 'FB_Dynamic_Ads',
      },
      {
        id: '4033',
        name: 'FB_Ads_allg',
      },
      {
        id: '4034',
        name: 'FB_Kampagne',
      },
      {
        id: '4035',
        name: 'IG_Shopping',
      },
      {
        id: '4036',
        name: 'IG_InstoryAds',
      },
      {
        id: '4037',
        name: 'WhatsApp',
      },
      {
        id: '4038',
        name: 'Youtube',
      },
      {
        id: '4101',
        name: 'Internet Mobile',
      },
      {
        id: '4102',
        name: 'In.Affiliate.Mobile',
      },
      {
        id: '4103',
        name: 'In.SEA.Mobile',
      },
      {
        id: '4105',
        name: 'In.SEO.Mobile',
      },
      {
        id: '4106',
        name: 'In.Aff.Klick.Mobile',
      },
      {
        id: '4107',
        name: 'In.Aff.PostV.Mobile',
      },
      {
        id: '4108',
        name: 'In.Aff.Retrgt. Mobile',
      },
      {
        id: '4109',
        name: 'Display Mobile',
      },
      {
        id: '4110',
        name: 'Kooperationen Mobile',
      },
      {
        id: '4111',
        name: 'Newsletter Mobile',
      },
      {
        id: '4115',
        name: 'In.Goo.Base Mobile',
      },
      {
        id: '4118',
        name: 'Social Media mobile',
      },
      {
        id: '4124',
        name: 'not tracked Sales Mb',
      },
      {
        id: '4125',
        name: 'Freunde_werben_mob',
      },
      {
        id: '4126',
        name: 'Koop2_mobile',
      },
      {
        id: '4127',
        name: 'Koop3_mobile',
      },
      {
        id: '4128',
        name: 'Koop4_mobile',
      },
      {
        id: '4129',
        name: 'Test1_mobile',
      },
      {
        id: '4130',
        name: 'Test2_mobile',
      },
      {
        id: '4131',
        name: 'FB_Redaktion_mobile',
      },
      {
        id: '4132',
        name: 'FB_Dynamic_Ads_mob',
      },
      {
        id: '4133',
        name: 'FBk_Ads_allg_mobile',
      },
      {
        id: '4134',
        name: 'FB_Kampagne_mobile',
      },
      {
        id: '4135',
        name: 'IG_Shopping_mobile',
      },
      {
        id: '4136',
        name: 'IG_InstoryAds_mob',
      },
      {
        id: '4137',
        name: 'WhatsApp_mobile',
      },
      {
        id: '4138',
        name: 'Youtube_mobile',
      },
      {
        id: '4204',
        name: 'Android Phones',
      },
      {
        id: '4212',
        name: 'Android Tablets',
      },
      {
        id: '4304',
        name: 'iPhones',
      },
      {
        id: '4312',
        name: 'iPads',
      },
      {
        id: '4401',
        name: 'Direct Limelight',
      },
      {
        id: '4402',
        name: 'SEM Limelight',
      },
      {
        id: '4403',
        name: 'SEO Limelight',
      },
      {
        id: '4404',
        name: 'Display Limelight',
      },
      {
        id: '4405',
        name: 'FB Ads Limelight',
      },
      {
        id: '4406',
        name: 'IG Shopping Limeligh',
      },
      {
        id: '4407',
        name: 'Youtube Limelight',
      },
      {
        id: '4408',
        name: 'ShopNow Limelight',
      },
      {
        id: '4409',
        name: 'Test Limelight',
      },
      {
        id: '4410',
        name: 'Not tracked Limeligh',
      },
      {
        id: '4411',
        name: 'Frde werben Frde LL',
      },
      {
        id: '4412',
        name: 'Newsletter Limelight',
      },
      {
        id: '4413',
        name: 'Affiliate Limelight',
      },
      {
        id: '4500',
        name: 'SC Creator',
      },
      {
        id: '4501',
        name: 'SC Kunde',
      },

      { id: '4602', name: 'SEM iOS' },
      { id: '4603', name: 'SEO iOS' },
      { id: '4604', name: 'Display iOS' },
      { id: '4605', name: 'FB Ads iOS' },
      { id: '4606', name: 'IG Shopping iOS' },
      { id: '4607', name: 'Youtube iOS' },
      { id: '4612', name: 'Newsletter iOS' },
      { id: '4613', name: 'Affiliate iOS' },
      { id: '4610', name: 'Not tracked iOS' },

      { id: '4702', name: 'SEM Android' },
      { id: '4703', name: 'SEO Android' },
      { id: '4704', name: 'Display Android' },
      { id: '4705', name: 'FB Ads Android' },
      { id: '4706', name: 'IG Shopping Android' },
      { id: '4707', name: 'Youtube Android' },
      { id: '4712', name: 'Newsletter Android' },
      { id: '4713', name: 'Affiliate Android' },
      { id: '4710', name: 'Not tracked Android' },

      { id: '4800', name: 'Stream Classic Web' },
      { id: '4801', name: 'Stream Classic iOS' },
      { id: '4802', name: 'Stream Classic Andro*' }
    ],
  },
];
