
export interface SuggestionsResponse{
  suggestions: Suggestion[]
}

export interface Suggestion{
  text: string,
  type: SuggestionType,
  categoryPath?: string,
  baseProductNo?: string
}

export enum SuggestionType {
  CUSTOM = 'CUSTOM',
  BRAND = 'BRAND',
  PRODUCT_LINE = 'PRODUCT_LINE',
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT'
}