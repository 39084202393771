
function splitArrayIntoChunks(array: string[], chunk: number) {
  let i = 0;
  const tempArray = [];

  for (; i < array.length; i += chunk) {
    tempArray.push(array.slice(i, i + chunk));
  }

  return tempArray;
}

export default splitArrayIntoChunks;
