import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import { showSuccessNotification } from 'src/redux/app/appSlice';
import {
  AddCustomerReceptionProvider,
  addCustomerReceptionProvider,
  addCustomerReceptionProviderSuccess,
  createCustomerError,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { DEFAULT_PAYMENTS, setAvailablePaymentMethods } from 'src/redux/payment/paymentSlice';
import { getOfferIfOrderLineItemsExist } from 'src/sagas/offer/getOffer';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { CustomerPreferencesRequest } from 'src/types/customer/CustomerPreferencesRequest';
import localCustomerStorage from 'src/utils/localCustomerStorage';


const { isExistingCustomer, getCustomerId, getSelectedCustomerLocalId } = customerInfoSelectors;

export function* addCustomerReceptionProviderSaga(action: AddCustomerReceptionProvider) {
  try {
    yield put(addCustomerReceptionProviderSuccess(action.payload));

    const customerLocalId = yield select(getSelectedCustomerLocalId);
    yield call(localCustomerStorage.setReceptionByLocalCustomerId, customerLocalId, action.payload);

    const existingCustomer = yield select(isExistingCustomer);
    if (!existingCustomer) {
      yield put(showSuccessNotification('customer.createEdit.createSuccess'));
      yield put(setAvailablePaymentMethods(DEFAULT_PAYMENTS));
      yield call(getOfferIfOrderLineItemsExist);
    } else {
      const customerId: string = yield select(getCustomerId);
      const customerApi: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
      const customerPreference: CustomerPreferencesRequest = {
        reception: action.payload,
      };
      yield call(customerApi.updateCustomerPreferences, customerId, customerPreference);
    }
  } catch (err) {
    const errorCode = (err?.response?.status) || 400;
    yield put(createCustomerError(errorCode));
    yield put(logErrorEvent({ message: `Couldn't set reception to ${action.payload}`, err }));
  }
}

export function* addCustomerReceptionProviderWatcher() {
  yield takeLatest(addCustomerReceptionProvider.type, addCustomerReceptionProviderSaga);
}
