import { CountryCode } from 'src/api/orderTypes';
import Config from 'src/config';
import countryLocalStorage from 'src/utils/countryLocalStorage';


const getSearchBaseURL = (): string => {
  switch (countryLocalStorage.get()) {
  case CountryCode.at:
    return Config.env.searchUrl.at;
  case CountryCode.ch:
    return Config.env.searchUrl.ch;
  default:
    return Config.env.searchUrl.de;
  }
};

export default getSearchBaseURL;
