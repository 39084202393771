import {
  call,
  getContext,
  put,
  select,
  takeLatest
} from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import {
  openModal,
  showErrorNotification,
  showSuccessNotification
} from 'src/redux/app/appSlice';
import {
  cancelOrder,
  CancelOrderAction,
  cancelOrderError,
  cancelOrderSuccess,
  setCancelledOrders,
} from 'src/redux/customer/orderHistorySlice';
import { editOrderSelector } from 'src/redux/editOrder/editOrderSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { getLastCanceledOrders, storeCanceledOrder } from 'src/utils/canceledOrders';


export function* cancelOrderSaga({ payload: { customerId, orderNumber, reasonCode } }: CancelOrderAction) {
  try {
    const cancelWholeOrder = yield select(editOrderSelector.cancelTheWholeOrder);
    const apiOrder = yield getContext(SagaContextItem.apiOrder);
    yield call(apiOrder.cancelOrder, orderNumber, customerId, reasonCode);

    yield put(cancelOrderSuccess());
    if (cancelWholeOrder) {
      yield put(openModal(Modals.orderConfirmation));
    } else {
      storeCanceledOrder(orderNumber);
      yield put(setCancelledOrders(getLastCanceledOrders()));

      yield put(showSuccessNotification('order.orderCanceled'));
    }
  } catch (e) {
    yield put(
      logEvent({
        level: LOG_LEVEL.WARN,
        message: `Couldn't cancel history order: ${orderNumber}, ${customerId}, ${reasonCode}`,
        err: e,
      }),
    );

    yield put(cancelOrderError());
    yield put(showErrorNotification());
  }
}

export function* cancelOrderWatcher() {
  yield takeLatest(cancelOrder.type, cancelOrderSaga);
}
