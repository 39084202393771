import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal, showSuccessNotification } from 'src/redux/app/appSlice';
import { changeAddress, emptyAddressSuggestions } from 'src/redux/customer/addressSuggestionsSlice';
import {
  CustomerInfoActionTypes,
  editCustomerError,
  EditCustomerResponse,
  editCustomerSuccess,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { Modals } from 'src/types/Modals';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import stringify from 'src/utils/stringify';

import { AddressSuggestionsSaga } from '../addressSuggestions/addressSuggestions';
import { getOfferIfOrderLineItemsExist } from '../offer/getOffer';


export function* editCustomer(action: EditCustomerResponse) {
  try {
    const customer = yield select(customerInfoSelectors.getCustomerInfo);
    const isBillingAddressValidated = yield select(customerInfoSelectors.billingAddressValidated);
    // We need to update the id because we get it empty from the component.
    // We will use the current customer id from the store.
    const payload = action.payload;
    payload.id = customer.customerResponse.id;
    const { billingAddress } = payload;
    if (billingAddress) {
      yield put(changeAddress(billingAddress));
    }
    const validAddress = yield call(AddressSuggestionsSaga, isBillingAddressValidated);
    if (validAddress) {
      yield put(editCustomerSuccess(payload));
      yield put(closeModal(Modals.customer));
      yield put(emptyAddressSuggestions());
      yield call(localCustomerStorage.upsertCustomer, payload, customer?.selectedLocalCustomerId);
      yield put(showSuccessNotification('customer.createEdit.editSuccess'));
      yield call(getOfferIfOrderLineItemsExist);
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not edit customer ${stringify(action.payload)}`, err }));
    const errorCode = (err?.response?.status) || 400;
    yield put(editCustomerError(errorCode));
  }
}

export default function* editCustomerWatcher() {
  yield takeLatest(CustomerInfoActionTypes.EDIT_CUSTOMER, editCustomer);
}
