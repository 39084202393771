import { PaymentMethod } from 'src/types/offer/PaymentMethod';


export const RECREATE_ORDER_POSSIBLE_PAYMENTS = [
  PaymentMethod.cashOnDelivery,
  PaymentMethod.invoice,
  PaymentMethod.directDebit,
  PaymentMethod.installments,
];

const RECREATE_ORDER_ID_SESSION_KEY = 'recreate_order_id';

export const saveOrderIdInSession = (orderId: string) => {
  sessionStorage.setItem(RECREATE_ORDER_ID_SESSION_KEY, orderId);
};

export const getOrderIdFromSession = () => {
  return sessionStorage.getItem(RECREATE_ORDER_ID_SESSION_KEY);
};

export const removeOrderIdFromSession = () => {
  sessionStorage.removeItem(RECREATE_ORDER_ID_SESSION_KEY);
};
