import { AxiosRequestConfig } from 'axios';

import { RequestInterceptor } from '../HttpClient';


export const arrayBufferResponseTypeInterceptor: RequestInterceptor = {
  onFulfilled(config: AxiosRequestConfig) {
    config.responseType = 'arraybuffer';
    return config;
  },
};
