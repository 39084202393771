import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  CancellationSubscriptionItemRequest,
  SubscriptionCancellationReason,
  SubscriptionHistoryResponse,
  SubscriptionItem,
} from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionStatusRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';


export interface SubscriptionHistoryState {
  externalCustomerId: string;
  loading: boolean;
  data?: SubscriptionHistoryResponse;
  searchCriteria?: SubscriptionsSearchCriteria;
  subscriptionsItemsIds?: string[];
  subscriptionItemCancellationReason?: SubscriptionCancellationReason;
  editSubscriptionItemData?: SubscriptionItem;
  error?: boolean;
  errorCode?: number;
}

export const subscriptionHistoryInitialState: SubscriptionHistoryState = {
  externalCustomerId: '',
  loading: false,
};

export interface SubscriptionsSearchCriteria {
  keyword?: string;
  status?: SubscriptionStatusRequest;
}

type FetchSubscriptionHistory = {
  externalCustomerId: string,
  criteria: SubscriptionsSearchCriteria
};
export const fetchSubscriptionHistory = createAction<FetchSubscriptionHistory>('subscriptionHistory/fetchSubscriptionHistory');

export const cancelSubscriptionItem = createAction<{
    customerId: string,
    cancellationSubscriptionItemRequest: CancellationSubscriptionItemRequest
}>('subscriptionHistory/cancelSubscriptionItem');
export type CancelSubscriptionItem = ReturnType<typeof cancelSubscriptionItem>;

export const editSubscriptionItem = createAction<SubscriptionItem>('subscriptionHistory/editSubscriptionItem');
export type EditSubscriptionItem = ReturnType<typeof editSubscriptionItem>;

const subscriptionHistorySlice = createSlice({
  name: 'subscriptionHistory',
  initialState: subscriptionHistoryInitialState,
  reducers: {
    fetchSubscriptionHistoryInProgress(state) {
      state.loading = true;
    },
    fetchSubscriptionHistorySuccess(state, { payload }: PayloadAction<SubscriptionHistoryResponse>) {
      state.data = payload;
      state.loading = false;
    },
    fetchSubscriptionHistoryError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    clearSubscriptionHistory(state) {
      state.loading = false;
      state.data = undefined;
      state.subscriptionsItemsIds = undefined;
      state.subscriptionItemCancellationReason = undefined;
      state.editSubscriptionItemData = undefined;
    },
    cancelSubscriptionItemInProgress(state) {
      state.loading = true;
    },
    cancelSubscriptionItemSuccess(state) {
      state.loading = false;
    },
    cancelSubscriptionItemError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    setSubscriptionCancellationReason(state, { payload }: PayloadAction<SubscriptionCancellationReason | undefined>) {
      state.subscriptionItemCancellationReason = payload;
    },
    setSubscriptionCancellationItemIds(state, { payload }: PayloadAction<string[] | undefined>) {
      state.subscriptionsItemsIds = payload;
    },
    setEditSubscriptionItem(state, { payload }: PayloadAction<SubscriptionItem>) {
      state.editSubscriptionItemData = payload;
    },
    editSubscriptionOrderInProgress(state) {
      state.loading = true;
    },
    editSubscriptionOrderSuccess(state) {
      state.loading = false;
    },
    editSubscriptionOrderFailed(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    editSubscriptionHistoryItemSuccess: (state, { payload }: PayloadAction<SubscriptionItem>) => {
      if (state?.data?.subscriptions) {
        state.data.subscriptions = state.data.subscriptions.map(item => item.id === payload.id ? payload : item) || [];
      }
    },
    setSubscriptionSearchKeyword: (state, { payload }: PayloadAction<string | undefined>) => {
      state.loading = true;
      state.searchCriteria = {
        ...state.searchCriteria,
        keyword: payload,
        status: state.searchCriteria?.status ?? SubscriptionStatusRequest.ALL,
      };
    },
    setSelectedSubscriptionStatus: (state, { payload }: PayloadAction<SubscriptionStatusRequest | undefined>) => {
      state.loading = true;
      state.searchCriteria = {
        ...state.searchCriteria,
        status: payload ?? SubscriptionStatusRequest.ALL,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase( fetchSubscriptionHistory, (state, { payload }: PayloadAction<FetchSubscriptionHistory>) => {
        state.searchCriteria = {
          status: payload.criteria.status ?? SubscriptionStatusRequest.ALL,
          keyword: payload.criteria.keyword,
        };
        state.externalCustomerId = payload.externalCustomerId;
        state.loading = true;
      });
  },
});

export const {
  fetchSubscriptionHistoryInProgress,
  fetchSubscriptionHistorySuccess,
  fetchSubscriptionHistoryError,
  clearSubscriptionHistory,
  cancelSubscriptionItemInProgress,
  cancelSubscriptionItemSuccess,
  cancelSubscriptionItemError,
  setSubscriptionCancellationReason,
  setSubscriptionCancellationItemIds,
  setEditSubscriptionItem,
  editSubscriptionOrderInProgress,
  editSubscriptionOrderSuccess,
  editSubscriptionOrderFailed,
  editSubscriptionHistoryItemSuccess,
  setSelectedSubscriptionStatus,
  setSubscriptionSearchKeyword,
} = subscriptionHistorySlice.actions;

export default subscriptionHistorySlice.reducer;
