import { Status } from 'src/api/orderTypes';
import { RootStateType } from 'src/constants/types';
import { RECREATE_ORDER_POSSIBLE_PAYMENTS } from 'src/OrderDisplay/utils/recreateOrderUtils';
import { createSelectors } from 'src/utils/state';


const orderDisplaySelector = createSelectors({
  getOrder(state: RootStateType) {
    return state.order.orderDisplay.orderResponse;
  },
  canDisplayRecreateOrderButton(state: RootStateType) {
    const order = state.order.orderDisplay.orderResponse;
    const statuses = order?.statusHistory;
    const isRejected = (!!statuses?.length) && statuses[statuses.length - 1].status === Status.rejected;
    return isRejected && RECREATE_ORDER_POSSIBLE_PAYMENTS.some(v => v === order?.payment?.method);
  },
});

export default orderDisplaySelector;
