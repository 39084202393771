import { EditedOrderResponse } from 'src/editedorder/types/EditedOrderResponse';


export interface EditedOrderState {
  loading: boolean;
  history?: EditedOrderResponse[];
}

export const initialEditedOrderState = {
  loading: false,
  history: undefined,
} as EditedOrderState;
