import {
  createSlice,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';

import { NotificationsResponse, Notification } from 'src/types/NotificationResponse';


export interface NotificationsTickerState {
  error: string | null;
  loading: boolean;
  isOpened: boolean;
  notifications: Notification[];
  newNotifications?: Notification[];
}

export const notificationsTickerInitialState: NotificationsTickerState = {
  error: null,
  loading: false,
  isOpened: false,
  notifications: [],
  newNotifications: [],
};

export const getNotifications = createAction(
  'notificationsTicker/getNotifications',
);

const slice = createSlice({
  name: 'notificationsTicker',
  reducers: {
    getNotificationsInProgress(state) {
      state.loading = true;
    },
    getNotificationsSuccess(state, { payload }: PayloadAction<NotificationsResponse>) {
      state.loading = false;
      state.notifications = payload.notifications;
      state.newNotifications = payload.newNotifications;
    },
    getNotificationsFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;
    },
    showNotifications(state,{ payload }: PayloadAction<boolean>){
      state.isOpened = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase( getNotifications,state => {
      return state;
    });
  },
  initialState: notificationsTickerInitialState,
});

export const notificationsTickerSlice = slice.reducer;
export const notificationsTickerActions = slice.actions;
