const NotificationSuccessIcon = () => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM16.8344 8.62828C16.6291 8.4436 16.313 8.46031 16.1283 8.66561L11.4908 13.8208L8.80563 11.7471L8.75361 11.7119C8.53962 11.5856 8.26009 11.6355 8.10429 11.8373C7.93551 12.0558 7.97586 12.3698 8.19442 12.5386L11.2467 14.8957L11.296 14.9293C11.4985 15.0505 11.7625 15.0139 11.9241 14.8344L16.8717 9.3344L16.9108 9.28517C17.0527 9.08124 17.0239 8.79876 16.8344 8.62828Z"
        fill="white" />
    </svg>
  );
};

export default NotificationSuccessIcon;
