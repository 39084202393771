import { VoucherRestriction } from 'src/types/voucher/VirtualVoucherRequest';

import { ProgressiveDiscountInfo } from './VoucherResponse';


export enum VirtualVoucherType {
  percentage = 'PERCENTAGE',
  value = 'VALUE',
  freeShipping = 'FREE_SHIPPING'
}

export enum VoucherRestrictionType {
  category = 'CATEGORY',
  brand = 'BRAND',
  product = 'PRODUCT',
  productPriceLabel = 'PRODUCT_PRICE_LABEL',
}

export interface VoucherShortType {
  code: string;
  value?: number;
  calculatedPercentageValue?: number;
  type?: VirtualVoucherType;
  percentage?: number;
  restrictions: VoucherRestriction[];
  loading?: boolean;
  progressiveDiscountInfo?: ProgressiveDiscountInfo;
}
