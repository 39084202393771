import {
  call,
  put,
  takeEvery,
  getContext,
  select
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import {
  closeModal,
  ErrorContext,
  trackUserFailureLoginsEvent
} from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { fetchCustomer, setCustomerNumberField } from 'src/redux/customer/customerInfoSlice';
import {
  fetchCustomersSuccess,
  customersError,
  FetchCustomers,
  resetCustomers,
  fetchCustomers,
  SearchCustomersErrorKey,
} from 'src/redux/customer/customerSearchSlice';
import { Modals } from 'src/types/Modals';
import { transformCustomerSearchCriteria } from 'src/utils/mappers/transformCustomerSearchCriteria';
import stringify from 'src/utils/stringify';


export const foundTooMuchCustomersErrorKey = '/dpl/cs/errors/found_too_much_customers';
export const insufficientCustomersSearchCriteriaErrorKey =  '/dpl/cs/errors/insufficient_customers_search_criteria';

export function* getCustomersSaga(action: FetchCustomers) {
  try {
    const apiCustomer: ApiCustomer = yield getContext('apiCustomer');
    const response = yield call(apiCustomer.getCustomers, transformCustomerSearchCriteria(action.payload));
    const agentUsername = yield select(appSelectors.getAgentUsername);
    const loginMethod = yield select(appSelectors.getLoginMethod);

    const results = response.results;

    if (results.length === 0) {
      yield put(trackUserFailureLoginsEvent({
        c_agentId: agentUsername,
        loginMethod: loginMethod,
        errorContext: ErrorContext.LOGIN,
        errorMessage: 'no customer found'
      }));
    }

    if (results.length === 1) {
      const { externalCustomerId } = results[0];
      yield put(fetchCustomer(externalCustomerId));
      yield put(setCustomerNumberField(externalCustomerId));
      yield put(resetCustomers());
      yield put(closeModal(Modals.customerSearch));
      return;
    }
    yield put(fetchCustomersSuccess(results));
  } catch (err) {
    const agentUsername = yield select(appSelectors.getAgentUsername);
    const loginMethod = yield select(appSelectors.getLoginMethod);
    const searchCustomerErrorKey = mapSearchCustomersErrorKeys(err.response?.data?.key);

    if(searchCustomerErrorKey === SearchCustomersErrorKey.INTERNAL_SERVER_ERROR) {
      yield put(logErrorEvent({ message: `Could not get customers ${stringify(action.payload)}`, err }));
    }
    yield put(customersError(searchCustomerErrorKey));
    yield put(trackUserFailureLoginsEvent({
      c_agentId: agentUsername,
      loginMethod: loginMethod,
      errorContext: ErrorContext.LOGIN,
      errorMessage: `Could not get customers due to reason: ${searchCustomerErrorKey}`
    }));
  }
}

export default function* getCustomersWatcher() {
  yield takeEvery(fetchCustomers.type, getCustomersSaga);
}

const mapSearchCustomersErrorKeys = (errorKey: string): SearchCustomersErrorKey => {
  switch(errorKey){
  case foundTooMuchCustomersErrorKey: return SearchCustomersErrorKey.FOUND_TOO_MUCH_CUSTOMERS;
  case insufficientCustomersSearchCriteriaErrorKey: return SearchCustomersErrorKey.INSUFFICIENT_CUSTOMERS_SEARCH_CRITERIA;
  default: return SearchCustomersErrorKey.INTERNAL_SERVER_ERROR;
  }
};
