import {
  call,
  getContext,
  select,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { newOrderSelector } from 'src/redux/order/selectors/newOrderSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer, MarketResearchConsent } from 'src/types/customer/customer';
import { UpdateMarketResearchRequest } from 'src/types/customer/UpdateMarketResearchRequest';
import { getLastManagedConsentForCustomer, setlastConsentsManagementState } from 'src/utils/lastConsentsManagementState';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* checkAndUpdateMarketResearchConsentSaga() {
  const { getCustomer, isExistingCustomer } = customerInfoSelectors;

  const customer: Customer = yield select(getCustomer);

  try {
    const phoneNumber = customer?.phoneNumber;
    const email = customer?.email;
    const mafoConsent = customer?.consents;
    const existingCustomer = yield select(isExistingCustomer);
    const orderId: string = yield select(newOrderSelector.getOrderId);
    const hasAlreadyBeenUpdated = getLastManagedConsentForCustomer(customer.externalCustomerId)?.submitted;

    if (hasAlreadyBeenUpdated || !existingCustomer || !customer?.consents) {
      return;
    }

    if ((mafoConsent?.phoneMarketResearch && !phoneNumber) || (mafoConsent?.emailMarketResearch && !email)) {
      yield call(
        logErrorAndShowNotification,
        `Couldn't allow mafo consent without phone number and email for customer ${customer.id}`,
        'Hoppla... Die Zustimmung von mafo konnte ohne Telefonnummer für den Kunden nicht akzeptiert werden',
      );

      return;
    }

    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);

    const requestBody: UpdateMarketResearchRequest = {
      phoneMarketResearchConsent: mafoConsent?.phoneMarketResearch ? MarketResearchConsent.ALLOWED : MarketResearchConsent.NOT_ALLOWED,
      emailMarketResearchConsent: mafoConsent?.emailMarketResearch ? MarketResearchConsent.ALLOWED : MarketResearchConsent.NOT_ALLOWED,
      phoneNumber: (!phoneNumber || phoneNumber === '') ? undefined : phoneNumber,
      email: (!email || email === '') ? undefined : email,
      orderId: orderId,
    };

    yield call(apiCustomer.updateMarketResearchConsent, customer.id, requestBody);
    setlastConsentsManagementState(mafoConsent?.phoneMarketResearch, mafoConsent?.emailMarketResearch, phoneNumber, email, customer.externalCustomerId, true);
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      `Couldn't update mafo consent for customer ${customer.id}, consents: ${JSON.stringify(customer.consents)}`,
      'Hoppla... Die Zustimmung zur Marktforschung konnte nicht festgelegt werden.',
      err,
    );
  }
}
