import { push } from 'connected-react-router';
import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import orderDisplaySelector from 'src/OrderDisplay/redux/orderDisplaySelector';
import { prepareOrderRecreation } from 'src/OrderDisplay/redux/orderDisplaySlice';
import { saveOrderIdInSession } from 'src/OrderDisplay/utils/recreateOrderUtils';
import routes from 'src/routes/routes';


export function* prepareOrderRecreationSaga() {
  const order = yield select(orderDisplaySelector.getOrder);
  try {
    saveOrderIdInSession(order.id);
    yield put(push(routes.orderApp.path));
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not prepare the recreation of order '${order?.id}'`, err }));
  }
}

export default function* prepareOrderRecreationWatcher() {
  yield takeLatest(prepareOrderRecreation.type, prepareOrderRecreationSaga);
}
