import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiSubscriptionsManagement } from 'src/api/apiSubscriptionsManagement';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { subscriptionReportSelectors } from 'src/subscriptionProductManagement/redux/selectors/subscriptionReportSelectors';
import { createSubscriptionProductsReportFetchRequest } from 'src/subscriptionProductManagement/types/request/FetchSubscriptionProductsReportRequest';
import { SubscriptionReportResponse } from 'src/subscriptionProductManagement/types/response/SubscriptionReportResponse';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import {
  clearSubscriptionsReportSelectedMonthFilter,
  fetchInitialSubscriptionProductsReport,
  fetchProductsReportByKeyword,
  fetchSubscriptionsReport,
  fetchSubscriptionsReportError,
  fetchFullSubscriptionReportSuccess,
  fetchPartialSubscriptionReportSuccess,
  setSubscriptionsReportFetchSelectedMonthFilter,
  SubscriptionsReportFetchFilters,
  clearReportSearchKeyword,
  setSubscriptionReportSortBy,
} from '../redux/subscriptionReportSlice';


const SUBSCRIPTION_PRODUCTS_REPORT_ITEMS_FETCH_MAX = 100;

export function* fetchSubscriptionsReportSaga(action: PayloadAction) {

  try {
    const offset: number = yield select(subscriptionReportSelectors.getOffset);
    const filters: SubscriptionsReportFetchFilters = yield select(subscriptionReportSelectors.getSubscriptionsReportFilters);
    const loadMore = action.type !== fetchInitialSubscriptionProductsReport.type;

    const apiSubscriptionProductsManagement: ApiSubscriptionsManagement = yield getContext(SagaContextItem.apiSubscriptionsManagement);

    const response: SubscriptionReportResponse = yield call(
      apiSubscriptionProductsManagement.getSubscriptionReportList,
      createSubscriptionProductsReportFetchRequest(offset, SUBSCRIPTION_PRODUCTS_REPORT_ITEMS_FETCH_MAX, filters, loadMore),
    );

    const subscriptionProductsReportItems = (action.type === fetchSubscriptionsReport.type)
      ? (yield select(subscriptionReportSelectors.getSubscriptionsReport)).concat(response.items) : response.items;

    if ( loadMore ) {
      yield put(fetchPartialSubscriptionReportSuccess({
        page: response.page,
        size: response.size,
        items: subscriptionProductsReportItems,
      }));
    } else {
      yield put(fetchFullSubscriptionReportSuccess({
        page: response.page,
        size: response.size,
        totalActiveSubscriptions: response.totalActiveSubscriptions,
        floorSalesVariation: response.floorSalesVariation,
        previousMonthFloorSales: response.previousMonthFloorSales,
        currentMonthFloorSales: response.currentMonthFloorSales,
        totalSubscribedProducts: response.totalSubscribedProducts,
        monthlyStockStatus: response.monthlyStockStatus,
        items: subscriptionProductsReportItems,
      }));
    }
  } catch (err) {
    yield put(fetchSubscriptionsReportError());
    yield call(
      logErrorAndShowNotification,
      'Oops... Error fetching subscriptions overview.',
      'Hoppla... Fehler beim Abrufen der Abonnementübersicht',
      err,
    );
  }
}

export default function* fetchSubscriptionsReportWatcher() {
  yield takeLatest(fetchInitialSubscriptionProductsReport.type, fetchSubscriptionsReportSaga);
  yield takeLatest(fetchSubscriptionsReport.type, fetchSubscriptionsReportSaga);
  yield takeLatest(setSubscriptionsReportFetchSelectedMonthFilter.type, fetchSubscriptionsReportSaga);
  yield takeLatest(clearSubscriptionsReportSelectedMonthFilter.type, fetchSubscriptionsReportSaga);
  yield takeLatest(fetchProductsReportByKeyword.type, fetchSubscriptionsReportSaga);
  yield takeLatest(clearReportSearchKeyword.type, fetchSubscriptionsReportSaga);
  yield takeLatest(setSubscriptionReportSortBy.type, fetchSubscriptionsReportSaga);
}
