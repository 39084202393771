import dayjs from 'dayjs';

import { DEFAULT_DISPLAY_DATE_FORMAT } from 'src/constants/date';
import { CustomerInfoState } from 'src/redux/customer/customerInfoSlice';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';

import fromDisplayFormatToISO8601 from './formatters/fromDisplayFormatToISO8601';


function buildCustomerForWaitlistAndSubscription(customerState: CustomerInfoState) {
  const customer = customerState.customerResponse;

  if (!customer || !customer.billingAddress) {
    return ;
  }

  const billingAddress = customer.billingAddress;
  const dateOfBirth =
    customer.dateOfBirth && dayjs(customer.dateOfBirth, DEFAULT_DISPLAY_DATE_FORMAT).isValid() ?
      fromDisplayFormatToISO8601(customer.dateOfBirth) || '' : customer.dateOfBirth;

  return {
    salutation: customer.salutation,
    firstName: customer.firstName,
    lastName: customer.lastName,
    street: billingAddress.street,
    streetNumber: billingAddress.streetNumber,
    addressAddition: billingAddress.addressAddition,
    zipCode: billingAddress.zipCode,
    city: billingAddress.city,
    countryCode: billingAddress.countryCode,
    email: customer.email,
    phone: customer.originalPhoneNumber,
    dateOfBirth: dateOfBirth,
    dplCustomerId: trimStringOrNull(customer.id),
    crmPartnerId: customer.externalCustomerId,
    careOf: billingAddress.careOf,
    reception: customer.reception,
  };
}

export const buildSubscriptionProductCustomer = buildCustomerForWaitlistAndSubscription;
export const buildWaitlistItemCustomer = buildCustomerForWaitlistAndSubscription;
