import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ProductDetails, UpsellType } from 'src/types/product/product';


export type UpsellsLocation = 'ordertable' | 'productdetails' | 'productsearch';

export interface ProductUpsellsState {
  upsells: ProductDetails[];
  upsellsLocation: 'ordertable' | 'productdetails' | 'productsearch';
  upsellsMainProduct?: string;
  upsellsMainProductSku?: string;
}

export const productUpsellsInitialState: ProductUpsellsState = {
  upsells: [],
  upsellsLocation: 'ordertable',
};

export const fetchUpsells = createAction<UpsellType>('productUpsells/fetchUpsells');
export type FetchUpsellsAction = ReturnType<typeof fetchUpsells>

export const setUpsellsLocation = createAction<UpsellsLocation>('productUpsells/setUpsellsLocation');

const productUpsellsSlice = createSlice({
  name: 'productUpsells',
  initialState: productUpsellsInitialState,
  reducers: {
    updateUpsells(state, { payload }: PayloadAction<ProductDetails[]>){
      state.upsells = payload;
    },
    clearUpsells(state){
      state.upsells = [];
    },
    updateUpsellsMainProduct(state, { payload }: PayloadAction<string>){
      state.upsellsMainProduct = payload;
    },
    updateUpsellsMainProductSku(state, { payload }: PayloadAction<string>){
      state.upsellsMainProductSku = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase( setUpsellsLocation,(state, { payload }: PayloadAction<UpsellsLocation>) => {
      state.upsellsLocation = payload;
    });
  },
});

export const {
  updateUpsells,
  clearUpsells,
  updateUpsellsMainProduct,
  updateUpsellsMainProductSku,
} = productUpsellsSlice.actions;

export default productUpsellsSlice.reducer;
