import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DeliveryAddress } from 'src/types/customer/address';
import { SubscriptionItem } from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionRotation, SubscriptionStartIn } from 'src/types/subscription/SubscriptionRequest';
import { WaitlistItemPayment } from 'src/types/waitlist/CreateWaitlistItemRequest';


export interface EditSubscriptionState {
  originalSubscription?: SubscriptionItem;
  editedSubscription?: SubscriptionItem;
  loading?: boolean;
  editSuccess?: boolean;
  isEditSubscriptionDateNotValid?: boolean;
  }

export const editSubscriptionInitialState: EditSubscriptionState = {};

export const submitEditedSubscriptionAction = createAction<SubscriptionItem>('editSubscription/submitEditedSubscriptionAction');
export type SubmitEditedSubscriptionAction = ReturnType<typeof submitEditedSubscriptionAction>;

export const submitEditedSubscriptionNumberAction = createAction<SubscriptionItem>('editSubscription/submitEditedSubscriptionNumberAction');
export type SubmitEditedSubscriptionNumberAction = ReturnType<typeof submitEditedSubscriptionNumberAction>;

const editSubscriptionSlice = createSlice({
  name: 'editSubscription',
  initialState: editSubscriptionInitialState,
  reducers: {
    cleanEditedSubscriptionState() {
      return editSubscriptionInitialState;
    },
    setOriginalSubscription(state, { payload }: PayloadAction<SubscriptionItem>) {
      state.originalSubscription = payload;
      state.editedSubscription = {
        ...payload,
        aboInfo: {
          ...payload.aboInfo,
          startIn: SubscriptionStartIn.CUSTOM,
        },
      };
    },
    updateSubscriptionProductQuantity(state, { payload }: PayloadAction<number>) {
      if (state.editedSubscription) {
        state.editedSubscription.details.product.quantity = payload;
      }
    },
    updateSubscriptionRotation(state, { payload }: PayloadAction<SubscriptionRotation>) {
      if (state.editedSubscription) {
        state.editedSubscription.aboInfo.rotation = payload;
      }
    },
    updateSubscriptionStartIn(state, { payload }: PayloadAction<SubscriptionStartIn>) {
      if (state.editedSubscription) {
        state.editedSubscription.aboInfo.startIn = payload;
      }
    },
    updateSubscriptionNextOrderDate(state, { payload }: PayloadAction<string | undefined>) {
      if (state.editedSubscription) {
        if (payload !== undefined) {
          state.editedSubscription.aboInfo.nextOrderDate = payload;
        }
      }
    },
    updateSubscriptionPayment(state, { payload }: PayloadAction<WaitlistItemPayment>) {
      if (state.editedSubscription) {
        state.editedSubscription.details.payment = payload;
      }
    },
    updateSubscriptionDeliveryAddress(state, { payload }: PayloadAction<DeliveryAddress>) {
      if (state.editedSubscription) {
        state.editedSubscription.details.deliveryAddress = payload;
      }
    },
    updateSubscriptionProductNumber(state, { payload }: PayloadAction<string>) {
      if (state.editedSubscription) {
        state.editedSubscription.aboInfo.aboNumber = payload;
      }
    },
    updateDeliveryProductNumber(state, { payload }: PayloadAction<string>) {
      if (state.editedSubscription) {
        state.editedSubscription.details.product.sku = payload;
      }
    },
    setIsEditSubscriptionDateNotValid(state, { payload }: PayloadAction<boolean>) {
      state.isEditSubscriptionDateNotValid = payload;
    },
    editSubscriptionHistoryItemFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase( submitEditedSubscriptionAction,state => {
        state.loading = true;
      })
      .addCase( submitEditedSubscriptionNumberAction,state => {
        state.loading = true;
      });
  },
});

export const {
  setOriginalSubscription,
  updateSubscriptionProductQuantity,
  updateSubscriptionRotation,
  updateSubscriptionStartIn,
  updateSubscriptionNextOrderDate,
  updateSubscriptionPayment,
  cleanEditedSubscriptionState,
  updateSubscriptionDeliveryAddress,
  setIsEditSubscriptionDateNotValid,
  editSubscriptionHistoryItemFailed,
  updateDeliveryProductNumber,
  updateSubscriptionProductNumber,
} = editSubscriptionSlice.actions;

export default editSubscriptionSlice.reducer;
