export enum BotRequestReason {
    INVALID_AUTHENTICATION = 'INVALID_AUTHENTICATION',
    INVALID_ORDER_SELECTION = 'INVALID_ORDER_SELECTION',
    INVALID_RETURN_SELECTION = 'INVALID_RETURN_SELECTION',
    ASK_FOR_AGENT = 'ASK_FOR_AGENT',
    ERROR_GENERAL = 'ERROR_GENERAL',
    ERROR_TRACKING = 'ERROR_TRACKING',
    ERROR_RETURNLABEL = 'ERROR_RETURNLABEL',
    ERROR_RETURNTRACKING = 'ERROR_RETURNTRACKING'
}

export interface BotRequestInfo {
    code: number;
    comment?: string;
    externalCustomerId?: string;
    reason: BotRequestReason;
    isAuthenticated: boolean;
}