import { PaymentMethod } from 'src/types/offer/PaymentMethod';


export enum BankingDetailsState {
  initial = 'initial',
  noBankingDetails = 'noBankingDetails',
  prefilled = 'prefilled',
  prefillChanged = 'prefillChanged'
}

export interface BankingInfo {
  iban: string;
  accountHolder: string;
  bankName: string;
  ibanValid: boolean;
  usesPrefilledIban?: BankingDetailsState;
  firstTime?: boolean;
  ibanError?: string;
}

export interface BankingDetails {
    bankingDetails: {
        accountHolder: string;
        maskedIban: string;
        bankName: string;
    };
}

export interface IbanValidationResponse {
    bankDetails: {
    name: string;
    bic: string;
  };
}

export interface BankingDetailsRequest {
  accountHolder: string;
  iban: string;
}

export interface CustomerBankingDetailsResponse {
  accountHolder: string;
  maskedIban: string;
  bankName: string;
}

export interface InvoicePayment {
  method: PaymentMethod.invoice;
}

export interface PaypalPayment {
  method: PaymentMethod.paypal;
  requestId: string;
}

export interface DirectDebitPayment {
  method: PaymentMethod.directDebit;
  accountHolder: string;
  iban: string;
  usesPrefilledIban?: boolean;
}
export interface InstallmentPlan {
  installmentCount: number;
  installment: number;
  finalInstallment: number;
  total: number;
  interestRate: number;
  totalInterest: number;
  totalGrossAmount?: number;
  effectiveInterestRate?: number;
  creditInformationUrl?: string;
  installments?: Installment[];
}

export interface Installment{
  amount: number,
  dueDay: string
}
export interface InstallmentsPayment extends InstallmentPlan {
  method: PaymentMethod.installments;
  accountHolder: string;
  iban: string;
  dueDay: number;
  usesPrefilledIban?: boolean
}


export interface InstallmentsFactoringPayment extends InstallmentPlan {
  method: PaymentMethod.installmentsFactoring;
  accountHolder: string;
  iban: string;
  calculationId?: string;
  channel?: string;
  usesPrefilledIban?: boolean
}

export interface CashOnDeliveryPayment {
  method: PaymentMethod.cashOnDelivery;
}

export type Payment =
  | InvoicePayment
  | PaypalPayment
  | DirectDebitPayment
  | InstallmentsPayment
  | CashOnDeliveryPayment
  | InstallmentsFactoringPayment;
