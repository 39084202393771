import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import {
  editOrderSelector,
  updateProduct,
  updateProductSuccess,
} from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId, EditOrderActionType } from 'src/types/orderhistory/EditOrderActionRequest';

import { setDeliveryTimesSaga } from './initEditOrder';


export function* updateProductSaga(action: PayloadAction<{itemId: string, quantity: number}>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    
    const editOrderId = yield select(editOrderSelector.getEditedOrderId);
    const request: EditOrderActionRequestWithOrderId = {
      orderId: editOrderId,
      actionRequest: {
        type: EditOrderActionType.UPDATE_POSITION,
        quantity: action.payload.quantity,
        id: action.payload.itemId,
      },
    };
    
    const response: EditedOffer = yield call(apiEditOrder.updatePosition, request);

    const offer = yield call(setDeliveryTimesSaga, response);

    yield put(updateProductSuccess(offer));
  } catch (error) {
    yield handleFailureState(error,'Couldn\'t update Product on edit order actionRequest: ',action.payload);
  }
}

export default function* updateProductWatcher() {
  yield takeLatest(updateProduct.type, updateProductSaga);
}
