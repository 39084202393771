import { createAction } from '@reduxjs/toolkit';

import { LOG_LEVEL } from 'src/logging/loggingService';


export const logEvent = createAction<LogEventProps>('logging/logEvent');
export const logErrorEvent = createAction<LogEventProps>('logging/logErrorEvent');

export type LogEventAction = ReturnType<typeof logEvent>

export interface LogEventProps {
  message: string,
  level?: LOG_LEVEL,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  err?: any,
  args?: object;
}

