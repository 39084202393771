import { OrderHistoryResponse } from 'src/types/orderhistory/OrderHistoryResponse';


export const getProductSkusFromOrderHistoryResponse = (orders: OrderHistoryResponse[]) => {
  let skus: string[] = [];
  orders.forEach(order => {
    skus = [...order.items.map(item => item.sku), ...skus];
  });
  return skus;
};
