import {
  MasterVariant,
  Product,
  ProductSearchResponse,
  ProductStatus,
  Variant
} from 'src/types/product/product';
import { ProductSuggestParams } from 'src/types/product/SearchAndSuggestionsParams';
import { SearchResponse } from 'src/types/product/SearchResponse';
import { SearchSuggestionResponse } from 'src/types/product/SearchSuggestionResponse';
import { SelectedVariant } from 'src/types/product/SelectedVariant';
import { SuggestionsResponse } from 'src/types/product/SuggestionsResponse';


export function normalizeProductSearch({ hits: products }: SearchResponse): ProductSearchResponse {
  const selectedVariants: SelectedVariant[] = products.map(hit => {
    const masterValues: MasterVariant = {
      brand: hit.brand?.longName || '',
      additionalInformation:  '',
      categoryPath: [[hit.categoryPath]],
      masterProductNumber: hit.baseProductNo,
      freeShipping: hit.freeShipping,
      description: hit.productName.longName,
      categoryUrl: [],
      salesdrivers: hit.salesDriver ? [hit.salesDriver.name] : [],
      brandUrl: '',
      name: hit.productName.longName,
      shortDescription: hit.productName.shortName || '',
      longName: hit.productName.longName,
      brandName: hit.brand?.shortName,
    };

    const variantValues: Variant =  {
      basePrice: hit.variant.currentPrice.base?.value || 0,
      basePriceAmount: hit.variant.currentPrice.base?.amount || 1,
      basePriceUnit: hit.variant.currentPrice.base?.unit || '',
      imageURL: hit.variant.image.uri,
      price: hit.variant.currentPrice.value,
      priceLabel: hit.variant.priceLabels[0],
      productNumber: hit.variant.sku,
      referencePrice: hit.variant.currentPrice.reference?.value || 0,
      referencePriceLabel: '',
      referencePriceSaving: hit.variant.currentPrice.reference?.savingPercent || 0,
      referencePriceSavingPercent: hit.variant.currentPrice.reference?.savingPercent || 0,
      referencePriceType: '',
      status: ProductStatus.Sellable,
      colorFamilies: hit.variant.colorFamilies,
      sIZE: hit.variant.sizes,
      cOLOR: hit.variant.colors,
      tASTE: [],
      aLLOY: hit.variant.alloys,
      mOTIV: [],
      aROMA: [],
      aMOUNT: [],
      aTONE: [],
    };

    const product: Product = {
      id: hit.baseProductNo,
      masterValues,
      variantValues: [variantValues],
    };

    return {
      product,
      variant: variantValues
    };
  });

  return {
    totalProducts: selectedVariants.length,
    products: selectedVariants,
  };
}

export function normalizeProductSuggestions({ suggestions }: SuggestionsResponse, searchParams: ProductSuggestParams): SearchSuggestionResponse[] {
  return suggestions.map(it => ({
    name: it.text,
    type: it.type,
    attributes: it.baseProductNo,
    searchParams: {
      channel: searchParams.countryCode,
    },
  } as SearchSuggestionResponse));
}