import { createSelector } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';

import { CountryCode, ProcessingSystem } from 'src/api/orderTypes';
import { RootStateType } from 'src/constants/types';
import { DEFAULT_PAYMENTS } from 'src/redux/payment/paymentSlice';
import { DeliveryAddressType } from 'src/types/customer/address';
import { BankingDetailsState, BankingInfo } from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { CustomerType } from 'src/types/voucher/EmployeeDiscount';
import { createSelectors } from 'src/utils/state';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


export const editOrderSelector = createSelectors({
  getEditedOffer(state: RootStateType) {
    return state.editOrder.editedOffer;
  },
  getOriginalOrder(state: RootStateType) {
    return state.editOrder.originalOrder;
  },
  getEditedOrderCustomerId(state: RootStateType) {
    return state.editOrder.originalOrder?.externalCustomerId ?? '';
  },
  isOrderEdited(state: RootStateType) {
    const length = state.editOrder.editedOffer?.changes?.length;
    if (length) {
      return length > 0;
    }
    return false;
  },
  getEditedOrderId(state: RootStateType) {
    return state.editOrder.editedOffer?.editedOrderId ?? '';
  },
  getEditedExternalOrderId(state: RootStateType) {
    return state.editOrder.originalOrder?.number ?? '';
  },
  getOfferNewItems(state: RootStateType) {
    return state.editOrder.editedOffer?.items.filter(v => v.number === undefined) ?? state.editOrder.newItems;
  },
  getOfferOldItems(state: RootStateType) {
    return state.editOrder.editedOffer?.items.filter(v => v.number !== undefined) ?? state.editOrder.oldItems;
  },
  submitOrderLoading(state: RootStateType) {
    return state.editOrder.submitLoading;
  },
  getEditedOrderDeliveryTimes(state: RootStateType) {
    return state.editOrder.orderDeliveryTimes;
  },
  cancelTheWholeOrder(state: RootStateType) {
    return state.editOrder.editedOffer?.items.every(i => i.cancellationReason ?? false) === true;
  },
  getPositionToCancel(state: RootStateType) {
    return state.editOrder.positionToCancel ?? '';
  },
  getSubmittedOrderId(state: RootStateType) {
    return state.editOrder.submitEditedOrder?.id ?? '';
  },
  isUpdatePositionLoading(state: RootStateType) {
    return state.editOrder?.updatePositionLoading;
  },
  getAdditionalDeliveryAddress(state: RootStateType) {
    return state.editOrder.additionalDeliveryAddress;
  },
  getAvailablePaymentMethods(state: RootStateType) {
    return state.editOrder.editedOffer?.paymentOptions ?? DEFAULT_PAYMENTS;
  },
  getBankDetails(state: RootStateType) {
    const customer = state.editOrder.originalOrder?.customer;
    const existingCustomerBankDetails = customer?.bankingDetails;
    const bankingInfoState = state.editOrder.bankingInfo;

    const accountHolder =
      existingCustomerBankDetails?.accountHolder ??
      trimStringOrNull(bankingInfoState.accountHolder) ??
      (customer?.firstName && customer.lastName
        ? `${customer.firstName} ${customer.lastName}`
        : '');

    return {
      iban: existingCustomerBankDetails?.maskedIban ?? bankingInfoState.iban,
      accountHolder: accountHolder,
      bankName: existingCustomerBankDetails?.bankName ?? bankingInfoState.bankName,
      ibanValid: !!existingCustomerBankDetails || bankingInfoState.ibanValid,
      usesPrefilledIban: existingCustomerBankDetails
        ? BankingDetailsState.prefilled
        : bankingInfoState.usesPrefilledIban,
      firstTime: existingCustomerBankDetails ? false : bankingInfoState.firstTime,
      ibanError: existingCustomerBankDetails ? undefined : bankingInfoState.ibanError,
    } as BankingInfo;
  },
  hasUpdatingDeliveryAddressStarted(state: RootStateType) {
    return state.editOrder.updateDeliveryAddress;
  },
  getInstallmentPlans(state: RootStateType) {
    return (
      state.editOrder.installments.installmentPlans?.map(installement => ({
        ...installement,
        total: installement.totalGrossAmount ?? installement.total,
      })) ?? []
    );
  },
  getInstallmentFactoringPlans(state: RootStateType) {
    return (
      state.editOrder.installmentsFactoring.installmentPlans?.map(installement => ({
        ...installement,
        total: installement.totalGrossAmount ?? installement.total,
      })) ?? []
    );
  },
  getInstallmentFactoringModalStep(state: RootStateType) {
    return state.editOrder.installmentsFactoring.modalStep;
  },
  getInstallmentFactoringCurrentInstallmentPlan(state: RootStateType) {
    return state.editOrder.installmentsFactoring.currentInstallmentPlan;
  },
  hasInstallmentFactoringConsent(state) {
    return state.editOrder.installmentsFactoring.conditionsConsent;
  },
  getPayment(state) {
    return state.editOrder.payment;
  },
  getBankingInfo(state: RootStateType) {
    return state.editOrder.bankingInfo;
  },
  openUpdatePayment(state: RootStateType) {
    return state.editOrder.updatePayment;
  },
  updateInstallmentsPlan(state: RootStateType) {
    return state.editOrder.updateInstallmentPlan;
  },
  getInstallments(state: RootStateType) {
    return state.editOrder.installments;
  },
  getInstallmentsModalStep(state: RootStateType) {
    return state.editOrder.installments.modalStep;
  },
  getUpdateInstallmentsFactoringPlan(state: RootStateType) {
    return state.editOrder.updateInstallmentFactoringPlan;
  },
  deliveryAddressMessing(state: RootStateType) {
    const { editedOffer } = state.editOrder;
    return (
      !editedOffer?.deliveryAddress && !editedOffer?.items.some(i => i.deliveryAddress ?? false)
    );
  },
  isDeliveryAddressSelected(state: RootStateType) {
    return state.editOrder.deliveryAddressSelected;
  },
  isCustomerHSEEmployee(state: RootStateType) {
    const { originalOrder } = state.editOrder;
    return (
      !!originalOrder?.customer?.type &&
      ![CustomerType.REGULAR, CustomerType.TV_CREATOR, CustomerType.INFLUENCER].includes(
        originalOrder?.customer?.type,
      )
    );
  },
  getCustomerType(state: RootStateType) {
    return state.editOrder.originalOrder?.customer?.type;
  },
  getEmployeeDiscount(state: RootStateType) {
    return state.editOrder.editedOffer?.employeeDiscount;
  },
  getEditedOfferCurrency(state: RootStateType) {
    return state.editOrder.editedOffer?.currency;
  },
  getEditedOfferTotalPrice(state: RootStateType) {
    return state.editOrder.editedOffer?.totalPrice;
  },
  getEditOrderInstallmentsCurrentInstallmentPlans(state: RootStateType) {
    return state.editOrder.installments.currentInstallmentPlan;
  },
  isThereEnabledTakeBackObligation(state: RootStateType) {
    return (
      state.app.country === CountryCode.de &&
      state.editOrder?.editedOffer?.items?.some(item => item.weeeTakeBackEnabled)
    );
  },
  doesOrderHaveDifferentDeliveryAddresses(state: RootStateType) {
    try {
      const groupedPositionsByDeliveryAddress = groupBy(
        state.editOrder.oldItems,
        'deliveryAddress.id',
      );
      return Object.entries(groupedPositionsByDeliveryAddress).length > 1;
    } catch (err) {
      return false;
    }
  },
  getEditedOrderSalesChannel(state: RootStateType) {
    return state.editOrder.originalOrder?.disChannel;
  },
  getCanUsePackstationAddress(state: RootStateType) {
    return state.editOrder.editedOffer?.canUsePackstationAddress !== false;
  },
  getEditedOrderDeliveryAddress(state: RootStateType) {
    return state.editOrder.editedOffer?.deliveryAddress;
  },
  isERPOrder(state: RootStateType) {
    return state.editOrder.originalOrder?.processingSystem === ProcessingSystem.ERP;
  },
  isPaymentIgnored(state: RootStateType) {
    return state.editOrder.originalOrder?.payment?.type === 'A';
  },
  getBlockedDeliveryAddresses(state: RootStateType) {
    return state.editOrder.blockedDeliveryAddresses;
  },
  getContactDetails(state: RootStateType) {
    return state.editOrder.contactDetails;
  },
  getCustomerCountry(state: RootStateType) {
    return state.editOrder.originalOrder?.customer?.billingAddress?.countryCode;
  },
  isThereAnEditOrderActionInProgress(state: RootStateType) {
    return state?.editOrder?.loading;
  },
  getInitLoadingState({ editOrder }: RootStateType) {
    return editOrder.initLoading;
  },
  getEditOfferTotal(state: RootStateType) {
    return state.editOrder?.editedOffer?.totalPrice;
  },
  getInstallmentsFactoringModalMode(state: RootStateType) {
    return state.editOrder.installmentsFactoring.installmentFactoringModalMode;
  },
  isVoucherRedeemed(state: RootStateType) {
    return state.editOrder.editedOffer?.voucher !== undefined;
  },
  isReklaOrder(state: RootStateType) {
    return !!state.editOrder.originalOrder?.reklaFlag;
  }
});
export const isInvalidOrder = createSelector(
  editOrderSelector.updateInstallmentsPlan,
  editOrderSelector.getUpdateInstallmentsFactoringPlan ,
  editOrderSelector.getPayment,
  editOrderSelector.isPaymentIgnored,
  editOrderSelector.deliveryAddressMessing,
  editOrderSelector.isDeliveryAddressSelected,
  editOrderSelector.getEditedOrderDeliveryAddress,
  editOrderSelector.getCanUsePackstationAddress,
  (
    shouldUpdateInstallmentsPlan,
    shouldUpdateFactoringInstallmentsPlan,
    selectedPayment,
    shouldIgnorePayment,
    isMissingDeliveryAddress,
    isDeliveryAddressSelected,
    selectedDeliveryAddress,
    canUsePackstationAddress,
  ) => {
    const isPaymentMissing = !shouldIgnorePayment && !selectedPayment;
    const invalidInstallmentPayment = (shouldUpdateInstallmentsPlan && selectedPayment?.method === PaymentMethod.installments) ||
            (shouldUpdateFactoringInstallmentsPlan && selectedPayment?.method === PaymentMethod.installmentsFactoring);
    const cantUsePackstationAddress = selectedDeliveryAddress?.type === DeliveryAddressType.Packstation && !canUsePackstationAddress;
    return isPaymentMissing ||
          invalidInstallmentPayment ||
          cantUsePackstationAddress ||
          isMissingDeliveryAddress ||
          !isDeliveryAddressSelected;
  });

export const canSubmitEditedOrder = createSelector(
  editOrderSelector.isOrderEdited,
  editOrderSelector.submitOrderLoading,
  editOrderSelector.isUpdatePositionLoading,
  editOrderSelector.isThereAnEditOrderActionInProgress,
  isInvalidOrder,
  (
    isOrderTouched,
    isSubmittingOrder,
    isUpdatingPosition,
    isEditingOrder,
    shouldDisableOrder,
  ) => {
    const hasPendingAction = isSubmittingOrder || isUpdatingPosition || isEditingOrder;
    return isOrderTouched && !shouldDisableOrder && !hasPendingAction;
  });
