export enum SubscriptionChangesHistorySortingMode {
  OLD_FIRST = 'OLD_FIRST',
  NEW_FIRST = 'NEW_FIRST',
}

export interface FetchSubscriptionChangesHistoryRequest {
  page: number;
  size: number;
  sortingMode: SubscriptionChangesHistorySortingMode;
  subscriptionId: string;
}
export const DEFAULT_SUBSCRIPTION_CHANGES_HISTORY_SIZE = 20;

export const createSubscriptionChangesHistoryRequest = (
  subscriptionId: string,
  offset: number,
  sortBy: SubscriptionChangesHistorySortingMode,
): FetchSubscriptionChangesHistoryRequest => ({
  page: offset,
  sortingMode: sortBy,
  size: DEFAULT_SUBSCRIPTION_CHANGES_HISTORY_SIZE,
  subscriptionId,
});
