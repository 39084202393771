
import {
  call,
  getContext,
  put,
} from 'redux-saga/effects';

import { ApiProduct } from 'src/api/apiProduct';
import { logErrorEvent } from 'src/logging/loggingActions';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { OrderItem } from 'src/types/offer/OrderItem';
import { UpgradeItem } from 'src/types/product/CrossSell';
import { ProductDetails } from 'src/types/product/product';
import getDeliveryTimes from 'src/utils/getters/getDeliveryTimes';
import stringify from 'src/utils/stringify';


export function* getItemsDeliveryTimesSaga(items: OrderItem[], ids: string[][]) {
  try {
    if (ids[0]?.length && ids[1]?.length) {
      const apiProduct: ApiProduct = yield getContext(SagaContextItem.apiProduct);
      const data = yield call(apiProduct.getProducts, ids[0]);
      const itemsDeliveryTimes = yield call(getDeliveryTimes, data, ids[1]);

      const upgradeProducts = JSON.parse(localStorage.getItem('upgradeProducts') || '[]');

      const upgradeItems = items.filter(i => !!upgradeProducts.find((u: UpgradeItem) => i.baseProductNo === u.sku || i.sku === u.sku))
        .map((i: OrderItem) => {
          return upgradeProducts.filter((u: UpgradeItem) => {
            if (i.baseProductNo === u.sku || i.variant.sku === u.sku) {
              return ({
                sku: i.baseProductNo || i.variant.sku,
                upgradeSku: u.upgradeSku!,
                crossSell: u.crossSell,
              });
            }
          });
        });

      const orderItems: OrderItem[] = [];

      for (const orderItem of items) {
        const currentUpgradeItems = upgradeItems.find(i => i[0].sku === orderItem.baseProductNo);

        let upgrades: ProductDetails[] = [];

        if(currentUpgradeItems && currentUpgradeItems.length > 0) {
          upgrades = yield call(
            apiProduct.getProducts,
            currentUpgradeItems.map((i: { upgradeSku: string; }) => i.upgradeSku),
          );
        }

        const crossSell1 = upgradeItems[0]?.find((upgradeItem: UpgradeItem) =>
          (upgradeItem.sku === orderItem.baseProductNo || upgradeItem.sku === orderItem.variant.sku) &&
          (upgradeItem.upgradeSku === upgrades[0]?.baseProductNo),
        )?.crossSell;

        const crossSell2 = upgradeItems[0]?.find((upgradeItem: UpgradeItem) =>
          (upgradeItem.sku === orderItem.baseProductNo || upgradeItem.sku === orderItem.variant.sku) &&
          (upgradeItem.upgradeSku === upgrades[1]?.baseProductNo),
        )?.crossSell;

        orderItems.push({
          ...orderItem,
          deliveryTime: itemsDeliveryTimes[orderItem.variant.sku],
          upgrade: upgrades[0] ? { ...upgrades[0], crossSell: crossSell1 } : undefined,
          upgrade2: upgrades[1] ? { ...upgrades[1], crossSell: crossSell2 } : undefined,
        });
      }

      return orderItems;
    }
    return items;

  } catch (err) {
    yield put(logErrorEvent({ message: `Could not get delivery_times for items ${stringify(items)}, ids: ${stringify(ids)}`, err }));
    return items;
  }
}
