import { VoucherRestrictionType } from 'src/types/voucher/VirtualVoucherType';


export enum VoucherRestrictionMode {
  include = 'INCLUDE',
  exclude = 'EXCLUDE',
}

export interface VoucherCategoryRestriction {
  type: VoucherRestrictionType.category;
  name: string;
  path: string;
  mode: string;
}

export interface VoucherBrandRestriction {
  type: VoucherRestrictionType.brand;
  name: string;
  mode: string;
}

export interface VoucherProductRestriction {
  type: VoucherRestrictionType.product;
  name: string;
  sku: string;
  mode: string;
}

export interface VoucherProductPriceLabelRestriction {
  type: VoucherRestrictionType.productPriceLabel;
  name: string;
  mode: string;
}

export type VoucherRestriction = VoucherCategoryRestriction | VoucherBrandRestriction | VoucherProductRestriction | VoucherProductPriceLabelRestriction;
