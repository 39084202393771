import { AxiosInstance } from 'axios';

import { getHttpClient } from './httpClient/HttpClient';
import { arrayBufferResponseTypeInterceptor } from './httpClient/interceptors/arrayBufferResponseTypeInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


interface LetterPreviewRequest {
  fileName: string
}
export interface ApiLetterPreview{
  client: AxiosInstance,
  getPreviewLetter: (request: LetterPreviewRequest) => Promise<Blob>
}

export function getApiLetterPreview(baseUrl: string): ApiLetterPreview{
  const axiosInstance = getHttpClient({
    baseUrl,
    interceptors: {
      response: refreshTokenInterceptor,
      request: [xAuthorizationHeaderInterceptor, arrayBufferResponseTypeInterceptor],
    },
  });

  return {
    client: axiosInstance,
    getPreviewLetter: async (request: LetterPreviewRequest )=>{
      const response = await axiosInstance.get(`/schema-letter/preview-letter?file_name=${request.fileName}`);
      return response.data;
    },
  };

}

