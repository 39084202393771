import { PayloadAction } from '@reduxjs/toolkit';
import {
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import {
  changeIBAN,
  savePaymentData,
  setSelectedPaymentMethod,
  submitBankInfo,
  submitInstallmentBankInfo,
  validateIBAN,
} from 'src/redux/payment/paymentSlice';
import { paymentSelector } from 'src/redux/payment/selectors/paymentSelectors';
import { Payment } from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import Log from 'src/utils/log';


const { getPayment } = paymentSelector;
const { getCustomerInfo } = customerInfoSelectors;

export function* savePaymentToStorage() {
  const paymentState = yield select(getPayment);
  const customerInfo = yield select(getCustomerInfo);
  const bankingDetails = yield select(orderEntrySelector.getCustomerBankingDetailsState);

  if(paymentState && customerInfo && bankingDetails) {
    // maintain this, the requirement is saving only the iban in case of new added one and not the whole payment
    Log.message('');
  }
}

export function* validateAndSetSelectedPayment({ payload }: PayloadAction<Payment>) {
  if (payload.method === PaymentMethod.installments) {
    return;
  }
  if (payload.method === PaymentMethod.directDebit) {
    yield put(changeIBAN(payload.iban));
    yield put(validateIBAN());
    yield put(submitBankInfo(payload.accountHolder));
  }
}

export default function* paymentStorageHandlerWatcher() {
  yield takeLatest(submitInstallmentBankInfo.type, savePaymentToStorage);
  yield takeLatest(submitBankInfo.type, savePaymentToStorage);
  yield takeEvery(setSelectedPaymentMethod.type, savePaymentToStorage);

  yield takeEvery(savePaymentData.type, validateAndSetSelectedPayment);
}
