import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { openModal } from 'src/redux/app/appSlice';
import {
  submitEditedOrder,
  submitEditedOrderSuccess,
  editOrderSelector,
} from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { OrderResponse } from 'src/types/offer/OrderResponse';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';


export function* submitEditedOrderSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  try {
    const cancelWholeOrder = yield select(editOrderSelector.cancelTheWholeOrder);
    if (cancelWholeOrder) {
      yield put(openModal(Modals.orderCancellationConfirmation));
      yield put(submitEditedOrderSuccess({} as OrderResponse));
    } else {
      const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
      const response: OrderResponse = yield call(apiEditOrder.submitOrder, action.payload);
      yield put(submitEditedOrderSuccess(response));
      yield put(openModal(Modals.orderConfirmation));
    }
  } catch (error) {
    yield handleFailureState(error, 'Couldn\'t submit Edited Order actionRequest: ', action.payload.actionRequest);
    if (error.response?.data.title === 'ALL_POSITIONS_ARE_CANCELLED') {
      yield put(openModal(Modals.orderCancellationConfirmation));
    }
  }
}

export default function* submitEditedOrderWatcher() {
  yield takeLatest(submitEditedOrder.type, submitEditedOrderSaga);
}
