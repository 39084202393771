import { takeEvery } from 'redux-saga/effects';

import { OpenOrderInfo, openOrderInfo } from 'src/OrderDisplay/redux/orderDisplaySlice';
import routes from 'src/routes/routes';


export function* openOrderInfoSaga(action: OpenOrderInfo) {
  const orderRejectionPath = routes.orderRejection.path;
  yield window.location.href = orderRejectionPath.replace(':id', action.payload);
}

export default function* watchOpenOrderInfo() {
  yield takeEvery(openOrderInfo.type, openOrderInfoSaga);
}
