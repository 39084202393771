import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_SALES_CHANNEL } from 'src/config/salesChannels';


export type SalesChannelState = {
    value: string;
    loading: boolean;
};

export const salesChannelInitialState: SalesChannelState = {
  value : DEFAULT_SALES_CHANNEL,
  loading: false,
};

const salesChannelSlice = createSlice({
  name: 'salesChannel',
  initialState: salesChannelInitialState,
  reducers: {
    changeSalesChannel(state, { payload }: PayloadAction<string>) {
      state.value = payload;
      state.loading = true;
    },
    setSalesChannel(state, { payload }: PayloadAction<string>) {
      state.value = payload;
    },
    resetSalesChannel() {
      return salesChannelInitialState;
    },
    toggleChannelSwitchToOff(state) {
      state.loading = false;
    },
  },
});

export const {
  changeSalesChannel,
  resetSalesChannel,
  toggleChannelSwitchToOff,
  setSalesChannel,
} = salesChannelSlice.actions;

export default salesChannelSlice.reducer;
