import { IntlFormatters } from 'react-intl';

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  DEFAULT_TIMEZONE,
  DISPLAY_DATETIME_FORMAT,
} from 'src/constants/date';
import de from 'src/i18n/de';
import dayjs from 'src/lib/datetime';
import subscriptionManagement from 'src/subscriptionProductManagement/messages/subscriptionManagement.de';
import {
  SubscriptionChangeEvent,
  SubscriptionChangeEventType,
  SubscriptionChangeItem,
} from 'src/subscriptionProductManagement/types/response/SubscriptionChangesHistoryResponse';
import {
  SubscriptionCancellationReason,
  SubscriptionItemFailure,
  SubscriptionItemStatus,
  SubscriptionItemStatusType,
} from 'src/types/subscription/SubscriptionHistoryResponse';
import {
  KeywordType,
  SearchDateType,
  SubscriptionStatusRequest,
} from 'src/types/subscription/SubscriptionsOverviewRequest';

import { EditDeliveryDateFilter } from '../redux/subscriptionManagementSlice';


export enum SubscriptionManagementView {
  ABO_OVERVIEW = 0,
  ABO_PRODUCT = 1,
}

export enum SortBy { 
  NEXT_ORDER_DATE = 'NEXT_ORDER_DATE', 
  UPDATED_DATE = 'UPDATED_DATE',
}

export enum SubscriptionReportSortBy { 
  PRODUCT_NAME = 'PRODUCT_NAME', 
}

export enum SortingMode { 
  ASC = 'ASC', 
  DESC = 'DESC',
}

export enum CheckSubscriptionPeriodSelectValues {
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_SIX_MONTHS = 'LAST_SIX_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  NEXT_WEEK = 'NEXT_WEEK',
  NEXT_MONTH = 'NEXT_MONTH',
  CUSTOM = 'CUSTOM',
  ALL_TIME = 'ALL_TIME'
}

export const tabs = Object.keys(SubscriptionManagementView)
  .filter((key) => isNaN(Number(key)))
  .map(it => ({ label: subscriptionManagement[`subscriptionManagement.tabs.${it}`], id: it }));

export const statusFiltersArray = Object.keys(SubscriptionStatusRequest)
  .filter((key) => isNaN(Number(key)));

export const statusFilters = statusFiltersArray
  .map(it => ({ label: subscriptionManagement[`subscriptionManagement.statusFilters.${it}`] }));

export const dateParameterFilters = [
  SearchDateType.CREATION_DATE,
  SearchDateType.NEXT_DELIVERY_DATE,
  SearchDateType.PREVIOUS_EDIT_DATE,
].map(it => ({ label: subscriptionManagement[`subscriptionManagement.dateParameterFilter.${it}`], value: it }));

export const periodFilters = [
  CheckSubscriptionPeriodSelectValues.YESTERDAY,
  CheckSubscriptionPeriodSelectValues.LAST_MONTH,
  CheckSubscriptionPeriodSelectValues.LAST_SIX_MONTHS,
  CheckSubscriptionPeriodSelectValues.LAST_YEAR,
  CheckSubscriptionPeriodSelectValues.CUSTOM,
].map(it => ({ label: subscriptionManagement[`subscriptionManagement.periodFilters.${it}`], value: it }));

export const nextOrderDateFilter = [
  CheckSubscriptionPeriodSelectValues.NEXT_WEEK,
  CheckSubscriptionPeriodSelectValues.NEXT_MONTH,
  CheckSubscriptionPeriodSelectValues.CUSTOM,
].map(it => ({ label: subscriptionManagement[`subscriptionManagement.periodFilters.${it}`], value: it }));


export function inferSubscriptionDateRangeFromPeriod(period: CheckSubscriptionPeriodSelectValues | undefined) {
  let [ start, end ]: [ Date | undefined, Date | undefined ] = [ undefined, undefined ];

  switch (period) {
  case CheckSubscriptionPeriodSelectValues.YESTERDAY:
    [ start, end ] = [ dayjs().subtract(1, 'day').toDate(), new Date() ];
    break;
  case CheckSubscriptionPeriodSelectValues.LAST_MONTH:
    [ start, end ] = [ dayjs().subtract(1, 'month').toDate(), dayjs().toDate() ];
    break;
  case CheckSubscriptionPeriodSelectValues.LAST_SIX_MONTHS:
    [ start, end ] = [ dayjs().subtract(6, 'month').toDate(), dayjs().toDate() ];
    break;
  case CheckSubscriptionPeriodSelectValues.LAST_YEAR:
    [ start, end ] = [ dayjs().subtract(12, 'month').toDate(), dayjs().toDate() ];
    break;
  case CheckSubscriptionPeriodSelectValues.NEXT_WEEK:
    [ start, end ] = [ new Date(), dayjs().add(7, 'day').toDate() ];
    break;
  case CheckSubscriptionPeriodSelectValues.NEXT_MONTH:
    [ start, end ] = [ new Date(), dayjs().add(1, 'month').toDate() ];
    break;
  case CheckSubscriptionPeriodSelectValues.ALL_TIME:
    [ start, end ] = [ dayjs('1970-01-01').toDate(), dayjs().toDate() ];
    break;
  default:
  }

  return [ start, end ];
}

export enum ProductFilter {
  product = 'product',
  aboNbr = 'aboNbr'
}

export const productFilterOptions: Array<{label: string, value: KeywordType}>  = [
  KeywordType.DELIVERY_PRODUCT_NO,
  KeywordType.SUBSCRIPTION_NO,
  KeywordType.CUSTOMER_NO,
].map(it => ({
  label: subscriptionManagement[`subscriptionManagement.productFilters.${it}`],
  value: it,
}));

export enum SelectionState {
  ALL = 'ALL',
  SOME = 'SOME',
  NONE = 'NONE'
}

export function calculateNextDeliveryDate(
  filter: EditDeliveryDateFilter,
  currentDate?: Date,
  currentRotation?: String,
): Date {
  const result = (currentDate ? dayjs(currentDate) : dayjs()).tz(DEFAULT_TIMEZONE).toDate();
  if (filter === EditDeliveryDateFilter.WEEK) {
    result.setDate(result.getDate() + 7);
  } else if (filter === EditDeliveryDateFilter.SKIP ) {
    result.setMonth(result.getMonth() + getCurrentRotationMonths(currentRotation));
  } else {
    result.setMonth(result.getMonth() + 1);
  }
  return result;
}

function getCurrentRotationMonths(value?: String): number {
  switch(value) {
  case 'ONE_MONTH': return 1;
  case 'TWO_MONTHS': return 2;
  case 'THREE_MONTHS': return 3;
  case 'FOUR_MONTHS': return 4;
  case 'FIVE_MONTHS': return 5;
  case 'SIX_MONTHS': return 6;
  default: return 0;
  }
}

export function calculateNextOrderDate(
  currentOrderDate?: string,
  dateOption?: string,
  rotation?: string,
): string {

  const orderDate = (currentOrderDate ? dayjs(currentOrderDate) : dayjs());
  switch (dateOption) {
  case undefined:
  case 'CUSTOM':
    return orderDate.format(DEFAULT_DISPLAY_DATE_FORMAT);
  case 'TODAY': 
    return dayjs().format(DEFAULT_DISPLAY_DATE_FORMAT);
  case 'ONE_MONTH': 
    return orderDate.add(1, 'M').format(DEFAULT_DISPLAY_DATE_FORMAT);
  case 'SKIP_NEXT_DELIVERY': 
    return orderDate.add(getCurrentRotationMonths(rotation), 'M').format(DEFAULT_DISPLAY_DATE_FORMAT);
  default: 
    return dayjs().add(getOrderDateOptionsValue(dateOption), 'd').format(DEFAULT_DISPLAY_DATE_FORMAT);
  }
}

function getOrderDateOptionsValue(value?: String): number {
  switch (value) {
  case 'TODAY':
    return 0;
  case 'ONE_WEEK':
    return 7;
  case 'TWO_WEEKS':
    return 14;
  case 'THREE_WEEKS':
    return 21;
  case 'SIX_WEEKS':
    return 42;
  default:
    return 0;
  }
}

export function extractAgentUsernameFromSubscriptionChange(item: SubscriptionChangeItem) {
  const changeEvent = item.changeEvent;
  return (changeEvent.type !== SubscriptionChangeEventType.STATUS_UPDATED) ?
    changeEvent.agentUsername : changeEvent.newStatus.agentUsername;
}

export function getMonthNumberFromName(value?: String): number {
  switch (value?.trim().toLowerCase()) {
  case 'january':
    return 1;
  case 'february':
    return 2;
  case 'march':
    return 3;
  case 'april':
    return 4;
  case 'may':
    return 5;
  case 'june':
    return 6;
  case 'july':
    return 7;
  case 'august':
    return 8;
  case 'september':
    return 9;
  case 'october':
    return 10;
  case 'november':
    return 11;
  case 'december':
    return 12;
  default:
    return 0;
  }
}

export function extractMonthNumberFromStockDueDate(date: string) {
  return dayjs(date, DISPLAY_DATETIME_FORMAT).month() + 1;
}

export function excludeFailedStatusesChanges(changes: SubscriptionChangeItem[]) {
  return changes.filter(change => !(
    change.changeEvent.type === SubscriptionChangeEventType.STATUS_UPDATED &&
      change.changeEvent.newStatus.type === SubscriptionItemStatusType.FAILED
  ),
  );
}

// TODO: unit tests
export function buildFormattedChangeEventNote(changeEvent: SubscriptionChangeEvent, formatMsg: IntlFormatters['formatMessage']) {
  switch (changeEvent.type) {
  case SubscriptionChangeEventType.CUSTOM_NOTE: return changeEvent.note;
  case SubscriptionChangeEventType.QUANTITY_UPDATED:
  case SubscriptionChangeEventType.ROTATION_UPDATED:
  case SubscriptionChangeEventType.DELIVERY_DATE_UPDATED:
    return changeEvent.note;
  case SubscriptionChangeEventType.STATUS_UPDATED:
    if (changeEvent.newStatus.orderId) {
      return `${formatMsg({ id : 'orderId' })}: ${changeEvent.newStatus.orderId}`;
    } else if (changeEvent.newStatus.type !== SubscriptionItemStatusType.CANCELED) {
      return formatMsg({ id : 'subscriptionProductManagement.subscriptionChangeHistory.automaticUpdateNote' });
    }
    return undefined;
  default:
    return undefined;
  }
}

export function getSubscriptionRejectionReasonText(status: SubscriptionItemStatus, formatMessage: (id: string) => string) {
  const statusType = status.type;

  if(statusType === SubscriptionItemStatusType.DELAYED_IN_DELIVERY) {
    const failure = status.reason as SubscriptionItemFailure;
    return getTextFromErrorKey(failure?.errorKey) ?? failure.details;
  }

  if(statusType === SubscriptionItemStatusType.IN_PROGRESS) {
    return getTextFromErrorKey(status.failure?.errorKey) ?? status.failure?.details;
  }

  const isWarning = status.type === SubscriptionItemStatusType.FAILED;
  const isCancelled = status.type === SubscriptionItemStatusType.CANCELED;
  const rejectionReasonId = isCancelled && `subscription.cancellation.reason.${status.reason}` || isWarning && 'subscription.history.delivery.failed' || '';

  return rejectionReasonId && formatMessage(rejectionReasonId);
}

function getTextFromErrorKey(errorKey?: string) {
  return subscriptionFailureKeys[Object.keys(subscriptionFailureKeys).find(key => errorKey?.includes(key)) ?? ''];
}

const subscriptionFailureKeys: {[key: string]: string} = {
  'invalid_payment_method': 'Die gewählte Zahlungsart steht derzeit nicht zur Verfügung. Bitte andere Zahlungsart wählen.',
  'credit_limit_exceeded': 'Auftragswert zu groß',
  'missing_banking_info': 'Keine/gesperrte Bankverbindung',
  'missing_delivery_address': 'Lieferadresse nicht in den Kunden-Stammdaten vorhanden',
  'order_with_address_category1_not_allowed': 'Lieferadresse KAT1',
  'order_with_invoice_address_category1_not_allowed': 'Meldeadresse KAT1',
  'stock_reservation_failure': 'Dieses Produkt ist leider ausverkauft.',
  'basket_invalid': 'Dieses Produkt ist leider ausverkauft.',
  'product_not_sellable': 'Dieses Produkt ist derzeit nicht verfügbar.',
  'product_not_found': 'Artikel-Status "gelöscht"',
  'invalid_variant_sku': 'Artikel-Status "gelöscht"',
  'partially_returned_order': 'Teil-Retoure',
  'returned_order': 'Retoure',
  'cancelled_order': 'Storno',
  'order_delivery_block': 'Kunde mit Auftragsbeschränkung',
  'deviant_customer_data': 'Abweichende Kundendaten',
  'general_crm_rejection': 'Ablehnung des CRM-Schecks',
  'general_erp_rejection': 'Ablehnung des ERP-Schecks',
};

export const subscriptionCancellationReasonOptions = [
  { label: '-', value: '-' },
  ...(Object.keys(SubscriptionCancellationReason).map(it => ({ label: de[`subscription.cancellation.reason.${it}`], value: it })))
];
