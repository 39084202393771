export default {
  'notification.modal.title': 'Benachrichtigung',
  'notification.modal.noNotification': 'Zurzeit gibt es keine Benachrichtigung',
  'notification.type.Eilmeldung': 'Eilmeldung',
  'notification.type.Information': 'Information',
  'notification.since.afterDay': '{type} · {value}',
  'notification.since.hours': '{type} · Vor {value} Stunden',
  'notification.since.minutes': '{type} · Vor {value} Minuten',
  'notification.since.seconds': '{type} · Vor {value} Sekunden',
};
