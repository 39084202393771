import { createAction } from '@reduxjs/toolkit';

import { ReklaOrder } from 'src/redux/order/orderEntrySlice';
import { CreateOrderResultResponse } from 'src/types/offer/CreateOrderResultResponse';
import { OfferItem } from 'src/types/offer/Offer';
import { OrderItem } from 'src/types/offer/OrderItem';
import { ProductDetails } from 'src/types/product/product';

import { VoucherState } from '../voucherSlice';


export const deleteOrderItem = createAction<OrderItem>('orderEntry/deleteOrderItem');
export const addOrderItemBySku = createAction(
  'orderEntry/addOrderItemBySku',
  (sku: string, skipUpsells?: boolean, uniqueID?: string, productDetails?: ProductDetails) => ({ payload: { sku, skipUpsells, uniqueID, productDetails } }),
);
export const trackRemoveProductFromCartEvent = createAction<{ productDetail: OfferItem, salesChannel: string }>('orderEntry/trackRemoveProductFromCartEvent');
export const trackAddProductToCartEvent = createAction<{ productDetails?: ProductDetails, salesChannel: string, sku: string, upsellsMainProduct?: string, isAddedBySKU?: boolean }>('orderEntry/trackingAddProductToCart');
export const upgradeOrderItem = createAction<{item: OrderItem, upgrade: ProductDetails, selectedUpgradeVariant?: string}>('orderEntry/upgradeOrderItem');
export const upgradeOrderItemSuccess = createAction(
  'orderEntry/upgradeOrderItemSuccess',
  (sku: string, productDetails?: ProductDetails) => ({ payload: { sku, productDetails } }),
);
export const setEnableTakeBack = createAction('orderEntry/setEnableTakeBack', (id: string, enableTakeBack: boolean) => ({ payload: { id, enableTakeBack } }));
export const changePriceDate = createAction('orderEntry/changePriceDate', (id: string, priceDate: Date) => ({ payload: { id, priceDate } }));
export const changeQuantity = createAction('orderEntry/changeQuantity', (id: string, quantity: number) => ({ payload: { id, quantity } }));
export const trackOrderEvent = createAction<{
  orderTrack: CreateOrderResultResponse,
  orderItems: OrderItem[],
  agentUsername: string,
  voucher: VoucherState,
  salesChannel: string,
  cdt: string
}>('orderEntry/trackOrderEvent');
export const getOffer = createAction('orderEntry/getOffer');
export const search = createAction('orderEntry/search', (searchTerm: string, showOnlyDetail?: boolean) => ({ payload: { searchTerm, showOnlyDetail } }));
export const prepareReklaOrder = createAction<ReklaOrder>('orderEntry/prepareReklaOrder');
export const loadReklaOrder = createAction<ReklaOrder>('orderEntry/loadReklaOrder');

export type TrackOrderEvent = ReturnType<typeof trackOrderEvent>
export type ChangeQuantityAction = ReturnType<typeof changeQuantity>
export type ChangePriceDateAction = ReturnType<typeof changePriceDate>
export type DeleteOrderItemAction = ReturnType<typeof deleteOrderItem>
export type UpgradeOrderItem = ReturnType<typeof upgradeOrderItem>
export type SetEnableTakeBackAction = ReturnType<typeof setEnableTakeBack>
export type TrackRemoveProductToCartEvent = ReturnType<typeof trackRemoveProductFromCartEvent>
export type TrackAddProductToCartEvent = ReturnType<typeof trackAddProductToCartEvent>
export type AddOrderItemAction = ReturnType<typeof addOrderItemBySku>
export type SearchAction = ReturnType<typeof search>
export type PrepareReklaOrder = ReturnType<typeof prepareReklaOrder>;
export type LoadReklaOrder = ReturnType<typeof loadReklaOrder>;
