import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal, openModal } from 'src/redux/app/appSlice';
import { changeAddress, emptyAddressSuggestions } from 'src/redux/customer/addressSuggestionsSlice';
import {
  createCustomerError,
  CreateCustomerResponse,
  createCustomerSuccess,
  CustomerInfoActionTypes,
  setSelectedCustomerLocalId,
  setSelectedDeliveryAddress,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { getOffer } from 'src/redux/order/orderEntrySlice';
import { buildInvoiceAddressFromCustomer } from 'src/sagas/customer/fetchCustomerByNumberSaga';
import { Customer } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import stringify from 'src/utils/stringify';

import { AddressSuggestionsSaga } from '../addressSuggestions/addressSuggestions';


export function* createCustomer(action: CreateCustomerResponse) {
  try {
    const { billingAddressValidated } = customerInfoSelectors;
    const isBillingAddressValidated = yield select(billingAddressValidated);
    const {
      billingAddress,
    } = action.payload;
    if (billingAddress) {
      yield put(changeAddress(billingAddress));
    }

    const validAddress = yield call(AddressSuggestionsSaga, isBillingAddressValidated);
    if (validAddress) {
      yield call(createCustomerSuccessfullySaga, action.payload);
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not create customer ${stringify(action.payload)}`, err }));
    const errorCode = (err?.response?.status) || 400;
    yield put(createCustomerError(errorCode));
  }
}

export function* createCustomerSuccessfullySaga(payload: Customer) {
  const invoiceAddress = buildInvoiceAddressFromCustomer(payload);
  const customer = { ...payload, deliveryAddresses: [invoiceAddress] };
  yield put(createCustomerSuccess(customer));
  yield put(setSelectedDeliveryAddress(invoiceAddress));
  yield put(getOffer());

  yield put(closeModal(Modals.customer));
  yield put(emptyAddressSuggestions());
  yield put(openModal(Modals.consentManagement));
  const localCustomerId = localCustomerStorage.saveCustomer(customer);
  yield put(setSelectedCustomerLocalId(localCustomerId));
}


export default function* createCustomerWatcher() {
  yield takeLatest(CustomerInfoActionTypes.CREATE_CUSTOMER, createCustomer);
}
