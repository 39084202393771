import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ProductDetails } from 'src/types/product/product';
import { StockReminderRequest } from 'src/types/stockReminder/StockReminderRequest';
import { TrackEvent } from 'src/types/TrackEvent';


export interface StockReminderState {
  loading: boolean;
  sku?: string,
  productName?: string,
  productBrandName?: string,
  productDivisionName?: string,
  value?: number;
  email?: string
  subscriptionSuccess?: boolean;
  error?: boolean;
  errorCode?: number;
}

export const stockReminderInitialState: StockReminderState = {
  loading: false,
};

export const trackStockReminderPopupImpression = createAction<TrackEvent>('stockReminder/trackStockReminderPopupImpression');
export type TrackStockReminderPopupImpression = ReturnType<typeof trackStockReminderPopupImpression>;

export const trackStockReminderSetReminder = createAction<TrackEvent>('stockReminder/trackStockReminderSetReminder');
export type TrackStockReminderSetReminder = ReturnType<typeof trackStockReminderSetReminder>;

const stockReminderSlice = createSlice({
  name: 'storckReminder',
  initialState: stockReminderInitialState,
  reducers: {
    subscribeStockReminder(state, { payload }: PayloadAction<StockReminderRequest>) {
      state.sku = payload.sku;
      state.email = payload.email;
      state.loading = true;
    },
    subscribeStockReminderSuccess(state) {
      state.loading = false;
      state.subscriptionSuccess = true;
    },
    subscribeStockReminderFailed(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.subscriptionSuccess = false;
      state.error = true;
      state.errorCode = payload;
    },
    resetStockReminderState(state) {
      state.loading = false;
      state.subscriptionSuccess = undefined;
      state.error = undefined;
      state.errorCode = undefined;
      state.email = undefined;
      state.sku = undefined;
    },
    initStockReminderState(state, { payload }: PayloadAction<{sku: string, productDetails?: ProductDetails, value: number}>) {
      state.loading = false;
      state.sku = payload.sku;
      state.productName = payload.productDetails?.name.long ?? payload.productDetails?.name.short;
      state.productBrandName = payload.productDetails?.brand?.brandName ?? '-';
      state.productDivisionName = payload.productDetails?.categoryPath ?? '-';
      state.value = payload.value;
    },
  },
});

export const {
  subscribeStockReminder,
  subscribeStockReminderSuccess,
  subscribeStockReminderFailed,
  resetStockReminderState,
  initStockReminderState,
} = stockReminderSlice.actions;

export type SubscribeStockReminder = ReturnType<typeof subscribeStockReminder>;

export default stockReminderSlice.reducer;
