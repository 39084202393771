import { LanguageOptions } from 'src/redux/app/appSlice';

import deTranslationMessages from './de';
import enTranslationMessages from './en';


const DEFAULT_LOCALE = LanguageOptions.de.toString();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatTranslationMessages = (locale: string, messages: any): {} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultFormattedMessages: any = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
    : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  en: enTranslationMessages,
  de: deTranslationMessages,
};

export function formatMessage(messageId: string, args?: {[field: string]: string}) {
  const deMessage = deTranslationMessages[messageId].toString();
  return !args ? deMessage : Object.keys(args).reduce(
    (previousValue, field: string) => previousValue.replace(`{${field}}`, args[field]),
    deMessage,
  );
}
