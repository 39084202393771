import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ProductRow } from 'src/components/ProductSearch/productsSearchUtils';
import { ItemSource } from 'src/types/offer/ItemSource';
import { OrderItem } from 'src/types/offer/OrderItem';
import { CrossSellInfo } from 'src/types/product/CrossSell';
import {
  ProductDetails,
  ProductDetailsViews,
  ProductDetailsViewsSource,
  ProductImpressionsView,
  VariantValue,
} from 'src/types/product/product';


export interface ProductDetailsState {
  loading: boolean;
  product?: ProductDetails;
  selectedVariant?: VariantValue;
  error?: boolean;
  errorCode?: number;
  source?: ProductDetailsViewsSource;
  showList?: boolean;
  displayUpgradeButton?: boolean;
  upgradeItem?: OrderItem;
}

export const productDetailsInitialState: ProductDetailsState = {
  loading: true,
  showList: true,
  displayUpgradeButton: false,
};

export interface ItemProps {
    sku: string;
    itemSource?: ItemSource;
    tvOrTopDealInfo?: string;
    crossSell?: CrossSellInfo;
}

export const fetchProduct = createAction<ItemProps>('productDetails/fetchProduct');
export type FetchProduct = ReturnType<typeof fetchProduct>;

export const trackProductClick = createAction<{
    source: string;
    product: ProductDetails;
    position: number;
    channel: string;
}>('productDetails/trackProductClick');
export type TrackProductClick = ReturnType<typeof trackProductClick>;

export const trackRowClick = createAction<{
    source: string;
    row: ProductRow;
    channel: string;
}>('productDetails/trackRowClick');
export type TrackRowClick = ReturnType<typeof trackRowClick>;

export const trackProductImpressionsEvent = createAction<ProductImpressionsView>('productDetails/trackProductImpressionsEvent');
export type TrackProductImpressionsEvent = ReturnType<typeof trackProductImpressionsEvent>;

export const trackOfferProductImpression = createAction<{
    product: ProductDetails,
    salesChannel: string
}>('productDetails/trackOfferProductImpression');
export type TrackOfferProductImpression = ReturnType<typeof trackOfferProductImpression>;

export const trackProductRowImpression = createAction<{
    products: ProductRow[],
    salesChannel: string
}>('productDetails/trackProductRowImpression');
export type TrackProductRowImpression = ReturnType<typeof trackProductRowImpression>;

export const trackProductDetailsViews = createAction<ProductDetailsViews | undefined>('productDetails/trackProductDetailsViews');
export type TrackProductDetailsViews = ReturnType<typeof trackProductDetailsViews>;

const productsDetailsSlice = createSlice({
  name: 'productDetails',
  initialState: productDetailsInitialState,
  reducers: {
    fetchProductInProgress(state){
      state.loading = true;
    },
    fetchProductSuccess(state, { payload }: PayloadAction<ProductDetails>){
      state.loading = false;
      state.product = payload;
    },
    fetchProductError(state, { payload }: PayloadAction<number>){
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    setSelectedVariant(state, { payload }: PayloadAction<VariantValue | undefined>){
      state.selectedVariant = payload;
    },
    setSource(state, { payload }: PayloadAction<ProductDetailsViewsSource | undefined>){
      state.source = payload;
    },
    setShowList(state, { payload }: PayloadAction<boolean | undefined>){
      state.showList = payload;
    },
    setDisplayUpgradeButton(state, { payload }: PayloadAction<boolean>){
      state.displayUpgradeButton = payload;
    },
    setUpgradeItem(state, { payload }: PayloadAction<OrderItem | undefined>){
      state.upgradeItem = payload;
    },
  },
});

export const {
  fetchProductInProgress,
  fetchProductSuccess,
  setSelectedVariant,
  fetchProductError,
  setSource,
  setShowList,
  setDisplayUpgradeButton,
  setUpgradeItem,
} = productsDetailsSlice.actions;

export default productsDetailsSlice.reducer;
