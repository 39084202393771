export enum OrderRestriction {
  creditLimit = 'CREDIT_LIMIT',
  dunningLevel1 = 'DUNNING_LEVEL_1',
  creditCard = 'CREDIT_CARD',
  infodataInquiry4 = 'INFODATA_INQUIRY_4',
  negativeInquiry = 'NEGATIVE_INQUIRY',
  financing = 'FINANCING',
  infodataCreditLimit = 'INFODATA_CREDIT_LIMIT',
  BIG_INQUIRY = 'BIG_INQUIRY',
  infodataInquiry9 = 'INFODATA_INQUIRY_9',
  addressCheckMissing = 'ADDRESS_CHECK_MISSING',
  reservation= 'RESERVATION',
  frequentReturner = 'FREQUENT_RETURNER',
  accountNumberValidated = 'ACCOUNT_NUMBER_VALIDATED',
  dubiousBankAccounts = 'DUBIOUS_BANK_ACCOUNTS',
  ls18 = 'LS18',
  lsIntern = 'LS_INTERN',
  duplicate = 'DUPLICATE',
  permanantReturner = 'PERMANANT_RETURNER',
  nonPayeru = 'NON_PAYERU',
  fraud = 'FRAUD',
  customerRequirement = 'CUSTOMER_REQUIREMENT',
  prankOrder = 'PRANK_ORDER',
  sapLegacyData = 'SAP_LEGACY_DATA',
  deceased = 'DECEASED',
  unknownAddress = 'UNKNOWN_ADDRESS',
  underaged = 'UNDERAGED',
  disability = 'DISABILITY',
  drmLock = 'DRM_LOCK',
  customerToBeDeleted = 'CUSTOMER_TO_BE_DELETED',
  asInternal = 'AS_INTERNAL',
  subjectToLawyer = 'SUBJECT_TO_LAWYER',
  insolvency = 'INSOLVENCY',
  departedEmployee = 'DEPARTED_EMPLOYEE',
  seeMasterData = 'SEE_MASTER_DATA',
  dataPrivacyLock = 'DATA_PRIVACY_LOCK',
  deletionGdpr = 'DELETION_GDPR',
  toBeArchived = 'TO_BE_ARCHIVED',
  archived = 'ARCHIVED',
  siebelApproved = 'SIEBEL_APPROVED',
  temporaryDeliveryBlock = 'TEMPORARY_DELIVERY_BLOCK',
  nonPayerKsv = 'NON_PAYER_KSV',
  nonPayerReal = 'NON_PAYER_REAL',
  nonPayerKsp = 'NON_PAYER_KSP',
  departedCreator = 'DEPARTED_CREATOR',
  nonPayerEos = 'NON_PAYER_EOS',
  nonPayerInfoScore = 'NON_PAYER_INFOSCORE',
}
