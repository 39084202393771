import copySubscriptionsCustomersInfoSagaWatcher from 'src/subscriptionProductManagement/sagas/copySubscriptionsCustomerInfo';
import loadCurrentSubscriptionCustomerWatcher from 'src/subscriptionProductManagement/sagas/loadCurrentSubscriptionCustomer';
import refreshCurrentSubscriptionWatcher from 'src/subscriptionProductManagement/sagas/refreshCurrentSubscription';

import cancelSubscriptionWatcher from './cancelSubscription';
import { editSubscriptionProductReportWatcher } from './editSubscriptionProductReportNote';
import { editSubscriptionsDeliveryDatesWatcher } from './editSubscriptionsDeliveryDates';
import { fetchSubscriptionChangesHistoryWatcher } from './fetchSubscriptionHistory';
import fetchSubscriptionsReportWatcher from './fetchSubscriptionReport';
import fetchSubscriptionsOverviewWatcher from './fetchSubscriptionsOverview';
import { saveSubscriptionCustomEventWatcher } from './saveSubscriptionCustomEvent';
import { saveSubscriptionNoteWatcher } from './saveSubscriptionNote';


export default [
  cancelSubscriptionWatcher,
  fetchSubscriptionsOverviewWatcher,
  editSubscriptionsDeliveryDatesWatcher,
  fetchSubscriptionChangesHistoryWatcher,
  editSubscriptionProductReportWatcher,
  saveSubscriptionNoteWatcher,
  fetchSubscriptionsReportWatcher,
  loadCurrentSubscriptionCustomerWatcher,
  refreshCurrentSubscriptionWatcher,
  saveSubscriptionCustomEventWatcher,
  copySubscriptionsCustomersInfoSagaWatcher,
];
