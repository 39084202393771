import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { closeModal } from 'src/redux/app/appSlice';
import { getOffer } from 'src/redux/order/orderEntrySlice';
import {
  changeInstallmentFactoringModalMode,
  cancelInstallmentFactoringPaymentForm,
  InstallmentFactoringModalMode,
  onClosingInstallmentFactoringModal,
} from 'src/redux/payment/paymentSlice';
import { paymentSelector } from 'src/redux/payment/selectors/paymentSelectors';
import { Modals } from 'src/types/Modals';


export function* onClosingInstallmentFactoringModalSaga() {
  const installmentsFactoringModalMode = yield  select(paymentSelector.getInstallmentsFactoringModalMode);
  if (installmentsFactoringModalMode === InstallmentFactoringModalMode.NORMAL_MODE) {
    yield put(cancelInstallmentFactoringPaymentForm());
  }
  yield put(closeModal(Modals.installmentFactoringPayment));
  yield put(getOffer());
  yield put(changeInstallmentFactoringModalMode(InstallmentFactoringModalMode.NORMAL_MODE));
}

export default function* onClosingInstallmentFactoringModalWatcher() {
  yield takeLatest(onClosingInstallmentFactoringModal.type, onClosingInstallmentFactoringModalSaga);
}
