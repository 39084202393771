export default {
  'voucher.createVoucher': 'Neuen Gutschein anlegen',
  'voucher.percentageVoucherInfo': 'Prozentgutschein',
  'voucher.valueVoucherInfo': 'Wertgutschein',
  'voucher.code': 'Gutscheincode eingeben',
  'voucher.aliasCode': 'Alias Code (optional)',
  'voucher.description': 'Beschreibung (max. 40 Zeichen)',
  'voucher.percentage': 'Prozentwert (min. 1%,  max. 20%)',
  'voucher.value': 'Wert des Gutscheins auswählen',
  'voucher.maxValue': 'Höchstbestellwert wird automatisch berechnet',
  'voucher.minValue': 'Mindestbestellwert eingeben (min. {VALUE}€)',
  'voucher.startDate': 'Startdatum (tt.mm.jjjj)',
  'voucher.endDate': 'Enddatum (tt.mm.jjjj)',
  'voucher.startTime': 'Startuhrzeit  (ss:mm)',
  'voucher.endTime': 'Enduhrzeit (ss:mm)',
  'voucher.country': 'In welchen Ländern soll der Gutschein gültig sein?',
  'voucher.channel': 'Für welchem Bestellkanal soll der Gutschein gültig sein?',
  'voucher.confirmData': 'Ich habe alle Daten geprüft. Der Gutschein wird angelegt und kann nicht mehr verändert werden, wenn ich auf „Jetzt anlegen“ klicke.',
  'voucher.applyNow': 'Jetzt anlegen',
  'voucher.enterData': 'Daten eingeben',
  'voucher.code.required': 'Bitte Gutscheincode prüfen',
  'voucher.description.required': 'Bitte Beschreibung prüfen',
  'voucher.code.range': 'Gutscheincode sollte 11 Zeichen nicht überschreiten',
  'voucher.description.range': 'die Beschreibung  sollte 40 Zeichen nicht überschreiten',
  'voucher.validityStartTime.required': 'Bitte Beginndatum prüfen',
  'voucher.validityEndTime.required': 'Bitte Enddatum prüfen',
  'voucher.validityCountry.required': 'Bitte wählen Sie mindestens ein Land aus',
  'voucher.channelValidity.required': 'Bitte wählen Sie mindestens ein Bestellkanal aus',
  'voucher.percentage.required': 'Bitte Prozents prüfen',
  'voucher.percentage.range': 'Zahl des Prozents (min. 1%,  max. 20%)',
  'voucher.percentage.decimal': 'Prozents sollte keine Dezimalzahl sein',
  'voucher.minValue.lessThan': 'Bitte Mindestwert des Mindestbestellwerts prüfen.',
  'voucher.minValue.decimal': 'Der Mindestwert sollte keine Dezimalzahl sein',
  'voucher.minValue.required': 'Bitte Mindestwert prüfen',
  'voucher.value.required': 'Bitte Gutscheinwert prüfen',
  'voucher.restriction': 'Suche nach Kategorie, Marke, Produkt, SKU',
  'voucher.restrictionTitle': 'Soll der Gutschein auf eine Ebene eingeschränkt werden? (optional)',
  'voucher.category': 'Kategorie',
  'voucher.brand': 'Marke',
  'voucher.product': 'Produkt',
  'voucher.noResults': 'Keine Ergebnisse gefunden',
  'voucher.onlyForNewCustomers': 'Ja, nur gültig für Neukunden',
  'voucher.onlyForNewCustomersHint': 'Soll der Gutschein nur für Neukunden gültig sein? (optional)',
  'voucher.enteredText': 'Keines der oben genannten? Eingegebenen Markennamen verwenden:',
  'voucher.enteredTextHint': 'Bitte prüfe, ob der Markename korrekt ist. Die Kunden werden denselben Name bei der Gutscheineinlösung sehen.',
  'voucher.charactersRemaining': '{CHAR} Zeichen verbleibend',
  'voucher.aliasCode.min': 'Der Alias-Code sollte 5 eine Ziffer sein',
  'voucher.aliasCode.max': 'Der Alias-Code sollte 5 eine Ziffer sein',
  'voucher.aliasCode.digit': 'Der Alias-Code sollte eine Ziffer sein',
  'voucher.deletedHint':'Der Gutschein wurde zurückgesetzt',

  'voucher.created': 'Gutschein angelegt',

  'voucher.list.code': 'Gutscheincode',
  'voucher.list.discountValue': 'Wert/Rabatt',
  'voucher.list.from': 'Ab',
  'voucher.list.restriction': 'Einschränkung',
  'voucher.list.restrictions': 'Beschränkungen',
  'voucher.list.restriction.category': 'Kategorie',
  'voucher.list.restriction.brand': 'Marke',
  'voucher.list.restriction.product': 'Produkt',
  'voucher.list.noRestriction': 'Keine',
  'voucher.list.createdAt': 'Erstelldatum',
  'voucher.list.createdBy': 'Ersteller',
  'voucher.list.validOrderValue': 'Gültiger Bestellwert',
  'voucher.list.validityTime': 'Gültigkeitszeitraum',
  'voucher.list.status': 'Status',
  'voucher.list.country': 'Gültiges Land',
  'voucher.list.customers': 'Kunden',
  'voucher.list.customers.new': 'Neu',
  'voucher.list.orderChannel': 'Bestellkanal',
  'voucher.list.limitation': 'Max. 100 Gutscheine können zur Zeit angezeigt werden.',
  'voucher.list.switch': 'Schalter',
  'voucher.list.activated': 'Aktiviert',
  'voucher.list.deactivated': 'Deaktiviert',
  'voucher.list.expiryDate': 'Gültig bis',
  'voucher.list.validFromDate': 'Gültig von',
  'voucher.list.redemptionDate': 'Datum der Einlösung',

  'voucher.status.CREATED': 'Angelegt',
  'voucher.status.ACTIVE': 'Aktiv',
  'voucher.status.REJECTED': 'Abgewiesen',
  'voucher.status.EXPIRED': 'Abgelaufen',
  'voucher.status.PENDING': 'wird übermittelt',
  'voucher.navigation.status.CREATED': 'Gutschein angelegt',
  'voucher.navigation.status.ACTIVE': 'Gutschein aktiv',
  'voucher.navigation.status.REJECTED': 'Gutscheinanlage',
  'voucher.navigation.status.EXPIRED': 'Gutschein abgelaufen',
  'voucher.navigation.status.PENDING': 'Gutscheinanlage',
  'voucher.navigation.status.dataFilling': 'Daten eingeben',

  'voucher.rejectionReasons': 'Abwurfgründe',
  'voucher.navigation.allVouchers': 'Zurück zu alle Gutschein',

  'voucher.value.code': 'Gutscheincode',
  'voucher.value.value': 'Gutscheinwert',
  'voucher.value.minValue': 'Mindestbestellwert',
  'voucher.value.status': 'Status',

  'voucher.suggestions.title': 'Bitte die Einlösbarkeit prüfen und einen Gutschein einlösen',
  'voucher.suggestions.personalizedVouchers': 'Persönliche Gutschein',
  'voucher.suggestions.generalVouchers': 'Allgemeine Gutscheine',
  'voucher.suggestions.nonRedeemableVouchers': 'Nicht einlösbare Gutscheine',
  'voucher.suggestions.newCustomersVouchers': 'Neukunden Gutschein',
  'voucher.suggestions.redeem': 'Jetzt einlösen',
  'voucher.suggestions.notRedeemable': 'Nicht einlösbar',
  'voucher.suggestions.checkRedemption': 'Einlösbarkeit prüfen',
  'voucher.allVouchers': 'Alle Gutscheine',
  'voucher.redeemableVOuchers': 'Einlösbare Gutscheine',
  'voucher.redeemedVouchers': 'Eingelöste Gutscheine',

  'voucher.progressiveVouchers.nextTier': 'Noch {amountLeft} einkaufen, um {toDiscountValue} zu erhalten',
  'voucher.progressiveVouchers.maxTier': 'Maximal {discountValue} wurden eingelöst!',
};
