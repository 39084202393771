import { OrderHistoryResponse } from 'src/types/orderhistory/OrderHistoryResponse';


export interface OrdersOverviewHistoryResponse {
  orders: OrderHistoryResponse[];
  page: number;
  size: number;
  total: number;
}

export const ORDERS_OVERVIEW_SIZE = 10;
