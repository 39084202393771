import { isBirthdayCheckConfirmed } from 'src/utils/lastBirthdayCheck';


export const LOGIN_TO_CUSTOMER = 'loginToCustomer';

export function setLoginToCustomer(customerId: string) {
  if (!isBirthdayCheckConfirmed(customerId)) {
    sessionStorage.setItem(LOGIN_TO_CUSTOMER, customerId);
  }
}

export function getLoginToCustomer(): string | null {
  return sessionStorage.getItem(LOGIN_TO_CUSTOMER);
}

export function removeLoginToCustomer() {
  sessionStorage.removeItem(LOGIN_TO_CUSTOMER);
}
