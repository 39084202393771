import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  takeEvery,
  delay,
} from 'redux-saga/effects';

import {
  upgradeEditProduct,
  removeProduct,
  addNewProduct,
  UpgradeProductType,
} from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { EditOrderActionType } from 'src/types/orderhistory/EditOrderActionRequest';

import { showNotificationAndLogError } from '../orderEntry/showNotificationAndLogError';


export function* upgradeProductSaga({ payload: { item, orderId, upgrade } }: PayloadAction<UpgradeProductType>) {
  try {
    if (upgrade) {
      yield put(removeProduct({
        orderId,
        actionRequest: {
          id: item.id,
          type: EditOrderActionType.REMOVE_POSITION,
        },
      }));
      yield delay(1000);
      yield put(addNewProduct({
        orderId,
        actionRequest: {
          sku: upgrade.baseProductNo,
          type: EditOrderActionType.ADD_POSITION,
          quantity: 1,
          crossSell: upgrade.crossSell,
        },
      }));
    }
  } catch (error) {
    yield call(showNotificationAndLogError, error);
    yield handleFailureState(error, 'Couldn\'t upgrade Product on edit order actionRequest: ',
      {
        item,
        orderId,
        upgrade,
      });
  }
}

export default function* upgradeProductWatcher() {
  yield takeEvery(upgradeEditProduct.type, upgradeProductSaga);
}
