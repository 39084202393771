import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal, openModal } from 'src/redux/app/appSlice';
import {
  getInstallmentPlans,
  getInstallmentPlansFailure,
  getInstallmentPlansSuccess,
} from 'src/redux/payment/paymentSlice';
import { getOfferAndStoreBasket } from 'src/sagas/offer/getOffer';
import { Modals } from 'src/types/Modals';


export function* getInstallmentPlansSaga() {
  try {
    const offerResult = yield call(getOfferAndStoreBasket);
    const offerResponse = offerResult?.response;

    if (offerResponse && offerResponse.installmentPlans && offerResponse.installmentPlans.length > 0) {
      yield put(getInstallmentPlansSuccess(offerResponse.installmentPlans));
      yield put(openModal(Modals.installmentPayment));

    } else {
      yield put(getInstallmentPlansFailure());
    }
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could get installment plans', err }));
    yield put(getInstallmentPlansFailure());
    yield put(closeModal(Modals.installmentPayment));
  }
}

export default function* getInstallmentPlansWatcher() {
  yield takeLatest(getInstallmentPlans.type, getInstallmentPlansSaga);
}
