import { CountryCode } from 'src/api/orderTypes';


export const COUNTRY_KEY = 'Country';

function get() {
  try {
    const country = sessionStorage.getItem(COUNTRY_KEY);
    switch(country) {
    case 'AT': return CountryCode.at;
    case 'CH': return CountryCode.ch;
    default: return CountryCode.de;
    }
  } catch (err) {
    return CountryCode.de;
  }
}

function set(country: CountryCode) {
  try {
    sessionStorage.setItem(COUNTRY_KEY, country.toString());
  } catch (err) {
    return CountryCode.de;
  }
}

export default { get, set };
