import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { updateSubscriptionPayment } from 'src/redux/customer/editSubscriptionSlice';
import { editSubscriptionSelector } from 'src/redux/customer/selectors/editSubscriptionSelectors';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import {
  getInstallmentFactoringPlans,
  getInstallmentPlans,
  setSelectedPaymentMethod,
} from 'src/redux/payment/paymentSlice';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';

import { getOfferIfOrderLineItemsExist } from '../offer/getOffer';


export function* onSelectedPaymentMethod(action?: { payload: PaymentMethod; }) {
  try {
    const editedSubscription = yield select(editSubscriptionSelector.getEditedSubscription);
    if (action) {
      if (action.payload === PaymentMethod.installments) {
        yield put(getInstallmentPlans());
      } else if (action.payload === PaymentMethod.installmentsFactoring) {
        yield put(getInstallmentFactoringPlans());
      } else if (editedSubscription?.id !== undefined) {
        if (action.payload !== PaymentMethod.directDebit) {
          yield put(updateSubscriptionPayment({ method: action.payload }));
        } else {
          const bankingDetails = yield select(orderEntrySelector.getCustomerBankingDetailsState);
          yield put(updateSubscriptionPayment(
            {
              method: action.payload,
              accountHolder: bankingDetails.accountHolder,
              usesPrefilledIban: bankingDetails.usesPrefilledIban,
              iban: bankingDetails.iban,
            }));
        }
      } else {
        yield call(getOfferIfOrderLineItemsExist);
      }
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not select payment ${action?.payload}`, err }));
  }
}

export default function* onSelectedPaymentMethodWatcher() {
  yield takeEvery(setSelectedPaymentMethod.type, onSelectedPaymentMethod);
}
