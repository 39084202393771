import { RejectionReason } from 'src/api/orderTypes';
import { WaitlistItemCancellationReason, WaitlistItemRequest } from 'src/types/waitlist/CreateWaitlistItemRequest';


export enum WaitlistItemStatusType {
  CREATED = 'CREATED',
  PROCESSED = 'PROCESSED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
}

export type OrderErrorResponse = {
  detail: string,
  title?: string,
}

export type WaitlistItemCreated = {
  type: WaitlistItemStatusType,
  timestamp: string,
  userAgentName: string,
}

export type WaitlistItemProcessed = {
  type: WaitlistItemStatusType,
  timestamp: string,
  orderId: string,
}

export type WaitlistItemCancelled = {
  type: WaitlistItemStatusType,
  timestamp: string,
  userAgentName: String,
  reason: WaitlistItemCancellationReason
}

export type WaitlistItemFailed = {
  type: WaitlistItemStatusType,
  timestamp: string,
  createOrderErrorResponse: OrderErrorResponse,
}

export type WaitlistItemRejected = {
  type: WaitlistItemStatusType,
  timestamp: string,
  rejectionReason: RejectionReason,
  orderId: string,
}

export type WaitlistItemStatus = WaitlistItemCreated | WaitlistItemProcessed | WaitlistItemCancelled | WaitlistItemFailed | WaitlistItemRejected;

export type WaitlistStatusesHistory = {
  statuses: WaitlistItemStatus[];
}

export type WaitlistHistoryItem = {
  id: string;
  externalCustomerId: string;
  status: WaitlistItemStatus;
  details: WaitlistItemRequest;
  statusesHistory: WaitlistStatusesHistory;
  orderCreatedAt?: string;
}

export type WaitlistHistoryItemsResponse = {
  offset: number;
  size: number;
  total: number;
  items: WaitlistHistoryItem[];
}
