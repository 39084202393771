
export enum EditedOrderStatusTypes {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

interface PendingStatus {
  readonly status: EditedOrderStatusTypes.PENDING;
  readonly timestamp: string;
}

interface AcceptedStatus {
  readonly status: EditedOrderStatusTypes.ACCEPTED;
  readonly timestamp: string;
}

interface RejectedStatus {
  readonly status: EditedOrderStatusTypes.REJECTED;
  readonly timestamp: string;
  readonly errorCode?: string;
  readonly errorMessage?: string;
  readonly sapMessages?: string[];
  readonly crmTotalPrice?: number;
  readonly crmShippingCost?: number;
}

export type EditedOrderStatus = AcceptedStatus | RejectedStatus | PendingStatus;
