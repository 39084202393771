import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const createWaitlistItemSelector = createSelectors({
  getParams(state: RootStateType) {
    return state.product.createWaitlistItem;
  },
  getSelectedPayment(state: RootStateType) {
    return state.product.createWaitlistItem?.selectedPaymentMethod;
  },
  getCreateWaitlistErrorMessage(state: RootStateType) {
    const selectedPaymentMethod = state.product.createWaitlistItem.selectedPaymentMethod;
    const selectedDeliveryAddress =  state.customer.info.selectedDeliveryAddress;

    const { deliveryAddress, paymentOptions } = state.orderPreCheck.result || {};
    const messageId = [];

    if (!paymentOptions?.find(p => p.method === selectedPaymentMethod && !!p.enabled)) {
      messageId.push('order.chosePaymentMethod');
    }

    if ( selectedDeliveryAddress && !deliveryAddress?.isValid ) {
      messageId.push('waitlist.choseDeliveryAddress');
    }

    return messageId;
  }
});
