export default {
  'subscriptionproductsreport.currentmonthfloorsales': 'Floor sales on current month',
  'subscriptionproductsreport.previousmonthfloorsales': 'Floor sales on previous month',
  'subscriptionproductsreport.totalsubscription': 'Abos total',
  'subscriptionproductsreport.totalproducts': 'Abos products',
  'subscriptionproductsreport.activesubscription': 'Active subscriptions',
  'subscriptionproductsreport.available': 'available',
  'subscriptionproductsreport.product': 'Product',
  'subscriptionproductsreport.stock': 'Stock',
  'subscriptionproductsreport.warning': 'Warnung',
  'subscriptionproductsreport.review': 'Review',
  'subscriptionproductsreport.backToOverview': 'Back to Products-overview',
};
