import Loadable from 'react-loadable';

import LoadingSpinner from 'src/components/shared/LoadingSpinner/LoadingSpinner';
import { Routes } from 'src/routes/routes';


const subscriptionProductManagementLoadable = Loadable({
  loader: () => import('src/subscriptionProductManagement/SubscriptionProductManagementView'),
  loading: () => <LoadingSpinner visible={true}/>,
});

const subscriptionProductManagementRoutes = {
  component: subscriptionProductManagementLoadable,
  protected: true,
  exact: true,
  path: '/subscription-product-management',
};

export default {
  subscriptionProductManagement: subscriptionProductManagementRoutes,
} as Routes;
