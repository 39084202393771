// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { put } from 'redux-saga/effects';

import { setCHOrderEntryFailure } from 'src/CHOrderEntry/redux/chOrderEntrySlice';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { showErrorNotification } from 'src/redux/app/appSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleFailureState(error: any, message: string, payload: any) {
  yield put(setCHOrderEntryFailure({
    title: error.response?.data?.title || 'errors.general',
    detail: error.response?.data?.detail || 'errors.general',
  }));
  yield put(showErrorNotification(error.response?.data?.detail));
  const preparedMessage = message ? `${message} ${payload !== undefined ? JSON.stringify(payload) : ''}` : error.response?.data?.detail;
  yield put(
    logEvent({
      level: LOG_LEVEL.WARN,
      message: preparedMessage,
      err: error,
    }),
  );
}
