import { AxiosInstance } from 'axios';
import { decamelizeKeys } from 'humps';

import { SearchOrderResult } from 'src/types/orderhistory/OrderHistoryResponse';
import { OrdersOverviewHistoryRequest } from 'src/types/orderhistory/OrdersOverviewHistoryRequest';
import { OrdersOverviewHistoryResponse } from 'src/types/orderhistory/OrdersOverviewHistoryResponse';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


export interface ApiOrderHistory {
  client: AxiosInstance;
  getCustomerOrdersOverViewHistory: (ordersOverviewHistoryRequest: OrdersOverviewHistoryRequest) => Promise<OrdersOverviewHistoryResponse>;
  getExternalCustomerIdByLimelightOrderId: (limelightOrderId: string) => Promise<SearchOrderResult>
}

export function getApiOrderHistory(baseURL: string): ApiOrderHistory {
  const orderHistoryClient = getHttpClient(
    {
      baseUrl: baseURL,
      transform: {
        camelize: true,
      },
      interceptors: {
        response: refreshTokenInterceptor,
        request: [
          localeHeaderInterceptor,
          xAuthorizationHeaderInterceptor,
        ],
      },
    });

  return {
    client: orderHistoryClient,
    getCustomerOrdersOverViewHistory: async (ordersOverviewHistoryRequest: OrdersOverviewHistoryRequest) => {
      const result = await orderHistoryClient.post('/private/orders-overview', decamelizeKeys(ordersOverviewHistoryRequest));
      return result.data;
    },
    getExternalCustomerIdByLimelightOrderId: async (limelightOrderId: string) => {
      const { data } = await orderHistoryClient.get(`/private/search-order-info?shop_order_id=${limelightOrderId}`);
      return data;
    },
  };
}
