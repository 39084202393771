import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  call,
  put,
  takeLatest,
  select,
  getContext,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { openModal } from 'src/redux/app/appSlice';
import {
  emptyEditedOrder,
  emptyEditedOrderSuccess,
  orderToOrderHistory,
  editOrderSelector,
  closeChOrderEditing,
} from 'src/redux/editOrder/editOrderSlice';
import routes from 'src/routes/routes';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';


export function* emptyEditedOrderSaga(action: PayloadAction<boolean | undefined>) {
  const OrderId = yield select(editOrderSelector.getEditedOrderId);
  try {
    const orderEdited = yield select(editOrderSelector.isOrderEdited);
    if (orderEdited && !action.payload) {
      yield put(openModal(Modals.editOrderLeaveConfirmation));
      return;
    }
  
    const originalOrder = yield select(editOrderSelector.getOriginalOrder);
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    
    yield put(emptyEditedOrderSuccess());
    
    const isChOrderEditing = action.type === closeChOrderEditing.type;
    if (isChOrderEditing) {
      yield put(openModal(Modals.chOrderHistoryModal));
    }
  
    if (originalOrder.isEditable) {
      yield call(apiEditOrder.clearOrder, OrderId);
    }
    
    if (!isChOrderEditing) {
      yield put(push(routes.OrderHistory.path.replace(':customerId', originalOrder.externalCustomerId)));
    }
  } catch (error) {
    yield handleFailureState(error, `Couldn't empty Edited Order with id ${OrderId} on edit order`, undefined);
  }
}

export default function* emptyEditedOrderWatcher() {
  yield takeLatest(emptyEditedOrder.type, emptyEditedOrderSaga);
  yield takeLatest(closeChOrderEditing.type, emptyEditedOrderSaga);
  yield takeLatest(orderToOrderHistory.type, emptyEditedOrderSaga);
}
