import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { FactFinderResponse } from 'src/types/product/factFinder';
import { SearchParams } from 'src/types/product/product';
import { SearchSuggestionResponse } from 'src/types/product/SearchSuggestionResponse';
import getFactFinderBaseURL from 'src/utils/getters/getFactFinderBaseURL';
import getFactFinderChannel from 'src/utils/getters/getFactFinderChannel';
import getFactFinderQuery, { FilterType } from 'src/utils/getters/getFactFinderQuery';
import getQueryString from 'src/utils/getters/getQueryString';


const httpAgent = axios.defaults.httpAgent;
const httpsAgent = axios.defaults.httpsAgent;

// TODO:
//  - Configure HSE24 proxy.
const factFinderClient: AxiosInstance = axios.create({
  httpAgent,
  httpsAgent,
  proxy: false,
});

const getProductSearchResult = async (params: SearchParams): Promise<AxiosResponse<FactFinderResponse>> => {
  return await factFinderClient.get(`${getFactFinderBaseURL()}/search/${getFactFinderChannel()}?${getQueryString(params)}&hitsPerPage=48`);
};

const getProductSuggestionsResult = async (params: SearchParams): Promise<AxiosResponse<SearchSuggestionResponse[]>> => {
  return await factFinderClient.get(`${getFactFinderBaseURL()}/suggest/${getFactFinderChannel()}?${getQueryString(params)}`);
};

const fetchTopDeals = async (type: FilterType, numberOfProducts: number): Promise<AxiosResponse<FactFinderResponse>> => {
  return await factFinderClient.get(`${getFactFinderBaseURL()}/search/${getFactFinderChannel()}?hitsPerPage=${numberOfProducts}&useCache=false&query=*${getFactFinderQuery(type)}`);
};

export { factFinderClient, getProductSearchResult, getProductSuggestionsResult, fetchTopDeals };
