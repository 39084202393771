import { Customer } from 'src/types/customer/customer';
import getOrderedCustomerPhoneNumber from 'src/utils/getters/getOrdredCustomerPhoneNumber';


export const ORIGINAL_CUSTOMER_INFO_KEY = 'originalCustomerInfoToken';

export function setOriginalCustomerInfoInSessionStorage(customer: Customer) {
  try{
    const phoneNumbers = getOrderedCustomerPhoneNumber(customer.phoneNumbers);
    const originalCustomerInfo: OriginalCustomerInfo = {
      externalCustomerId: customer?.externalCustomerId,
      email: customer.email,
      phoneNumber: phoneNumbers.phoneNumber,
      originalPhoneNumber: phoneNumbers.originalPhoneNumber,
      careOf: customer.billingAddress?.careOf,
      addressAddition: customer.billingAddress?.addressAddition,
    };

    sessionStorage.setItem(ORIGINAL_CUSTOMER_INFO_KEY, JSON.stringify(originalCustomerInfo));
  } catch (err) {
    console.error('unexpected error', err);
  }
}

export function getOriginalCustomerInfoFromSessionStorage(externalCustomerId?: string): OriginalCustomerInfo | undefined {
  try {
    const originalCustomerInfoToken = sessionStorage.getItem(ORIGINAL_CUSTOMER_INFO_KEY);
    if (originalCustomerInfoToken) {
      const originalCustomerInfo = JSON.parse(originalCustomerInfoToken) as OriginalCustomerInfo;
      return (originalCustomerInfo.externalCustomerId === externalCustomerId) ? originalCustomerInfo : undefined;
    }
  } catch (err) {
    console.error('unexpected error', err);
  }

  return undefined;
}

export type OriginalCustomerInfo = {
  externalCustomerId?: string;
  email?: string;
  phoneNumber?: string;
  originalPhoneNumber?: string;
  careOf?: string;
  addressAddition?: string;
}
