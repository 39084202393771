import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { closeModal, showErrorNotification } from 'src/redux/app/appSlice';
import {
  skipPinChallengeAction,
  SkipPinChallengeActionType,
  skipPinChallengeFailed,
  skipPinChallengeSuccess,
} from 'src/redux/customer/CustomerPinSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';


export function* skipCustomerPinSaga(action: SkipPinChallengeActionType) {
  try {
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    yield call(apiCustomer.skipPinChallenge, action.payload);
    yield put(closeModal(Modals.pinChallengeModal));
    yield put(skipPinChallengeSuccess());
  } catch (err) {
    yield put(skipPinChallengeFailed());

    yield put(
      logEvent({
        level: LOG_LEVEL.ERROR,
        message: `Couldn't skip customer pin for customer with id ${action.payload.externalCustomerId})`,
        err: err,
      }),
    );

    yield put(showErrorNotification('customer.skipPinVerification.error_request'));
  }
}

export default function* skipCustomerPinWatcher() {
  yield takeLatest(skipPinChallengeAction.type, skipCustomerPinSaga);
}
