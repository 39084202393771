import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import {
  setPreferredAddress,
  updateCustomerPreferencesAddress,
  UpdateCustomerPreferencesAddresses
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { CustomerPreferencesRequest } from 'src/types/customer/CustomerPreferencesRequest';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


const { getCustomerId } = customerInfoSelectors;

export function* updateCustomerPreferencesAddressSaga(action: PayloadAction<UpdateCustomerPreferencesAddresses>) {
  const { payload: { preferredAddressId, revertAddressId, isNewAddress } } = action;

  try {
    yield put(setPreferredAddress(preferredAddressId));

    if(isNewAddress) {
      return;
    }

    const customerId: string = yield select(getCustomerId);
    const customerApi: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);

    const customerPreference: CustomerPreferencesRequest = {
      preferredAddressId
    };
    yield call(customerApi.updateCustomerPreferences, customerId, customerPreference);
  } catch (err) {
    yield put(setPreferredAddress(revertAddressId));
    yield call(handleUpdateCustomerPreferencesAddressFailed, err, preferredAddressId);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleUpdateCustomerPreferencesAddressFailed(err: any, preferredAddressId: string) {
  yield call(
    logErrorAndShowNotification,
    `Couldn't update preferred address for address ${preferredAddressId}`,
    'Hoppla... Fehler beim Aktualisieren der bevorzugten Adresse',
    err,
  );
}

export default function* updateCustomerPreferencesAddressWatcher() {
  yield takeLatest(updateCustomerPreferencesAddress.type, updateCustomerPreferencesAddressSaga);
}
