import { CountryCode } from 'src/api/orderTypes';
import { PhoneNumber } from 'src/types/customer/phoneNumber';
import { Salutation } from 'src/types/customer/Salutation';


export interface BillingAddress {
  id?: string;
  type?: AddressType.Billing;
  careOf?: string;
  street: string;
  streetNumber: string;
  addressAddition?: string;
  zipCode: string;
  city: string;
  countryCode: CountryCode;
}

export enum AddressType {
  Delivery = 'DELIVERY',
  Billing = 'BILLING',
}

export enum DeliveryAddressType {
  Postal = 'POSTAL',
  Packstation = 'PACKSTATION',
}

export interface RecommendedAddress {
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  selected?: boolean;
  countryCode?: CountryCode;
}

export interface PostalDeliveryAddress {
  id?: string;
  externalAddressId?: string;
  type?: DeliveryAddressType.Postal;
  salutation?: Salutation;
  firstName: string;
  lastName: string;
  careOf?: string,
  street: string;
  streetNumber: string;
  phoneNumbers?: PhoneNumber[];
  phoneNumber?: string;
  addressAddition?: string;
  zipCode: string;
  city: string;
  countryCode: CountryCode;
  isDefault?: boolean;
  invalid?: boolean;
  fromInvoiceAddress?: boolean;
  ignoreAddressCheck?: boolean;
  usedInOrder?: boolean;
}

export interface PackstationDeliveryAddress {
  id?: string;
  externalAddressId?: string;
  type?: DeliveryAddressType.Packstation;
  salutation?: Salutation;
  firstName: string;
  lastName: string;
  postNumber: string;
  packstationNumber: string;
  zipCode: string;
  city: string;
  countryCode: CountryCode;
  isDefault?: boolean;
  invalid?: boolean;
  ignoreAddressCheck?: boolean;
  usedInOrder?: boolean;
}

export type DeliveryAddress = PostalDeliveryAddress | PackstationDeliveryAddress;

export type DeliveryAddressResponse = (PostalDeliveryAddress | PackstationDeliveryAddress) & {
  type: DeliveryAddressType;
  salutation: Salutation;
};
