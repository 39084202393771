import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { addToBlockedDeliveryAddresses, storeSelectedDeliveryAddressOrUseBilling } from 'src/redux/customer/customerInfoSlice';
import { switchDeliveryAddress, switchDeliveryAddressSuccess } from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import {
  EditedOffer,
  PaymentCheckResult,
  PaymentCheckResultReason,
} from 'src/types/orderhistory/EditedOrder';
import {
  EditOrderActionRequestWithOrderId,
  EditOrderActionType,
  EditOrderSwitchDeliveryAddressActionRequest,
} from 'src/types/orderhistory/EditOrderActionRequest';
import { allPaymentMethodsDisabled } from 'src/utils/validators/isPaymentMethodAvailable';


export function* switchDeliveryAddressSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  try {
    const deliveryAddress = (action.payload.actionRequest as EditOrderSwitchDeliveryAddressActionRequest).address;

    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);

    const response: EditedOffer = yield call(apiEditOrder.switchDeliveryAddress, action.payload);
    yield put(switchDeliveryAddressSuccess(response));
    
    yield put(storeSelectedDeliveryAddressOrUseBilling(deliveryAddress));

    if (isAddressBlocked(response.paymentCheckResult) && response.paymentOptions && allPaymentMethodsDisabled(response.paymentOptions)) {
      yield put(addToBlockedDeliveryAddresses(deliveryAddress.id!));
    }
  } catch (error) {
    if (error.response?.data?.key === '/dpl/os/errors/address_category_blocked_exception' && action.payload.actionRequest.type === EditOrderActionType.SWITCH_DELIVERY_ADDRESS) {
      yield put(addToBlockedDeliveryAddresses(action.payload.actionRequest.address.id!));
    }
    yield handleFailureState(error, 'Couldn\'t switch Delivery Address on edit order actionRequest: ', action.payload.actionRequest);
  }
}

function isAddressBlocked(paymentCheckResult: PaymentCheckResult | undefined) {
  const paymentCheckResultReason = paymentCheckResult?.reason;
  return paymentCheckResultReason === PaymentCheckResultReason.ADDRESS_CAT_2 || paymentCheckResultReason === PaymentCheckResultReason.NEW_CUSTOMER_ADDRESS_CAT_4;
}

export default function* switchDeliveryAddressWatcher() {
  yield takeLatest(switchDeliveryAddress.type, switchDeliveryAddressSaga);
}
