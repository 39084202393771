import { CountryCode } from '@hse24/shared-components';
import {
  call,
  getContext,
  put,
  select,
} from 'redux-saga/effects';

import { ApiBasket } from 'src/api/apiBasket';
import de from 'src/i18n/de';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { setBasketId, setPregeneratedErpNumber } from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { BasketIdResponse } from 'src/types/offer/BasketIdResponse';
import { BasketItemRequest } from 'src/types/offer/BasketItemRequest';
import {
  addBasketId,
  getBasketId,
  removeBasketId,
} from 'src/utils/basketId';
import { isTimeoutStatus } from 'src/utils/errorStatusChecks';


interface AddItemToBasketOptions {
  retryOnBasketNotFound?: boolean;
}

export enum AddItemToBasketStatus {
  SUCCESS,
  PRODUCT_NOT_FOUND,
  INVALID_VARIANT_SKU,
  RESERVATION_FAILURE,
  INVALID_PRICE_DATE,
  BASKET_NOT_FOUND,
  FAILED
}

export interface AddItemToBasketResult {
  status: AddItemToBasketStatus,
  details?: string,
}

// @ts-ignore
export function* addItemToBasket(addItemRequest: BasketItemRequest, options: AddItemToBasketOptions = { retryOnBasketNotFound: true }): AddItemToBasketResult {
  const country: CountryCode = yield select(appSelectors.getCountry);
  const apiBasket: ApiBasket = yield getContext(SagaContextItem.apiBasket);
  const currentBasketId = getBasketId();
  const salesOffice = yield select(orderEntrySelector.getOrderSalesOffice);
  const salesChannel = yield select(salesChannelSelector.getSalesChannel);
  try {
    if(addItemRequest?.source !== undefined) {
      addItemRequest.source = undefined; // temporary till merge basket service MR to avoid having JsonDecodeException
    }
    const basket: BasketIdResponse = yield call(apiBasket.addItemToBasket, addItemRequest, currentBasketId, salesOffice, salesChannel, country === CountryCode.CH);
    if ( basket.pregenerated_erp_number ) {
      yield put(setPregeneratedErpNumber(basket.pregenerated_erp_number));
    }

    if(basket.id !== currentBasketId) {
      addBasketId(basket.id);
      yield put(setBasketId(basket.id));
    }

    return { status: AddItemToBasketStatus.SUCCESS };
  } catch (basketApiError) {
    if (isTimeoutStatus(basketApiError)) {
      return { status: AddItemToBasketStatus.FAILED, details: de['errors.timeout'] };
    }
    const basketErrorResponse = basketApiError.response?.data;

    if(basketErrorResponse?.type === 'BasketNotFound') {
      removeBasketId();
      if(options.retryOnBasketNotFound === true) {
        return yield call(addItemToBasket, addItemRequest, { retryOnBasketNotFound: false });
      }
    }

    const details = basketErrorResponse?.detail ?? basketApiError?.message;
    switch (basketErrorResponse?.type) {
    case 'StockReservationFailure': return { status: AddItemToBasketStatus.RESERVATION_FAILURE, details };
    case 'ProductNotFound': return { status: AddItemToBasketStatus.PRODUCT_NOT_FOUND, details };
    case 'InvalidVariantSku': return { status: AddItemToBasketStatus.INVALID_VARIANT_SKU, details };
    case 'BasketNotFound': return { status: AddItemToBasketStatus.BASKET_NOT_FOUND, details };
    case 'InvalidPriceDate': return { status: AddItemToBasketStatus.INVALID_PRICE_DATE, details };
    default: return { status: AddItemToBasketStatus.FAILED, details };
    }
  }
}
