import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { ProductDetails } from 'src/types/product/product';
import { SubscriptionRotation, SubscriptionStartIn } from 'src/types/subscription/SubscriptionRequest';
import { TrackEvent } from 'src/types/TrackEvent';


export interface CreateSubscriptionState {
  sku?: string;
  product?: ProductDetails;
  quantity?: number;
  totalPrice?: number;
  shippingCosts?: number;
  rotation?: SubscriptionRotation,
  startIn?: SubscriptionStartIn,
  loading?: boolean;
  lastLoading?: boolean;
  creationSuccess?: boolean;
  aboNumber?: string;
  selectedPaymentMethod?: PaymentMethod;
  nextOrderDate: string;
  sourceChannel?: SourceChannels;
  salesOffice?: string;
  salesChannel?: string;
  variantSku?: string;
}

export const createSubscriptionInitialState: CreateSubscriptionState = {
  nextOrderDate: '',
};

export interface CreateSubscriptionParams {
  product: ProductDetails;
  sku: string;
  shippingCosts: number;
  quantity: number;
  rotation?: SubscriptionRotation;
  startIn: SubscriptionStartIn;
  totalPrice: number;
  selectedPaymentMethod?: PaymentMethod;
  nextOrderDate: string;
  sourceChannel: SourceChannels;
  salesOffice: string;
  salesChannel: string;
  variantSku?: string;
}

export const trackSubscriptionImpression = createAction<TrackEvent>('subscription/trackSubscriptionImpression');
export type TrackSubscriptionImpression = ReturnType<typeof trackSubscriptionImpression>;

export const trackSubscriptionReservation = createAction<TrackEvent>('subscription/trackSubscriptionReservation');
export type TrackSubscrptionReservation = ReturnType<typeof trackSubscriptionReservation>;

const createSubscriptionSlice = createSlice({
  name: 'createSubscription',
  initialState: createSubscriptionInitialState,
  reducers: {
    initializeCreateSubscription(state, { payload }: PayloadAction<CreateSubscriptionParams>) {
      state.sku = payload.sku;
      state.product = payload.product;
      state.quantity = payload.quantity;
      state.shippingCosts = payload.shippingCosts;
      state.totalPrice = payload.totalPrice;
      state.rotation = payload.rotation;
      state.startIn = payload.startIn;
      state.nextOrderDate = payload.nextOrderDate;
      state.loading = false;
      state.selectedPaymentMethod = payload.selectedPaymentMethod;
      state.sourceChannel = payload.sourceChannel;
      state.salesOffice = payload.salesOffice;
      state.salesChannel = payload.salesChannel;
      state.variantSku = payload.variantSku;
    },
    setSubscriptionNextOrderDate(state, { payload }: PayloadAction<string>) {
      state.nextOrderDate = payload;
    },
    setSubscriptionRotation(state, { payload }: PayloadAction<SubscriptionRotation>) {
      state.rotation = payload;
    },
    setSubscriptionStartIn(state, { payload }: PayloadAction<SubscriptionStartIn>) {
      state.startIn = payload;
    },
    setSubscriptionQuantity(state, { payload }: PayloadAction<number>) {
      state.quantity = payload;
    },
    createSubscription(state) {
      state.lastLoading = state.loading;
      state.loading = true;
    },
    createSubscriptionSuccess(state) {
      state.loading = false;
      state.creationSuccess = true;
    },
    createSubscriptionFailed(state) {
      state.loading = false;
      state.creationSuccess = false;
    },
    resetSubscriptionState() {
      return createSubscriptionInitialState;
    },
    setSubscriptiontSelectedPaymentMethod(state, { payload }: PayloadAction<PaymentMethod>) {
      state.selectedPaymentMethod = payload;
    },
    setSourceChannel(state, { payload }: PayloadAction<SourceChannels>) {
      state.sourceChannel = payload;
    },
    setSalesOffice(state, { payload }: PayloadAction<string>) {
      state.salesOffice = payload;
    },
    setSalesChannel(state, { payload }: PayloadAction<string>) {
      state.salesChannel = payload;
    },
  },
});

export const {
  initializeCreateSubscription,
  setSubscriptionRotation,
  setSubscriptionStartIn,
  setSubscriptionQuantity,
  createSubscription,
  createSubscriptionSuccess,
  createSubscriptionFailed,
  resetSubscriptionState,
  setSubscriptiontSelectedPaymentMethod,
  setSubscriptionNextOrderDate,
  setSourceChannel,
  setSalesOffice,
  setSalesChannel,
} = createSubscriptionSlice.actions;

export type CreateSubscription = ReturnType<typeof createSubscription>;

export default createSubscriptionSlice.reducer;
