import { createReducer } from '@reduxjs/toolkit';

import {
  fetchOffers,
  fetchOffersSuccess,
  fetchTopDeals,
  fetchTopDealsSuccess,
  resetOffers,
  fetchOffers_TopDeals,
  setShowOffers,
  setAllOffers,
  resetAllOffers,
  selectOffersType,
  fetchOffersAndTopDealsLoadingDone,
} from './offersActions';
import { offersInitialState } from './offersState';


const offersReducer = createReducer(offersInitialState, {
  [fetchOffers_TopDeals.type]: (state) => {
    state.loading = true;
  },
  [fetchOffers.type]: (state) => {
    state.loading = true;
  },
  [fetchOffersSuccess.type]: (state, { payload }) => {
    state.tvOffers = payload;
  },
  [fetchTopDeals.type]: (state) => {
    state.loading = false;
  },
  [fetchTopDealsSuccess.type]: (state, { payload }) => {
    state.topDeals = payload;
  },
  [resetOffers.type]: () => offersInitialState,
  [setShowOffers.type]: (state, { payload }) => {
    state.showOffers = payload;
  },
  [setAllOffers.type]: (state, { payload }) => {
    state.allOfferItems =payload;
  },
  [resetAllOffers.type]: (state) => {
    state.allOfferItems = [];
  },
  [selectOffersType.type]: (state, { payload }) => {
    state.offersType = payload;
  },
  [fetchOffersAndTopDealsLoadingDone.type]: (state) => {
    state.loading = false;
  },
});

export default offersReducer;
