import { put } from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { loadOriginalOrderFailed } from 'src/redux/recreateOrder/recreateOrderSlice';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';
import stringify from 'src/utils/stringify';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleLoadOriginalOrderFailureState(error: any, payload: any) {

  yield put(logEvent({
    message: `Could not load original order ${stringify(payload)}`,
    err: error,
    level: isNotFoundStatus(error) ? LOG_LEVEL.WARN : LOG_LEVEL.ERROR,
  }));

  yield put(loadOriginalOrderFailed({
    title: error.response?.data?.title || '',
    detail: error.response?.data?.detail || '',
    key: error.response?.data?.key || '',
  }));

  yield put(showErrorNotification(error.response?.data?.detail));
}
