import { AxiosInstance } from 'axios';

import { CrossSellResponse, DISPLAYED_CROSS_SELL_PRODUCTS_MAX } from 'src/types/product/CrossSell';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';


export interface ApiUpsells {
  client: AxiosInstance;
  getUpsells(sku: string): Promise<CrossSellResponse>;
}

export function getApiUpsells(baseUrl: string): ApiUpsells{
  const axios = getHttpClient({
    baseUrl,
    headers: { appDevice: 'IPAD' },
    interceptors: {
      request: localeHeaderInterceptor,
    },
    transform: {
      camelize: true,
    },
  });

  return {
    client: axios,
    async getUpsells(sku: string): Promise<CrossSellResponse> {
      const linkMaxCount = 10;
      const response = await axios.get<CrossSellResponse>(`/product/${sku}/cross-sell-products?maxProducts=${linkMaxCount}&displayedProducts=${DISPLAYED_CROSS_SELL_PRODUCTS_MAX}`);

      return response.data;
    },
  };
}
