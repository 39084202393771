import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { showSuccessNotification } from 'src/redux/app/appSlice';
import { subscriptionHistorySelector } from 'src/redux/customer/selectors/subscriptionHistorySelectors';
import {
  CancelSubscriptionItem,
  cancelSubscriptionItem,
  cancelSubscriptionItemError,
  cancelSubscriptionItemInProgress,
  cancelSubscriptionItemSuccess,
  fetchSubscriptionHistory,
} from 'src/redux/customer/subscriptionHistorySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* cancelSubscriptionItemSaga(action: CancelSubscriptionItem) {
  try {
    yield put(cancelSubscriptionItemInProgress());

    const apiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    yield call(apiCallCenter.cancelSubscriptions, action.payload.cancellationSubscriptionItemRequest);

    yield put(cancelSubscriptionItemSuccess());

    const currentSearchCriteria = yield select(subscriptionHistorySelector.getSubscriptionsSearchCriteria);
    // TODO: refresh single element instead of reload everything
    yield put(fetchSubscriptionHistory({ externalCustomerId: action.payload.customerId, criteria: currentSearchCriteria }));

    yield put(showSuccessNotification('subscription.item.cancel.successNotificationHint'));

  } catch (err) {
    const errorCode = (err && err.response && err.response.status) || 400;
    yield call(
      logErrorAndShowNotification,
      `Couldn't cancel subscription item, body ${JSON.stringify(action.payload.cancellationSubscriptionItemRequest)}`,
      'Hoppla... Der Abonnementprodukte konnte nicht storniert werden. Bitte versuchen Sie es erneut',
      err,
    );
    yield put(cancelSubscriptionItemError(errorCode));
  }

}

export default function* cancelSubscriptionItemWatcher() {
  yield takeLatest(cancelSubscriptionItem.type, cancelSubscriptionItemSaga);
}
