import { AxiosInstance } from 'axios';
import { decamelizeKeys } from 'humps';

import { FetchSubscriptionProductsReportRequest } from 'src/subscriptionProductManagement/types/request/FetchSubscriptionProductsReportRequest';
import { FetchSubscriptionChangesHistoryRequest } from 'src/subscriptionProductManagement/types/request/SubscriptionChangesHistoryRequest';
import { SubscriptionChangesHistoryResponse } from 'src/subscriptionProductManagement/types/response/SubscriptionChangesHistoryResponse';
import { SubscriptionReportResponse } from 'src/subscriptionProductManagement/types/response/SubscriptionReportResponse';
import { SubscriptionsOverviewRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import { SubscriptionsCustomersInfoResponse, SubscriptionsOverviewResponse } from 'src/types/subscription/SubscriptionsOverviewResponse';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


export interface ApiSubscriptionsManagement {
  axiosInstance: AxiosInstance;
  getSubscriptionsOverview: (fetchSubscriptionsReq: SubscriptionsOverviewRequest) => Promise<SubscriptionsOverviewResponse>;
  getSubscriptionChangesHistory: (request: FetchSubscriptionChangesHistoryRequest) => Promise<SubscriptionChangesHistoryResponse>;
  getSubscriptionReportList: (fetchSubscriptionReportRequest: FetchSubscriptionProductsReportRequest) => Promise<SubscriptionReportResponse>;
  getSubscriptionsCustomersInfo: (req: SubscriptionsOverviewRequest) => Promise<SubscriptionsCustomersInfoResponse>;
}

export function getSubscriptionsManagementApi(
  baseUrl: string,
): ApiSubscriptionsManagement {
  const axiosInstance = getHttpClient({
    baseUrl,
    transform: {
      camelize: true,
    },
    interceptors: {
      response: refreshTokenInterceptor,
      request: [localeHeaderInterceptor, xAuthorizationHeaderInterceptor],
    },
  });

  return {
    axiosInstance: axiosInstance,
    getSubscriptionsOverview: async (req: SubscriptionsOverviewRequest) => {
      const response = await axiosInstance.post('/subscription-product-management/overview',  decamelizeKeys(req));
      return response.data;
    },
    getSubscriptionChangesHistory: async (req: FetchSubscriptionChangesHistoryRequest) => {
      const response = await axiosInstance.post('/subscription-changes-history', decamelizeKeys(req));
      return response.data;
    },
    getSubscriptionReportList: async (req: FetchSubscriptionProductsReportRequest) => {
      const response = await axiosInstance.post('/subscription-product-management/products-report', decamelizeKeys(req));
      return response.data;
    },
    getSubscriptionsCustomersInfo: async (req: SubscriptionsOverviewRequest) => {
      const response = await axiosInstance.post('/subscriptions-management/customers-info', decamelizeKeys(req));
      return response.data;
    },
  };
}
