import { SubscriptionsReportFetchFilters } from 'src/subscriptionProductManagement/redux/subscriptionReportSlice';
import {
  SortingMode,
  SubscriptionReportSortBy,
  getMonthNumberFromName,
} from 'src/subscriptionProductManagement/utils/subscriptionProductManagementUtils';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


export interface FetchSubscriptionProductsReportRequest {
  page: number;
  pageSize: number;
  keyword?: string;
  monthReview?: number;
  loadMore: boolean;
  sortField?: SubscriptionReportSortBy;
  sortingMode?: SortingMode;
}

export const createSubscriptionProductsReportFetchRequest = (
  page: number,
  size: number,
  filters: SubscriptionsReportFetchFilters,
  loadMore: boolean,
): FetchSubscriptionProductsReportRequest => {
  return {
    page: page,
    pageSize: size,
    keyword: trimStringOrNull(filters?.keywords),
    monthReview: getMonthNumberFromName(filters?.selectedMonth) || undefined,
    loadMore: loadMore,
    sortField: filters?.sortBy || undefined,
    sortingMode: filters?.sortBy ? filters.sortingMode : undefined,
  };
};
