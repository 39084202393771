import { createSelector } from '@reduxjs/toolkit';

import { RootStateType } from 'src/constants/types';
import { editOrderSelector } from 'src/redux/editOrder/selectors/editOrderSelector';
import { newOrderSelector } from 'src/redux/order/selectors/newOrderSelectors';
import { createSelectors } from 'src/utils/state';


const simpleChOrderEntrySelectors = createSelectors({
  getOrderHistoryState({ chOrderEntry }: RootStateType) {
    return chOrderEntry.ordersHistory;
  },
  
  canCreateOrder({ order }: RootStateType) {
    const orderEntry = order.orderEntry;
    return orderEntry.offer && orderEntry.offer.totalPrice > 0 && !orderEntry.getBasketLoading;
  },
  getShowEditOrder({ editOrder }: RootStateType) {
    return editOrder.initLoading || editOrder?.editedOffer?.editedOrderId;
  },
});

const isSaveButtonLoading = createSelector(
  newOrderSelector.newOrderLoadingStatus,
  editOrderSelector.submitOrderLoading,
  ( newOrderLoadingStatus, submitOrderLoadingStatus ) => {
    return newOrderLoadingStatus || submitOrderLoadingStatus;
  }
);

const isSaveButtonEnabled = createSelector(
  isSaveButtonLoading,
  simpleChOrderEntrySelectors.canCreateOrder,
  editOrderSelector.isThereAnEditOrderActionInProgress,
  editOrderSelector.isOrderEdited,
  (
    loading,
    canCreateOrder?, 
    anEditOrderActionInProgress?, 
    isEdited?
  ) => {
    return loading || canCreateOrder || (isEdited && !anEditOrderActionInProgress);
  }
);

const chOrderEntrySelectors = {
  ...simpleChOrderEntrySelectors,
  isSaveButtonEnabled,
  isSaveButtonLoading,
};

export default chOrderEntrySelectors;
