import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { showErrorNotification } from 'src/redux/app/appSlice';
import {
  deleteBasketItemStatus,
  deleteBasketSuccess,
  deleteOrderItem,
  DeleteOrderItemAction,
  searchError,
  trackRemoveProductFromCartEvent,
  updateBasketItemStatus,
} from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { onGetBasketSaga } from 'src/sagas/orderEntry/onGetBasket';
import { removeItemFromBasket, RemoveItemFromBasketStatus } from 'src/sagas/orderEntry/removeItemFromBasketService';
import { OfferItem } from 'src/types/offer/Offer';
import Log from 'src/utils/log';


const { getSalesChannel } = salesChannelSelector;
const { getOffer } = orderEntrySelector;

export function* onDeleteOrderItemSaga({ payload }: DeleteOrderItemAction) {
  try {
    yield put(updateBasketItemStatus({ id: payload.id, status: 'in_progress' }));

    const deleteItemStatus = yield call(removeItemFromBasket, payload.id);

    if(deleteItemStatus === RemoveItemFromBasketStatus.SUCCESS) {
      const offer = yield select(getOffer);
      const item = offer.items.find((it: OfferItem) =>it.sku === payload.sku);
      yield call(trackRemovedProductFromCartSaga,item);
    }
    else if(deleteItemStatus === RemoveItemFromBasketStatus.BASKET_NOT_FOUND) {
      yield put(deleteBasketSuccess());
      return;
    }

    yield call(onGetBasketSaga);
  } catch (error) {
    const errorMessage  = error.response ? error.response.data?.detail : error?.message;
    yield put(showErrorNotification(errorMessage));
    yield put(searchError(error));
  } finally {
    yield put(deleteBasketItemStatus(payload.id));
  }
}

export function* trackRemovedProductFromCartSaga(productDetail: OfferItem) {
  try {
    const salesChannel: string = yield select(getSalesChannel);
    yield put(trackRemoveProductFromCartEvent({ productDetail, salesChannel: salesChannel === '04' ?'Internet':'TV' }));
  } catch (e) {
    yield call(Log.error, e);
  }
}

export default function* onDeleteOrderItemWatcher() {
  yield takeEvery(deleteOrderItem.type, onDeleteOrderItemSaga);
}
