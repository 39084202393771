import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  SetEnableTakeBackAction,
  deleteBasketSuccess,
  deleteBasketItemStatus,
  updateBasketItemStatus,
  setEnableTakeBack,
} from 'src/redux/order/orderEntrySlice';
import { showNotificationAndLogError } from 'src/sagas/orderEntry/showNotificationAndLogError';
import { updateBasketItem, UpdateBasketItemStatus } from 'src/sagas/orderEntry/updateBasketItemService';
import { getBasketId } from 'src/utils/basketId';

import { onGetBasketSaga } from './onGetBasket';


export function *onSetEnableTakeBackSaga({ payload }: SetEnableTakeBackAction) {
  try {
    yield put(updateBasketItemStatus({ id: payload.id, status: 'in_progress' }));

    const updateItemStatus = yield call(updateBasketItem, payload.id, { weeeTakeBackEnabled: payload.enableTakeBack });

    if(updateItemStatus === UpdateBasketItemStatus.BASKET_NOT_FOUND) {
      yield put(deleteBasketSuccess());
      return;
    }

    yield call(onGetBasketSaga);
  } catch (error) {
    yield put(
      logErrorEvent({
        message: `Couldn't enable takeback option on item(id=${payload.id}, basketId=${getBasketId()}).`,
        err: error,
      }),
    );

    yield call(showNotificationAndLogError, error);
  } finally {
    yield put(deleteBasketItemStatus(payload.id));
  }
}

export default function *onSetEnableTakeBackWatcher() {
  yield takeLatest(setEnableTakeBack.type, onSetEnableTakeBackSaga);
}
