import * as Sentry from '@sentry/browser';


const Log = {
  message: (message: string, severity?: Sentry.Severity) => {
    // eslint-disable-next-line no-console
    console.log(message, severity);

    // Sentry.captureMessage(message, severity);
  },
  error: (message: unknown) => {
    // eslint-disable-next-line no-console
    console.error(message);

    /*
    if (typeof message === 'string') {
      Sentry.captureMessage(message, Sentry.Severity.Error);
    }

    Sentry.captureException(message);
    */
  },
};

export default Log;
