import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { logErrorEvent } from 'src/logging/loggingActions';
import {
  fetchInflightOrderSuccess,
  loadInflightOrder,
  fetchInflightOrder,
  fetchInflightOrderProduct,
  resetLoadInflightOrder,
} from 'src/redux/inflightOrder/inflightOrderSlice';
import { handleFetchInflightOrderFailure } from 'src/sagas/inflightOrder/handelFailureState';
import getProductBySku from 'src/sagas/product/productService';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { InflightOrder } from 'src/types/inflightOrder/InflightOrder';
import { Steps } from 'src/types/inflightOrder/InflightOrderRequest';
import { ProductDetails } from 'src/types/product/product';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';


export function* fetchInflightOrderSaga(action: PayloadAction<number>) {
  try {
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);
    const inflightOrder: InflightOrder = yield call(apiCallCenter.getInflightOrder, action.payload);
    yield put(fetchInflightOrderSuccess(inflightOrder));
    yield put(loadInflightOrder(inflightOrder));
    if (inflightOrder.order.baseProductNo && inflightOrder.order.step && [Steps.INVALID_CROSS_SELLING, Steps.INVALID_PRODUCT_VARIANT_SELECTION].includes(inflightOrder.order.step)) {
      const inflightProduct: ProductDetails = yield call(getProductBySku, inflightOrder.order.baseProductNo);
      yield put(fetchInflightOrderProduct(inflightProduct));
    }
  } catch (err) {
    if(!isNotFoundStatus(err)) {
      yield put(logErrorEvent({ message: `Could not fetch inflight order ${action.payload}`, err }));
    }
    yield call(handleFetchInflightOrderFailure, err);
  } finally {
    yield put(resetLoadInflightOrder());
  }
}

export default function* fetchInflightOrderWatcher() {
  yield takeLatest(fetchInflightOrder.type, fetchInflightOrderSaga);
}
