import { uniq } from 'lodash';
import { call, put } from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { doFetchProducts } from 'src/sagas/product/productService';
import { OrderItem } from 'src/types/offer/OrderItem';
import { ProductDetails } from 'src/types/product/product';
import { productsAndTheirSubscriptions } from 'src/utils/product/productUtils';


export function* getLinkedSubscriptionsForOrderItems(
  orderItems: OrderItem[],
  salesChannel?: string
) {
  const linkedSubscriptionProducts: { [sku: string]: ProductDetails } = {};
  const linkedSubscriptionProductsNos: { [sku: string]: string } = {};
  try {
    orderItems.forEach(item => {
      if (item.variant.sku in productsAndTheirSubscriptions){
        linkedSubscriptionProductsNos[item.variant.sku] =
          productsAndTheirSubscriptions[item.variant.sku];
      }
    });

    const regularProductNos = Object.keys(linkedSubscriptionProductsNos);
    if (regularProductNos.length < 1) {
      return linkedSubscriptionProducts;
    }
    const subscriptionProducts: ProductDetails[] = yield call(
      doFetchProducts,
      uniq(Object.values(linkedSubscriptionProductsNos)),
      salesChannel
    );

    Object.keys(linkedSubscriptionProductsNos).forEach(key => {
      const subscriptionProduct = subscriptionProducts.find(
        e => productsAndTheirSubscriptions[key] === e.baseProductNo
      );
      if (subscriptionProduct?.subscriptionInfo) {
        linkedSubscriptionProducts[key] = subscriptionProduct;
      }
    });
  } catch (err) {
    yield put(
      logErrorEvent({
        message: 'could not fetch linked subscriptions products',
        err: err,
      })
    );
  }

  return linkedSubscriptionProducts;
}
