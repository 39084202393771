import { CountryCode } from 'src/api/orderTypes';
import Config from 'src/config';

import countryLocalStorage from '../countryLocalStorage';


export default function getProductOnAirBaseURL(): string {
  switch (countryLocalStorage.get()) {
  case (CountryCode.ch): return Config.env.offers.ch;
  case (CountryCode.at): return Config.env.offers.at;
  default: return Config.env.offers.de;
  }
}