export  interface  EmployeeDiscount {
  customerType: CustomerType;
  value: number;
  percent: number;
}
export enum CustomerType {
  EMPLOYEE = 'EMPLOYEE',
  PARTNER = 'PARTNER',
  REGULAR = 'REGULAR',
  CREATOR = 'CREATOR',
  TV_CREATOR = 'TV_CREATOR',
  INFLUENCER = 'INFLUENCER'
}
