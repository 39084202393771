export default {
  'subscription.step.login' : 'Kundennummer eingeben',
  'subscription.step.birthday.check' : 'Authentifizierung',
  'subscription.step.login.text' : '„Nennen Sie mir bitte zuerst Ihre Kundennummer.”',
  'subscription.step.birthday.check.text' : '„Nennen Sie mir zum Datenabgleich bitte Ihr Geburtsdatum (Passwort).”',
  'subscription.back.login' : 'Zurück zur Kundennummer',
  'subscription.step.confirmed' : 'Treue Abo bestätigt',
  'subscription.confirmed.hint': '"Sie erhalten Ihre(n) Wunschartikel ab jetzt regelmäßig zum Vorteilspreis geliefert."',

  'subscription.title': 'Treue-Abo',
  'subscription.saveAndContinue': 'Speichern und weiter',
  'subscription.edit.successNotificationHint': 'Das Abonnement wurde erfolgreich bearbeitet',
  'subscription.item.cancel.successNotificationHint': 'Der Abonnementprodukte wurde erfolgreich gekündigt',
  'subscription.editMultipleSubscriptionNumbers.successNotificationHint': 'Abonnementnummern wurden erfolgreich bearbeitet',
  'subscription.quantity': 'Bestellmenge/Einzelpreis',

  // rotation options
  'subscription.rotation': 'Turnus',
  'subscription.rotation.ONE_MONTH': 'Monatlich',
  'subscription.rotation.TWO_MONTHS': '2-monatlich',
  'subscription.rotation.THREE_MONTHS': '3-monatlich',
  'subscription.rotation.FOUR_MONTHS': '4-monatlich',
  'subscription.rotation.FIVE_MONTHS': '5-monatlich',
  'subscription.rotation.SIX_MONTHS': '6-monatlich',
  'subscription.rotation.hint': 'Bestätigen Sie mit dem Kunden den nächsten Liefertermin',

  // start date options
  'subcscription.startDate.TODAY': 'Heute',
  'subcscription.startDate.ONE_WEEK': 'In einer Woche',
  'subcscription.startDate.TWO_WEEKS': 'In 2 Wochen',
  'subcscription.startDate.THREE_WEEKS': 'In 3 Wochen',
  'subcscription.startDate.ONE_MONTH': 'In 1 Monat',
  'subcscription.startDate.SIX_WEEKS': 'In 6 Wochen',
  'subcscription.startDate.CUSTOM': 'Datum eingeben',

  'subcscription.nextOrderDate.TODAY': 'Heute',
  'subcscription.nextOrderDate.ONE_WEEK': 'Nach einer Woche',
  'subcscription.nextOrderDate.TWO_WEEKS': 'Nach 2 Wochen',
  'subcscription.nextOrderDate.THREE_WEEKS': 'Nach 3 Wochen',
  'subcscription.nextOrderDate.ONE_MONTH': 'Nach 1 Monat',
  'subcscription.nextOrderDate.SIX_WEEKS': 'Nach 6 Wochen',
  'subcscription.nextOrderDate.SKIP_NEXT_DELIVERY': 'Nächste Lieferung aussetzen',
  'subcscription.nextOrderDate.CUSTOM': 'Datum eingeben',

  'subscription.variantHint': 'Wussten Sie, dass es diesen Artikel auch im Treue-Abo gibt? Jetzt {price} sparen bei monatlicher Lieferung.',

  'subscription.hint': 'Wussten Sie, dass es diesen Artikel auch im Treue-Abo gibt? Jetzt {price} sparen bei monatlicher Lieferung.',
  'subscription.hint.NoReduction': 'Wussten Sie, dass es diesen Artikel auch im Treue-Abo gibt?',
  'subscription.title.withPrice':  'Treue-Abo {price} je Lieferung',
  'subscription.startIn': 'Nächste Belieferung',
  'subscription.source.channelId': 'Vertriebsweg',
  'subscription.source': 'Fenster',
  'subscription.source.channel': 'Eingangskanal',

  'subscription.paymentMethod': 'Zahlungsart',

  // subscription status hint
  'subscription.product.statusHint.RAN_OUT': 'Für dieses Treue Abo können derzeit keine neuen Abos angelegt werden.',
  'subscription.product.statusHint.STOPPED': 'Achtung Abo pausiert. (Nächstmögliches Lieferdatum in den KIK Abo-News ersichtlich.)',
  'subscription.product.statusHint.DELETED': 'Dieser Artikel wurde ausgelistet',
  'subscription.product.statusHint.ACTIVE_NO_STOCK': 'Das Treue-Abo ist in wenigen Tagen verfügbar. Auslieferung erfolgt zum nächstmöglichen Zeitpunkt.',
  'subscription.product.statusHint.ACTIVE_NO_STOCK.checkbox': 'Ich habe den Kunden informiert und möchte fortfahren',

};
