import React from 'react';

import Config from './index';


export const FeatureFlagsContext = React.createContext({});

export enum AvailableFlags {
  enableLanguageSwitch = 'enableLanguageSwitch'
}

export type FlagsType = { [key in AvailableFlags]: boolean };
const flags: FlagsType = {
  enableLanguageSwitch: Config.env.flags.enableLanguageSwitch,
};

type Children = JSX.Element[] | JSX.Element | null | string;
export const FeatureFlags = (props: { children: Children; values?: {} }) => (
  <FeatureFlagsContext.Provider value={props.values || flags}>
    {props.children}
  </FeatureFlagsContext.Provider>
);

interface Prop {
  name: AvailableFlags;
  children: Children;
}
export const Flag = (props: Prop) => (
  <FeatureFlagsContext.Consumer>
    {(flagsObj: { [key: string]: boolean }) =>
      flagsObj[props.name] ? props.children : null
    }
  </FeatureFlagsContext.Consumer>
);
export const IfFlagNotSet = (props: Prop) => (
  <FeatureFlagsContext.Consumer>
    {(flagsObj: { [key: string]: boolean }) =>
      flagsObj[props.name] ? null : props.children
    }
  </FeatureFlagsContext.Consumer>
);

export const flag = (
  name: AvailableFlags,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFlagTrue: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFlagFalse?: any,
) => {
  const relevantParam = flags[name] ? onFlagTrue : onFlagFalse;
  return relevantParam instanceof Function ? relevantParam() : relevantParam;
};
