import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ProductSearchResponse, SearchParams } from 'src/types/product/product';

import { search } from '../order/orderEntrySlice';


export interface ProductSearchState {
  loading: boolean;
  searchResponse?: ProductSearchResponse;
  error?: boolean;
  errorCode?: number;
  bnr: string;
  longTextSearch: string;
}

export const productSearchInitialState: ProductSearchState = {
  loading: false,
  bnr: '',
  longTextSearch: '',
};

export const fetchProductSearchResults = createAction<SearchParams>('productSearch/fetchProductSearchResults');
export type FetchProductSearchResults = ReturnType<typeof fetchProductSearchResults>;

const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState:productSearchInitialState,
  reducers: {
    fetchProductSearchResultsInProgress(state) {
      state.loading = true;
    },
    fetchProductSearchResultsSuccess(state, { payload }: PayloadAction<ProductSearchResponse>) {
      state.loading = false;
      state.searchResponse = payload;
    },
    fetchProductSearchResultsError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    emptyProductSearchResults(state) {
      state.bnr = '';
      state.longTextSearch = '';
      state.loading = false;
      state.searchResponse = {
        products: [],
        totalProducts: 0,
      };
    },
    productSearchOpened(state, { payload }: PayloadAction<{open?: boolean, searchTerm?: string}>) {
      state.bnr = payload.searchTerm?.match(/\d+/) ? payload.searchTerm : '';
      state.longTextSearch = payload.searchTerm && !payload.searchTerm.match(/\d+/) ? payload.searchTerm : '';
    },
    setProductSearchLongText(state, { payload }: PayloadAction<string>) {
      state.longTextSearch = payload && !payload.match(/\d+/) ? payload : '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase( search,(state, { payload }: PayloadAction<{searchTerm: string, showOnlyDetail?: boolean}>) => {
        state.bnr = payload.searchTerm.match(/\d+/) ? payload.searchTerm : '';
        state.longTextSearch = !payload.searchTerm.match(/\d+/) ? payload.searchTerm : '';
      });
  },
});

export const {
  fetchProductSearchResultsInProgress,
  fetchProductSearchResultsSuccess,
  fetchProductSearchResultsError,
  emptyProductSearchResults,
  productSearchOpened,
  setProductSearchLongText,
} = productSearchSlice.actions;

export type ProductSearchOpened = ReturnType<typeof productSearchOpened>;

export default productSearchSlice.reducer;
