export interface PhoneNumber {
  phoneNumberType: PhoneNumberType | string;
  value: string;
}

export enum PhoneNumberType {
  PRIVATE = 'PRIVATE',
  BUSINESS = 'BUSINESS',
  MOBILE = 'MOBILE',
  FAX = 'FAX'
}
