import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  changeVoucher,
  handleVoucherSession,
  resetVoucher,
  submitVoucherFailed,
  submitVoucherSuccess,
  VoucherState,
} from 'src/redux/order/voucherSlice';
import { getBasketId } from 'src/utils/basketId';
import voucherSessionStorage from 'src/utils/voucherSessionStorage';


export function* handleVoucherSessionChangeSaga(action: PayloadAction) {
  try {
    const basketId = getBasketId() ?? undefined;

    if(action.type === handleVoucherSession.type) {
      const voucher = voucherSessionStorage.getVoucher();
      if(basketId && basketId === voucher?.basketId && voucher.voucherCode) {
        yield put(changeVoucher(voucher.voucherCode));
      }
      return;
    }

    const voucherCode = (action.type === submitVoucherSuccess.type) ? (action.payload as unknown as VoucherState).code : undefined;

    voucherSessionStorage.saveVoucher({ voucherCode, basketId });
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not handle voucher change, ${err.toString()}` }));
  }
}

export default function* handleVoucherSessionChangeWatcher() {
  yield takeLatest(submitVoucherSuccess.type, handleVoucherSessionChangeSaga);
  yield takeLatest(submitVoucherFailed.type, handleVoucherSessionChangeSaga);
  yield takeLatest(resetVoucher.type, handleVoucherSessionChangeSaga);
  yield takeLatest(handleVoucherSession.type, handleVoucherSessionChangeSaga);
}
