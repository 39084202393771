import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryAddress } from 'src/types/customer/address';
import { Offer } from 'src/types/offer/Offer';
import { OrderItem, OrderItemError } from 'src/types/offer/OrderItem';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { OrderHistoryItem, OrderPayment } from 'src/types/orderhistory/OrderHistoryResponse';
import { ProductDetails } from 'src/types/product/product';

import { loadReklaOrder, search } from './orderEntrySagaActions';


export const MAX_QUANTITY = 9;
export interface OrderEntryState {
  searchTerm: string;
  subscribableOrderLineItemsDetails: { [key: string]: ProductDetails };
  orderLineItems: OrderItem[];
  orderLineItemsErrors: {
    [id: string]: OrderItemError;
  };
  offer?: Offer;
  searchLoading: boolean;
  getBasketLoading: boolean;
  basketItemsStatus: {
    [sku: string]: 'in_progress' | 'success' | 'error';
  };
  error?: Error;

  basketId?: string;
  preGeneratedErpNumber?: number;
  quantityFields: {
    [sku: string]: number;
  };
  globalLoading?: boolean;
  isRekla: boolean;
}

export const orderEntryInitialState: OrderEntryState = {
  searchTerm: '',
  orderLineItems: [],
  subscribableOrderLineItemsDetails: {},
  searchLoading: false,
  getBasketLoading: false,
  basketItemsStatus: {},
  quantityFields: {},
  orderLineItemsErrors: {},
  isRekla: false,
};

export interface ReklaOrder {
  externalOrderId: string;
  paymentMethod?: PaymentMethod;
  paymentInfo?: OrderPayment;
  item: OrderHistoryItem;
  deliveryAddress?: DeliveryAddress;
  externalCustomerId?: string;
}

const orderEntrySlice = createSlice({
  name: 'orderEntry',
  initialState: orderEntryInitialState,
  reducers: {
    searchError(state, { payload }: PayloadAction<Error>) {
      state.searchLoading = false;
      state.error = payload;
    },
    updateLineItems(state, { payload }: PayloadAction<OrderItem[]>) {
      state.orderLineItems = payload;
    },
    resetOrderLineItems(state) {
      state.orderLineItems = [];
      state.subscribableOrderLineItemsDetails = {};
      state.searchTerm = '';
    },
    getOfferInProgress(state) {
      state.getBasketLoading = true;
    },
    getOfferSuccess(state, { payload }: PayloadAction<Offer>) {
      state.getBasketLoading = false;
      state.offer = payload;
      state.preGeneratedErpNumber = payload.pregeneratedErpNumber;
    },
    getOfferFailed(state) {
      state.getBasketLoading = false;
    },
    setOffer(state, { payload }: PayloadAction<Offer>) {
      state.offer = payload;
    },
    orderTableSearchFinished(state) {
      state.searchLoading = false;
    },
    getBasket(state) {
      state.getBasketLoading = true;
    },
    getBasketSuccess(state, { payload }: PayloadAction<OrderItem[]>) {
      state.orderLineItems = payload;
      state.getBasketLoading = false;
    },
    getBasketError(state, { payload }: PayloadAction<Error>) {
      state.getBasketLoading = false;
      state.error = payload;
    },
    setSearchTerm(state, { payload }: PayloadAction<string>) {
      state.searchTerm = payload;
    },
    addOrderItems(state, { payload }: PayloadAction<OrderItem[]>) {
      state.orderLineItems = payload;
    },
    setSubscribableOrderLineItemsDetails(
      state,
      { payload }: PayloadAction<{ [key: string]: ProductDetails }>
    ) {
      state.subscribableOrderLineItemsDetails = payload;
    },
    updateBasketItemStatus(state, { payload: { id, status } }) {
      if (id?.length) {
        state.basketItemsStatus[id] = status;
      }
    },
    deleteBasketItemStatus(state, { payload }: PayloadAction<string | undefined>) {
      if (payload) {
        delete state.basketItemsStatus[payload];
      }
    },
    deleteBasketSuccess(state) {
      return {
        ...orderEntryInitialState,
        isRekla: state.isRekla
      };
    },
    setBasketId(state, { payload }: PayloadAction<string>) {
      state.basketId = payload;
    },
    setQuantityFields(state, { payload }) {
      const { sku, quantity } = payload;
      state.quantityFields = { ...state.quantityFields, [sku]: quantity };
    },
    setItemError(state, { payload: { id, error } }) {
      state.orderLineItemsErrors[id] = error;
    },
    setGlobalLoadingFlag(state, { payload }: PayloadAction<boolean>) {
      state.globalLoading = payload;
    },
    setPregeneratedErpNumber: (state, { payload }: PayloadAction<number | undefined>) => {
      state.preGeneratedErpNumber = payload;
    },
    toggleReklaFlag(state, { payload }: PayloadAction<boolean>) {
      state.isRekla = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(search, state => {
      state.searchLoading = true;
    });
    builder.addCase(loadReklaOrder, state => {
      state.isRekla = true;
    });
  },
});

export const {
  setSubscribableOrderLineItemsDetails,
  updateLineItems,
  searchError,
  resetOrderLineItems,
  getOfferInProgress,
  getOfferSuccess,
  getOfferFailed,
  setOffer,
  orderTableSearchFinished,
  getBasket,
  getBasketSuccess,
  getBasketError,
  setSearchTerm,
  addOrderItems,
  updateBasketItemStatus,
  deleteBasketItemStatus,
  deleteBasketSuccess,
  setBasketId,
  setQuantityFields,
  setItemError,
  setGlobalLoadingFlag,
  setPregeneratedErpNumber,
  toggleReklaFlag,
} = orderEntrySlice.actions;

export default orderEntrySlice.reducer;
