import CHOrderEntryEN from 'src/CHOrderEntry/messages/en';
import receptionSurvey from 'src/i18n/de/receptionSurvey';
import subscriptionManagementEn from 'src/subscriptionProductManagement/messages/subscriptionManagement.en';

import orderSource from './de/orderSource';
import address from './en/address';
import app from './en/app';
import consentManagement from './en/consentManagement';
import crossSellHints from './en/crossSellHints';
import customer from './en/customer';
import errors from './en/errors';
import inflightOrder from './en/inflightOrder';
import notepad from './en/notepad';
import notificationMessage from './en/notificationMessage';
import offers from './en/offers';
import order from './en/order';
import orderEntry from './en/orderEntry';
import orderHistory from './en/orderHistory';
import orderType from './en/orderType';
import payment from './en/payment';
import privacyPolicy from './en/privacyPolicy';
import saleSource from './en/saleSource';
import solvencyCheck from './en/solvencyCheck';
import stockReminder from './en/stockReminder';
import subscription from './en/subscription';
import subscriptionHistory from './en/subscriptionHistory';
import voucher from './en/voucher';
import waitlist from './en/waitlist';


export default {
  ...app,
  ...errors,
  ...address,
  ...customer,
  ...order,
  ...payment,
  ...saleSource,
  ...orderEntry,
  ...orderHistory,
  ...offers,
  ...voucher,
  ...solvencyCheck,
  ...notificationMessage,
  ...stockReminder,
  ...consentManagement,
  ...privacyPolicy,
  ...crossSellHints,
  ...inflightOrder,
  ...notepad,
  ...waitlist,
  ...receptionSurvey,
  ...subscriptionHistory,
  ...subscription,
  ...orderType,
  ...orderSource,
  ...subscriptionManagementEn,
  ...CHOrderEntryEN,

  'root.title': 'Welcome to the HSE24 Back-Office',
  'root.description': 'Or go to the route %orders/:id% to see an order',
  'root.orderId': 'Order ID',
  'root.errors.invalidOrderID': 'Invalid order ID',
  'auth.error':'An unexpected error occurred. Please contact ecom_team_order@hse24.de',
  'generic.error': 'Something went wrong, please try again.',
  'editable.empty': 'empty',
  'orderDisplay.orderId': 'Order ID:',
  'orderDisplay.exportedTo': 'Exported to',
  'orderDisplay.notFoundError': 'Order not found!',
  'orderDisplay.genericError': 'Error loading order. Please try again later.',
  'orderDisplay.priceDetails': 'Price Details',
  'orderDisplay.totalPrice': 'Total Price',
  'orderDisplay.shippingDetails': 'Shipping Details',
  'orderDisplay.deliveryFees': 'Delivery Fees',
  'orderDisplay.deliveryMode': 'Delivery Mode',
  'orderDisplay.itemSKU': 'Item SKU',
  'orderDisplay.itemQuantity': 'Quantity',
  'orderDisplay.itemPrice': 'Price',
  'orderDisplay.itemType': 'Typ',
  'orderDisplay.takeBackEnabled': 'Collection of old electrical appliances',
  'orderDisplay.customerDetails': 'Customer Details',
  'orderDisplay.customerDetails.dplValue': 'DPL Value',
  'orderDisplay.customerDetails.crmValue': 'CRM Value',
  'orderDisplay.customerDetails.crmToolTip': 'CRM Wert nach DPL Wert übertragen',
  'orderDisplay.customerConsentDetails': 'customer Consent Details',
  'orderDisplay.customerPrivacyPolicyDetails': 'Privacy Policy Details',
  'orderDisplay.customer.skipDoubleCheck': 'skip double check',
  'orderDisplay.deliveryAddress': 'Delivery Address',
  'orderDisplay.customer.salutation': 'Salutation',
  'orderDisplay.customer.salutation.mr': 'Mr',
  'orderDisplay.customer.salutation.ms': 'Ms',
  'orderDisplay.customer.salutation.company': 'Firma',
  'orderDisplay.customer.salutation.family': 'Familie',
  'orderDisplay.customer.email': 'E-Mail',
  'orderDisplay.customer.fullName': 'Name',
  'orderDisplay.customer.firstName': 'First Name',
  'orderDisplay.customer.careOf': 'C/O Address',
  'orderDisplay.customer.customerNumber': 'customer number',
  'orderDisplay.customer.lastName': 'Last Name',
  'orderDisplay.customer.customerName': 'Kundenname',
  'orderDisplay.customer.emailAddress': 'E-Mail-Adresse',
  'orderDisplay.customer.dateOfBirth': 'Date of Birth',
  'orderDisplay.customer.phone': 'Phone',
  'orderDisplay.customer.street': 'Street',
  'orderDisplay.customer.postNumber': 'Post Number',
  'orderDisplay.customer.addressAddition': 'Address Addition',
  'orderDisplay.customer.empty': 'No value',
  'orderDisplay.customer.streetNumber': 'Street Number',
  'orderDisplay.customer.packstation': 'Packstation',
  'orderDisplay.customer.zipcode': 'Zipcode',
  'orderDisplay.customer.city': 'City',
  'orderDisplay.customer.country': 'Country code',
  'orderDisplay.paymentDetails': 'Payment Details',
  'orderDisplay.paymentMethod': 'Payment Method',
  'orderDisplay.paymentMethod.sofort': 'Sofortüberweisung',
  'orderDisplay.paymentMethod.invoice': 'Invoice',
  'orderDisplay.paymentMethod.paypal': 'PayPal',
  'orderDisplay.paymentMethod.direct_debit': 'Direct Debit',
  'orderDisplay.paymentMethod.cash_on_delivery':'Cash on delivery',
  'orderDisplay.paymentMethod.installments': 'Installment Amount',
  'orderDisplay.paymentMethod.installments_factoring': 'Installment Factoring Amount',
  'orderDisplay.paymentMethod.credit_card': 'Credit Card',
  'orderDisplay.payment.paypal.transactionId': 'PayPal Transaction ID',
  'orderDisplay.payment.creditCard.pspReference': 'Credit Card Transaction ID',
  'orderDisplay.payment.accountHolder': 'Account Holder',
  'orderDisplay.payment.iban': 'IBAN',
  'orderDisplay.payment.installment': 'Installment',
  'orderDisplay.payment.installmentCount': 'Number of Installments',
  'orderDisplay.payment.finalInstallment': 'Final Installment',
  'orderDisplay.payment.total': 'Total',
  'orderDisplay.payment.dueDay': 'Due Day',
  'orderDisplay.payment.CalculationId': 'Calculation Id',
  'orderDisplay.payment.hint': '„Please tell me the name of your bank.“ ({BANKNAME})',
  'orderDisplay.payment.notAllowed': 'This payment method is not possible.',
  'orderDisplay.payment.installmentFactoring.notAllowed': 'Payment method not possible. UNZER/Payolution will answer questions about rejection at: datenschutz-vie@unzer.com or by letter to Unzer GmbH, c/o Payolution, Hanauer Landstraße 164, 60314 Frankfurt',
  'orderDisplay.payment.cachOnDeliveryNotAllowedWithoutBillingAddress': 'Payment by cash on delivery is not possible when shipping to a different delivery address. Please choose another payment method',
  'orderDisplay.otherInformation': 'Other Information',
  'orderDisplay.orderInformation': 'Order Information',
  'orderDisplay.voucherInformation': 'Voucher Information',
  'orderDisplay.voucherInformation.code': 'Virtual voucher',
  'orderDisplay.voucherInformation.valueVoucher.code': 'CRM voucher',
  'orderDisplay.voucherInformation.valueVoucher.value': 'CRM value',
  'orderDisplay.voucherInformation.value': 'Value',
  'orderDisplay.voucherInformation.freeShipping': 'Versandkostenfrei',
  'orderDisplay.voucherInformation.percentage': 'Percentage',
  'orderDisplay.order.createdAt': 'Order creation time',
  'orderDisplay.order.salesOffice': 'Sales Office',
  'orderDisplay.order.salesChannel': 'Sales Channel',
  'orderDisplay.order.orderType': 'Order Type',
  'orderDisplay.order.orderSource': 'Order Source',
  'orderDisplay.order.rekla.section.label': 'Reclamation Order',
  'orderDisplay.order.rekla.checkbox.label': 'Active',
  'orderDisplay.order.rekla.basket.hint.with.number': 'Reclamation Order: {previousOrderId}',
  'orderDisplay.order.rekla.basket.hint.without.number': 'Reclamation Order',
  'orderDisplay.order.rekla.basket.maxItems': 'Max. shopping cart size for advertising order reached.',
  'orderDisplay.statusHistory.unavailable':
    'The status history is not available for this order.',
  'orderDisplay.statusHistory.title': 'Status History',
  'orderDisplay.statusHistory.agentNamePrefix': 'by',
  'orderDisplay.statusHistory.status.created': 'Created',
  'orderDisplay.statusHistory.status.submitted': 'Submitted',
  'orderDisplay.statusHistory.status.accepted': 'Accepted',
  'orderDisplay.statusHistory.status.failed': 'Failed',
  'orderDisplay.statusHistory.status.erp_export_in_progress': 'CRM customer data accepted',
  'orderDisplay.statusHistory.status.failed.universeCreditCheckFailed': 'Universe Credit Check Failed',
  'orderDisplay.statusHistory.status.recreated_order': 'Re-order',
  'orderDisplay.statusHistory.status.rejected': 'Rejected',
  'orderDisplay.statusHistory.status.cancelled': 'Cancelled',
  'orderDisplay.statusHistory.status.rejected.customerModificationRequired': 'Customer already exists. The following fields could not be updated:',
  'orderDisplay.statusHistory.status.rejected.exceptionReason': 'An exception error occurred. Please find the technical error details in an attachment.',
  'orderDisplay.statusHistory.status.rejected.unexpectedResponseReason': 'SAP CRM sent an unexpected response. The technical response can be found in an attachment.',
  'orderDisplay.statusHistory.status.rejected.CrmErrorCodeReason': 'SAP CRM returned an error',
  'orderDisplay.statusHistory.status.edited': 'Edited',
  'orderDisplay.statusHistory.status.cancelled.cancelledBy.AGENT': 'A backoffice agent cancelled this order',
  'orderDisplay.statusHistory.status.cancelled.cancelledBy.SYSTEM.after36Hours': '{PAYMENT} order was cancelled automatically after 36 hours',
  'orderDisplay.statusHistory.status.cancelled.cancelledBy.SYSTEM': '{PAYMENT} order was cancelled automatically',
  'orderDisplay.statusHistory.cancelOrder_modalTitle': 'Would you like to cancel the order?',
  'orderDisplay.statusHistory.cancelOrder_modalDecline':'No',
  'orderDisplay.statusHistory.original_order': 'Original Order:',
  'orderDisplay.statusHistory.transferred': 'Transferred:',
  'orderDisplay.orderHistory.cancelOrder_modalConfirm': 'Yes',
  'orderDisplay.edit.error': 'You inserted an invalid value',
  'orderDisplay.resubmit': 'Resubmit Order',
  'orderDisplay.cancel': 'Cancel Order',
  'orderDisplay.recreateOrder': 'Nachbestellung in WILIS',
  'orderDisplay.paypalOrder.before36Hours':
    'Paypal orders can only be edited or resubmitted within 36 hours (they are automatically cancelled after that timeframe).',
  'orderDisplay.privacyPolicy.isRequested': 'is requested',
  'orderDisplay.privacyPolicy.infoRead': 'info Read',
  'orderDisplay.privacyPolicy.infoSend': 'info send',
  'orderDisplay.consent.exportDhlEmail': 'export DHL email',
  'orderDisplay.consent.emailNewsletter': 'email newsletter',
  'orderDisplay.consent.phoneMarketResearch': 'phone Market Research',
  'orderDisplay.consent.printedAdvertising': 'printed Advertising',
  'orderDisplay.payment.installmentFactoring.notAllowed.country.AT': 'Installment factoring payment is not possible in Austria.',

  'notification.resubmitOrder.success': 'Successfuly resubmitted order.',
  'notification.edit.success': 'Successfuly edited order.',
  'notification.operation.failed': 'Operation failed.',
  'notification.dismiss': 'Dismiss',
  // Customer
  'customer.voucher': 'Voucher',
  'customer.data': 'Customer Data',
  'customer.deliveryAddress': 'Delivery Address',
  'customer.paymentMethods': 'Payment Methods',
  'customer.kdnr': 'Kundennummer',
  'customer.search.kdnr': 'Nach KDNR suchen',
  'customer.customerNumber': 'Customer Number',
  'customer.search.error.customerNumber': 'The customer number is required!',
  'customer.angemahnterBetrag': 'Angemahnter Betrag',
  'customer.mahndatum': 'Mahndatum',
  'customer.mahnstufe': 'Mahnstufe',
  'customer.vonMoglichenMst': 'von möglichen MST',
  'customer.tzAngemahnterBetrag': 'TZ Angemahnter Betrag',
  'customer.tzMahndatum': 'TZ Mahndatum',
  'customer.tzMahnstufe': 'TZ Mahnstufe',
  'customer.tzInkassoubergabe': 'TZ Inkassoübergabe',
  'customer.auftragsbeschrankung': 'order restriction',
  'customer.rabattkennzeichen': 'discount',
  'customer.bemerkung': 'remark',
  'customer.fiDatenAktuell': 'FI-Daten aktuell',
  'customer.erfassungsdatumbv': 'Entry date BV',
  'customer.bevorzugtezahlungsart': 'Preferred payment method',
  'customer.ezmversand': 'EZM shipping',
  'customer.ezmstatus': 'EZM Status',
  'customer.inhber': 'Owner',
  'customer.banker': 'Bank',
  'customer.iban': 'IBAN',
  'customer.streetSuppl1': 'Street 1',
  // Customer Delivery Address
  'customer.buisenesspartner': 'Buiseness Partner',
  'customer.addAddress': 'Add delivery address',
  'customer.editAddress': 'Edit delivery address',
  'customer.addAddress.error': 'Error with adding new delivery address',
  'customer.editAddress.error': 'Error with editing delivery address',
  'customer.addAddress.success': 'Delivery address added',
  'customer.editAddress.success': 'Delivery address edited',
  'customer.addressSuggestions.error': 'Couldn\'t fetch address suggestions',
  'customer.emailkuntefr': 'E-Mail Kund Befrountry',
  'customer.Kommentar': 'Kommentar',
  'customer.standardUsage': 'Standard Usage',
  'customer.relationshCat': 'Title',
  'customer.academicTitle': 'Academic Title',
  'customer.lastName': 'Last Name',
  'customer.fullName': 'Full Name',
  'customer.firstName': 'First Name',
  'customer.careOf': 'C/O Address (optional)',
  'customer.kndr': 'KNDR',
  'customer.salutation': 'Anrede',
  'customer.street': 'Street',
  'customer.houseNumber': 'House Number',
  'customer.street4': 'Street 4',
  'customer.postalCode': 'Postal Code',
  'customer.city': 'City',
  'customer.ort': 'City',
  'customer.country': 'Country',
  'customer.country.DE': 'Deutschland',
  'customer.country.AT': 'Österreich',
  'customer.street2': 'Street 2',
  'customer.telephoneNo': 'Phone number',
  'customer.telephoneNoOptional': 'Phone number (optional)',
  'customer.telephoneNoNotOptional': 'Phone number',
  'customer.phoneType': 'Phone Type',
  'customer.notUsed': 'Not Used',
  'customer.azStradaGepruft': 'AZ-Strada geprüft',
  'customer.packstation': 'Packstation Number',
  'customer.packStationLabel': 'the Packstation Number',
  'customer.postNumber': 'Post Number',
  'customer.postNumberLabel': 'DHL Post Number',
  'customer.billingAddress':'Billing address',
  'customer.emailAddress.optional':'E-Mail-Adresse (optional)',
  'customer.emailAddress.no.optional': 'E-Mail-Adresse',
  'orderDisplay.customer.dateOfBirth.label': 'Geburtsdatum (tt.mm.jjjj)',
  // Add Edit Customer
  'customer.createEdit.submit': 'Submit',
  'customer.search': 'Suchen',
  'customer.createEdit.abort': 'Abort',
  'customer.createEdit.phoneType': 'Phone Type',
  'customer.createEdit.createTitle': 'Create Customer',
  'customer.createEdit.editTitle': 'Edit Customer',
  'customer.createEdit.lastCustomers': 'Last Customers',
  'customer.createEdit.createError': 'Can\'t create new customer, please try again.',
  'customer.createEdit.editError': 'Can\'t edit the customer, please try again.',
  'customer.createEdit.createSuccess': 'The user created successfully',
  'customer.createEdit.editSuccess': 'Customer data is only updated when the order is completed',
  // Customer order history
  'customer.orderHistory': 'Order History',
  'customer.history.date': 'Date',
  'customer.history.orderNumber': 'Order Number',
  'customer.history.paymentMethod': 'Payment Method',
  'customer.history.shipping': 'Shipping',
  'customer.history.productId': 'Product ID',
  'customer.history.description': 'Description',
  'customer.history.address': 'Address',
  'customer.history.lieferzeit': 'Lieferzeit',
  'customer.history.deliveryType': 'Delivery Type',
  'customer.history.createdBy': 'Created By',
  'customer.history.createdOn': 'Created On',
  'customer.history.changedBy': 'Changed By',
  'customer.history.changedOn': 'Changed On',
  // CTA
  'customer.lastCustomer': 'Last Customer',
  'customer.createCustomer': 'Create Customer',
  'customer.createAddress': 'Create Address',
  'customer.reset': 'Reset',
  'customer.editCustomer': 'Edit Customer',
  'product.search.title': 'Product search - QuickSearch',
  'product.search.bpno': 'Nach Bestellnr. suchen',
  'product.search.mark': 'Nach Produkt, Marke suchen',
  'product.search.resetAll': 'Alle zurücksetzen',
  'product.search.articleName': 'Article Name',
  'product.search.salesDriver': 'Type',
  'product.search.freeShipping': 'Free Shipping',
  'product.freeshippinglabel': '(Voucher Service)',
  'product.search.description': 'Long text search',
  'product.search.type.single': 'Single Article',
  'product.search.type.variant': 'Variant',
  'product.search.type.set': 'Set',
  'product.search.bnr': 'BNR',
  'product.search.error.bnr': 'The product number must consist of at least 4 characters!',
  'product.search.stock.AVAILABLE': 'Available',
  'product.search.stock.ALMOST_SOLD_OUT': 'Almost sold out',
  'product.search.stock.SOLD_OUT': 'Sold out',
  'product.productInformation': 'Dimensions & details:',
  'search': 'Search',
  'reset': 'Reset',
  'noResuts': 'No results found',
  'product information': 'Product Information',
  'take': 'Take',
  'view variants': 'Select variant',
  'upsells': 'Upsells',
  'product added successfully': 'product added successfully',

  'main product': 'Main Product',
  'upsell products': 'Upsell Products',
  'productDetails.close': 'Close',
  'productDetails.colorLabel': 'Color',
  'productDetails.description': 'Description',
  'productDetails.moreDetails': 'Dimensions & Details',
  'productDetails.infoAndDelivery': 'Product information and scope of delivery',
  'productDetails.readMore': 'Read more',
  'productDetails.readLess': 'Read less',
  'postpone': 'Postpone',
  'add to order': 'Add to Order',
  'abort': 'Abort',
  'save': 'Save',
  'validate': 'Validate',
  //Product
  'product.bnr': 'BNR',
  'product.image':'Image',
  'product.description': 'Description',
  'product.salesDriver': 'salesDriver',
  'product.freeshipping': 'Free Shipping',
  'product.price': 'price',
  'product.articletype':'Type',
  'product.stock': 'Stock',
  'product.variant': 'Variante',
  'product.upsells': 'Empfehlung',
  'product.waitlist': 'Waitlist',
  'product.name': 'Brand & Product',
  'product.wrongCountry': 'This product is not available in {country}.',
  'product.wrongCountry.DE': 'This product is not available in Germany.',
  'product.wrongCountry.AT': 'This product is not available in Austria.',
  'product.wrongCountry.CH': 'This product is not available in Switzerland.',
  'COLOR': 'Color',
  'SIZE': 'Size',
  'ALLOY': 'Legierung',
  'TASTE': 'Geschmacksrichtung',
  'MOTIV': 'Motiv',
  'AROMA': 'Aroma',
  'AMOUNT': 'Menge',
  'STONE': 'Stein',
  'OTHERS': 'Others',
  'freeShipping.VERARGERTERKD': 'VERÄRGERTER KD',
  'freeShipping.SYSTEMFEHLER':'SYSTEMFEHLER',
  'freeShipping.UMTAUSCH': 'UMTAUSCH',
  'product.stockReminder.hint': '„Unfortunately, this product is already sold out. Would you like to be informed by email as soon as it is available again? You can then order it at today\'s offer price of {price}.“',
  'product.notSellable': 'Please inform the team leader! Main product is not salable',
  'product.cantOrderSubscriptionProduct': 'Loyal subscription product! Please find the correct BNR (advertising number) in the subscription list in KIK.',

  //RejectionManagement
  'rejection.order.label':'Please enter order number',
  'rejection.order.input':'order number',
  'rejection.order.input.required':'Required',
  'rejection.order.submit':'Continue',

  'customer.banking.details.notFound': 'No banking details founded',
  'customer.upsells.notAllowed': 'No upsell desired',

  //Take to online shop modal
  'onlineshoppopup.title': 'Restricted payment methods',
  'onlineshoppopup.body': 'Only limited payment options are possible. Refer customers to online shop.',
  'onlineshoppopup.hint': '„I cannot currently select any payment methods. Please order the desired products in the online shop via PayPal.”',

  //ofer
  'offer.discarded': 'Please inform the team leader! Product on TV is not sellable.',
  'offer.product.discarded': 'This product is not sellable.',
  'product.stock.fetch.error': 'konnte den Bestand nicht abrufen.',

  // header InfoPost
  'header.infoPost.title': 'Infopost',
  'header.infoPost.ringstemplate': 'Ringschablone',
  'header.infoPost.request': 'Anfordern',
  'header.infoPost.requestSent': 'Letter Sent',

  // header Lernstudio
  'header.learningStudio.title': 'Lernstudio',

  //Ringschablon pdp hint
  'ringstemplate.hint.message': 'Work out your ring size with Ringschablone.',
  'ringstemplate.hint.request': 'Request Now for free',
  'ringstemplate.hint.request.success': 'Requested Successfully!',
  'ringstemplate.hint.request.confirm': 'Confirm Request',
  'ringstemplate.hint.request.cancel': 'No, Cancel',

  // schema letter preview
  'schemaLetter.preview.error': 'The schema letter preview could not be loaded !',

  // Bot Request
  'botRequest.info.loading': 'Loading Bot Request Info...',
  'botRequest.info.requestNumber': 'Request number',
  'botRequest.info.externalCustomerId': 'External Customer Id',
  'botRequest.info.isAuthenticated': 'Is Authenticated',
  'botRequest.info.isAuthenticatedYes': 'Yes',
  'botRequest.info.isAuthenticatedNo': 'No',
  'botRequest.info.reason': 'Reason',
  'botRequest.info.comment': 'Comment'
};
