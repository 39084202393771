import loadReklaOrderWatcher from './loadReklaOrder';
import onAddOrderItemWatcher from './onAddOrderItem';
import onChangeQuantityWatcher from './onChangeQuantity';
import onDeleteOrderItemWatcher from './onDeleteOrderItem';
import onGetBasketWatcher from './onGetBasket';
import getBasketErrorWatcher from './onGetBasketError';
import onPriceDateChangeWatcher from './onPriceDateChange';
import onSearchWatcher from './onSearch';
import onSearchErrorWatcher from './onSearchError';
import prepareReklaOrderWatcher from './prepareReklaOrder';
import onSetEnableTakeBackWatcher from './setEnableTakeback';
import updateBasketWatcher from './updateBasket';
import upgradeOrderItemWatcher from './upgradeOrderItem';
import subscribeStockReminderSagasWatcher from '../stockReminder/stockReminder';


export default [
  onSearchErrorWatcher,
  onSearchWatcher,
  onChangeQuantityWatcher,
  onPriceDateChangeWatcher,
  onAddOrderItemWatcher,
  onGetBasketWatcher,
  onDeleteOrderItemWatcher,
  getBasketErrorWatcher,
  subscribeStockReminderSagasWatcher,
  upgradeOrderItemWatcher,
  onSetEnableTakeBackWatcher,
  updateBasketWatcher,
  prepareReklaOrderWatcher,
  loadReklaOrderWatcher,
];
