import { OrderItem } from 'src/types/offer/OrderItem';
import {
  DeliveryTimeMessage,
  DeliveryTimeMessageType,
  SkuDeliveryTime,
} from 'src/types/product/DeliveryTime';
import {
  DeliveryTime,
  DeliveryType,
  ProductDetails,
} from 'src/types/product/product';


export default function getDeliveryTimes(products?: ProductDetails[], skus?: string[]): SkuDeliveryTime {
  if (!products || !skus) {
    return {};
  } else  {
    let deliveryTimes: SkuDeliveryTime = {};
    products.forEach(({ variants }) => {
      skus.forEach(sku => {
        const variant = variants.find(v => v.sku === sku);
        if(variant) {
          deliveryTimes = {
            ...deliveryTimes,
            [sku]: productDeliveryTime(variant.deliveryTime),
          };
        }
      });
    });
    return deliveryTimes;
  }
}

export function productDeliveryTime(deliveryTime?: DeliveryTime): DeliveryTimeMessage  {
  if (deliveryTime && deliveryTime.deliveryType === DeliveryType.TELEPHONE_NOTIFICATION) {
    return {
      type: DeliveryTimeMessageType.fourteenDaysDelay,
    };
  }
  if (
    (deliveryTime && deliveryTime.deliveryType === DeliveryType.THIRD_PARTY_DELIVERY) ||
    (deliveryTime &&
      deliveryTime.deliveryType === DeliveryType.CONSIGNMENT &&
      deliveryTime.deliveryDays &&
      deliveryTime.deliveryDays > 10)
  ) {
    return {
      type: DeliveryTimeMessageType.daysDeliveryTime,
      deliveryDays: deliveryTime.deliveryDays,
    };
  }
  return {
    type: DeliveryTimeMessageType.standardDeliveryTime,
  };
}

export function getDeliveryTimeMessage(items: OrderItem[]): DeliveryTimeMessage[] {
  const deliveryTimes = [];
  let fourteenDaysDelay = false;
  let deliveryDays = -1;
  for(const { deliveryTime } of items) {
    if(deliveryTime?.type === DeliveryTimeMessageType.fourteenDaysDelay) {
      fourteenDaysDelay = true;
    }
    else {
      if (deliveryTime?.type === DeliveryTimeMessageType.daysDeliveryTime
        && deliveryTime?.deliveryDays
        && deliveryTime?.deliveryDays > deliveryDays
      ) {
        deliveryDays = deliveryTime.deliveryDays;
      }
      else if(deliveryDays < 1) {
        deliveryDays = 0;
      }
    }
  }
  if (deliveryDays > 0) {
    deliveryTimes.push({
      type: DeliveryTimeMessageType.daysDeliveryTime,
      deliveryDays: deliveryDays,
    });
  }
  else if(deliveryDays === 0) {
    deliveryTimes.push({
      type: DeliveryTimeMessageType.standardDeliveryTime,
    });
  }
  if(fourteenDaysDelay) {
    deliveryTimes.push({
      type: DeliveryTimeMessageType.fourteenDaysDelay,
    });
  }
  return deliveryTimes;
}
