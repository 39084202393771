import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { closeModal } from 'src/redux/app/appSlice';
import { onCloseInstallmentFactoringModal } from 'src/redux/editOrder/editOrderSagaActions';
import {
  changeInstallmentFactoringModalMode,
  editOrderSelector,
  resetInstallmentFactoringPayment,
} from 'src/redux/editOrder/editOrderSlice';
import { InstallmentFactoringModalMode } from 'src/redux/payment/paymentSlice';
import { Modals } from 'src/types/Modals';


export function* onCloseInstallmentFactoringModalSaga() {

  const installmentsFactoringModalMode = yield  select(editOrderSelector.getInstallmentsFactoringModalMode);
  if (installmentsFactoringModalMode === InstallmentFactoringModalMode.NORMAL_MODE) {
    yield put(resetInstallmentFactoringPayment());
  }
  yield put(closeModal(Modals.editOrderInstallmentsFactoringPaymentModal));
  yield put(changeInstallmentFactoringModalMode(InstallmentFactoringModalMode.NORMAL_MODE));

}

export default function* onCloseInstallmentFactoringModalWatcher() {
  yield takeLatest(onCloseInstallmentFactoringModal.type, onCloseInstallmentFactoringModalSaga);
}
