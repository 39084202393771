import { CustomerBankingDetailsState, PaymentState } from 'src/redux/payment/paymentSlice';
import {
  CashOnDeliveryPayment,
  DirectDebitPayment,
  InstallmentsFactoringPayment,
  InstallmentsPayment,
  InvoicePayment,
  Payment,
} from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';


const DEFAULT_INSTALLMENTS_COUNT = 3;

export function buildPayment(payment: PaymentState, bankingDetails?: CustomerBankingDetailsState): Payment | undefined {
  switch (payment.selectedPaymentMethod) {
  case PaymentMethod.invoice:
    return {
      method: payment.selectedPaymentMethod,
    } as InvoicePayment;
  case PaymentMethod.directDebit:
    return {
      method: payment.selectedPaymentMethod,
      iban: bankingDetails?.iban,
      accountHolder: bankingDetails?.accountHolder,
      usesPrefilledIban: bankingDetails?.usesPrefilledIban,
    } as DirectDebitPayment;
  case PaymentMethod.cashOnDelivery:
    return {
      method: payment.selectedPaymentMethod,
    } as CashOnDeliveryPayment;
  case PaymentMethod.installments: {
    const currentInstallmentPlan = payment.installments.currentInstallmentPlan;

    return {
      dueDay: payment.installments.dueDay,
      installmentCount:
          (currentInstallmentPlan && currentInstallmentPlan.installmentCount) ||
          DEFAULT_INSTALLMENTS_COUNT,
      installment: currentInstallmentPlan && currentInstallmentPlan.installment,
      finalInstallment: currentInstallmentPlan && currentInstallmentPlan.finalInstallment,
      total: currentInstallmentPlan && currentInstallmentPlan.total,
      method: payment.selectedPaymentMethod,
      iban: bankingDetails?.iban,
      accountHolder: bankingDetails?.accountHolder,
      usesPrefilledIban: bankingDetails?.usesPrefilledIban,
    } as InstallmentsPayment;
  }
  case PaymentMethod.installmentsFactoring: {
    const currentInstallmentPlan = payment.installmentsFactoring.currentInstallmentPlan;
    return {
      calculationId: payment.installmentsFactoring.calculationId,
      channel: payment.installmentsFactoring.channel,
      installmentCount: currentInstallmentPlan?.installmentCount || DEFAULT_INSTALLMENTS_COUNT,
      installment: currentInstallmentPlan?.installment,
      finalInstallment: currentInstallmentPlan?.finalInstallment,
      total: currentInstallmentPlan?.total,
      method: payment.selectedPaymentMethod,
      iban: bankingDetails?.iban,
      accountHolder: bankingDetails?.accountHolder,
      usesPrefilledIban: bankingDetails?.usesPrefilledIban,
      interestRate: currentInstallmentPlan?.interestRate,
      totalInterest: currentInstallmentPlan?.totalInterest,
      totalGrossAmount: currentInstallmentPlan?.totalGrossAmount,
    } as InstallmentsFactoringPayment;

  }
  default:
    return undefined;
  }
}
