import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiSubscriptionsManagement } from 'src/api/apiSubscriptionsManagement';
import { logErrorEvent } from 'src/logging/loggingActions';
import { showErrorNotification, showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { SelectionState } from 'src/subscriptionProductManagement/utils/subscriptionProductManagementUtils';
import { SubscriptionProductCustomer } from 'src/types/subscription/SubscriptionProductCustomer';
import { createSubscriptionsOverviewFetchRequest, SubscriptionsOverviewRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import { SubscriptionsCustomersInfoResponse } from 'src/types/subscription/SubscriptionsOverviewResponse';
import stringify from 'src/utils/stringify';

import { getSelectedSubscriptionsCustomers, subscriptionManagementSelectors } from '../redux/selectors/subscriptionManagementSelectors';
import { copySubscriptionsCustomerInfo, copySubscriptionsCustomerDone } from '../redux/subscriptionManagementSlice';


interface CustomersInfoCopy {
  customers: { id: string, firstName: string, lastName: string }[];
}

export function* copySubscriptionsCustomersInfoSaga() {
  try {
    const selectionState = yield select(subscriptionManagementSelectors.getSubscriptionsSelectionState);

    let customerData: CustomersInfoCopy;
    if (selectionState !== SelectionState.ALL) {
      const selectedSubscriptionsCustomers: SubscriptionProductCustomer[] = yield select(getSelectedSubscriptionsCustomers);
      customerData = {
        customers: selectedSubscriptionsCustomers.map(customer => ({
          id: customer.crmPartnerId,
          firstName: customer.firstName,
          lastName: customer.lastName
        }))
      };
    } else {
      const filters = yield select(subscriptionManagementSelectors.getSubscriptionsOverviewFetchFilters);
      const apiSubscriptionsManagement: ApiSubscriptionsManagement = yield getContext(SagaContextItem.apiSubscriptionsManagement);
      const request: SubscriptionsOverviewRequest = createSubscriptionsOverviewFetchRequest(0, 0, filters, 0);
      const response: SubscriptionsCustomersInfoResponse = yield call(apiSubscriptionsManagement.getSubscriptionsCustomersInfo, request);
      customerData = {
        customers: response.customersInfo.map(c => ({
          id: c.externalCustomerId,
          firstName: c.firstName,
          lastName: c.lastName
        }))
      };
    }

    yield navigator.clipboard.writeText(stringify(customerData));

    yield put(showSuccessNotification('subscriptionManagement.copyCustomerData.success'));
  } catch (err) {
    yield put(showErrorNotification('subscriptionManagement.copyCustomerData.failed'));
    yield put(logErrorEvent({ message: 'Could not fetch and copy subscriptions customers data', err }));
  } finally {
    yield put(copySubscriptionsCustomerDone());
  }
}

export default function* copySubscriptionsCustomersInfoSagaWatcher() {
  yield takeLatest(copySubscriptionsCustomerInfo.type, copySubscriptionsCustomersInfoSaga);
}
