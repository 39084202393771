interface AuthorizedRolesDefinition {
  roles: Array<string>;
}

interface Roles {
  [propName: string]: AuthorizedRolesDefinition;
}

export enum RolesNames {
  OrderManagement = 'OrderManagementRole',
  VoucherManagement = 'VoucherManagementRole',
  ServiceManagement = 'ServiceManagementRole',
  SubscriptionManagement = 'SubscriptionManagementRole',
  ChOrderManagement = 'ChOrderManagementRole',
}

export const AuthorizedRoles: Roles = {
  rejectionMgmt: {
    roles: [RolesNames.OrderManagement],
  },
  orderApp: {
    roles: [RolesNames.OrderManagement],
  },
  orderRejection: {
    roles: [RolesNames.OrderManagement],
  },
  voucherMgmt: {
    roles: [RolesNames.VoucherManagement],
  },
  voucherForm: {
    roles: [RolesNames.VoucherManagement],
  },
  VoucherDetails: {
    roles: [RolesNames.VoucherManagement],
  },
  OrderHistory: {
    roles: [RolesNames.OrderManagement],
  },
  EditOrder: {
    roles: [RolesNames.OrderManagement],
  },
  editCustomer: {
    roles: [RolesNames.ServiceManagement],
  },
  subscriptionProductManagement: {
    roles: [RolesNames.OrderManagement],
  },
  accessSubscriptionReports: {
    roles: [RolesNames.SubscriptionManagement]
  },
  wilisForCH: {
    roles: [RolesNames.ChOrderManagement]
  },
  limelightSearch: {
    roles: [RolesNames.ServiceManagement]
  }
};
