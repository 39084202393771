import {
  call,
  getContext,
  put,
  takeEvery,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import {
  fetchCustomerByNameAndBirthdaySuccess,
  FetchDuplicatedCustomers,
  fetchDuplicatedCustomers,
} from 'src/redux/customer/customerSuggestionsSlice';
import { CheckCustomerRequest } from 'src/types/customer/CheckCustomerRequest';
import { Customer } from 'src/types/customer/customer';
import stringify from 'src/utils/stringify';


export function* fetchDuplicatedCustomersSaga({ payload }: FetchDuplicatedCustomers) {
  try {
    const apiCustomer: ApiCustomer = yield getContext('apiCustomer');
    const customers = [];

    if(payload.email) {
      const customerByEmail: Customer = yield call(apiCustomer.fetchCustomerByEmail, payload.email);
      customers.push(customerByEmail);
    }

    if(payload.dateOfBirth) {
      const { firstName, lastName, dateOfBirth } = payload;
      const customersByNameAndDateOfBirth: Customer[] = yield call(apiCustomer.fetchCustomersByNameAndDateOfBirth, {
        firstName, lastName, dateOfBirth,
      } as CheckCustomerRequest);
      customers.push(...customersByNameAndDateOfBirth);
    }

    yield put(fetchCustomerByNameAndBirthdaySuccess(customers));
  } catch(err) {
    yield put(logErrorEvent({ message: `Could not fetch duplicate customer ${stringify(payload)}`, err }));
  }
}

export default function* fetchDuplicatedCustomersWatcher() {
  yield takeEvery(fetchDuplicatedCustomers.type, fetchDuplicatedCustomersSaga);
}
