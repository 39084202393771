import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SourceChannels } from 'src/types/offer/SalesSource';


export type SourceChannelState = SourceChannels;
export const sourceChannelInitialState = SourceChannels.TELE;

const sourceChannelSlice = createSlice({
  name: 'sourceChannel',
  initialState: sourceChannelInitialState,
  reducers: {
    changeSourceChannel(_, { payload }: PayloadAction<SourceChannels>) {
      return payload;
    },
    resetSourceChannel() {
      return sourceChannelInitialState;
    },
  },
});

export const {
  changeSourceChannel,
  resetSourceChannel,
} = sourceChannelSlice.actions;

export default sourceChannelSlice.reducer;
