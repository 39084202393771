import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { showErrorNotification } from 'src/redux/app/appSlice';
import {
  addNewProduct,
  editOrderSelector,
  onEditOrderProductSearch,
} from 'src/redux/editOrder/editOrderSlice';
import { orderTableSearchFinished, setSearchTerm } from 'src/redux/order/orderEntrySlice';
import { fetchProduct } from 'src/redux/product/productDetailsSlice';
import {
  emptyProductSearchResults,
  fetchProductSearchResults,
  productSearchOpened,
} from 'src/redux/product/productSearchSlice';
import { fetchProductStock } from 'src/redux/product/productStockSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import getProductBySku from 'src/sagas/product/productService';
import { EditOrderActionType } from 'src/types/orderhistory/EditOrderActionRequest';
import { ProductDetails } from 'src/types/product/product';
import isSearchTermNotSku from 'src/utils/validators/isSearchTermNotSku';
import isVariantSku from 'src/utils/validators/isVariantSku';


export function* onEditOrderProductSearchSaga(action: PayloadAction<string>) {
  try {
    yield put(setSearchTerm(''));
    yield put(emptyProductSearchResults());

    const orderId = yield select(editOrderSelector.getEditedOrderId);

    if (isSearchTermNotSku(action.payload)) {
      yield put(
        fetchProductSearchResults({ query: action.payload }),
      );
    } else if (isVariantSku(action.payload.replace(/ /g, ''))) {
      const productSku = action.payload.replace(/ /g, '');
      const salesChannel = yield select(editOrderSelector.getEditedOrderSalesChannel);
      yield call(getProductBySku, productSku, salesChannel);
      yield put(fetchProductStock([productSku]));
      yield put(addNewProduct({
        orderId: orderId,
        actionRequest: {
          sku: productSku,
          type: EditOrderActionType.ADD_POSITION,
          quantity: 1,
        },
      }));
    } else {
      const productSku = action.payload.replace(/ /g, '');

      const mainProduct: ProductDetails = yield call(getProductBySku, productSku);

      if ( mainProduct.variants.length === 1) {
        yield put(addNewProduct({
          orderId: orderId,
          actionRequest: {
            sku: mainProduct.variants[0].sku,
            type: EditOrderActionType.ADD_POSITION,
            quantity: 1,
          },
        }));
      } else {
        // has multiple variants
        yield put(fetchProduct({ sku: productSku }));
        yield put(fetchProductStock([productSku]));
      }
    }

    yield put(orderTableSearchFinished());
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(orderTableSearchFinished());
      yield put(productSearchOpened({ open: true, searchTerm: action.payload }));
    } else {
      const errorMessage = error.response ? error.response.data?.detail : error?.message;
      yield put(showErrorNotification(errorMessage));
      yield put(emptyProductSearchResults());
      yield handleFailureState(error, `Couldn't Search for a product named: ${action.payload} on edit order`, undefined);
    }
  }
}

export default function* onEditOrderProductSearchWatcher() {
  yield takeLatest(onEditOrderProductSearch, onEditOrderProductSearchSaga);
}
