import {
  call,
  takeLatest,
  put,
  getContext,
} from 'redux-saga/effects';

import { ApiSearch } from 'src/api/apiSearch';
import {  openModal } from 'src/redux/app/appSlice';
import {
  fetchProductSearchResultsError,
  fetchProductSearchResultsSuccess,
  FetchProductSearchResults,
  fetchProductSearchResultsInProgress,
  fetchProductSearchResults,
} from 'src/redux/product/productSearchSlice';
import { fetchProductStock } from 'src/redux/product/productStockSlice';
import { emptyProductSuggestionsResults } from 'src/redux/product/productSuggestionsSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';
import { ProductSearchResponse } from 'src/types/product/product';
import { ProductSearchParams } from 'src/types/product/SearchAndSuggestionsParams';
import countryLocalStorage from 'src/utils/countryLocalStorage';


export function* productSearchSaga(action: FetchProductSearchResults) {
  try {
    const apiSearch: ApiSearch = yield getContext(SagaContextItem.apiSearch);
    const searchParams: ProductSearchParams = { query: action.payload.query ?? '', countryCode: countryLocalStorage.get(), size: 100 };
    yield put(fetchProductSearchResultsInProgress());
    yield put(emptyProductSuggestionsResults());
    const data: ProductSearchResponse = yield call(apiSearch.searchProducts, searchParams);

    if (data.products.length > 0) {
      yield put(fetchProductStock(data.products.map(hit => hit.product.masterValues.masterProductNumber)));
    }

    yield put(fetchProductSearchResultsSuccess(data));
  } catch (err) {
    const errorCode = (err && err.response && err.response.status) || 400;
    yield put(fetchProductSearchResultsError(errorCode));
  } finally {
    yield put(openModal(Modals.productSearch));
  }
}

export default function* fetchProductSearchWatcher() {
  yield takeLatest(fetchProductSearchResults.type, productSearchSaga);
}
