import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { ProductDetails } from 'src/types/product/product';
import { TrackEvent } from 'src/types/TrackEvent';


export interface CreateWaitlistItemState {
  sku?: string;
  product?: ProductDetails;
  quantity?: number;
  totalPrice?: number;
  shippingCosts?: number;
  loading?: boolean;
  lastLoading?: boolean;
  creationSuccess?: boolean;
  selectedPaymentMethod?: PaymentMethod;
}

export const createWaitlistItemInitialState: CreateWaitlistItemState = {};

export interface CreateWaitlistItemParams {
  product: ProductDetails,
  sku: string;
  shippingCosts: number;
  quantity: number;
  totalPrice: number;
  selectedPaymentMethod?: PaymentMethod;
}

export const trackWaitListImpression = createAction<TrackEvent>('waitList/trackWaitListImpression');
export type TrackWaitListImpression = ReturnType<typeof trackWaitListImpression>;

export const trackWaitListReservation = createAction<TrackEvent>('waitList/trackWaitListReservation');
export type TrackWaitListReservation = ReturnType<typeof trackWaitListReservation>;

const createWaitlistItemSlice = createSlice({
  name: 'createWaitlistItem',
  initialState: createWaitlistItemInitialState,
  reducers: {
    initializeCreateWaitlistItem(state, { payload }: PayloadAction<CreateWaitlistItemParams>) {
      state.sku = payload.sku;
      state.product = payload.product;
      state.quantity = payload.quantity;
      state.shippingCosts = payload.shippingCosts;
      state.totalPrice = payload.totalPrice;
      state.selectedPaymentMethod = payload.selectedPaymentMethod;
      state.loading = false;
    },
    createWaitlistItem(state, { payload }: PayloadAction<number>) {
      state.lastLoading = state.loading;
      state.loading = true;
      state.quantity = payload;
    },
    createWaitlistItemSuccess(state) {
      state.loading = false;
      state.creationSuccess = true;
    },
    createWaitlistItemFailed(state) {
      state.loading = false;
      state.creationSuccess = false;
    },
    resetCreateWaitlistItemState() {
      return createWaitlistItemInitialState;
    },
    setWaitlistSelectedPaymentMethod(state, { payload }: PayloadAction<PaymentMethod>){
      state.selectedPaymentMethod = payload;
    },
  },
});

export const {
  initializeCreateWaitlistItem,
  createWaitlistItem,
  createWaitlistItemSuccess,
  createWaitlistItemFailed,
  resetCreateWaitlistItemState,
  setWaitlistSelectedPaymentMethod,
} = createWaitlistItemSlice.actions;

export type CreateWaitlistItem = ReturnType<typeof createWaitlistItem>;

export default createWaitlistItemSlice.reducer;
