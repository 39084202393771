import { call, getContext } from 'redux-saga/effects';

import { ApiBasket } from 'src/api/apiBasket';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import {
  getBasketId,
  removeBasketId,
  trackNewSessionDeletedBasket,
} from 'src/utils/basketId';


export function* deleteCurrentBasket() {
  try {
    const apiBasket: ApiBasket = yield getContext(SagaContextItem.apiBasket);
    const currentBasketId = getBasketId();

    if(!currentBasketId) {
      return;
    }

    removeBasketId();
    trackNewSessionDeletedBasket(currentBasketId);

    yield call(apiBasket.deleteBasket, currentBasketId);
  } catch (basketApiError) {
    return;
  }
}
