import {
  call,
  getContext,
  put,
  select,
} from 'redux-saga/effects';

import { ApiProduct } from 'src/api/apiProduct';
import Config from 'src/config';
import { DEFAULT_SALES_CHANNEL } from 'src/config/salesChannels';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { ProductDetails } from 'src/types/product/product';
import { getCampaignChannelFromSalesChannel } from 'src/utils/formatters/freeShipping';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';
import { isVariantStatusSellable } from 'src/utils/mappers/discardedOffers';
import { productsAndTheirSubscriptions } from 'src/utils/product/productUtils';

/*
  TODO: centralize products & stocks api calls to facilitate the usage everywhere instead of repeating same logics
   - mark all variants with flag isSellable basing on status & erp status (done)
   - infer sellable status basing on stock
   - remove this comments at the end
*/

function* getProductBySku(sku: string, channel?: string) {
  const apiProduct: ApiProduct = yield getContext(SagaContextItem.apiProduct);

  const salesChannel = channel ? channel : yield select(salesChannelSelector.getSalesChannel);

  const productResponse: ProductDetails = yield call(apiProduct.getProduct, toBaseProductNumber(sku), getCampaignChannelFromSalesChannel(salesChannel));

  return yield call(checkAndAppendNeededInfoToVariants, productResponse, salesChannel);
}

function* checkAndAppendNeededInfoToVariants(productResponse: ProductDetails, salesChannel?: string) {
  const linkedSubscriptionsProducts = yield call(getLinkedSubscriptionsForProduct, productResponse, salesChannel);

  const markedVariants = productResponse?.variants?.map(v => ({
    ...v,
    linkedSubscriptionProduct: linkedSubscriptionsProducts[v.sku],
    isSellable: isVariantStatusSellable(v.status, v.erpStatus),
    erpStatus: salesChannel === '01'? v.erpStatusTv : v.erpStatus,
  }));
  return {
    ...productResponse,
    variants: markedVariants,
  } as ProductDetails;
}

function* getLinkedSubscriptionsForProduct(productResponse: ProductDetails, salesChannel?: string) {
  const linkedSubscriptionProducts: {[sku: string]: ProductDetails} = {};
  try {
    if (Config.env.enableSubscriptionHistory) {
      const linkedSubscriptionProductNos = productResponse.variants
        .map(variant => productsAndTheirSubscriptions[variant.sku])
        .filter(sku => !!sku);

      if (linkedSubscriptionProductNos.length < 1) {
        return linkedSubscriptionProducts;
      }

      const subscriptionProducts: ProductDetails[] = yield call(doFetchProducts, linkedSubscriptionProductNos, salesChannel);

      productResponse.variants.forEach(variant => {
        const subscriptionProduct = subscriptionProducts.find(e => productsAndTheirSubscriptions[variant.sku] === e.baseProductNo);
        if (subscriptionProduct && subscriptionProduct.subscriptionInfo) {
          linkedSubscriptionProducts[variant.sku] = subscriptionProduct;
        }
      });
    }
  } catch (err) {
    yield put(
      logEvent({
        level: LOG_LEVEL.ERROR,
        message: 'could not fetch linked subscriptions products',
        err: err,
      }),
    );
  }

  return linkedSubscriptionProducts;
}

export function* doFetchProducts(skus: string[], salesChannel?: string) {
  const campaignSalesChannel = getCampaignChannelFromSalesChannel(salesChannel ?? DEFAULT_SALES_CHANNEL);
  const apiProduct: ApiProduct = yield getContext(SagaContextItem.apiProduct);
  return yield call(apiProduct.getProducts, skus.map(sku => toBaseProductNumber(sku)), campaignSalesChannel);
}

export default getProductBySku;
