import { decamelizeKeys, camelizeKeys } from 'humps';

import { Endpoints } from 'src/constants/endpoints';
import { OfferRequest } from 'src/types/offer/OfferRequest';
import { OfferResponse } from 'src/types/offer/OfferResponse';
import { OrderPreCheckRequest, OrderPreCheckResponse } from 'src/types/offer/OrderPreCheck';

import { appendLocaleAndXAuthHeaders, getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { versionHeaderInterceptor } from './httpClient/interceptors/versionHeaderInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';


export interface ApiOffer {
  createOffer: (offerRequest: OfferRequest) => Promise<OfferResponse>;
  preCheckOrder: (request: OrderPreCheckRequest) => Promise<OrderPreCheckResponse>;
}

export function getApiOffer(baseURL: string): ApiOffer {
  const offerClient = getHttpClient(
    {
      baseUrl: baseURL,
      headers: { 'Content-Type': 'application/json' },
      interceptors: {
        response: [
          refreshTokenInterceptor,
          versionHeaderInterceptor,
        ],
        request: [
          localeHeaderInterceptor,
          xAuthorizationHeaderInterceptor,
        ],
      },
    });

  offerClient.interceptors.request.use(appendLocaleAndXAuthHeaders);

  return {
    createOffer: async (offerRequest: OfferRequest) =>{
      const result = await offerClient.post<OfferResponse>(Endpoints.offer, decamelizeKeys(offerRequest));
      return camelizeKeys(result.data) as OfferResponse;
    },
    preCheckOrder: async (request: OrderPreCheckRequest) => {
      const result = await offerClient.post<OrderPreCheckResponse>(Endpoints.orderPreCheck, decamelizeKeys(request));
      return camelizeKeys(result.data) as OrderPreCheckResponse;
    },
  };
}
