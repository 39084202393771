import clearVouchersSuggestionsWatcher from './clearVoucherSuggestions';
import getRedeemedVouchersWatcher from './getRedeemedVouchers';
import getVouchersSuggestionsWatcher from './getVouchersSuggestions';
import resetVoucherWatcher from './resetVoucher';
import submitSuggestedVoucherWatcher from './submitSuggestedVoucher';
import submitVoucherWatcher from './submitVoucher';
import verifyVoucherWatcher from './verifyVoucher';


export default [
  resetVoucherWatcher,
  submitVoucherWatcher,
  submitSuggestedVoucherWatcher,
  getVouchersSuggestionsWatcher,
  clearVouchersSuggestionsWatcher,
  getRedeemedVouchersWatcher,
  verifyVoucherWatcher,
];
