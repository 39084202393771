import { put, takeLatest } from 'redux-saga/effects';

import { closeModal } from 'src/redux/app/appSlice';
import { clearVouchersSuggestions } from 'src/redux/order/voucherSlice';
import { Modals } from 'src/types/Modals';


export function* clearVouchersSuggestionsSaga() {
  yield put(closeModal(Modals.voucherSuggestions));
}

export default function* clearVouchersSuggestionsWatcher() {
  yield takeLatest(clearVouchersSuggestions.type, clearVouchersSuggestionsSaga);
}
