import { RootStateType } from 'src/constants/types';
import { unTrackedUpSellProductsImpressions } from 'src/utils/mappers/unTrackedUpSellsProductImpressions';
import { sliceArray } from 'src/utils/sliceArray';
import { createSelectors } from 'src/utils/state';


export const productUpsellsSelector = createSelectors({
  getUpsellsLocation(state: RootStateType) {
    return state.product.upsells.upsellsLocation;
  },
  getCrossSells(state: RootStateType) {
    return state.product.upsells.upsells;
  },
  shouldShowUpsells(state: RootStateType) {
    return !!state.product.upsells.upsells.length;
  },
  getUpsellsMainProduct(state: RootStateType) {
    return state.product?.upsells?.upsellsMainProduct;
  },
  getUpsellsMainProductSku(state: RootStateType) {
    return state.product?.upsells?.upsellsMainProductSku;
  },
});

export const firstSlideUpsellToTrack = (end: number) => (state: RootStateType) => {
  const firstSlideUpSellUntrakedProduct = sliceArray(state.product.upsells.upsells, 0, end);
  return (
    firstSlideUpSellUntrakedProduct &&
    unTrackedUpSellProductsImpressions(firstSlideUpSellUntrakedProduct)
  );
};
