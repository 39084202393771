import Log from 'src/utils/log';


interface LastConsentsManagementState {
  customerId: string;
  phoneMarketResearchConsent?: boolean;
  emailMarketResearchConsent?: boolean;
  customerPhoneNumber?: string;
  customerEmail?: string;
  submitted?: boolean;
}

export const LAST_MANAGED_CONSENTS_HISTORY_KEY = 'lastManagedConsentsHistory';

export function setlastConsentsManagementState(
  phoneMarketResearchAllowed?: boolean,
  emailMarketResearchAllowed?: boolean,
  customerPhoneNumber?: string,
  customerEmail?: string,
  customerId?: string,
  submitted?: boolean,
) {
  if (customerId) {
    try {
      const lastManagedConsent: LastConsentsManagementState = {
        customerId: customerId,
        phoneMarketResearchConsent: phoneMarketResearchAllowed,
        emailMarketResearchConsent: emailMarketResearchAllowed,
        customerPhoneNumber:  customerPhoneNumber,
        customerEmail: customerEmail,
        submitted: submitted,
      };
      sessionStorage.setItem(LAST_MANAGED_CONSENTS_HISTORY_KEY, JSON.stringify(lastManagedConsent));
    } catch (err) {
      Log.error('unexpected error');
    }
  }
}

export function getLastManagedConsentForCustomer(customerId?: string) {
  try {
    const lastManagedConsentsToken = sessionStorage.getItem(LAST_MANAGED_CONSENTS_HISTORY_KEY);
    if (lastManagedConsentsToken) {
      const lastState = JSON.parse(lastManagedConsentsToken) as LastConsentsManagementState;
      return customerId === lastState.customerId ? lastState : undefined;
    }
  } catch (err) {
    Log.error('unexpected error');
  }

  return undefined;
}

export function removeLastManagedConsent() {
  try {
    sessionStorage.removeItem(LAST_MANAGED_CONSENTS_HISTORY_KEY);
  } catch (err) {
    Log.error('unexpected error');
  }
}
