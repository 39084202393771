import { camelizeKeys } from 'humps';

import {
  Product,
  SearchParams,
  OfferProductInterface,
} from 'src/types/product/product';
import { SearchSuggestionResponse } from 'src/types/product/SearchSuggestionResponse';
import { FilterType } from 'src/utils/getters/getFactFinderQuery';
import { mapProductsToOfferProducts } from 'src/utils/mappers/mapProductsToOfferProducts';

import { ProductSearchService } from './ProductSearchService';
import {
  getProductSearchResult,
  getProductSuggestionsResult,
  fetchTopDeals,
} from '../apiFactFinder';


export class FactFinderService implements ProductSearchService {
  constructor() {
    this.searchBySku = this.searchBySku.bind(this);
  }

  async searchByParams(params: SearchParams): Promise<Array<Product>> {
    const factFinderResponseAxiosResponse = await getProductSearchResult(params);
    // TODO: remove camelizeKeys from here
    return camelizeKeys(factFinderResponseAxiosResponse.data.hits) as Array<Product>;
  }

  searchBySku(sku: string): Promise<Array<Product>> {
    return this.searchByParams({
      filters: [
        { name: 'ProductNumber', value: sku, substring: true },
        { name: 'MasterProductNumber', value: sku, substring: true },
      ],
    });
  }

  searchByLongText(searchTerm: string): Promise<Array<Product>> {
    return this.searchByParams(this.getSearchByLongTextParams(searchTerm));
  }

  getSearchByLongTextParams(searchTerm: string): SearchParams {
    return {
      query: searchTerm,
      filters: [
        { name: 'MasterProductNumber', value: '', substring: true },
        { name: 'ProductNumber', value: '', substring: true },
      ],
    };
  }

  getSearchSuggestions = async (params: SearchParams): Promise<Array<SearchSuggestionResponse>> => {
    const factFinderResponseAxiosResponse = await getProductSuggestionsResult(params);
    // TODO: remove camelizeKeys from here
    return camelizeKeys(factFinderResponseAxiosResponse.data) as Array<SearchSuggestionResponse>;
  }

  getTopDeals = async (type: FilterType, numberOfProducts = 20): Promise<Array<OfferProductInterface>> => {
    const factFinderResponseAxiosResponse = await fetchTopDeals(type, numberOfProducts);
    // TODO: remove camelizeKeys from here
    const products = camelizeKeys(factFinderResponseAxiosResponse.data.hits) as Array<Product>;
    return mapProductsToOfferProducts(products);
  }
}
