import { CustomerInfoState } from 'src/redux/customer/customerInfoSlice';
import { CustomerBankingDetailsState } from 'src/redux/payment/paymentSlice';
import { CreateWaitlistItemState } from 'src/redux/product/createWaitlistItemSlice';
import { DeliveryAddress } from 'src/types/customer/address';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { WaitlistItemRequest } from 'src/types/waitlist/CreateWaitlistItemRequest';

import { buildWaitlistItemCustomer } from './buildCustomerForSubscriptionAndWaitlist';


export function buildWaitlistItemRequest(
  customer: CustomerInfoState,
  createWaitlistItemParams: CreateWaitlistItemState,
  paymentMethod: PaymentMethod,
  salesChannel: string,
  salesOffice: string,
  bankingDetails: CustomerBankingDetailsState,
  quantity: number,
  deliveryAddress: DeliveryAddress,
) {
  const additionalPaymentFields = paymentMethod === PaymentMethod.directDebit ? {
    iban: bankingDetails.iban,
    accountHolder: bankingDetails.accountHolder,
    usesPrefilledIban: bankingDetails.usesPrefilledIban,
  } : {};

  const variantPrice = createWaitlistItemParams.product?.variants?.find(v => v.sku === createWaitlistItemParams.sku)?.price;

  return {
    customer: buildWaitlistItemCustomer(customer),
    product: {
      sku: createWaitlistItemParams.sku,
      quantity,
      price: variantPrice?.value,
    },
    payment: { method: paymentMethod, ...additionalPaymentFields },
    deliveryAddress,
    salesChannel: salesChannel,
    salesOffice: salesOffice,
    sourceChannel: SourceChannels.ECOM,
  } as WaitlistItemRequest;
}
