import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { setSelectedDeliveryAddress } from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { PaymentState, setSelectedPaymentMethod } from 'src/redux/payment/paymentSlice';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';

import { getOfferIfOrderLineItemsExist } from '../offer/getOffer';


export function* setSelectedDeliveryAddressSaga() {
  try {
    const payment: PaymentState = yield select(state => state.payment);
    const useBillingAsDeliveryAddress = yield select(customerInfoSelectors.billingAsDeliveryAddress);

    if (payment.selectedPaymentMethod === PaymentMethod.cashOnDelivery && !useBillingAsDeliveryAddress) {
      yield put(setSelectedPaymentMethod());
    }

    yield call(getOfferIfOrderLineItemsExist);
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not set selected delivery address', err }));
  }
}

export default function* setSelectedDeliveryAddressWatcher() {
  yield takeEvery(setSelectedDeliveryAddress.type, setSelectedDeliveryAddressSaga);
}
