import { ReklaOrder } from 'src/redux/order/orderEntrySlice';


const REKLA_ORDER = 'rekla_order';

export const saveReklaOrderInfoInSession = (reklaOrderObject: ReklaOrder) => {
  sessionStorage.setItem(REKLA_ORDER, JSON.stringify(reklaOrderObject));
};

export const removeReklaOrderInfoFromSession = () => {
  sessionStorage.removeItem(REKLA_ORDER);
};

export const getReklaOrderInfoFromSession = () => {
  const token = sessionStorage.getItem(REKLA_ORDER);
  
  return token ? JSON.parse(token) as ReklaOrder : undefined;
};
