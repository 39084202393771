import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const serviceVoucherSelectors = createSelectors({
  getServiceVoucher(state: RootStateType) {
    return state.serviceVoucher.code;
  },
  isServiceVoucherApplied(state: RootStateType) {
    return state.serviceVoucher.code !== '----';
  },
});
