import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';

import { CountryCode } from 'src/api/orderTypes';
import { changeDropDownCountry, setCountry } from 'src/redux/app/appSlice';
import { onGetBasketSaga } from 'src/sagas/orderEntry/onGetBasket';
import countryLocalStorage from 'src/utils/countryLocalStorage';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* onChangeCountrySaga(action: PayloadAction<CountryCode>) {
  try {
    countryLocalStorage.set(action.payload);
    if(action.type === changeDropDownCountry.type) {
      yield call(onGetBasketSaga);
    }
  } catch (err) {
    const countryTxt = action.payload === CountryCode.at ? CountryCode.at : CountryCode.de;
    const errorReason = err.response.data.detail ?? 'Etwas ist schief gelaufen. Bitte versuche es erneut';
    const errorMessage = `Land kann nicht in ${countryTxt} geändert werden: ${errorReason}`;
    yield call(logErrorAndShowNotification, `Could not change country to ${action.payload}`, errorMessage, err);
  }
}

export default function* onChangeCountryWatcher() {
  yield takeLatest(setCountry.type, onChangeCountrySaga);
  yield takeLatest(changeDropDownCountry.type, onChangeCountrySaga);
}
