import {
  put,
  takeEvery,
  getContext,
  call,
  select,
} from 'redux-saga/effects';

import { ApiVoucher } from 'src/api/apiVoucher';
import { logErrorEvent } from 'src/logging/loggingActions';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { sourceChannelSelector } from 'src/redux/order/selectors/sourceChannelSelectors';
import {
  addNonRedeemableVoucher,
  VerifyVoucher,
  verifyVoucher,
  verifyVoucherFailure,
  verifyVoucherSuccess,
} from 'src/redux/order/voucherSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { VerifyVoucherRequest } from 'src/types/voucher/VoucherRequest';
import { getBasketId } from 'src/utils/basketId';


export function* verifyVoucherSaga(action: VerifyVoucher) {

  const basketId = getBasketId();

  const salesChannel = yield select(salesChannelSelector.getSalesChannel);
  const sourceChannel = yield select(sourceChannelSelector.getSourceChannel);
  const externalCustomerId = yield select(customerInfoSelectors.getCustomerExternalId);

  const apiVoucher: ApiVoucher = yield getContext(SagaContextItem.apiVoucher);

  const voucherRequest: VerifyVoucherRequest = {
    code: action.payload,
    externalCustomerId: externalCustomerId ? externalCustomerId : null,
    basketId: basketId || undefined,
    salesChannel,
    sourceChannel
  };

  try {
    const response: string = yield call(apiVoucher.verifyVoucherRedemption, voucherRequest);

    if (response === '') {
      yield put(verifyVoucherSuccess(action.payload));
    }
  } catch (err) {
    if (err.response?.status === 400 && err.response?.data?.details) {
      yield put(
        addNonRedeemableVoucher({ code: action.payload, errors: err.response?.data?.details }),
      );
    } else {
      yield put(logErrorEvent({ message: `Could not verify voucher ${action.payload}`, err }));
      yield put(showErrorNotification());
      yield put(verifyVoucherFailure(action.payload));
    }
  }
}

export default function* verifyVoucherWatcher() {
  yield takeEvery(verifyVoucher.type, verifyVoucherSaga);
}
