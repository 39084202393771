import { RefObject, useEffect } from 'react';


function useOutsideClick(
  ref: RefObject<HTMLElement>,
  handler: (event: Event) => void,
  when = true,
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      // Type assertion is not harmfull here since typescript compiler fails to infer the right type
      if (!ref.current || ref.current.contains(<HTMLElement>event.target)) {
        return;
      }
      handler(event);
    };
    if (when) {
      document.addEventListener('click', listener);
      document.addEventListener('touchstart', listener);
    }
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, when]);
}

export default useOutsideClick;
