import { put } from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { showErrorNotification } from 'src/redux/app/appSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* logErrorAndShowNotification(logErrMsg: string, notifErrMsg: string, err?: any) {
  yield put(
    logEvent({
      level: LOG_LEVEL.ERROR,
      message: `${logErrMsg}, error: ${err?.toString()}`,
      err: err,
    }),
  );

  yield put(showErrorNotification(notifErrMsg));
}

export default logErrorAndShowNotification;
