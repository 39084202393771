import {
  object,
  string,
  ValidationError,
} from 'yup';

import { CountryCode } from 'src/api/orderTypes';
import { DeliveryAddress, DeliveryAddressType } from 'src/types/customer/address';
import { FormFieldError, Customer } from 'src/types/customer/customer';
import { Salutation } from 'src/types/customer/Salutation';


const initialCustomer: Customer = {
  id: '',
  salutation: Salutation.mister,
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  phoneNumbers: [],
  phoneNumber: '',
  billingAddress: {
    street: '',
    careOf: '',
    streetNumber: '',
    zipCode: '',
    city: '',
    countryCode: CountryCode.de,
  },
  deliveryAddresses: [],
  origin: '',
  externalCustomerId: '',
};

export  const validateCustomer = (state: Customer = initialCustomer, deliveryAddress?: DeliveryAddress): FormFieldError => {
  const mandatoryFields = {
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    phone: state.phoneNumber,
    dateOfBirth: state.dateOfBirth,
  };

  const billingAddress = state.billingAddress
    ? {
      billingAddress: {
        street: state.billingAddress.street,
        streetNumber: state.billingAddress.streetNumber,
        city: state.billingAddress.city,
        zipCode: state.billingAddress.zipCode,
        countryCode: state.billingAddress.countryCode,
      },
    }
    : {};


  let optionalFields = {};
  let deliveryAddressSchema = {};
  if (deliveryAddress?.type === DeliveryAddressType.Postal) {
    deliveryAddressSchema = {
      street: string().required(),
      streetNumber: string().required(),
    };
    optionalFields = {
      deliveryAddress: {
        street: deliveryAddress.street,
        streetNumber: deliveryAddress.streetNumber,
        city: deliveryAddress.city,
        zipCode: deliveryAddress.zipCode,
        countryCode: deliveryAddress.countryCode,
      },
    };
  } else if (deliveryAddress?.type === DeliveryAddressType.Packstation) {
    deliveryAddressSchema = {
      postNumber: string().required(),
      packstation: string().required(),
    };
    optionalFields = {
      deliveryAddress: {
        postNumber: deliveryAddress.postNumber,
        packstation: deliveryAddress.packstationNumber,
        city: deliveryAddress.city,
        zipCode: deliveryAddress.zipCode,
        countryCode: deliveryAddress.countryCode,
      },
    };
  }

  const  toValidate = {
    ...mandatoryFields,
    ...billingAddress,
    ...optionalFields,
  };

  const customerSchema = object({
    first: string(),
    lastName: string(),
    email: string().email(),
    phone: string(),
    dateOfBirth: string(),
    billingAddress: object({
      street: string().required(),
      streetNumber: string().required(),
      zipCode: string().required().matches(/[0-9]{4,5}/),
      city: string().required(),
      countryCode: string().required(),
    })
      .default(undefined),
    deliveryAddress: object({
      ...deliveryAddressSchema,
      zipCode: string().required().matches(/[0-9]{4,5}/),
      city: string().required(),
      countryCode: string().required(),
    })
      .default(undefined),
  });

  try {
    customerSchema.validateSync(toValidate, { abortEarly: false });
    return {};
  } catch (error) {
    return error.inner.reduce((ret: FormFieldError, e: ValidationError) => {
      if (e.path) {
        ret[e.path] = ret[e.path] ?? e.type;
      }
      return ret;
    }, {});
  }
};
