import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const customerSuggestionsSelector = createSelectors({
  getSuggestionCustomer({ customer: { suggestions } }: RootStateType) {
    return suggestions.customer;
  },
  getSuggestionCustomers({ customer: { suggestions } }: RootStateType) {
    return suggestions.customers;
  },
  getFormCustomer({ customer: { suggestions } }: RootStateType) {
    return suggestions.customerFormValues;
  },
  getIgnoreCustomerWithExternalId({ customer: { suggestions } }: RootStateType) {
    return suggestions.ignoreCustomerWithExternalId;
  },
});
