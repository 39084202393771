export default {
  'stock.reminder.modalPreTitle': 'Schritt {STEP} von 2',
  'stock.reminder.formTitle': 'E-Mail-Adresse des Kunden eingeben',
  'stock.reminder.successViewTitle': 'E-Mail-Posteingang prüfen',
  'stock.reminder.modalDescription': '„Ich aktiviere gerne die Verfügbarkeitserinnerung für Sie, dafür benötige' +
    ' ich Ihre persönliche E-Mail-Adresse. Wenn Sie bereits Kunde sind, nennen Sie mir bitte zuerst Ihre Kundennummer.“',

  'stock.reminder.externalCustomerId': 'Kundennummer (optional)',
  'stock.reminder.birthDay': 'Geburtsdatum (optional)',
  'stock.reminder.email': 'E-Mail-Adresse',
  'stock.reminder.subscribeButton': 'Bestätigungs-E-Mail schicken',
  'stock.reminder.info': 'Nach Kundennummereingabe, bitte identifiziere den Kunden mit dem Geburtsdatum. ' +
    'Wenn die E-Mail-Adresse in Kundenkonto gespeichert wird, wird sie automatisch ausgefüllt. ' +
    'Wird das Feld nicht befüllt, dann E-Mail-Adresse erfragen.',

  'stock.reminder.subscribeConfirmation': '„Wir haben Ihnen eine E-Mail gesendet.' +
    ' Klicken Sie auf <b>den Bestätigungs-Link</b> in der E-Mail, um die Verfügbarkeitserinnerung zu Ihrem Wunschprodukt zu aktivieren,' +
    ' damit der heutige Preis gesichert wird.\n' +
    'Sobald Ihr Wunschprodukt wieder bestellbar ist, senden wir Ihnen umgehend eine Nachricht.' +
    ' Über den Link in der E-Mail können Sie die ausgewählte Variante in unserem Online-Shop bestellen.“',

  'stock.reminder.subscriptionFailed.409': 'Lagerbestandserinnerung kann nicht abonniert werden: Der Produktbestand ist noch verfügbar',
  'stock.reminder.subscriptionFailed.429': 'Sie haben mehrere Anfragen für eine Bestandserinnerung gesendet',
  'stock.reminder.subscriptionFailed.other': 'Technischer Fehler. Bitte erneut versuchen.',
  
  'stock.reminder.RememberAvailability': 'Verfügbarkeit erinnern',
  'stock.reminder.productSoldOut': '„Dieses Produkt {variant} ist leider aktuell ausverkauft.“',
  'stock.reminder.productReminder': '„Dieses Produkt {variant} ist leider schon ausverkauft. Möchten Sie per E-Mail informiert werden, sobald es wieder verfügbar ist? ' +
    'Sie können es dann zum heutigen Angebotspreis von {price} bestellen.“',
};
