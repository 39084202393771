import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Customer } from 'src/types/customer/customer';


export interface CustomerSearchState {
  loading: boolean;
  customers: Customer[];
  error?: SearchCustomersErrorKey;
}

export enum SearchCustomersErrorKey {
  INSUFFICIENT_CUSTOMERS_SEARCH_CRITERIA = 'INSUFFICIENT_CUSTOMERS_SEARCH_CRITERIA',
  FOUND_TOO_MUCH_CUSTOMERS = 'FOUND_TOO_MUCH_CUSTOMERS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export const customerSearchInitialState: CustomerSearchState = {
  loading: false,
  customers: [],
};

export const fetchCustomers = createAction<object>('app/FETCH_CUSTOMERS');
export type FetchCustomers = ReturnType<typeof fetchCustomers>;

const customerSearchSlice = createSlice({
  name: 'customerSearch',
  initialState: customerSearchInitialState,
  reducers: {
    fetchCustomersSuccess(state, { payload }: PayloadAction<Customer[]>) {
      state.loading = false;
      state.customers = payload;
      state.error = undefined;
    },
    customersError(state, { payload }: PayloadAction<SearchCustomersErrorKey>) {
      state.loading = false;
      state.error = payload;
    },
    resetCustomers() {
      return customerSearchInitialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase( fetchCustomers,(state) => {
        state.loading = true;
        state.customers = [];
        state.error = undefined;
      });
  },
},
);

export const {
  fetchCustomersSuccess,
  customersError,
  resetCustomers,
} = customerSearchSlice.actions;

export default customerSearchSlice.reducer;
