import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { translationMessages } from 'src/i18n';
import { appSelectors } from 'src/redux/app/selectors/appSelector';


interface Props {
  children: React.ReactElement;
}

const LanguageProvider: React.FC<Props> = (props: Props) => {
  const locale = useSelector(appSelectors.getLanguage);

  return (
    <IntlProvider
      locale="en"
      key={locale}
      messages={translationMessages[locale]}
      {...props}
    >
      {React.Children.only(props.children) || null}
    </IntlProvider>
  );
};

export default LanguageProvider;
