import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { closeModal, showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { SubscriptionChangesHistorySortingMode } from 'src/subscriptionProductManagement/types/request/SubscriptionChangesHistoryRequest';
import { Modals } from 'src/types/Modals';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import { subscriptionManagementSelectors } from '../redux/selectors/subscriptionManagementSelectors';
import {
  fetchSubscriptionChangesHistory,
  saveSubscriptionCustomEvent,
  saveSubscriptionCustomEventFailed,
  saveSubscriptionCustomEventSuccess,
} from '../redux/subscriptionManagementSlice';


export function* saveSubscriptionCustomEventSaga({ payload }: PayloadAction<string>) {
  try {
    const api: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);
    const currentSubscriptionId = yield select(subscriptionManagementSelectors.getCurrentSubscriptionId);

    yield call(api.saveSubscriptionCustomEvent, currentSubscriptionId, { note: payload });

    yield put(saveSubscriptionCustomEventSuccess());
    yield put(fetchSubscriptionChangesHistory({ page: 1, sortingMode: SubscriptionChangesHistorySortingMode.NEW_FIRST }));
    yield put(closeModal(Modals.subscriptionCustomEventModal));
    yield put(showSuccessNotification('subscriptionProductManagement.notes.saveNote.successNotificationHint'));
  } catch (err) {
    yield put(saveSubscriptionCustomEventFailed());
    yield call(
      logErrorAndShowNotification,
      'oops... failed to save subscription note',
      'hoppla... abonnementnotiz konnte nicht gespeichert werden',
      err,
    );
  }
}

export function* saveSubscriptionCustomEventWatcher() {
  yield takeLatest(saveSubscriptionCustomEvent.type, saveSubscriptionCustomEventSaga);
}
