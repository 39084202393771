import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderHistoryResponse } from 'src/types/orderhistory/OrderHistoryResponse';
import { OrdersOverviewHistoryRequest } from 'src/types/orderhistory/OrdersOverviewHistoryRequest';


export interface CHOrderEntryError {
  title?: string;
  detail: string;
}

export interface CHOrderEntryState {
  ordersHistory: {
    loading: boolean;
    searchRequestValue?: OrdersOverviewHistoryRequest;
    searchResult?: OrderHistoryResponse;
    error?: CHOrderEntryError;
  }
}

export const initialCHOrderEntryState = {
  ordersHistory: {
    loading: false,
  }
} as CHOrderEntryState;


const CHOrderEntrySlice = createSlice({
  name: 'CHOrderEntrySlice',
  initialState: initialCHOrderEntryState,
  reducers: {
    setChOrderSearchRequest(state, { payload }: PayloadAction<string>) {
      const searchRequest: OrdersOverviewHistoryRequest ={
        externalCustomerId: '11199999',
        externalOrderId: payload,
        page: 0,
        size: 10
      };
      state.ordersHistory.loading = true;
      state.ordersHistory.searchRequestValue = searchRequest;
    },
    setChOrderSearchResult(state, { payload }: PayloadAction<OrderHistoryResponse>) {
      state.ordersHistory.loading = false;
      state.ordersHistory.searchResult = payload;
    },
    setCHOrderEntryFailure(state, action: PayloadAction<CHOrderEntryError>) {
      state.ordersHistory.loading = false;
      state.ordersHistory.error = action.payload;
    },
  },
});

export const { 
  setChOrderSearchRequest, 
  setChOrderSearchResult, 
  setCHOrderEntryFailure 
} = CHOrderEntrySlice.actions;
export const chOrderEntryReducer = CHOrderEntrySlice.reducer;
