import { put, takeLatest } from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  addCustomerConsent,
  AddCustomerConsent,
  addCustomerConsentSuccess,
  createCustomerError,
} from 'src/redux/customer/customerInfoSlice';
import stringify from 'src/utils/stringify';


export function* addCustomerConsentSaga(action: AddCustomerConsent) {
  try {
    yield put(addCustomerConsentSuccess(action.payload));
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not add customer consent ${stringify(action.payload)}`, err }));
    yield put(createCustomerError(400));
  }
}

export function* addCustomerConsentWatcher() {
  yield takeLatest(addCustomerConsent.type, addCustomerConsentSaga);
}
