import formatBirthdayToServicesFormat from 'src/utils/formatters/formatBirthdayToServicesFormat';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformCustomerSearchCriteria(customer: any) {
  const newBirthdayFormat = formatBirthdayToServicesFormat(customer?.date_of_birth);
  return {
    ...customer,
    date_of_birth: newBirthdayFormat,
    first_name: customer?.first_name?.length ?`${customer?.first_name}*` : '',
    last_name: customer?.last_name?.length ?`${customer?.last_name}*` : '',
    street: customer?.street?.length ?`${customer?.street}*` : '',
    city: customer?.city?.length ?`${customer?.city}*` : '',
  };
}
