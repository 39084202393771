import fetchEditedOrderHistoryWatcher  from 'src/editedorder/sagas/fetchEditedOrderHistory';
import reloadPageWatcher from 'src/editedorder/sagas/refreshPage';
import resubmitEditedOrderWatcher from 'src/editedorder/sagas/ResubmitEditedOrder';


export default [
  fetchEditedOrderHistoryWatcher,
  resubmitEditedOrderWatcher,
  reloadPageWatcher,
];
