import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal } from 'src/redux/app/appSlice';
import { fetchCustomer } from 'src/redux/customer/customerInfoSlice';
import { loadInflightOrder } from 'src/redux/inflightOrder/inflightOrderSlice';
import { getBasket, setBasketId } from 'src/redux/order/orderEntrySlice';
import { changeSourceChannel } from 'src/redux/order/sourceChannelSlice';
import { changeVoucher, submitVoucher } from 'src/redux/order/voucherSlice';
import { setSelectedPaymentMethod } from 'src/redux/payment/paymentSlice';
import { handleFetchInflightOrderFailure } from 'src/sagas/inflightOrder/handelFailureState';
import { getOfferAndStoreBasket } from 'src/sagas/offer/getOffer';
import { InflightOrder } from 'src/types/inflightOrder/InflightOrder';
import { Modals } from 'src/types/Modals';
import { SourceChannels } from 'src/types/offer/SalesSource';
import { addBasketId } from 'src/utils/basketId';
import stringify from 'src/utils/stringify';


export function* loadInflightOrderSaga(action: PayloadAction<InflightOrder>) {
  try {
    const { order } = action.payload;
    yield put(changeSourceChannel(SourceChannels.IVR));
    if (order.step) {
      yield put(openModal(Modals.rejectedInflightOrder));
    }
    if (order.basketId) {
      addBasketId(order.basketId);
      yield put(setBasketId(order.basketId));
      yield put(getBasket());
    }
    if (order.customer) {
      yield put(fetchCustomer(order.customer.customerNumber));
    }
    if (order.voucherCode) {
      yield put(changeVoucher(order.voucherCode));
      yield put(submitVoucher());
    }
    if (order.paymentMethod) {
      yield call(getOfferAndStoreBasket);
      yield put(setSelectedPaymentMethod(order.paymentMethod));
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not load inflight order ${stringify(action.payload)}`, err }));
    yield call(handleFetchInflightOrderFailure, err);
  }
}

export default function* loadInflightOrderWatcher() {
  yield takeLatest(loadInflightOrder.type, loadInflightOrderSaga);
}
