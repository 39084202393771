import { formatToTimeZone } from 'date-fns-timezone';

import { DEFAULT_TIMEZONE } from 'src/constants/date';
import { SubscriptionsOverviewFetchFilters } from 'src/subscriptionProductManagement/redux/subscriptionManagementSlice';

import { KeywordType } from './SubscriptionsOverviewRequest';


export interface EditSubscriptionDeliveryDatesRequest {
  subscriptions: string[];
  nextDeliveryDate: string;
  filters: SubscriptionsOverviewFetchFilters;
  note?: string;
  selectAll?: boolean;
  subscriptionsTotalToEdit?: number;
}

export function createEditSubscriptionsDeliveryDatesRequest(
  subscriptions: string[],
  excludeSubscriptionIds: string[],
  nextDeliveryDate: Date,
  filters: SubscriptionsOverviewFetchFilters,
  note?: string,
  selectAll?: boolean,
  subscriptionsTotalToEdit?: number,
): EditSubscriptionDeliveryDatesRequest {
  const request: EditSubscriptionDeliveryDatesRequest = {
    subscriptions: selectAll ? [] : subscriptions,
    nextDeliveryDate: formatToTimeZone(nextDeliveryDate, 'YYYY-MM-DD', { timeZone: DEFAULT_TIMEZONE }),
    filters: {
      ...filters,
      keywords: !filters.keywords ? '' : filters.keywords,
      keywordType: !filters.keywordType ? KeywordType.DELIVERY_PRODUCT_NO : filters.keywordType,
      excludeSubscriptionIds: excludeSubscriptionIds?.length > 0 ? excludeSubscriptionIds : undefined,
    },
    note,
  };
  if (selectAll) {
    request.selectAll = selectAll;
    request.subscriptionsTotalToEdit = subscriptionsTotalToEdit;
  }
  return request;
}
