import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SearchParams } from 'src/types/product/product';
import { SearchSuggestionResponse } from 'src/types/product/SearchSuggestionResponse';


export interface ProductSuggestionsState {
  loading: boolean;
  data: SearchSuggestionResponse[];
  error?: boolean;
  errorCode?: number;
}

export const productSuggestionsInitialState: ProductSuggestionsState = {
  loading: false,
  data: [],
};

export const fetchProductSuggestionsResults = createAction<SearchParams>('productSuggestions/fetchProductSuggestionsResults');
export type FetchProductSuggestionsResults = ReturnType<typeof fetchProductSuggestionsResults>;

const productSuggestionsSlice = createSlice({
  name: 'productSuggestions',
  initialState: productSuggestionsInitialState,
  reducers: {
    fetchProductSuggestionsResultsInProgress(state) {
      state.loading = true;
    },
    fetchProductSuggestionsResultsSuccess(state, { payload }: PayloadAction<SearchSuggestionResponse[]>) {
      state.data = payload;
      state.loading = false;
    },
    fetchProductSuggestionsResultsError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    emptyProductSuggestionsResults(state) {
      state.loading = false;
      state.data = [];
      state.error = undefined;
      state.errorCode = undefined;
    },
  },
});

export const {
  fetchProductSuggestionsResultsInProgress,
  fetchProductSuggestionsResultsSuccess,
  fetchProductSuggestionsResultsError,
  emptyProductSuggestionsResults,
} = productSuggestionsSlice.actions;

export default productSuggestionsSlice.reducer;
