import { ProductStock } from 'src/types/product/product';


export const mergeProductsStocks = (
  oldProductStock: ProductStock[],
  newProductStock: ProductStock[],
): ProductStock[] => {
  const unupdatedProductStock = oldProductStock.filter((oldProduct: ProductStock) => {
    return newProductStock.every(value => value.sku !== oldProduct.sku);
  });
  return [...unupdatedProductStock, ...newProductStock];
};
export default mergeProductsStocks;
