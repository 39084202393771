import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ProductStock } from 'src/types/product/product';
import { StockResponse } from 'src/types/product/StockResponse';


export interface ProductStockState {
  loading: boolean;
  data: ProductStock[];
  error?: boolean;
  errorCode?: number;
}

export const productStockInitialState: ProductStockState = {
  loading: false,
  data: [],
};

export const fetchProductStock = createAction<string[]>('productStock/fetchProductStock');
export type FetchProductStock = ReturnType<typeof fetchProductStock>;

const productStockSlice = createSlice({
  name: 'productStock',
  initialState: productStockInitialState,
  reducers: {
    fetchProductStockInProgress(state) {
      state.loading = true;
    },
    fetchProductStockSuccess(state, { payload }: PayloadAction<StockResponse>) {
      state.data = payload.stock;
      state.loading = false;
    },
    fetchProductStockError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.error = true;
      state.errorCode = payload;
    },
    resetProductStock() {
      return productStockInitialState;
    },
  },
});

export const {
  fetchProductStockInProgress,
  fetchProductStockSuccess,
  fetchProductStockError,
  resetProductStock,
} = productStockSlice.actions;

export default productStockSlice.reducer;
