export const LAST_PIN_CHECK_KEY = 'lastPinChallengeCheck';

export function setLastPinChallengeCheck(customerId?: string) {
  if(customerId) {
    sessionStorage.setItem(LAST_PIN_CHECK_KEY, customerId);
  }
}

export function resetLastPinChallengeCheck() {
  sessionStorage.removeItem(LAST_PIN_CHECK_KEY);
}

export function isLastPinChallengeCheckConfirmed(customerId?: string): Boolean {
  const token = sessionStorage.getItem(LAST_PIN_CHECK_KEY);
  return token ? token === customerId : false;
}