export interface CrossSellResponse {
  baseProductNo: string
  crossSells: CrossSellInfo[];
  periodicalCrossSells: CrossSellInfo[];
}

export interface CrossSellInfo {
  id: number;
  productNo: string;
  priority?: number;
  type: CrossSellType;
  scripting?: ScriptingType;
  feature?: CrossSellFeature;
  leadProductSku?: string;
  leadProductName?: string;
  triggerType?: CrossSellTrigger;
  sourceType: String;
  averageRating?: number;
}

export enum CrossSellTrigger {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum CrossSellType {
  ADT = 'ADT',
  NORMAL = 'NORMAL',
  STUNDE = 'STUNDE',
  EVENT = 'EVENT',
}

export enum ScriptingType {
  ADT = 'ADT',
  ADT_ACTIV = 'ADT_ACTIV',
  JDW = 'JDW',
  JDW_ACTIV = 'JDW_ACTIV',
  ERGANZEND = 'ERGANZEND',
  PERFEKT = 'PERFEKT',
  PREISREDUZIERUNG = 'PREISREDUZIERUNG',
  MARKE = 'MARKE',
  SAISON = 'SAISON',
  STUNDE = 'STUNDE',
  SONDERANGEBOTE = 'SONDERANGEBOTE',
  UPGRADE_SET = 'UPGRADE_SET',
  ADS_AKTIV = 'ADS_AKTIV',
  PDM_AKTIV = 'PDM_AKTIV',
  PREMIERE = 'PREMIERE',
}

export enum CrossSellFeature {
  ONE_WAY = 'ONE WAY',
  TWO_WAY = 'TWO WAY',
  KOLLEKTION = 'KOLLEKTION',
  UPGRADE = 'UPGRADE'
}

export const DISPLAYED_CROSS_SELL_PRODUCTS_MAX = 3;

export interface UpgradeItem {
  sku: string;
  upgradeSku: string;
  crossSell?: CrossSellInfo;
}
