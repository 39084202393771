import { RootStateType } from 'src/constants/types';
import { CreateOrderCustomerResponse } from 'src/types/customer/CreateOrderCustomerResponse';
import { OfferItem } from 'src/types/offer/Offer';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { DeliveryTimeMessageType } from 'src/types/product/DeliveryTime';
import { createSelectors } from 'src/utils/state';


export const newOrderSelector = createSelectors({
  getOrderResultItems(state: RootStateType): OfferItem[] {
    return state.order.newOrder.orderResponse?.items ?? [];
  },
  getOrderCustomer({ order: { newOrder } }: RootStateType): CreateOrderCustomerResponse  | undefined {
    return newOrder.orderResponse?.customer;
  },
  getOrderSummary(state: RootStateType) {
    const order = state.order.newOrder.orderResponse;
    return (
      order && {
        pregeneratedErpNumber: order.pregeneratedErpNumber,
        currency: order.currency,
        totalPrice: order.totalPrice,
        totalProductPrice: order.totalProductPrice,
        totalSavings: order.totalSavings,
        voucher: order.voucher?.value,
        shippingCost: order.delivery.price,
        ...(order.payment?.method === PaymentMethod.installments ||
        order.payment?.method === PaymentMethod.installmentsFactoring
          ? { totalInterest: order.payment?.totalInterest }
          : {}),
      }
    );
  },
  getOrderDeliveryAddress({ order: { newOrder } }: RootStateType) {
    return newOrder.orderResponse?.deliveryAddress;
  },
  getOrderpayment({ order: { newOrder } }: RootStateType) {
    return newOrder.orderResponse?.payment;
  },
  getOrderId({ order: { newOrder } }: RootStateType) {
    return newOrder.orderResponse?.id || '';
  },
  newOrderLoadingStatus({ order: { newOrder } }: RootStateType) {
    return newOrder.loading;
  },
  getLastLoadingStatus({ order: { newOrder } }: RootStateType) {
    return newOrder.lastLoading;
  },
  hasTelephoneNotification({ order: { newOrder } }: RootStateType) {
    return !!newOrder.orderDeliveryTimes.find(
      orderDeliveryTime => orderDeliveryTime.type === DeliveryTimeMessageType.fourteenDaysDelay,
    );
  },
});
