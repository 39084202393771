import { AxiosRequestConfig } from 'axios';

import { LocalStorageKeys } from 'src/constants/localStorageKeys';

import { RequestInterceptor } from '../HttpClient';


export const X_AUTHORITZATION_HEADER = 'X-Authorization-Header';
export const CUSTOM_AUTH_HEADER = 'X-Authorization'; // Used by logging-lambda to facilitate filtering logs by session

export function getAuthHeader(): string {
  return `Bearer ${localStorage.getItem(LocalStorageKeys.IdToken)}`;
}

export const xAuthorizationHeaderInterceptor: RequestInterceptor = {
  onFulfilled(config: AxiosRequestConfig) {
    config.headers = {
      ...config.headers,
      [X_AUTHORITZATION_HEADER]: getAuthHeader(),
    };
    return config;
  },
};
