import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  AvailabalePaymentMethodPayload,
  PaymentState,
  resetSelectedPaymentMethod,
  setAvailablePaymentMethods,
  setSelectedPaymentMethod,
} from 'src/redux/payment/paymentSlice';
import scrollToTop from 'src/utils/scrollToTop';
import stringify from 'src/utils/stringify';
import isPaymentMethodAvailable from 'src/utils/validators/isPaymentMethodAvailable';

import { getOfferIfOrderLineItemsExist } from '../offer/getOffer';


export function* resetSelectedPaymentMethodSaga(action: PayloadAction<AvailabalePaymentMethodPayload>) {
  try {
    const payment: PaymentState = yield select(state => state.payment);
    const selectedPaymentMethod = payment.selectedPaymentMethod;

    if (selectedPaymentMethod && !isPaymentMethodAvailable(selectedPaymentMethod, action.payload.payments)) {
      if (!action.payload.skipGetOffer) {
        yield put(setSelectedPaymentMethod());
        scrollToTop();
        yield call(getOfferIfOrderLineItemsExist);
      } else {
        yield put(resetSelectedPaymentMethod());
        scrollToTop();
      }
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not reset selected payment, available payments: ${stringify(action.payload)}`, err }));
  }
}

export default function* setAvailablePaymentMethodsWatcher() {
  yield takeEvery(setAvailablePaymentMethods.type, resetSelectedPaymentMethodSaga);
}
