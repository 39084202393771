import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiSubscriptionsManagement } from 'src/api/apiSubscriptionsManagement';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { createSubscriptionsOverviewFetchRequest, SubscriptionsOverviewRequest } from 'src/types/subscription/SubscriptionsOverviewRequest';
import { SubscriptionsOverviewResponse, SUBSCRIPTIONS_OVERVIEW_SIZE } from 'src/types/subscription/SubscriptionsOverviewResponse';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import { subscriptionManagementSelectors } from '../redux/selectors/subscriptionManagementSelectors';
import {
  clearSubscriptionsOverviewFetchFilters,
  clearSubscriptionsSearchFilters,
  clearSubscriptionsSearchKeywordsFilter,
  fetchSubscriptionsOverview,
  fetchSubscriptionsOverviewError,
  fetchSubscriptionsOverviewSuccess,
  loadSingleSubscription,
  resetLoadingStatus,
  setCurrentSubscriptionDetails,
  setSortBy,
  setSubscriptionsOverviewFetchFilters,
  SubscriptionOverviewView,
  SubscriptionsOverviewFetchFilters,
  viewSubscriptions,
} from '../redux/subscriptionManagementSlice';


export function* fetchSubscriptionsOverviewSaga(action: PayloadAction) {
  try {
    const currenttotalSubscriptions: number = yield select(subscriptionManagementSelectors.gettotalSubscriptions);
    const offset: number = yield select(subscriptionManagementSelectors.getOffset);
    const filters: SubscriptionsOverviewFetchFilters = yield select(subscriptionManagementSelectors.getSubscriptionsOverviewFetchFilters);
    const apiSubscriptionsManagement: ApiSubscriptionsManagement = yield getContext(SagaContextItem.apiSubscriptionsManagement);

    const request: SubscriptionsOverviewRequest = createSubscriptionsOverviewFetchRequest(offset, SUBSCRIPTIONS_OVERVIEW_SIZE, filters, currenttotalSubscriptions);

    const response: SubscriptionsOverviewResponse = yield call(
      apiSubscriptionsManagement.getSubscriptionsOverview, request,
    );

    const { page, size, total } = response;

    const subscriptions = (action.type === fetchSubscriptionsOverview.type)
      ? (yield select(subscriptionManagementSelectors.getSubscriptionsOverview)).concat(response.result)
      : response.result;


    yield put(fetchSubscriptionsOverviewSuccess({
      page,
      size,
      subscriptions,
      totalSubscriptions: total,
      hasMore: response.result.length === SUBSCRIPTIONS_OVERVIEW_SIZE,
    }));

    if ( response.total === 1 ) {
      yield put(
        setCurrentSubscriptionDetails({
          subscription: response.result[0],
          view: SubscriptionOverviewView.ABO_DETAILS,
        }),
      );
    }
  } catch (err) {
    const errorCode = (err && err.response && err.response.status) || 400;
    yield call(
      logErrorAndShowNotification,
      'Oops... Error fetching subscriptions overview.',
      'Hoppla... Fehler beim Abrufen der Abonnementübersicht',
      err,
    );

    yield put(fetchSubscriptionsOverviewError(errorCode));
  } finally {
    yield put(resetLoadingStatus());
  }
}

export default function* fetchSubscriptionsOverviewWatcher() {
  yield takeLatest(fetchSubscriptionsOverview.type, fetchSubscriptionsOverviewSaga);
  yield takeLatest(setSubscriptionsOverviewFetchFilters.type, fetchSubscriptionsOverviewSaga);
  yield takeLatest(clearSubscriptionsOverviewFetchFilters.type, fetchSubscriptionsOverviewSaga);
  yield takeLatest(clearSubscriptionsSearchKeywordsFilter.type, fetchSubscriptionsOverviewSaga);
  yield takeLatest(clearSubscriptionsSearchFilters.type, fetchSubscriptionsOverviewSaga);
  yield takeLatest(viewSubscriptions.type, fetchSubscriptionsOverviewSaga);
  yield takeLatest(loadSingleSubscription.type, fetchSubscriptionsOverviewSaga);
  yield takeLatest(setSortBy.type, fetchSubscriptionsOverviewSaga);
}
