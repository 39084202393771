import {
  SubscriptionInfo,
  SubscriptionItemDetails,
  SubscriptionItemStatus,
  SubscriptionOfferProduct,
  SubscriptionProductOrderResult,
} from 'src/types/subscription/SubscriptionHistoryResponse';


export interface SubscriptionProductResponse {
  id: string;
  contractNumber: number;
  externalCustomerId: string;
  status: SubscriptionItemStatus;
  details: SubscriptionItemDetails;
  aboInfo: SubscriptionInfo;
  previousOrder?: SubscriptionProductOrderResult;
  ordersCount: number;
  createdAt: string;
  updatedAt: string;
  product: SubscriptionOfferProduct;
  note?: string;
}

export interface SubscriptionsOverviewResponse {
  result: SubscriptionProductResponse[];
  page: number;
  size: number;
  total: number;
}

export interface SubscriptionsCustomersInfoResponse {
  customersInfo: CustomerInfo[];
}

export interface CustomerInfo {
   externalCustomerId: string,
   firstName: string,
   lastName: string
}

export const SUBSCRIPTIONS_OVERVIEW_SIZE = 10;
