import { createSelector } from '@reduxjs/toolkit';

import { RootStateType } from 'src/constants/types';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { FailedRecreateOrderStep } from 'src/redux/recreateOrder/recreateOrderSlice';
import { DeliveryAddressType } from 'src/types/customer/address';
import { CustomerType } from 'src/types/voucher/EmployeeDiscount';
import { createSelectors } from 'src/utils/state';
import { removeSpaces } from 'src/utils/string/removeSpaces';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


const {
  hasCustomer,
  hasSelectedDefaultPaymentMethod,
  isCustomerLocked,
  isCustomerHasEmail,
  getSelectedDeliveryAddress,
} = customerInfoSelectors;

const { isInternetChannel } = salesChannelSelector;

export const orderEntrySelector = createSelectors({

  getSubscribableOrderItemsDetails(state: RootStateType) {
    return state.order.orderEntry.subscribableOrderLineItemsDetails;
  },
  hasOrderItems(state: RootStateType) {
    return state.order.orderEntry.orderLineItems.length > 0;
  },
  hasStockWarning(state: RootStateType) {
    return state.order.orderEntry.orderLineItems.some(
      item => item.reserved === false || item.isSellable === false,
    );
  },
  getOfferTotalPrice(state: RootStateType) {
    return state.order.orderEntry.offer?.totalPrice;
  },
  getCanUsePackstationAddress(state: RootStateType) {
    return state.order.orderEntry.offer?.canUsePackstationAddress !== false;
  },
  getBasketItemsStatus(state: RootStateType) {
    return state.order.orderEntry.basketItemsStatus;
  },
  getBasketId(state: RootStateType) {
    return state.order.orderEntry.basketId;
  },
  getCurrency(state: RootStateType) {
    return state.order.orderEntry.offer?.currency;
  },
  getOffer(state: RootStateType) {
    return state.order.orderEntry.offer;
  },
  getEmployeeDiscount(state: RootStateType) {
    return state.order.orderEntry.offer?.employeeDiscount;
  },
  isThereAnActionInProgress(state: RootStateType) {
    return (
      state.order.orderEntry.getBasketLoading ||
      state.order.orderEntry.searchLoading ||
      state.product.search.loading ||
      Object.keys(state.order.orderEntry.basketItemsStatus).length > 0
    );
  },
  canShowGlobalLoadingIcon(state: RootStateType) {
    return state.order.orderEntry.globalLoading;
  },
  getOrderEntryBasketLoading(state: RootStateType) {
    return state.order.orderEntry.getBasketLoading;
  },
  getOrderLineItems(state: RootStateType) {
    return state.order.orderEntry.orderLineItems;
  },
  getQuantityFields(state: RootStateType) {
    return state.order.orderEntry.quantityFields;
  },
  getSearchTerm(state: RootStateType) {
    return state.order.orderEntry.searchTerm;
  },
  getOrderDisplayLoading(state: RootStateType) {
    return state.order.orderDisplay.loading;
  },
  getOrderDisplayError(state: RootStateType) {
    return state.order.orderDisplay.error;
  },
  getOrderDisplayErrorCode(state: RootStateType) {
    return state.order.orderDisplay.errorCode;
  },
  getPregeneratedErpNumber(state: RootStateType) {
    return state.order.orderEntry.preGeneratedErpNumber;
  },
  getOrderDisplayOrderResponse(state: RootStateType) {
    return state.order.orderDisplay.orderResponse;
  },
  getOrderSalesOffice(state: RootStateType) {
    return state.order.salesOffice;
  },
  getOrderSalesChannel(state: RootStateType) {
    return state.order.salesChannel;
  },
  getOrderSourceChannel(state: RootStateType) {
    return state.order.sourceChannel;
  },
  getVoucher(state: RootStateType) {
    return state.order.voucher;
  },
  getVoucherLoading(state: RootStateType) {
    return state.order.voucher.loading;
  },
  getVoucherCode(state: RootStateType) {
    return state.order.voucher.code;
  },
  getPendingRedeemVouchers(state: RootStateType) {
    return state.order.voucher.pendingRedeemVouchers;
  },
  getNonRedeemableVouchers(state: RootStateType) {
    return state.order.voucher.nonRedeemableVouchers;
  },
  getRedeemableVouchers(state: RootStateType) {
    return state.order.voucher.redeemableVouchers;
  },
  getGlobalPaymentError(state: RootStateType) {
    return state.recreateOrder.errors.find(e => e.step === FailedRecreateOrderStep.SET_PAYMENT)
      ?.detail;
  },
  getGlobalDeliveryAddressError(state: RootStateType) {
    return state.recreateOrder.errors.find(
      e => e.step === FailedRecreateOrderStep.SET_DELIVERY_ADDRESS,
    )?.detail;
  },
  getGlobalVoucherError(state: RootStateType) {
    return state.recreateOrder.errors.find(e => e.step === FailedRecreateOrderStep.REDEEM_VOUCHER)
      ?.detail;
  },
  getGlobalServiceVoucherError(state: RootStateType) {
    return state.recreateOrder.errors.find(
      e => e.step === FailedRecreateOrderStep.SET_SERVICE_VOUCHER,
    )?.detail;
  },
  getGlobalBasketErrors(state: RootStateType) {
    return state.recreateOrder.errors.find(e => e.step === FailedRecreateOrderStep.LOAD_BASKET)
      ?.detail;
  },
  // If customer has already banking info then no need to take them from state; since they are unchangeable
  getCustomerBankingDetailsState(state: RootStateType) {
    const bankingInfo = state.payment.bankingInfo;
    const customer = state.customer.info.customerResponse;
    const existedBankingDetails = customer?.bankingDetails;

    const accountHolder =
      existedBankingDetails?.accountHolder ??
      trimStringOrNull(bankingInfo.accountHolder) ??
      (customer?.firstName && customer.lastName
        ? `${customer.firstName} ${customer.lastName}`
        : '');

    const isExisted = !!existedBankingDetails;

    return {
      isExisted: isExisted,
      accountHolder: accountHolder,
      bankName: existedBankingDetails?.bankName ?? bankingInfo.bankName,
      iban: existedBankingDetails?.maskedIban ?? removeSpaces(bankingInfo.iban).toUpperCase(),
      usesPrefilledIban: isExisted,
      ibanValid: isExisted || bankingInfo.ibanValid,
      firstTime: !(isExisted || !bankingInfo.firstTime),
      ibanError: bankingInfo.ibanError,
    };
  },
  getVoucherRestrictions(state: RootStateType) {
    return state.order.orderEntry.offer?.voucher?.restrictions;
  },
  isSocialLivePartner(state: RootStateType){
    return state.order.orderEntry.offer?.employeeDiscount?.customerType === CustomerType.CREATOR;
  },
  isReklaOrder(state: RootStateType) {
    return state?.order.orderEntry.isRekla;
  },
  isReklaOrderFilledWithItem(state: RootStateType) {
    return state?.order.orderEntry.isRekla && state.order.orderEntry.orderLineItems.length > 0;
  }
});

export const canOrderNow = createSelector(
  orderEntrySelector.hasOrderItems,
  orderEntrySelector.hasStockWarning,
  hasCustomer,
  hasSelectedDefaultPaymentMethod,
  isCustomerLocked,
  isCustomerHasEmail,
  isInternetChannel,
  orderEntrySelector.getCanUsePackstationAddress,
  getSelectedDeliveryAddress,
  (
    items,
    stockWarning,
    customer,
    paymentMethod,
    customerLocked,
    customerHasEmail,
    internetChannel,
    canUsePackstationAddress,
    selectedAddress,
  ) => {
    if (!canUsePackstationAddress && selectedAddress?.type === DeliveryAddressType.Packstation) {
      return false;
    }
    if (internetChannel) {
      return (
        items && !stockWarning && customer && paymentMethod && !customerLocked && customerHasEmail
      );
    }
    return items && !stockWarning && customer && paymentMethod && !customerLocked;
  },
);

export const resonsForDisabledConfirm = createSelector(
  orderEntrySelector.hasOrderItems,
  orderEntrySelector.hasStockWarning,
  hasCustomer,
  hasSelectedDefaultPaymentMethod,
  isCustomerLocked,
  isInternetChannel,
  isCustomerHasEmail,
  orderEntrySelector.getCanUsePackstationAddress,
  getSelectedDeliveryAddress,
  (
    items,
    stockWarning,
    customer,
    paymentMethod,
    customerLocked,
    internetChannel,
    customerHasEmail,
    canUsePackstationAddress,
    selectedAddress,
  ) => [
    ...(!items ? ['order.basketIsEmpty'] : []),
    ...(stockWarning ? ['order.stockWarning'] : []),
    ...(!paymentMethod ? ['order.chosePaymentMethod'] : []),
    ...(!customer ? ['order.enterCustomerData'] : []),
    ...(customerLocked ? ['customer.locked'] : []),
    ...(customer &&
    !canUsePackstationAddress &&
    selectedAddress?.type === DeliveryAddressType.Packstation
      ? ['order.choseDeliveryAddress']
      : []),
    ...(customer && !customerHasEmail && internetChannel ? ['order.CustomerWithoutEmail'] : []),
  ],
);

export const getOrderLineItemBySku = (sku: string) => (state: RootStateType) =>
  state.order.orderEntry.orderLineItems.find(item => item.variant.sku === sku);

export const getOrderLineItemByBaseProductNo = (mainProductSku: string | undefined) => (state: RootStateType) =>
  state.order.orderEntry.orderLineItems.find(product => product.baseProductNo === mainProductSku);

export const getOrderLineItemById = (id: string) => (state: RootStateType) =>
  state.order.orderEntry.orderLineItems.find(item => item.id === id);
