import { createTheme } from '@material-ui/core/styles';

import theme from 'src/styles/theme.module.scss';


const scalingFactor = 16;

export const baseTheme = createTheme({
  // add material ui theme here
  // changing colors
  palette: {
    primary: {
      main: theme.primary,
    },
    secondary: {
      main: theme.secondary,
    },
    background: {
      default: theme.lightGrey,
    },
    error: {
      main: theme.error,
    },
  },
  // globally overriding components css properties
  overrides: {
    MuiTabs: {
      root: {
        minHeight: 'auto',
      },
    },
    MuiTab: {
      root: {
        minHeight: 'auto',
        padding: 8,
        textTransform: 'none',
        color: theme.pickledBlueWood,
        fontSize: '12px',
        lineHeight: 1.67,
        '&:focus': {
          opacity: 1,
        },
        '&$selected': {
          backgroundColor: theme.white,
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
          fontSize: '16px',
          lineHeight: 1.25,
          fontWeight: 'bold',
        },
      },
      textColorSecondary: {
        color: theme.secondary,
      },
      wrapper: {
        flexDirection: 'row',
        alignItems: 'normal',
        marginTop: '6px',
      },
      labelIcon: {
        minHeight: 'unset',
        paddingTop: '2px',
        paddingBottom: '2px',
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: '20px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
      },
      elevation4: {
        boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
      },
    },
    MuiCardActions: {
      root: {
        padding: `${scalingFactor / 4 }px ${scalingFactor}px`,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding:'8px',
      },
      root: {
        borderRadius: 0,
        '&$focused': {
        },
      },
    },

    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${theme.secondary}`,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(10px, 10px) scale(1)',
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '100%',
        '@media (min-width: 600px)': {
          minHeight: '100%',
        },
      },
    },
    // Style sheet name
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        textTransform: 'none',
        borderRadius: '0px',
      },
      contained: {
        '&$disabled': {
          backgroundColor: theme.alto,
        },
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiSwitch: {
      switchBase: {
        '&$checked + $track': {
          opacity: 1,
          backgroundColor: theme.primary,
        },
      },
      thumb: {
        backgroundColor: theme.gray,
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor : 'inherit',
      },
      sizeSmall: {
        padding: '8px',
      },
      head: {
        color: theme.scorpion,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: theme.secondary,
      },
    },
    MuiSnackbarContent: {
      root: {
        '&[class*="variantInfo"]': {
          backgroundColor: theme.info,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(37, 37, 37, 0.75)',
      },
    },
  },
  typography: {
    fontFamily: [
      'Lato',
    ].join(','),
  },
});
