import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { loadAuthenticatedAgentInfo, setAgentInfo } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import JWTParser  from 'src/utils/JWTParser';
import Log from 'src/utils/log';
import { capitalizeFirstLetter } from 'src/utils/string/capitalizeFirstLetter';


export function* loadAuthenticatedAgent() {
  const jwtToken = yield select(appSelectors.getJwt);
  try {
    const decodedJwt = JWTParser(jwtToken);

    const agentUsername =  decodedJwt['cognito:username'] ?? '';
    const roles =  decodedJwt['cognito:roles']?.map(e => e.split('role/')[1]) || [];
    const agentFullName = `${capitalizeFirstLetter(decodedJwt.given_name)} ${capitalizeFirstLetter(decodedJwt.family_name)}`;

    // existing problem: duplicate tracking, if the agent reload the page or navigate between pages
    // possible solution: save a boolean in localstorage to indicate if agent is already tracked...
    if(agentUsername) {
      yield put(setAgentInfo({
        username: agentUsername,
        roles: roles,
        fullName: agentFullName,
      }));
    } else {
      yield put(logErrorEvent({ message: `Could not extract agent username, jwtToken: ${jwtToken}` }));
    }
  } catch (err) {
    Log.error(err);
  }
}

export default function* loadAuthenticatedAgentWatcher() {
  yield takeLatest(loadAuthenticatedAgentInfo.type, loadAuthenticatedAgent);
}
