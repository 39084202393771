import { AuthorizedRoles } from 'src/constants/authorizedRoles';


export function authorizedRole(route: string, cognitoRoles: string[] ): boolean {
  if (!AuthorizedRoles[route]?.roles?.length) {
    return true;
  }
  return (
    AuthorizedRoles[route].roles.some(
      index => cognitoRoles.indexOf(index) !== -1,
    )
  );
}
