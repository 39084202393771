import countryLocalStorage from 'src/utils/countryLocalStorage';

import { getLocaleFromCountryCode } from './getLocaleFromCountryCode';


const getFactFinderChannel = (): string => {
  const locale = getLocaleFromCountryCode(countryLocalStorage.get());

  return locale.toLowerCase().replace('_', '') || 'dede';
};

export default getFactFinderChannel;
