import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal, showErrorNotification } from 'src/redux/app/appSlice';
import { customerInfoSelectors, isPhoneNumberRequired } from 'src/redux/customer/selectors/customerInfoSelectors';
import { setOrderDeliveryTimes } from 'src/redux/order/newOrderSlice';
import { addOrderItems } from 'src/redux/order/orderEntrySlice';
import { Modals } from 'src/types/Modals';
import { OrderItem } from 'src/types/offer/OrderItem';
import { getDeliveryTimeMessage } from 'src/utils/getters/getDeliveryTimes';
import stringify from 'src/utils/stringify';

import { getItemsDeliveryTimesSaga } from './getItemsDeliveryTimesSaga';


export function* deliveryTimesSaga(items: OrderItem[], ids: string[][]) {
  try {
    const orderItems = yield call(getItemsDeliveryTimesSaga, items, ids);
    if (orderItems.length > 0) {
      const orderDeliveryTimes = yield call(getDeliveryTimeMessage, orderItems);
      yield put(addOrderItems(orderItems));
      yield put(setOrderDeliveryTimes(orderDeliveryTimes));
      const { hasCustomer } = customerInfoSelectors;
      const customer = yield select(hasCustomer);
      const phoneNumberRequired = yield select(isPhoneNumberRequired);
      if (customer && phoneNumberRequired) {
        yield put(openModal(Modals.telephoneNotification));
      }
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could process delivery_times, items: ${stringify(items)}, ids: ${stringify(ids)}`, err }));
    yield put(showErrorNotification());
  }
}
