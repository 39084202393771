import {
  call,
  getContext,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { ApiProduct } from 'src/api/apiProduct';
import {
  fetchProductStock,
  FetchProductStock,
  fetchProductStockError,
  fetchProductStockInProgress,
  fetchProductStockSuccess,
} from 'src/redux/product/productStockSlice';
import { productStockSelector } from 'src/redux/product/selectors/productStockSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { StockResponse } from 'src/types/product/StockResponse';
import formatSKUs from 'src/utils/formatters/formatSKUs';
import splitArrayIntoChunks from 'src/utils/formatters/splitArrayIntoChunks';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import mergeProductsStocks from 'src/utils/mergeProductsStocks';


const { getStocks } = productStockSelector;

const maxBpnosPerRequest = 60;

export function* productStockSaga(action: FetchProductStock) {
  try {
    yield put(fetchProductStockInProgress());

    const baseProductNos = formatSKUs(action.payload);

    const apiProduct: ApiProduct = yield getContext(SagaContextItem.apiProduct);
    const splitBpnos = splitArrayIntoChunks(baseProductNos, maxBpnosPerRequest);
    const productsStock: StockResponse = { stock: [] };
    for (const bpnos of splitBpnos) {
      const response = yield call(apiProduct.getProductsStock, bpnos);
      productsStock.stock.push(...response.stock);
    }

    const listOfOldStocks = yield select(getStocks);
    const stocks = yield call(mergeProductsStocks, listOfOldStocks, productsStock.stock);

    yield put(fetchProductStockSuccess({
      stock: stocks,
    }));
  } catch (err) {
    const errorCode = err?.response?.status || 400;
    yield put(fetchProductStockError(errorCode));
    yield call(
      logErrorAndShowNotification,
      `Could not fetch stocks ${JSON.stringify(action.payload)}`,
      'product.stock.fetch.error',
      err,
    );
  }
}

export default function* fetchProductStockWatcher() {
  yield takeEvery(fetchProductStock.type, productStockSaga);
}
