import { AxiosInstance } from 'axios';
import { decamelizeKeys } from 'humps';

import { getHttpClient } from 'src/api/httpClient/HttpClient';
import { localeHeaderInterceptor } from 'src/api/httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from 'src/api/httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from 'src/api/httpClient/interceptors/xAuthorizationHeaderInterceptor';
import { Endpoints } from 'src/constants/endpoints';
import { OrderResponse } from 'src/types/offer/OrderResponse';
import { EditedOffer, EditedOrder } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';


export interface ApiEditOrder {
  client: AxiosInstance;
  initEditOrder: (externalOrderId: string) => Promise<EditedOrder>;
  addPosition: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  updatePosition: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  enableWeeTakeBack: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  cancelPosition: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  removePosition: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  updatePositionPriceDate: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  switchDeliveryAddress: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  changePayment: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  submitOrder: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<OrderResponse>;
  clearOrder: (externalOrderId: string) => Promise<string>;
  redeemVoucher: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  removeVoucher: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
  changeServiceVoucher: (editOrderActionWithOrderId: EditOrderActionRequestWithOrderId) => Promise<EditedOffer>;
}

export function getApiEditOrder(baseUrl: string): ApiEditOrder {
  const axiosInstance = getHttpClient({
    baseUrl,
    transform: {
      camelize: true,
    },
    headers: { 'Content-Type': 'application/json' },
    interceptors: {
      response: refreshTokenInterceptor,
      request: [
        localeHeaderInterceptor,
        xAuthorizationHeaderInterceptor,
      ],
    },
  });

  return {
    client: axiosInstance,
    initEditOrder: async (externalOrderId: string) => {
      const response = await axiosInstance.post<EditedOrder>(`${ Endpoints.editOrder }/init/${ externalOrderId }`);
      return response.data as EditedOrder;
    },
    addPosition: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(
        `${ Endpoints.editOrder }/${ action.orderId }/add-position`,
        decamelizeKeys(action.actionRequest),
      );
      return response.data as EditedOffer;
    },
    updatePosition: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/update-position`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    enableWeeTakeBack: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/update-weee-take-back`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    cancelPosition: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/cancel-position`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    removePosition: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/remove-position`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    updatePositionPriceDate: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${Endpoints.editOrder}/${action.orderId}/update-position-price-date`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    switchDeliveryAddress: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/switch-delivery-address`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    changePayment: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/change-payment`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    submitOrder: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<OrderResponse>(`${ Endpoints.editOrder }/${ action.orderId }`, decamelizeKeys(action.actionRequest));
      return response.data as OrderResponse;
    },
    clearOrder: async (orderId: string) => {
      const response = await axiosInstance.delete<string>(`${ Endpoints.editOrder }/${ orderId }`);
      return response.data;
    },
    redeemVoucher: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/redeem-voucher`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    removeVoucher: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/remove-voucher`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
    changeServiceVoucher: async (action: EditOrderActionRequestWithOrderId) => {
      const response = await axiosInstance.post<EditedOffer>(`${ Endpoints.editOrder }/${ action.orderId }/change-service-voucher`, decamelizeKeys(action.actionRequest));
      return response.data as EditedOffer;
    },
  };
}
