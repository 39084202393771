import Log from 'src/utils/log';
import stringify from 'src/utils/stringify';


const SESSION_VOUCHER_CODE = 'voucherCode';

interface VoucherSessionStorage {
  voucherCode?: string;
  basketId?: string;
}

const getVoucher = (): VoucherSessionStorage | undefined =>  {
  try {
    const voucherAsString = window.sessionStorage.getItem(SESSION_VOUCHER_CODE);
    return voucherAsString ? JSON.parse(voucherAsString) : undefined;
  } catch (err) {
    Log.error('Could not get voucher');
    return undefined;
  }
};

const saveVoucher = (voucher: VoucherSessionStorage) =>  {
  try {
    window.sessionStorage.setItem(SESSION_VOUCHER_CODE, stringify(voucher));
  } catch (err) {
    Log.error('Could not save voucher');
  }
};

export default {
  getVoucher,
  saveVoucher,
};
