import { PayloadAction } from '@reduxjs/toolkit';
import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import {
  authenticateCustomer,
  checkCustomerPinAction,
  setDisableSkipOptionForever,
  setFormPinValue,
  skipPinChallengeAction,
} from 'src/redux/customer/CustomerPinSlice';
import { customerPinSelector } from 'src/redux/customer/selectors/customerPinSelectors';
import { SkipCustomerPinChallengeRequest, VerifyCustomerPinChallengeRequest } from 'src/types/customer/VerifyCustomerPinChallengeRequest';
import { setLastBirthdayCheck } from 'src/utils/lastBirthdayCheck';


export function* authenticateCustomerSaga(action: PayloadAction) {
  try {
    const pinValue = yield select(customerPinSelector.getFormPinValue);
    const skipReason = yield select(customerPinSelector.getFormSkipReason);
    const skipOptionDisabled = yield select(customerPinSelector.getDisableSkipOptionForever);

    const isTypingAction = action.type === setFormPinValue.type;
    if(!skipOptionDisabled && isTypingAction) {
      yield put(setDisableSkipOptionForever());
    }

    const pinIsEntered = pinValue.length >= 3;
    if(isTypingAction && !pinIsEntered && !skipReason) {
      return;
    }

    const customer = yield select(customerPinSelector.getPreLoadedCustomer);

    if (skipReason !== undefined) {
      setLastBirthdayCheck(customer!.externalCustomerId);
      const skipCustomerPinChallengeRequest: SkipCustomerPinChallengeRequest = {
        skippingReason: skipReason,
        externalCustomerId: customer!.externalCustomerId,
      };
      yield put(skipPinChallengeAction(skipCustomerPinChallengeRequest));
      return;
    }

    const verifyCustomerPinChallengeRequest: VerifyCustomerPinChallengeRequest = {
      pin: pinValue,
      externalCustomerId: customer!.externalCustomerId,
    };
    yield put(checkCustomerPinAction(verifyCustomerPinChallengeRequest));
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not authenticate customer', err }));
  }
}

export default function* authenticateCustomerWatcher() {
  yield takeLatest(authenticateCustomer.type, authenticateCustomerSaga);
  yield takeLatest(setFormPinValue.type, authenticateCustomerSaga);
}
