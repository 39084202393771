import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { OrderHistoryResponse } from 'src/types/orderhistory/OrderHistoryResponse';
import { OrderStatusRequest } from 'src/types/orderhistory/OrdersOverviewHistoryRequest';
import { getLastCanceledOrders } from 'src/utils/canceledOrders';


export interface OrderHistoryFilters {
  searchFrom?: Date | null;
  searchTo?: Date | null;
  keywords?: string;
  status?: OrderStatusRequest;
}

export interface OrderHistoryState {
  externalCustomerId: string;
  loading: boolean;
  isLoadingMore: boolean;
  data?: OrderHistoryResponse[];
  error?: boolean;
  errorCode?: number;
  cancelOrderInProgress: boolean;
  offset: number;
  totalOrders: number;
  totalFilteredOrders: number;
  filters: OrderHistoryFilters;
  cancelledOrders?: string[];
  searchedLimelightOrderId?: string;
}

interface FetchedOrdersState {
  orders: OrderHistoryResponse[];
  page: number;
  size: number;
  totalOrders: number;
  totalFilteredOrders: number;
}

export const orderHistoryInitialState: OrderHistoryState = {
  externalCustomerId: '',
  loading: false,
  isLoadingMore: false,
  cancelOrderInProgress: false,
  data: [],
  offset: 1,
  totalOrders: 0,
  totalFilteredOrders: 0,
  filters: {
    keywords: '',
    searchFrom: null,
    searchTo: null,
  },
  cancelledOrders: getLastCanceledOrders(),
};

interface InitialOrdersHistoryFetchPayload {
  externalCustomerId: string;
  keyword?: string;
}

export const cancelOrder = createAction('orderHistory/cancelOrder',
  (orderNumber: string, customerId: string, reasonCode: string) => ({ payload: { customerId, orderNumber, reasonCode } }),
);
export const cancelOrderSuccess = createAction('orderHistory/CANCEL_ORDER_SUCCESS');
export const cancelOrderError = createAction('orderHistory/CANCEL_ORDER_ERROR');
export const fetchOrdersOverviewHistory = createAction<InitialOrdersHistoryFetchPayload>('orderHistory/FETCH_ORDERS_OVERVIEW_HISTORY');
export const fetchByLimelightOrderId = createAction<string>('orderHistory/FETCH_BY_LIMELIGHT_ORDER_ID');

export type CancelOrderAction = ReturnType<typeof cancelOrder>;

const orderHistorySlice = createSlice({
  name: 'orderHistory',
  initialState: orderHistoryInitialState,
  reducers: {
    fetchOrderOverviewHistoryResultsInProgress(state) {
      state.isLoadingMore = state.offset !== 1;
      state.loading = state.offset === 1;
    },
    fetchOrderOverviewHistoryResultsSuccess(state, { payload }: PayloadAction<FetchedOrdersState>) {
      state.data = payload.orders;
      state.offset = payload.page;
      state.totalOrders = payload.totalOrders;
      state.totalFilteredOrders = payload.totalFilteredOrders;
      state.loading = false;
      state.isLoadingMore = false;
    },
    setOrdersFetchFilters(state, { payload }: PayloadAction<OrderHistoryFilters>) {
      const statusChanged = (!!payload.status && payload.status !== state.filters.status);
      state.offset = 1;
      state.totalFilteredOrders = 0;
      state.loading = statusChanged;
      state.filters = {
        keywords: payload.keywords ?? state.filters.keywords,
        searchFrom: payload.searchFrom ?? state.filters.searchFrom,
        searchTo: payload.searchTo ?? state.filters.searchTo,
        status: payload.status ?? state.filters.status,
      } as OrderHistoryFilters;
    },
    clearOrdersFetchFilters(state) {
      state.offset = 1;
      state.totalFilteredOrders = 0;
      state.filters = {};
    },
    updateOffset(state, { payload }: PayloadAction<number>) {
      state.offset = payload;
    },
    fetchOrderHistoryResultsError(state, { payload }: PayloadAction<number>) {
      state.loading = false;
      state.isLoadingMore = false;
      state.error = true;
      state.errorCode = payload;
    },
    emptyOrderHistoryResults(state) {
      state.loading = false;
      state.data = [];
      state.externalCustomerId = '';
      state.error = undefined;
      state.errorCode = undefined;
      state.offset = 1;
      state.totalOrders = 0;
      state.totalFilteredOrders = 0;
    },
    setCancelledOrders(state, { payload }: PayloadAction<string[]>) {
      state.cancelledOrders = payload;
    },
    setSearchedLimelightOrderId(state, { payload }: PayloadAction<string>) {
      state.searchedLimelightOrderId = payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase( cancelOrder,(state) => {
        state.cancelOrderInProgress = true;
      })
      .addCase( cancelOrderSuccess,(state) => {
        state.cancelOrderInProgress = false;
      })
      .addCase( cancelOrderError,(state) => {
        state.cancelOrderInProgress = false;
      })
      .addCase( fetchOrdersOverviewHistory,(state, { payload }: PayloadAction<InitialOrdersHistoryFetchPayload>) => {
        state.externalCustomerId = payload.externalCustomerId;
        state.filters = {
          keywords: payload.keyword,
        };
        state.loading = true;
      });
  },
},
);

export const {
  fetchOrderOverviewHistoryResultsInProgress,
  fetchOrderOverviewHistoryResultsSuccess,
  fetchOrderHistoryResultsError,
  updateOffset,
  emptyOrderHistoryResults,
  setOrdersFetchFilters,
  clearOrdersFetchFilters,
  setCancelledOrders,
  setSearchedLimelightOrderId,
} = orderHistorySlice.actions;

export default orderHistorySlice.reducer;
