import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { AuthorizationCodeResponse } from './authTypes';
import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from './httpClient/interceptors/xAuthorizationHeaderInterceptor';
import { Order, StatusHistoryResponse } from './orderTypes';
import Config from '../config';


export const CUSTOMER_CORRELATION_ID_HEADER = 'X-CorrelationId';

export const getCorrelationHeader = () => ({
  [CUSTOMER_CORRELATION_ID_HEADER]: uuidv4(),
});

export const apiClient = getHttpClient({
  baseUrl: Config.env.orderServiceUrl,
  interceptors: {
    response: refreshTokenInterceptor,
    request: [
      localeHeaderInterceptor,
      xAuthorizationHeaderInterceptor,
    ],
  },
});

export function getApiOrder(baseURL: string) {
  const apiOrder = getHttpClient({
    baseUrl: baseURL,
    interceptors: {
      response: refreshTokenInterceptor,
      request: [
        localeHeaderInterceptor,
        xAuthorizationHeaderInterceptor,
      ],
    },
  });

  return {
    ...(ApiOrder(apiOrder)),
    client: apiOrder,
  };
}

const ApiOrder = (apiOrder: AxiosInstance) => ({
  async getOauthTokens(code: string): Promise<AuthorizationCodeResponse> {
    const response: AxiosResponse<object> = await axios.post(
      `${Config.env.cognito.url}/oauth2/token`,
      queryString.stringify({
        grant_type: 'authorization_code',
        client_id: Config.env.cognito.clientId,
        redirect_uri: `${Config.env.backofficeUrl}/oauth-sign-in`,
        code: code,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    return camelizeKeys(response.data) as AuthorizationCodeResponse;
  },

  async getOrder(orderId: string): Promise<Order> {
    const response: AxiosResponse<object> = await apiOrder.get(`/orders/${orderId}`);
    return camelizeKeys(response.data) as Order;
  },

  async resubmitOrder(orderId: string): Promise<Order> {
    const response: AxiosResponse<object> = await apiOrder.post(
      `/orders/${orderId}/submission`,
      undefined,
      {
        headers: getCorrelationHeader(),
      });
    return camelizeKeys(response.data) as Order;
  },

  async updateOrder(updatedOrder: Order): Promise<Order> {
    const response: AxiosResponse<object> = await apiOrder.put(
      `/orders/${updatedOrder.id}`,
      decamelizeKeys(updatedOrder),
      {
        headers: getCorrelationHeader(),
      },
    );
    return camelizeKeys(response.data) as Order;
  },

  async cancelRejectedOrder(orderId: string): Promise<StatusHistoryResponse> {
    const response: AxiosResponse<object>  = await apiOrder.post(`backoffice-orders/${orderId}/cancel`);
    return camelizeKeys(response.data) as StatusHistoryResponse;
  },

  cancelOrder(orderNumber: string, customerId: string, reasonCode: string) {
    return apiOrder.post(`orders/${orderNumber}/cancel`, { 'external_customer_id': customerId, 'reason_code': reasonCode });
  },
});

/**
 * @deprecated
 */
export default ApiOrder(apiClient);
