import { DeliveryAddress } from 'src/types/customer/address';
import { SubscriptionItemStatus, SubscriptionPayment } from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionRotation } from 'src/types/subscription/SubscriptionRequest';


export interface SubscriptionChangesHistoryResponse {
    page: number;
    size: number;
    changes: SubscriptionChangeItem[];
}

export interface SubscriptionChangeItem {
    subscriptionId: string;
    createdAt: string;
    changeEvent: SubscriptionChangeEvent;
}

export enum SubscriptionChangeEventType {
    STATUS_UPDATED = 'STATUS_UPDATED',
    QUANTITY_UPDATED = 'PRODUCT_QUANTITY_UPDATED',
    ROTATION_UPDATED = 'ROTATION_UPDATED',
    DELIVERY_DATE_UPDATED = 'DELIVERY_DATE_UPDATED',
    DELIVERY_ADDRESS_UPDATED = 'DELIVERY_ADDRESS_UPDATED',
    PAYMENT_UPDATED = 'PAYMENT_UPDATED',
    CUSTOM_NOTE = 'CUSTOM_NOTE',
    DELIVERY_PRODUCT_NUMBER_UPDATED = 'DELIVERY_PRODUCT_NUMBER_UPDATED',
    SUBSCRIPTION_PRODUCT_NUMBER_UPDATED = 'SUBSCRIPTION_PRODUCT_NUMBER_UPDATED'
}

export interface UpdatedSubscriptionStatus {
    type: SubscriptionChangeEventType.STATUS_UPDATED;
    oldStatus?: SubscriptionItemStatus;
    newStatus: SubscriptionItemStatus;
}

export interface UpdatedSubscriptionProductQuantity {
    type: SubscriptionChangeEventType.QUANTITY_UPDATED;
    oldQuantity: number;
    newQuantity: number;
    agentUsername: string;
    note?: string;
}

export interface UpdatedSubscriptionRotation {
    type: SubscriptionChangeEventType.ROTATION_UPDATED;
    oldRotation: SubscriptionRotation;
    newRotation: SubscriptionRotation;
    agentUsername: string;
    note?: string;
}

export interface UpdatedSubscriptionDeliveryDate {
    type: SubscriptionChangeEventType.DELIVERY_DATE_UPDATED;
    oldDeliveryDate: string;
    newDeliveryDate: string;
    agentUsername: string;
    note?: string;
}

export interface UpdatedSubscriptionPayment {
  type: SubscriptionChangeEventType.PAYMENT_UPDATED;
  oldPayment: SubscriptionPayment;
  newPayment: SubscriptionPayment;
  agentUsername: string;
}

export interface UpdatedSubscriptionDeliveryAddress {
  type: SubscriptionChangeEventType.DELIVERY_ADDRESS_UPDATED;
  oldAddress: DeliveryAddress;
  newAddress: DeliveryAddress;
  agentUsername: string;
}

export interface CustomSubscriptionNote {
  type: SubscriptionChangeEventType.CUSTOM_NOTE;
  note: string;
  agentUsername: string;
}

export interface UpdatedDeliveryProductNumber {
  type: SubscriptionChangeEventType.DELIVERY_PRODUCT_NUMBER_UPDATED;
  oldNumber: string;
  newNumber: string;
  agentUsername: string;
}

export interface UpdatedSubscriptionProductNumber {
  type: SubscriptionChangeEventType.SUBSCRIPTION_PRODUCT_NUMBER_UPDATED;
  oldNumber: string;
  newNumber: string;
  agentUsername: string;
}

export type SubscriptionChangeEvent = UpdatedSubscriptionStatus
  | UpdatedSubscriptionProductQuantity
  | UpdatedSubscriptionRotation
  | UpdatedSubscriptionDeliveryDate
  | UpdatedSubscriptionPayment
  | UpdatedSubscriptionDeliveryAddress
  | CustomSubscriptionNote
  | UpdatedDeliveryProductNumber
  | UpdatedSubscriptionProductNumber;
