export default {
  'inflightOrder.INVALID_BASE_PRODUCT_NUMBER_SELECTION': 'Ungültige Bestellnummer',
  'inflightOrder.request.INVALID_BASE_PRODUCT_NUMBER_SELECTION': 'Bitte gleichen Sie die Bestellnummer ab.',

  'inflightOrder.INVALID_PRODUCT_VARIANT_SELECTION': 'Variante nicht möglich (Hauptprodukt)',
  'inflightOrder.request.INVALID_PRODUCT_VARIANT_SELECTION': 'Bitte wählen Sie die Variante erneut aus.',

  'inflightOrder.INVALID_CROSS_SELLING': 'Variante nicht möglich (Crosssell)',
  'inflightOrder.request.INVALID_CROSS_SELLING': 'Bitte wählen Sie die Variante erneut aus.',

  'inflightOrder.INVALID_AUTHENTICATION': 'Kundendaten stimmen nicht überein',
  'inflightOrder.request.INVALID_AUTHENTICATION': 'Bitte suchen Sie das passende Kundenkonto.',

  'inflightOrder.INVALID_VOUCHER_SELECTION': 'Gutscheineinlösung nicht möglich',
  'inflightOrder.request.INVALID_VOUCHER_SELECTION': 'Prüfen Sie, ob der Gutschein im Kundenkonto hinterlegt ist, sowie die Gültigkeit und den Mindestbestellwert des Gutscheins.',

  'inflightOrder.INVALID_ADDRESS_SELECTION': 'Lieferadresse prüfen/ändern',
  'inflightOrder.request.INVALID_ADDRESS_SELECTION': 'Bitte klären Sie die gewünschte Lieferadresse ab.',

  'inflightOrder.INVALID_PAYMENT_METHOD_SELECTION': 'Zahlungsart ändern',
  'inflightOrder.request.INVALID_PAYMENT_METHOD_SELECTION': 'Bitte klären Sie die gewünschte Zahlungsart ab.',

  'inflightOrder.INVALID_ORDER': 'Bestellung abschließen',
  'inflightOrder.request.INVALID_ORDER': 'Bitte Bestellung abschließen. (ggf. sichere Zahlungsart anbieten)',

  'inflightOrder.WEEE_TAKEBACK_HANDOVER': 'Abholung Alt-Elektrogerät',
  'inflightOrder.request.WEEE_TAKEBACK_HANDOVER_1': 'Bitte Telefonnummer oder E-Mail-Adresse abfragen.',
  'inflightOrder.request.WEEE_TAKEBACK_HANDOVER_2': 'Bitte Telefonnummer mit Kunde abgleichen.',
  'inflightOrder.request.WEEE_TAKEBACK_HANDOVER_3': 'Bitte E-Mail-Adresse mit Kunde abgleichen.',
  
  'inflightOrder.BASKET_NOT_FOUND': 'Technischer Fehler Warenkorb',
  'inflightOrder.request.BASKET_NOT_FOUND': 'Bitte Bestellung ggf. neu erfassen.',

  'inflightOrder.submit': 'Weiter',
  'inflightOrder.submitGoToVariantSelection': 'Weiter zu der Variantenauswahl',
};