import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';
import isInternetSalesChannel from 'src/utils/validators/IsInternetSalesChannel';


export const salesChannelSelector = createSelectors({
  isInternetChannel(state: RootStateType) {
    return isInternetSalesChannel(state?.order.salesChannel.value);
  },
  getSalesChannel(state: RootStateType) {
    return state?.order.salesChannel.value;
  },
  getSalesChannelLoading(state: RootStateType) {
    return state?.order.salesChannel.loading;
  },
});
