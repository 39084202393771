import { FC } from 'react';

import styles from './LoadingSpinner.module.scss';


interface Props {
  visible: boolean;
}

const LoadingSpinner: FC<Props> = (props: Props) => {
  return (
    <div className={props.visible ? styles.spinnerWrapper : styles.hidden}>
      <div className={styles.spinner} />
    </div>
  );
};

export default LoadingSpinner;
