import { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import {
  editOrderSelector,
  updateProductPriceDate,
  updateProductPriceDateSuccess
} from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId, EditOrderActionType } from 'src/types/orderhistory/EditOrderActionRequest';

import { setDeliveryTimesSaga } from './initEditOrder';


export function* updateProductPriceDateSaga(action: PayloadAction<{itemId: string, date: Date}>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    
    const editedOrderId = yield select(editOrderSelector.getEditedOrderId);
    const request: EditOrderActionRequestWithOrderId = {
      orderId: editedOrderId,
      actionRequest: {
        type: EditOrderActionType.UPDATE_POSITION_PRICE_DATE,
        id: action.payload.itemId,
        priceDate: dayjs(action.payload.date).format('YYYY-MM-DD'),
      },
    };
    const response: EditedOffer = yield call(apiEditOrder.updatePositionPriceDate, request);

    const offer = yield call(setDeliveryTimesSaga, response);

    yield put(updateProductPriceDateSuccess(offer));
  } catch (error) {
    yield handleFailureState(error, 'Couldn\'t update Product price date on edit order actionRequest: ', action.payload);
  }
}

export default function* updateProductPriceDateWatcher() {
  yield takeLatest(updateProductPriceDate.type, updateProductPriceDateSaga);
}
