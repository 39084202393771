import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import {
  fetchBotRequest,
  fetchBotRequestError,
  fetchBotRequestInProgress,
  fetchBotRequestSuccess
} from 'src/redux/bot/botRequestSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { BotRequestInfo } from 'src/types/bot/BotRequest';


export function* fetchBotRequestByNumberSaga(action: PayloadAction<string>) {
  try {
    yield put(fetchBotRequestInProgress());
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    const botRequest: BotRequestInfo = yield call(apiCallCenter.getBotRequestByNumber, action.payload);
    
    yield put(fetchBotRequestSuccess(botRequest));
  } catch (err) {
    yield put(fetchBotRequestError(err.response?.data?.detail));
  }
}


export default function* fetchBotRequestByNumberWatcher() {
  yield takeLatest(fetchBotRequest.type, fetchBotRequestByNumberSaga);
}
