import salesChannels from 'src/config/salesChannels';
import { ListType } from 'src/types/offer/ListType';
import { VariantDimension } from 'src/types/product/dimensions';
import {
  ProductDetails,
  ProductImpressionsViewItem,
  Salesdriver,
  ProductBrand,
} from 'src/types/product/product';

import toBaseProductNumber from './formatters/toBaseProductNumber';


const NOT_AVAILABLE = 'not available';

interface Prop {
  offers: ProductDetails[];
  title?: string;
  tab?: string;
}

interface Params {
  mainTvChannelOffer: Prop[];
  salesChannel: string;
  upsellSku?: string;
  upsellsMainProduct?: string;
}

export const getProductImpressionsView = ({ mainTvChannelOffer, salesChannel, upsellSku, upsellsMainProduct }: Params) => {


  const salesChannelName =
    salesChannels.find(channel => channel.id === salesChannel)?.name || 'Other';

  const views: ProductImpressionsViewItem[] = [];

  const CROSS_SELL = 'Cross sell product';
  const MAIN_PRODUCT = 'Main Product';

  mainTvChannelOffer.map(({ offers, title, tab }: Prop) => {
    const productsView = offers.map((offer: ProductDetails, index: number) => {
      const sku6 = toBaseProductNumber(offer.variants[0].sku);
      const list = upsellSku && upsellsMainProduct ? `${toBaseProductNumber(upsellSku)} | ${upsellsMainProduct}` : tab && title ? `${tab} | ${title}` : `${sku6} | ${offer.name.long}`;
      return {
        name: `${sku6} | ${offer.name?.long ?? offer.name}`,
        id: sku6,
        price: offer.variants[0].price.value,
        brand: getBrand(offer.brand) ?? NOT_AVAILABLE,
        category: offer.categoryPath || NOT_AVAILABLE,
        variant: getDimensionss(offer.variants[0].dimensions) || NOT_AVAILABLE,
        list: list,
        position: index,
        dimension10: offer.variants[0].sku || sku6,
        dimension2: offer.crossSell ? CROSS_SELL : MAIN_PRODUCT,
        dimension8: getSalesDrivers(offer.salesdrivers) || NOT_AVAILABLE,
        dimension9: offer.variants[0].price.label || NOT_AVAILABLE,
        dimension12: salesChannelName,
      };
    });
    views.push(...productsView);
  });

  return { impressions: views };
};

export const getSalesDrivers = (salesDrivers?: Salesdriver[]) => {
  return salesDrivers?.map((salesdriver: Salesdriver) => `${salesdriver.typeCode}`).join('|');
};

export const getDimensionss = (dimensions?: VariantDimension[]) => {
  return dimensions
    ?.map((dimension: VariantDimension) => `${dimension.type}:${dimension.value}`)
    .join('|');
};

export const getBrand = (brand: unknown) => {
  if (typeof(brand) === 'object') {
    return (brand as ProductBrand)?.brandName;
  }
  else if (typeof(brand) === 'string') {
    return (brand as string);
  }
};

export const getSource = (list: string | undefined) => {
  if (list === 'HSE' || list === 'HSE EXTRA' ||list === 'HSE TREND') {
    return ListType.TV;
  }
  else if (list === ' Heutige Angebote' || list === 'Angebote der Woche' ||list === 'Angebote des Monats') {
    return ListType.TOP_DEALS;
  }
  return ListType.CROSS_SELL;
};
