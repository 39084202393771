import { put, takeEvery } from 'redux-saga/effects';

import { searchError } from 'src/redux/order/orderEntrySlice';
import { emptyProductSearchResults } from 'src/redux/product/productSearchSlice';


export function *onSearchErrorSaga() {
  yield put(emptyProductSearchResults());
}

export default function* onSearchErrorWatcher() {
  yield takeEvery(searchError.type, onSearchErrorSaga);
}
