import { Customer } from 'src/types/customer/customer';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';


const customerLockedRestriction = [
  OrderRestriction.duplicate,
  OrderRestriction.deletionGdpr,
  OrderRestriction.permanantReturner,
  OrderRestriction.customerRequirement,
  OrderRestriction.unknownAddress,
  OrderRestriction.drmLock,
  OrderRestriction.fraud,
  OrderRestriction.deceased,
  OrderRestriction.nonPayeru,
  OrderRestriction.prankOrder,
  OrderRestriction.underaged,
  OrderRestriction.disability,
  OrderRestriction.asInternal,
  OrderRestriction.dataPrivacyLock,
  OrderRestriction.addressCheckMissing,
  OrderRestriction.departedEmployee,
  OrderRestriction.nonPayerKsv,
  OrderRestriction.archived,
  OrderRestriction.nonPayerKsp,
  OrderRestriction.nonPayerReal,
  OrderRestriction.nonPayerEos,
  OrderRestriction.nonPayerInfoScore,
  OrderRestriction.departedCreator,
  OrderRestriction.toBeArchived,
];

export const isCustomerLocked = (customer?: Customer): boolean => {
  if (!customer?.orderRestriction) {
    return false;
  }

  return customerLockedRestriction.includes(customer.orderRestriction);
};

export default isCustomerLocked;
