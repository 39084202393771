import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const addressSuggestionSelectors = createSelectors({
  getAddressSuggestion({ customer: { addressSuggestions } }: RootStateType) {
    return addressSuggestions;
  },
  getAddressSuggestionAddresses({ customer: { addressSuggestions } }: RootStateType) {
    return addressSuggestions.addresses;
  },
  getSuggestedCity(state: RootStateType) {
    const { suggestedCities, loadingSuggestedcities } = state.customer.addressSuggestions;
    return { suggestedCities, loadingSuggestedcities } ;
  }
});
