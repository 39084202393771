import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import translations from 'src/i18n/de';
import {
  openModal,
  showErrorNotification,
  showInfoNotification,
  showSuccessNotification,
} from 'src/redux/app/appSlice';
import {
  AddOrderItemAction,
  addOrderItemBySku,
  deleteBasketItemStatus,
  searchError,
  trackAddProductToCartEvent,
  updateBasketItemStatus,
} from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { fetchProduct } from 'src/redux/product/productDetailsSlice';
import { fetchUpsells } from 'src/redux/product/productUpsellsSlice';
import { productUpsellsSelector } from 'src/redux/product/selectors/productUpsellsSelector';
import {
  AddItemToBasketStatus,
  addItemToBasket,
  AddItemToBasketResult,
} from 'src/sagas/orderEntry/addItemToBasketService';
import { showNotificationAndLogError } from 'src/sagas/orderEntry/showNotificationAndLogError';
import getProductBySku from 'src/sagas/product/productService';
import { logRequestTime } from 'src/sagas/utils/temporaryRequestLogger';
import { Modals } from 'src/types/Modals';
import { ProductDetails } from 'src/types/product/product';
import Log from 'src/utils/log';
import { isSellable } from 'src/utils/mappers/discardedOffers';
import { fromSKU } from 'src/utils/mappers/fromSku';
import { isSubscriptionsDeliveryProductByBpno } from 'src/utils/product/productUtils';

import { fetchUpsellsSaga } from './fetchUpsells';
import { onGetBasketSaga } from './onGetBasket';
import getRandomProductAddedConfirmationMessage from '../utils/getRandomProductAddedConfirmationMessage';


const { getSalesChannel } = salesChannelSelector;

export function* onAddOrderItemSaga({ payload: { sku, uniqueID, productDetails } }: AddOrderItemAction) {
  const startTime = new Date();
  try {
    yield put(updateBasketItemStatus({ id: uniqueID, status: 'in_progress' }));
    if ((yield call(isReklaOrder, sku)) || (yield call(isSubscriptionsDeliveryProduct, sku))) {
      return ;
    }

    const crossSell = productDetails?.crossSell? { ...productDetails.crossSell, productNo: sku, sourceType: 'CROSS_SELL' } : undefined;

    const addItemRequest = {
      ...fromSKU(sku),
      source: crossSell,
      crossSell,
    };
    const result: AddItemToBasketResult = yield call(addItemToBasket, addItemRequest);

    if (result.status !== AddItemToBasketStatus.SUCCESS && result.status !== AddItemToBasketStatus.INVALID_VARIANT_SKU) {
      yield put(showErrorNotification(result.details));
      if(result.status === AddItemToBasketStatus.RESERVATION_FAILURE) {
        yield put(fetchProduct({ sku }));
        return;
      }
    }

    const response = yield call(onGetBasketSaga);
    if (response) {
      let mainProduct: ProductDetails | undefined;
      if (!productDetails) {
        mainProduct = yield call(getProductBySku, sku);
      }
      yield call(trackAddedProductToCartSaga, sku, productDetails || mainProduct);
      yield delay(250);

      yield put(showSuccessNotification(getRandomProductAddedConfirmationMessage()));
    } else {
      yield put(searchError(new Error('')));
    }
  } catch (error) {
    yield call(showNotificationAndLogError, error);
  } finally {
    yield put(deleteBasketItemStatus(uniqueID));
    yield call(logRequestTime, 'Add item to basket', startTime);
  }
}

export function* onAddOrderItemBySkuSaga(sku: string) {
  const startTime = new Date();
  try {
    if ((yield call(isReklaOrder, sku)) || (yield call(isSubscriptionsDeliveryProduct, sku))) {
      return ;
    }
    yield put(updateBasketItemStatus({ id: sku, status: 'in_progress' }));

    const mainProduct: ProductDetails = yield call(getProductBySku, sku);

    if (!isSellable(mainProduct, sku)) {
      yield put(showErrorNotification('orderentry.productNotSellable'));
      yield put(deleteBasketItemStatus(sku));
      yield put(fetchProduct({ sku }));
      return;
    }

    const addItemResult: AddItemToBasketResult = yield call(addItemToBasket, { ...fromSKU(sku) });

    if (addItemResult.status !== AddItemToBasketStatus.SUCCESS && addItemResult.status !== AddItemToBasketStatus.INVALID_VARIANT_SKU) {
      yield put(showErrorNotification(addItemResult.details));
    }

    if (addItemResult.status === AddItemToBasketStatus.PRODUCT_NOT_FOUND) {
      yield put(openModal(Modals.productSearch));
      return;
    }

    if (addItemResult.status === AddItemToBasketStatus.INVALID_VARIANT_SKU || addItemResult.status === AddItemToBasketStatus.RESERVATION_FAILURE) {
      yield put(fetchProduct({ sku }));
      return;
    }

    const response = yield call(onGetBasketSaga);
    if (response) {
      yield call(trackAddedProductToCartSaga, sku, mainProduct, true);
      yield put(fetchUpsells({ sku: sku }));
      yield put(showSuccessNotification(getRandomProductAddedConfirmationMessage()));
    } else {
      yield put(searchError(new Error('')));
    }
  } catch (error) {
    yield call(showNotificationAndLogError, error);
  } finally {
    yield put(deleteBasketItemStatus(sku));
    yield call(logRequestTime, 'Add item to basket', startTime);
  }
}

export function* trackAddedProductToCartSaga(sku: string, productDetails?: ProductDetails, isAddedBySKU?: boolean) {

  try {
    const salesChannel: string = yield select(getSalesChannel);
    let upsellsMainProduct: string | undefined;
    if (productDetails?.crossSell) {
      upsellsMainProduct = yield select(productUpsellsSelector.getUpsellsMainProduct);
    }
    yield put(trackAddProductToCartEvent({ productDetails, salesChannel: salesChannel === '04' ? 'Internet' : 'TV', sku, upsellsMainProduct, isAddedBySKU }));
  } catch (e) {
    yield call(Log.error, e);
  }
}

function* isReklaOrder(sku: string) {
  const isReklaOrderFilledWithItem = yield select(orderEntrySelector.isReklaOrderFilledWithItem);
  if (isReklaOrderFilledWithItem) {
    yield put(deleteBasketItemStatus(sku));
    yield put(showInfoNotification('can\'t add new item, this is a rekla order'));
    return true;
  }
  return false;
}

function* isSubscriptionsDeliveryProduct(sku: string) {
  if (isSubscriptionsDeliveryProductByBpno(sku)) {
    yield put(showErrorNotification(translations['product.cantOrderSubscriptionProduct']));
    return true;
  }
  return false;
}

export default function* onAddOrderItemWatcher() {
  yield takeEvery(addOrderItemBySku.type, onAddOrderItemSaga);
  yield takeLatest(addOrderItemBySku.type, fetchUpsellsSaga);
  yield takeLatest(fetchUpsells.type, fetchUpsellsSaga);
}
