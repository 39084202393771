const products = [
  '296926',
  '353477',
  '302957',
  '355624',
  '359708',
  '370205',
  '369558',
  '369559',
  '372402',
  '297177',
  '315651',
  '373866',
  '373869',
  '377502',
  '363727',
  '376701',
  '377350',
  '349044',
  '366098',
  '349660',
  '349742',
  '353477',
  '353831',
  '357446',
  '356835',
  '357209',
  '357544',
  '359310',
  '359708',
  '359218',
  '358279',
  '356627',
  '357755',
];

export const getRandomMockedProductSKUs = (n: number) => {
  const result = new Array(n);
  let len = products.length;
  const taken = new Array(len);

  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = products[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};
