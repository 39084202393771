import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeliveryAddress } from 'src/types/customer/address';
import { OfferItem } from 'src/types/offer/Offer';
import { OrderResponse } from 'src/types/offer/OrderResponse';
import {
  BankingDetails,
  BankingDetailsState,
  BankingInfo,
  InstallmentPlan,
  Payment,
} from 'src/types/offer/Payment';
import { EditedOffer, EditedOrder } from 'src/types/orderhistory/EditedOrder';
import { OrderHistoryResponse } from 'src/types/orderhistory/OrderHistoryResponse';
import { DeliveryTimeMessage } from 'src/types/product/DeliveryTime';
import { ProductDetails } from 'src/types/product/product';

import * as sagaActions from './editOrderSagaActions';
import {
  FactoringInstallmentsModalState,
  InstallmentFactoringModalMode,
  InstallmentFactoringModalSteps,
  InstallmentModalSteps,
  InstallmentsModalState,
  paymentInitialState,
} from '../payment/paymentSlice';


export * from './editOrderSagaActions';
export * from './selectors/editOrderSelector';

export interface UpgradeProductType {
  item: OfferItem;
  orderId: string;
  upgrade: ProductDetails;
}

export interface EditOrderError {
  title?: string;
  detail: string;
}

export interface ContactDetails {
  email: string;
  phoneNumber: string;
}
export interface EditOrderState {
  loading: boolean;
  initLoading: boolean;
  error?: EditOrderError;
  originalOrder?: OrderHistoryResponse;
  editedOffer?: EditedOffer;
  submitEditedOrder?: OrderResponse;
  newItems: OfferItem[];
  oldItems: OfferItem[];
  additionalDeliveryAddress?: DeliveryAddress;
  submitLoading: boolean;
  orderDeliveryTimes: DeliveryTimeMessage[];
  positionToCancel?: string;
  updatePositionLoading: boolean;
  bankingInfo: BankingInfo;
  prevBankingInfo?: BankingInfo;
  updateDeliveryAddress: boolean;
  installments: InstallmentsModalState;
  installmentsFactoring: FactoringInstallmentsModalState;
  updatePayment?: boolean;
  payment?: Payment;
  updateInstallmentPlan?: boolean;
  updateInstallmentFactoringPlan?: boolean;
  deliveryAddressSelected: boolean;
  canUsePackstationAddress?: boolean;
  blockedDeliveryAddresses: DeliveryAddress[];
  contactDetails: ContactDetails;
}

export const editOrderInitialState: EditOrderState = {
  loading: false,
  initLoading: false,
  newItems: [],
  oldItems: [],
  submitLoading: false,
  orderDeliveryTimes: [],
  updatePositionLoading: false,
  bankingInfo: {
    iban: '',
    accountHolder: '',
    bankName: '',
    ibanValid: false,
    firstTime: true,
    usesPrefilledIban: BankingDetailsState.initial,
  },
  updateDeliveryAddress: false,
  installments: {
    dueDay: 1,
    modalStep: InstallmentModalSteps.CHOOSE_PLAN,
  },
  installmentsFactoring: {
    modalStep: InstallmentFactoringModalSteps.CHOOSE_PLAN,
    conditionsConsent: false,
    installmentFactoringModalMode: InstallmentFactoringModalMode.NORMAL_MODE,
  },
  deliveryAddressSelected: true,
  blockedDeliveryAddresses: [],
  contactDetails: {
    email: '',
    phoneNumber: '',
  },
};

const editedOrderSlice = createSlice({
  name: 'EditOffer',
  reducers: {
    initEditOrderSuccess(state, { payload }: PayloadAction<EditedOrder>) {
      state.loading = false;
      state.initLoading = false;
      state.originalOrder = payload.originalOrder;
      state.editedOffer = payload.editedOffer;
      state.oldItems = payload.editedOffer.items;
      state.payment = payload.editedOffer.payment;
    },
    editOrderFailure(state, action: PayloadAction<EditOrderError>) {
      state.loading = false;
      state.submitLoading = false;
      state.updatePositionLoading = false;
      state.error = action.payload;
    },
    emptyEditedOrderSuccess() {
      return editOrderInitialState;
    },
    submitEditedOrderSuccess(state, action: PayloadAction<OrderResponse>) {
      state.submitLoading = false;
      state.loading = false;
      state.submitEditedOrder = action.payload;
    },
    updateProductSuccess(state, action: PayloadAction<EditedOffer>) {
      state.updatePositionLoading = false;
      state.loading = false;
      state.editedOffer = action.payload;
    },
    setEditedOrderDeliveryTimes(state, action: PayloadAction<DeliveryTimeMessage[]>) {
      state.orderDeliveryTimes = action.payload;
    },
    cancelProductSuccess(state, action: PayloadAction<EditedOffer>) {
      state.updatePositionLoading = false;
      state.loading = false;
      state.editedOffer = action.payload;
    },
    removeProductSuccess(state, action: PayloadAction<EditedOffer>) {
      state.updatePositionLoading = false;
      state.loading = false;
      state.editedOffer = action.payload;
    },
    switchDeliveryAddressSuccess(state, action: PayloadAction<EditedOffer>) {
      state.loading = false;
      state.editedOffer = action.payload;
      state.deliveryAddressSelected = true;
      state.updateDeliveryAddress = true;
    },
    changePaymentSuccess(state, { payload }: PayloadAction<EditedOffer>) {
      state.loading = false;
      state.editedOffer = payload;
      state.payment = payload.payment;
    },
    removeAdditionalDeliveryAddress(state) {
      state.loading = false;
      state.additionalDeliveryAddress = undefined;
    },
    setBankDetails(state, { payload }: PayloadAction<BankingDetails>) {
      state.bankingInfo.iban = payload.bankingDetails.maskedIban;
      state.bankingInfo.bankName = payload.bankingDetails.bankName;
      state.bankingInfo.accountHolder = payload.bankingDetails.accountHolder;
      state.bankingInfo.ibanValid = true;
      state.bankingInfo.usesPrefilledIban = BankingDetailsState.prefilled;
      state.bankingInfo.firstTime = false;
    },
    validateIBANSuccess(
      state,
      { payload }: PayloadAction<{ bankName: string; ibanError?: string, iban: string }>,
    ) {
      state.bankingInfo.iban = payload.iban;
      state.bankingInfo.bankName = payload.bankName;
      state.bankingInfo.ibanError = payload.ibanError;
      state.bankingInfo.ibanValid = payload.ibanError === undefined;
    },
    resetBankInfo(state) {
      if (state.prevBankingInfo) {
        state.bankingInfo = state.prevBankingInfo;
      } else {
        state.bankingInfo = editOrderInitialState.bankingInfo;
      }
    },
    saveBankInfo(state) {
      state.prevBankingInfo = state.bankingInfo;
    },
    redeemVoucherSuccess(state, action: PayloadAction<EditedOffer>) {
      state.loading = false;
      state.editedOffer = action.payload;
    },
    removeVoucherSuccess(state, action: PayloadAction<EditedOffer>) {
      state.loading = false;
      state.editedOffer = action.payload;
    },
    startUpdateDeliveryAddress(state, action: PayloadAction<boolean>) {
      state.updateDeliveryAddress = action.payload;
    },
    removeSelectedDeliveryAddress(state) {
      state.editedOffer!.deliveryAddress = undefined;
      state.deliveryAddressSelected = false;
    },
    changeInstallmentPlan(state, { payload }: PayloadAction<InstallmentPlan>) {
      state.installments.currentInstallmentPlan = payload;
    },
    changeInstallmentModalStep(state, { payload }: PayloadAction<InstallmentModalSteps>) {
      state.installments.modalStep = payload;
    },
    changeInstallmentDueDay(state, { payload }: PayloadAction<number>) {
      state.installments.dueDay = payload;
    },
    resetInstallmentPayment(state) {
      state.installments = paymentInitialState.installments;
      state.payment = undefined;
    },
    setInstallmentPlans(state, { payload }: PayloadAction<InstallmentPlan[] | undefined>) {
      state.installments.installmentPlans = payload;
    },
    changeServiceVoucherSuccess(state, action: PayloadAction<EditedOffer>) {
      state.loading = false;
      state.editedOffer = action.payload;
    },
    updateProductPriceDateSuccess(state, action: PayloadAction<EditedOffer>) {
      state.updatePositionLoading = false;
      state.loading = false;
      state.editedOffer = action.payload;
    },
    updateContactDetails(state, { payload }: PayloadAction<ContactDetails>) {
      state.contactDetails = payload;
    },
    changeInstallmentFactoringPlan(state, { payload }: PayloadAction<InstallmentPlan>) {
      state.installmentsFactoring.currentInstallmentPlan = payload;
    },
    changeInstallmentFactoringModalStep(state, { payload }: PayloadAction<InstallmentFactoringModalSteps>) {
      state.installmentsFactoring.modalStep = payload;
    },
    resetInstallmentFactoringPayment(state) {
      state.installmentsFactoring = editOrderInitialState.installmentsFactoring;
      state.payment = undefined;
    },
    setInstallmentFactoringPlans(state, { payload }: PayloadAction<InstallmentPlan[] | undefined>) {
      state.installmentsFactoring.installmentPlans = payload;
    },
    setInstallmentFactoringConsent(state, { payload }: PayloadAction<boolean>) {
      state.installmentsFactoring.conditionsConsent = payload;
    },
    changeInstallmentFactoringModalMode(state, { payload }: PayloadAction<InstallmentFactoringModalMode>){
      state.installmentsFactoring.installmentFactoringModalMode = payload;
    },
  },
  extraReducers: builder =>  {
    builder
      .addCase( sagaActions.initEditOrder,state => {
        state.initLoading = true;
        state.loading = true;
      })
      .addCase( sagaActions.submitEditedOrder,state => {
        state.submitLoading = true;
        state.loading = true;
      })
      .addCase( sagaActions.addNewProduct,state => {
        state.updatePositionLoading = true;
        state.loading = true;
      })
      .addCase( sagaActions.setNewItems,(state, action: PayloadAction<{ newItems: OfferItem[], oldItems: OfferItem[] }>) => {
        state.newItems = action.payload.newItems;
        state.oldItems = action.payload.oldItems;
      })
      .addCase( sagaActions.addNewProductSuccess,(state, action: PayloadAction<EditedOffer>) => {
        state.updatePositionLoading = false;
        state.loading = false;
        state.editedOffer = action.payload;
      })
      .addCase( sagaActions.updateProduct,state => {
        state.updatePositionLoading = true;
        state.loading = true;
      })
      .addCase( sagaActions.updateWeeTakeBack,state => {
        state.updatePositionLoading = true;
        state.loading = true;
      })
      .addCase( sagaActions.cancelProduct,state => {
        state.updatePositionLoading = true;
        state.loading = true;
      })
      .addCase( sagaActions.removeProduct,state => {
        state.updatePositionLoading = true;
        state.loading = true;
      })
      .addCase( sagaActions.setPositionToCancel,(state, action: PayloadAction<string>) => {
        state.positionToCancel = action.payload;
      })
      .addCase( sagaActions.switchDeliveryAddress,state => {
        state.loading = true;
        state.loading = true;
      })
      .addCase( sagaActions.changePayment,state => {
        state.loading = true;
        state.loading = true;
      })
      .addCase( sagaActions.validateIBAN,state => {
        return state;
      })
      .addCase( sagaActions.redeemVoucher,state => {
        state.loading = true;
        state.loading = true;
      })
      .addCase( sagaActions.removeVoucher,state => {
        state.loading = true;
        state.loading = true;
      })
      .addCase( sagaActions.updatePayment,state => {
        state.updatePayment = true;
        state.loading = true;
      })
      .addCase( sagaActions.resetPayment,state => {
        state.installments = editOrderInitialState.installments;
        state.installmentsFactoring = editOrderInitialState.installmentsFactoring;
        state.payment = undefined;
      })
      .addCase( sagaActions.updateInstallmentPlan,(state, action: PayloadAction<boolean>) => {
        state.updateInstallmentPlan = action.payload;
      })
      .addCase( sagaActions.changeServiceVoucher,state => {
        state.loading = true;
      })
      .addCase( sagaActions.updateProductPriceDate,state => {
        state.loading = true;
        state.updatePositionLoading = true;
      })  
      .addCase( sagaActions.updateInstallmentFactoringPlan,(state, action: PayloadAction<boolean>) => {
        state.updateInstallmentFactoringPlan = action.payload;
      });
  },
  initialState: editOrderInitialState,
});

export const {
  editOrderFailure,
  initEditOrderSuccess,
  emptyEditedOrderSuccess,
  submitEditedOrderSuccess,
  updateProductSuccess,
  setEditedOrderDeliveryTimes,
  cancelProductSuccess,
  removeProductSuccess,
  switchDeliveryAddressSuccess,
  removeAdditionalDeliveryAddress,
  changePaymentSuccess,
  setBankDetails,
  validateIBANSuccess,
  resetBankInfo,
  saveBankInfo,
  redeemVoucherSuccess,
  removeVoucherSuccess,
  startUpdateDeliveryAddress,
  removeSelectedDeliveryAddress,
  changeInstallmentDueDay,
  changeInstallmentModalStep,
  changeInstallmentPlan,
  resetInstallmentPayment,
  setInstallmentPlans,
  changeServiceVoucherSuccess,
  updateProductPriceDateSuccess,
  updateContactDetails,
  changeInstallmentFactoringPlan,
  changeInstallmentFactoringModalStep,
  resetInstallmentFactoringPayment,
  setInstallmentFactoringPlans,
  setInstallmentFactoringConsent,
  changeInstallmentFactoringModalMode,
} = editedOrderSlice.actions;

export default editedOrderSlice.reducer;
