import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal, openModal } from 'src/redux/app/appSlice';
import {
  changeInstallmentFactoringCalculationId,
  changeInstallmentFactoringChannel,
  getInstallmentFactoringPlans,
  getInstallmentFactoringPlansFailure,
  getInstallmentFactoringPlansSuccess,
  setInstallmentFactoringConsent
} from 'src/redux/payment/paymentSlice';
import { getOfferAndStoreBasket } from 'src/sagas/offer/getOffer';
import { Modals } from 'src/types/Modals';


export function* getInstallmentFactoringPlansSaga() {
  try {
    const offerResult = yield call(getOfferAndStoreBasket);
    const offerResponse = offerResult?.response;

    if (offerResponse && offerResponse.installmentPlans && offerResponse.installmentPlans.length > 0) {
      yield put(getInstallmentFactoringPlansSuccess(offerResponse.installmentPlans));
      yield put(changeInstallmentFactoringCalculationId(offerResponse.offer.payment.calculationId));
      yield put(changeInstallmentFactoringChannel(offerResponse.offer.payment.channel));
      yield put(setInstallmentFactoringConsent(false));
      yield put(openModal(Modals.installmentFactoringPayment));
    } else {
      yield put(getInstallmentFactoringPlansFailure());
    }
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not get installment factoring plans', err }));
    yield put(getInstallmentFactoringPlansFailure());
    yield put(closeModal(Modals.installmentFactoringPayment));
  }
}

export default function* getInstallmentFactoringPlansWatcher() {
  yield takeLatest(getInstallmentFactoringPlans.type, getInstallmentFactoringPlansSaga);
}
