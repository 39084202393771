import { AxiosInstance } from 'axios';

import { TvChannels } from 'src/types/offer/tv';
import { ChannelProducts } from 'src/types/product/product';

import { getHttpClient } from './httpClient/HttpClient';
import { localeHeaderInterceptor } from './httpClient/interceptors/localeHeaderInterceptor';


export interface ApiOffers {
  client: AxiosInstance;
  getOffers(channel: TvChannels, numberOfProducts?: number): Promise<ChannelProducts>;
}

export function getApiOffers(baseUrl: string): ApiOffers {
  const axios = getHttpClient({
    baseUrl,
    headers: { appDevice: 'IPAD' },
    transform: {
      camelize: true,
    },
    interceptors: {
      request: localeHeaderInterceptor,
    },
  });

  return {
    client: axios,
    async getOffers(channel: TvChannels, numberOfProducts = 20): Promise<ChannelProducts> {
      const response = await axios.get(`/${channel}?count=${numberOfProducts}`);
      return response.data;
    },
  };
}
