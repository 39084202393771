import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { EditedOrderResponse } from 'src/editedorder/types/EditedOrderResponse';
import { initialEditedOrderState } from 'src/editedorder/types/EditedOrderState';


export const fetchEditedOrderHistory = createAction<string>('editedOrder/fetchEditedOrderHistory');
export const resubmitEditedOrder = createAction<string>('editedOrder/resubmitEditedOrder');
export const resubmitEditedOrderDone = createAction('editedOrder/resubmitEditedOrderDone');
export const resubmitEditedOrderFailed = createAction('editedOrder/resubmitEditedOrderFailed');

export type FetchEditedOrderHistory = ReturnType<typeof fetchEditedOrderHistory>

const EditedOrderSlice = createSlice({
  name: 'EditedOrder',
  reducers: {
    setEditedOrdersHistory(state, { payload }: PayloadAction<EditedOrderResponse[]>) {
      state.loading = false;
      state.history = payload;
    },
    fetchEditedOrderHistoryFailure(state) {
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase( fetchEditedOrderHistory,(state) => {
        state.loading = true;
      })
      .addCase( resubmitEditedOrder,(state) => {
        state.loading = true;
      })
      .addCase( resubmitEditedOrderFailed,(state) => {
        state.loading = false;
      });
  },
  initialState: initialEditedOrderState,
});

export const { setEditedOrdersHistory } = EditedOrderSlice.actions;
export const editedOrderReducer = EditedOrderSlice.reducer;
