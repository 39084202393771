import { DEFAULT_SALES_CHANNEL } from 'src/config/salesChannels';
import { CampaignSalesChannel, ProductDetails } from 'src/types/product/product';


const getProductFreeShipping = (salesChannel: CampaignSalesChannel, product: ProductDetails) => {
  return product.freeShippingCampaigns
    ?.filter(it => it.campaignType === 'PRODUCT')
    ?.find(it => it.salesChannels?.includes(salesChannel));
};

export const getCampaignChannelFromSalesChannel = (salesChannel: string): CampaignSalesChannel =>
  salesChannel === DEFAULT_SALES_CHANNEL ? CampaignSalesChannel.CLASSIC_TV : CampaignSalesChannel.CLASSIC_SHOP;

export default getProductFreeShipping;
