import { createAction } from '@reduxjs/toolkit';

import { OfferItem } from 'src/types/offer/Offer';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';

import { UpgradeProductType } from './editOrderSlice';


export const initEditOrder = createAction<string>('editOrder/initEditOrder');
export const emptyEditedOrder = createAction('editOrder/emptyEditedOrder');
export const closeChOrderEditing = createAction<boolean|undefined>('editOrder/closeChOrderEditing');
export const orderToOrderHistory = createAction<boolean>('editOrder/orderToOrderHistory');
export const submitEditedOrder = createAction<EditOrderActionRequestWithOrderId>(
  'editOrder/submitEditedOrder',
);
export const addNewProduct = createAction<EditOrderActionRequestWithOrderId>(
  'editOrder/addNewProduct',
);
export const setNewItems = createAction<{ newItems: OfferItem[]; oldItems: OfferItem[] }>(
  'editOrder/setNewItems',
);
export const addNewProductSuccess = createAction<EditedOffer>('editOrder/addNewProductSuccess');
export const onEditOrderProductSearch = createAction<string>('editOrder/onEditOrderProductSearch');
export const updateProduct = createAction<{itemId: string, quantity: number}>('editOffer/updateProduct');
export const updateWeeTakeBack = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/updateWeeTakeBack',
);

export const clearOrder = createAction('editOrder/clearOrder');
export const cancelProduct = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/cancelProduct',
);
export const removeProduct = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/removeProduct',
);
export const upgradeEditProduct = createAction<UpgradeProductType>('editOffer/upgradeProduct');
export const setPositionToCancel = createAction<string>('editOrder/setPositionToCancel');
export const switchDeliveryAddress = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/switchDeliveryAddress',
);
export const changePayment = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/changePayment',
);
export const validateIBAN = createAction<string>('editOrder/validateIBAN');
export const redeemVoucher = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/redeemVoucher',
);
export const removeVoucher = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/removeVoucher',
);
export const updatePayment = createAction('editOffer/updatePayment');
export const resetPayment = createAction('editOffer/resetPayment');
export const updateInstallmentPlan = createAction<boolean>('editOffer/updateInstallmentPlan');
export const updateInstallmentFactoringPlan = createAction<boolean>('editOffer/updateInstallmentFactoringPlan');
export const changeServiceVoucher = createAction<EditOrderActionRequestWithOrderId>(
  'editOffer/changeServiceVoucher',
);

export const updateProductPriceDate = createAction<{itemId: string, date: Date}>(
  'editOffer/updateProductPriceDate',
);

export const onCloseInstallmentFactoringModal = createAction('editOffer/onCloseInstallmentFactoringModal');

export type RedeemVoucher = ReturnType<typeof redeemVoucher>
