import { CountryCode } from 'src/api/orderTypes';
import Config from 'src/config';
import countryLocalStorage from 'src/utils/countryLocalStorage';


const getBasketServiceBaseUrl = (): string => {
  if (!Config.env.basketServiceAkamaiUrl.enabled) {
    return Config.env.basketURL;
  }
  
  switch (countryLocalStorage.get()) {
  case CountryCode.at: return Config.env.basketServiceAkamaiUrl.at;
  case CountryCode.ch: return Config.env.basketServiceAkamaiUrl.ch;
  default: return Config.env.basketServiceAkamaiUrl.de;
  }
};

export default getBasketServiceBaseUrl;
