import { put, select } from 'redux-saga/effects';

import Config from 'src/config';
import { openModal } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import {
  addCustomerConsent,
  setCustomerEmail,
  setCustomerPhoneNumber,
} from 'src/redux/customer/customerInfoSlice';
import { Customer, MarketResearchConsent } from 'src/types/customer/customer';
import { ReceptionProvider } from 'src/types/customer/ReceptionSurveyQuestionsData';
import { Modals } from 'src/types/Modals';
import { getLastManagedConsentForCustomer, removeLastManagedConsent } from 'src/utils/lastConsentsManagementState';


export function* loadAlreadyManagedConsents(customer: Customer) {
  const lastManagedConsentState = getLastManagedConsentForCustomer(customer.externalCustomerId);

  if (lastManagedConsentState) {
    if (!!lastManagedConsentState.phoneMarketResearchConsent && !!lastManagedConsentState.emailMarketResearchConsent) {
      yield put(addCustomerConsent({
        phoneMarketResearch: lastManagedConsentState.phoneMarketResearchConsent,
        emailMarketResearch: lastManagedConsentState.emailMarketResearchConsent,
      }));
    }
    if (lastManagedConsentState.customerPhoneNumber) {
      yield put(setCustomerPhoneNumber(lastManagedConsentState.customerPhoneNumber));
    }

    if (lastManagedConsentState.customerEmail) {
      yield put(setCustomerEmail(lastManagedConsentState.customerEmail));
    }
  } else {
    removeLastManagedConsent();
  }
}

export function* checkAndOpenReceptionSurvey(customer: Customer) {
  // todo this lines blow will be removed after enabling this feature to every call center agent
  const agentInfo = yield select(appSelectors.getAgentInfo);
  const allowedUsers = Config.env.userAllowedToUseReceptionSurvey;
  const superUser = allowedUsers.length === 0 || allowedUsers.find(v => agentInfo?.username?.startsWith(v)) !== undefined;

  if (superUser && (customer.reception === undefined || customer.reception === ReceptionProvider.NOT_ASKED)) {
    yield put(openModal(Modals.receptionSurvey));
  }
}

export function isConsentNotYetQueried(marketResearchConsent: MarketResearchConsent | undefined, alreadySet: boolean) {
  if (alreadySet) {
    return false;
  }

  return marketResearchConsent === undefined || marketResearchConsent === MarketResearchConsent.NOT_QUERIED;
}
