import { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import de from 'src/i18n/de';
import {
  closeModal,
  openModal,
  showSuccessNotification,
} from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { subscriptionManagementSelectors } from 'src/subscriptionProductManagement/redux/selectors/subscriptionManagementSelectors';
import { Modals } from 'src/types/Modals';
import { createEditSubscriptionsDeliveryDatesRequest, EditSubscriptionDeliveryDatesRequest } from 'src/types/subscription/EditSubscriptionsDeliveryDatesRequest';
import { extractDetails } from 'src/utils/errorStatusChecks';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import {
  editDeliveryDateFailed,
  editDeliveryDates,
  editDeliveryDatesActionCreator,
  EditDeliveryDatesState,
  editDeliveryDateSuccess,
  submitEditDeliveryDatesActionCreator,
  SubscriptionsOverviewFetchFilters,
} from '../redux/subscriptionManagementSlice';


export function* editDeliveryDatesSaga({ payload }: PayloadAction<string[]>) {
  yield put(openModal(Modals.editSubscriptionDeliveryDateModal));
  yield put(editDeliveryDates(payload));
}

export function* submitEditDeliveryRequestSaga() {
  try {
    const editDeliveryDatesState: EditDeliveryDatesState = yield select(
      subscriptionManagementSelectors.getDeliveryDatesState,
    );
    const selectAll: boolean = yield select(subscriptionManagementSelectors.areAllSubscriptionsSelected);
    const filters: SubscriptionsOverviewFetchFilters = yield select(subscriptionManagementSelectors.getSubscriptionsOverviewFetchFilters);
    const subscriptionsTotalToEdit: number = yield select(subscriptionManagementSelectors.getSubscriptionsTotal);
    const excludeSubscriptionIds: string[] = yield select(subscriptionManagementSelectors.getExcludedSubscriptions);
    const request: EditSubscriptionDeliveryDatesRequest = createEditSubscriptionsDeliveryDatesRequest(
      editDeliveryDatesState.subscriptions,
      excludeSubscriptionIds,
      dayjs(editDeliveryDatesState.newDate).toDate(),
      filters,
      editDeliveryDatesState.note,
      selectAll,
      subscriptionsTotalToEdit,
    );
    const api: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);
    const { alreadyProcessedSubscriptionsCount } = yield call(api.editSubscriptionsDeliveryDates, request);
    yield put(closeModal(Modals.editSubscriptionDeliveryDateModal));
    yield put(editDeliveryDateSuccess());

    const msgId = editDeliveryDatesState.subscriptions.length > 1 ? 'multipleSubscriptions' : 'oneSubscription';
    let successMessage = de[`subscriptionManagement.editDeliveryDate.${msgId}.successNotificationHint`];
    if (alreadyProcessedSubscriptionsCount) {
      successMessage = `${successMessage}. ${alreadyProcessedSubscriptionsCount} ${de['subscriptionManagement.editDeliveryDate.alreadyProcessedSubscriptions']}`;
    }
    yield put(showSuccessNotification(successMessage));
  } catch (err) {
    yield put(editDeliveryDateFailed());
    yield call(
      logErrorAndShowNotification,
      'Oops... Failed to update subscriptions delivery dates',
      extractDetails(err) ?? 'subscriptionManagement.editDeliveryDate.multipleSubscriptions.unsuccessfulNotificationHint',
      err,
    );
  }
}

export function* editSubscriptionsDeliveryDatesWatcher() {
  yield takeLatest(editDeliveryDatesActionCreator.type, editDeliveryDatesSaga);
  yield takeLatest(submitEditDeliveryDatesActionCreator.type, submitEditDeliveryRequestSaga);
}
