export default {
  'privacy.policy.title': 'Datenschutzbestimmung',
  'privacy.policy.detailedInfo':
    '„{salutation} {name}, wünschen Sie ausführliche Informationen über unseren Datenschutz bei HSE?“',
  'privacy.policy.sendOrReadInfo':
    '„Möchten Sie, dass ich Ihnen die Informationen zusende? Oder soll ich Ihnen die Datenschutzhinweise jetzt auszugsweise vorlesen?"',
  'privacy.policy.yes': 'Ja',
  'privacy.policy.no': 'Nein',
  'privacy.policy.save': 'Sichern',
  'privacy.policy.sendByPost': 'Per Post zusenden',
  'privacy.policy.sendByPostInfo':
    '„{salutation} {name}, unsere Datenschutzbestimmungen erhalten Sie in wenigen Tagen per Post zugesendet.“',
  'privacy.policy.readOutData': 'Datenschutz-Auszug vorlesen',
  'privacy.policy.readOutDataInfo': `„HSE verwendet Ihre Angaben zur Vertragsdurchführung und führt eine Bonitätsprüfung durch, sofern die Zahlung per Rechnung, Bankeinzug oder Ratenkauf gewünscht wird.
HSE verwendet Name und Anschrift zur Information über interessante Produkte und Dienstleistungen. Telefonnummer und E-Mail-Adresse verwenden wir grundsätzlich nur mit Ihrer ausdrücklichen Einwilligung für Werbe- und sonstige Zwecke; ggf. informieren wir Sie per E-Mail über Produkte, die für Sie aufgrund Ihrer bisherigen Einkäufe interessant sein könnten. Eine Übermittlung von Daten an Dritte für Werbezwecke erfolgt nicht.
Sie können einer Verarbeitung von Daten für Werbezwecke jederzeit widersprechen bzw. Ihre Einwilligungen widerrufen. Ausführliche Informationen zum Datenschutz finden Sie in unserem Online-Shop oder auf Videotext.
Haben Sie momentan noch Fragen?“`,
  'privacy.policy.emailPrivacyInfo':
    '„{salutation} {name}, unsere Datenschutzbestimmungen erhalten Sie in wenigen Minuten per E-Mail an Ihr Postfach. Wünschen Sie, dass ich Ihnen jetzt zusätzlich daraus vorlese?“',
  'privacy.policy.emailReadData': 'Datenschutz-Auszug vorlesen',
};
