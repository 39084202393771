import Log from 'src/utils/log';


export const CANCELED_ORDERS_TOKEN = 'canceledOrders';

export function getLastCanceledOrders(): string[] {
  try {
    const existingCanceledOrdersToken = sessionStorage.getItem(CANCELED_ORDERS_TOKEN);
    if(existingCanceledOrdersToken) {
      return JSON.parse(existingCanceledOrdersToken);
    }
  } catch (err) {
    Log.error('unexpected error');
  }
  return [];
}

export function storeCanceledOrder(orderNumber: string): void {
  try {
    const canceledOrders = getLastCanceledOrders();
    canceledOrders.push(orderNumber);
    sessionStorage.setItem(CANCELED_ORDERS_TOKEN, JSON.stringify(canceledOrders));
  } catch (err) {
    Log.error('unexpected error');
  }
}
