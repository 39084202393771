import months from 'src/subscriptionProductManagement/messages/months.en';
import subscriptionProductReports from 'src/subscriptionProductManagement/messages/subscriptionProductsReport.en';


export default {
  ...subscriptionProductReports,
  ...months,

  'subscriptionManagement.tabs.ABO_OVERVIEW': 'Abo Übersicht',
  'subscriptionManagement.tabs.ABO_PRODUCT': 'Abo-Produkte',

  'subscriptionManagement.statusFilters.ALL': 'All Abos',
  'subscriptionManagement.statusFilters.ACTIVE': 'Active',
  'subscriptionManagement.statusFilters.IN_PROGRESS': 'In progress',
  'subscriptionManagement.statusFilters.DELAY_IN_DELIVERY': 'Delay in delivery',
  'subscriptionManagement.statusFilters.CANCELED': 'Cancelled',

  'subscriptionManagement.datesFilter': 'Dates filter:',
  'subscriptionManagement.cancellationReasonFilter': 'Cancellation-reason filter:',

  'subscriptionManagement.dateParameterFilter.NONE': 'Select a filter',
  'subscriptionManagement.dateParameterFilter.CREATION_DATE': 'Creation Date',
  'subscriptionManagement.dateParameterFilter.NEXT_DELIVERY_DATE': 'Next delivery date',
  'subscriptionManagement.dateParameterFilter.PREVIOUS_EDIT_DATE': 'Previous edit date',

  'subscriptionManagement.periodFilters.YESTERDAY': 'Yesterday',
  'subscriptionManagement.periodFilters.LAST_WEEK': 'Last week',
  'subscriptionManagement.periodFilters.LAST_MONTH': 'Last month',
  'subscriptionManagement.periodFilters.LAST_SIX_MONTHS': 'Last 6 months',
  'subscriptionManagement.periodFilters.LAST_YEAR': 'Last year',
  'subscriptionManagement.periodFilters.NEXT_WEEK': 'Next week',
  'subscriptionManagement.periodFilters.NEXT_MONTH': 'Next month',
  'subscriptionManagement.periodFilters.CUSTOM': 'Custom date',

  'subscriptionManagement.productFilters.DELIVERY_PRODUCT_NO': 'Product',
  'subscriptionManagement.productFilters.SUBSCRIPTION_NO': 'Abo Nr.',
  'subscriptionManagement.productFilters.CUSTOMER_NO': 'Customer Nr',
  'subscriptionManagement.productFilters.searchPlaceHolder.DELIVERY_PRODUCT_NO': 'SKU; Name; Abo SKU.',
  'subscriptionManagement.productFilters.searchPlaceHolder.SUBSCRIPTION_NO': 'Abo Nr.',
  'subscriptionManagement.productFilters.searchPlaceHolder.CUSTOMER_NO': 'Customer Nr',
  'subscriptionManagement.selectAll': 'Select All',
  'subscriptionManagement.selected': 'Selected',
  'subscriptionManagement.editDeliveryDate': 'Change delivery date',
  'subscriptionManagement.copyCustomerData': 'Copy customer data',
  'subscriptionManagement.copyCustomerData.success': 'Copy customer data success',
  'subscriptionManagement.copyCustomerData.failed': 'Copy customer data failed',
  'subscriptionManagement.cancelSubscriptions': 'cancel subscriptions',

  'subscriptionManagement.table.status.ACTIVE': 'Active',
  'subscriptionManagement.table.status.CANCELED': 'Cancelled',
  'subscriptionManagement.table.status.CREATED': 'Created',
  'subscriptionManagement.table.status.FAILED': 'Failed',
  'subscriptionManagement.table.status.IN_PROGRESS': 'In progress',
  'subscriptionManagement.table.status.DELAYED_IN_DELIVERY': 'Delay in delivery',

  'subscriptionManagement.table.headers.aboNbr': 'Abo Nr.',
  'subscriptionManagement.table.headers.aboProductNumber': 'Werbe-best.',
  'subscriptionManagement.table.headers.baseProductNumber': 'Liefer-best.',
  'subscriptionManagement.table.headers.country': 'Country',
  'subscriptionManagement.table.headers.rotation': 'Rotation',
  'subscriptionManagement.table.headers.productName': 'Product name',
  'subscriptionManagement.table.headers.price': 'Price',
  'subscriptionManagement.table.headers.previousOrder': 'Previous order',
  'subscriptionManagement.table.headers.nextOrder': 'Next order',
  'subscriptionManagement.table.headers.deliveryEdited': 'Delivery edited',
  'subscriptionManagement.table.headers.status': 'Status',
  'subscriptionManagement.table.rowActions.edit': 'Edit',
  'subscriptionManagement.table.rowActions.cancel': 'Cancel',
  'subscriptionManagement.table.rowActions.editProductNumber': 'Edit Product BNR',

  'subscriptionManagement.productReport.headers.deliveryProductNo': 'Product Nr.',
  'subscriptionManagement.productReport.headers.subscriptionProductNo': 'Subscription Nr.',
  'subscriptionManagement.productReport.headers.productName': 'Product Name',
  'subscriptionManagement.productReport.headers.price': 'Price',
  'subscriptionManagement.productReport.headers.subscriptionsCount': 'Subscriptions',
  'subscriptionManagement.productReport.headers.currentStock': 'Stock',
  'subscriptionManagement.productReport.headers.available': 'Available',

  'subscriptionManagement.rotation.ONE_MONTH': 'one Month',
  'subscriptionManagement.rotation.TWO_MONTHS': '2 months',
  'subscriptionManagement.rotation.THREE_MONTHS': '3 months',
  'subscriptionManagement.rotation.FOUR_MONTHS': '4 months',
  'subscriptionManagement.rotation.FIVE_MONTHS': '5 months',
  'subscriptionManagement.rotation.SIX_MONTHS': '6 months',

  'subscriptionManagement.subscriptionNote.titleProductName': 'Notizen: {productName}',
  'subscriptionManagement.subscriptionNote.save': 'Speichern',
  'subscriptionManagement.subscriptionNote.close': 'Abbrechen',

  'subscriptionProductManagement.subscriptionCanceled': 'Subscription cancellation is being processed. It will take a moment.',

  'subscriptionManagement.editDeliveryDate.title': 'Edit subscription',
  'subscriptionManagement.editProductNumber.title': 'Edit product number',
  'subscriptionManagement.editDeliveryDate.reason': 'Would you like to change the next delivery date?',
  'subscriptionManagement.editProductNumber.reason': 'Would you like to change the product number?',
  'subscriptionManagement.editDeliveryDate.closeButton':'Interrupt',
  'subscriptionManagement.editDeliveryDate.submitButton': 'Save and continue',
  'subscriptionManagement.editDeliveryDate.week.option': '1 Week',
  'subscriptionManagement.editDeliveryDate.month.option': '1 Month',
  'subscriptionManagement.editDeliveryDate.skip.option': 'Skip turnus',
  'subscriptionManagement.editDeliveryDate.error': 'Skip Highlight and indicate the error, for example New date field',
  'subscriptionManagement.editDeliveryDate.addNote': 'Add a note (optional)',
  'subscriptionManagement.editDeliveryDate.newDate': 'Neues datum',
  'subscriptionManagement.editDeliveryDate.currentDate': 'Aktuelles datum',
  'subscriptionManagement.editProductNumber.currentSubscriptionNumber': 'Current Subscription Number',
  'subscriptionManagement.editProductNumber.newSubscriptionNumber': 'New Subscription Number',
  'subscriptionManagement.editProductNumber.currentDeliveryNumber': 'Current Delivery Number',
  'subscriptionManagement.editProductNumber.newDeliveryNumber': 'New Delivery Number',
  'subscriptionManagement.editDeliveryDate.multipleSubscriptions.successNotificationHint': 'The delivery date for selected orders has been successfully changed',
  'subscriptionManagement.editDeliveryDate.alreadyProcessedSubscriptions': 'subscriptions have been already processed...',
  'subscriptionManagement.editDeliveryDate.multipleSubscriptions.unsuccessfulNotificationHint': 'Failed to change the delivery date for selected orders',
  'subscriptionManagement.editDeliveryDate.oneSubscription.successNotificationHint': 'The delivery date has been successfully changed',

  'subscriptionProductManagement.subscriptionDetailsView.backToOverview': 'Back to abo-overview',
  'subscriptionProductManagement.subscriptionDetails.fullName': 'Full name',
  'subscriptionProductManagement.subscriptionDetails.completedOrders': 'Completed Orders',
  'subscriptionProductManagement.subscriptionDetails.deliveryDetails': 'Delivery details',
  'subscriptionProductManagement.subscriptionDetails.lastEditDate': 'Last edit date',
  'subscriptionProductManagement.subscriptionDetails.previousDelivery': 'Previous delivery',
  'subscriptionProductManagement.subscriptionDetails.nextDelivery': 'Next delivery',

  'subscriptionProductManagement.subscriptionChangeHistory.STATUS_UPDATED': 'Status updated',
  'subscriptionProductManagement.subscriptionChangeHistory.CREATED': 'Abo created',
  'subscriptionProductManagement.subscriptionChangeHistory.GENERATED_ORDER_FAILED': 'Failed order creation',
  'subscriptionProductManagement.subscriptionChangeHistory.DELIVERY_DATE_UPDATED': 'Delivery date updated',
  'subscriptionProductManagement.subscriptionChangeHistory.PRODUCT_QUANTITY_UPDATED': 'Quantityk updated',
  'subscriptionProductManagement.subscriptionChangeHistory.ROTATION_UPDATED': 'Rotation updated',
  'subscriptionProductManagement.subscriptionChangeHistory.GENERATED_ORDER': 'Auftrag generiert',
  'subscriptionProductManagement.subscriptionChangeHistory.PAYMENT_UPDATED': 'Zahlungsarten',
  'subscriptionProductManagement.subscriptionChangeHistory.DELIVERY_ADDRESS_UPDATED': 'Lieferadresse',
  'subscriptionProductManagement.subscriptionChangeHistory.CUSTOM_NOTE': 'Notiz hinzugefügt',
  'subscriptionProductManagement.subscriptionChangeHistory.DELIVERY_PRODUCT_NUMBER_UPDATED': 'Delivery product number',
  'subscriptionProductManagement.subscriptionChangeHistory.SUBSCRIPTION_PRODUCT_NUMBER_UPDATED': 'Subscription product number',
  
  'subscriptionProductManagement.subscriptionChangeHistory.title': 'History',
  'subscriptionProductManagement.subscriptionChangeHistory.sorting.OLD_FIRST': 'Old first',
  'subscriptionProductManagement.subscriptionChangeHistory.sorting.NEW_FIRST': 'Newest first',
  'subscriptionProductManagement.subscriptionChangeHistory.wilisAgent': 'Wilis Agent: ',
  'subscriptionProductManagement.subscriptionChangeHistory.automaticUpdate': 'Automatic update',
  'subscriptionProductManagement.subscriptionChangeHistory.automaticUpdateNote': 'This is an automatic update',
  'subscriptionProductManagement.subscriptionChangeHistory.newDeliveryDate': 'New delivery date: ',
  'subscriptionProductManagement.subscriptionChangeHistory.turnus': 'Rotation: ',
  'subscriptionProductManagement.subscriptionChangeHistory.payment': 'Payment method: ',
  'subscriptionProductManagement.notes': 'Anmerkungen',
  'subscriptionProductManagement.notes.clickToEdit': 'Klicken Sie hier, um Ihre Notiz zu schreiben...',
  'subscriptionProductManagement.notes.editNote': 'Edit Note',
  'subscriptionProductManagement.notes.editNote.successNotificationHint': 'The note was successfully edited',
  'subscriptionProductManagement.notes.saveNote.successNotificationHint': 'The note was successfully saved',
  'orderId' : 'Internet ANR',
  'subscriptionManagement.productReport.viewSubscriptions': 'View subscriptions',
  'subscriptionManagement.productReport.addNote': 'Add note',
  'subscriptionManagement.productReport.addCustomEventPlaceholder': 'Add letter delivery/email/customer contact details',

} as {[key: string]: string};
