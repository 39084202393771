import {
  call,
  put,
  takeLatest,
  select,
  getContext,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import {
  clearOrder,
  emptyEditedOrderSuccess,
  editOrderSelector,
} from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


export function* clearOrderSaga() {
  const OrderId = yield select(editOrderSelector.getEditedOrderId);
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);

    yield call(apiEditOrder.clearOrder, OrderId);
    yield put(emptyEditedOrderSuccess());
  } catch (error) {
    yield handleFailureState(error, `Couldn't clear order with id ${OrderId} on edit order`, undefined);
  }
}

export default function* clearOrderWatcher() {
  yield takeLatest(clearOrder.type, clearOrderSaga);
}
