export const LAST_BIRTHDAY_CHECK_KEY = 'lastBirthdayCheck';

export function setLastBirthdayCheck(customerId?: string) {
  if (customerId) {
    sessionStorage.setItem(LAST_BIRTHDAY_CHECK_KEY, customerId);
  }
}

export function isBirthdayCheckConfirmed(customerId?: string): boolean {
  const lastBirthdayCheck = sessionStorage.getItem(LAST_BIRTHDAY_CHECK_KEY);
  return lastBirthdayCheck ? lastBirthdayCheck === customerId : false;
}

export function clearBirthdayCheckSession() {
  sessionStorage.removeItem(LAST_BIRTHDAY_CHECK_KEY);
}
