export default {
  'solvency.back': 'Zurück zur Startseite',
  'solvency.customerInfo.title': '1. Kundendaten eingeben',
  'solvency.customerInfo.number': 'Kundennummer',
  'solvency.customerInfo.email': 'E-Mail-Adresse',
  'solvency.customerInfo.firstname': 'Vorname',
  'solvency.customerInfo.input.tip': 'Schnellsuche-Tipp:',
  'solvency.customerInfo.firstname.hint': ' statt Alexandra eingeben',
  'solvency.customerInfo.lastname': 'Nachname',
  'solvency.customerInfo.lastname.hint': ' statt Albrechtsberger eingeben',
  'solvency.customerInfo.dateOfBirth': 'Geburtsdatum (tt.mm.jjjj)',
  'solvency.checkPeriod.title': '2. Zeitraum auswählen',
  'solvency.checkPeriod.startDate': 'Startdatum (tt.mm.jjjj)',
  'solvency.checkPeriod.endDate': 'Enddatum (tt.mm.jjjj)',
  'solvency.errors.minFormFields': 'Please fill 1 argument of the form above',
  'solvency.or': 'Oder',
  'solvency.submit': 'Suchen',
  'solvency.checkPeriod.last14Days': 'Letzten 2 Wochen',
  'solvency.checkPeriod.lastMonth': 'Diesen Monat',
  'solvency.checkPeriod.lastYeas': 'Dieses Jahr',
  'solvency.checkPeriod.allTime': 'Gesamter Zeitraum',
  'solvency.checkPeriod.customRange': 'Datum eingeben',
  'solvency.noResult': 'Unfortunately no search results were found',

  'solvencycheck.customerNumber.range': 'Max length is 8 characters',
  'solvencycheck.firstName.validValue': 'Please enter a valid first name',
  'solvencycheck.firstName.minChars': 'First name must be longer than 2 characters',
  'solvencycheck.firstName.required': 'First name is required',
  'solvencycheck.lastName.validValue': 'Please enter a valid last name',
  'solvencycheck.lastName.minChars': 'Last name must be longer than 2 characters',
  'solvencycheck.lastName.required': 'Last name is required',
  'solvencycheck.filterStartDate.validValue': 'Please enter a valid date',
  'solvencycheck.dateOfBirth.required': 'Birthdate is required',
  'solvencycheck.filterStartDate.required': 'Start date is required',
  'solvencycheck.filterEndDate.validValue': 'Please enter a valid date',
  'solvencycheck.filterEndDate.required': 'End date is required',

  'solvency.status.SUCCESS': 'Positive von {type}',
  'solvency.status.DENIED': 'Negative von {type}',
  'solvency.status.FAILURE': 'Technischer Fehler',
  'solvency.list.status': 'Bonicheckstatus',
  'solvency.list.checkedAt': 'Bonicheckdatum',
  'solvency.list.paymentMethod': 'Zahlungsart',
  'solvency.list.totalPrice': 'Gesamtbetrag',
  'solvency.list.dateOfBirth': 'Geburtsdatum',
  'solvency.list.lastName': 'Nachname',
  'solvency.list.firstName': 'Vorname',
  'solvency.INVOICE': 'Rechnung',
  'solvency.DIRECT_DEBIT': 'Lastschrift',
  'solvency.CASH_ON_DELIVERY': 'Nachnahme',
  'solvency.INSTALLMENTS': 'Ratenzahlung',
  'solvency.RATENKAUF': 'Installments factoring',
  'solvency.result': 'Suchergebnis',
  'solvency.customerInformation': 'Kundendaten & Adressen',
  'solvency.orderInformation': 'Auftrag & Zahlungsinformationen',
  'solvency.checkInformation': 'Boni-Check-Informationen',

  'solvency.personalData': 'Persönliche Daten',
  'solvency.billingAddress': 'Rechnungsadresse',
  'solvency.deliveryAddress': 'Lieferadresse',
  'solvency.customerNr': 'Kundennummer',
  'solvency.email': 'E-Mail-Adresse',
  'solvency.salutation': 'Anrede',
  'solvency.firstName': 'Vorname',
  'solvency.lastName': 'Nachname',
  'solvency.dateOfBirth': 'Geburtsdatum',
  'solvency.phone': 'Telefonnummer',
  'solvency.street': 'Strasse',
  'solvency.streetNumber': 'Hausnummer',
  'solvency.packstation': 'Packstation',
  'solvency.postNumber': 'Post Nummer',
  'solvency.addressAddition': 'Hausnummer-Zusatz',
  'solvency.zipCode': 'PLZ',
  'solvency.city': 'Ort',
  'solvency.country': 'Land',
  'solvency.salutation.MR': 'Herr',
  'solvency.salutation.MS': 'Frau',
  'solvency.assignment': 'Auftrag',
  'solvency.paymentInformation': 'Zahlungsinformationen',
  'solvency.orderId': 'Auftragsnummer',
  'solvency.source': 'Bestellkanal',
  'solvency.totalItems': 'Gesamtartikelanzahl',
  'solvency.source.SHOP': 'Internet (web)',
  'solvency.source.MOBILE': 'Internet (mobile app)',
  'solvency.source.BACKOFFICE': 'Tv',

  'solvency.internalCheckInformation': 'Interne Boni-Check',
  'solvency.externalCheckInformation': 'Externe Boni-Check',
  'solvency.resultCheckInformation': 'Ergebnis Boni-Check',
  'solvency.requestCheckInformation': 'Anfrage Boni-Check',
  'solvency.installmentsCreditLimit': 'Limit Ratenkauf aktuell',
  'solvency.availableInstallmentCreditLimit': 'Limit Ratenkauf frei',
  'solvency.reminderStatus': 'Mahnstatus',
  'solvency.onAccountLimit': 'HSE limit Rechnungskauf',
  'solvency.interestRate': 'TZ-Zins',
  'solvency.checkStatus': 'Bonicheckstatus',
  'solvency.rejectionReason': 'Ablehnungsgrund',
  'solvency.tzCreditLimit': 'TZ-Kreditlimit',
  'solvency.transactionId': 'TZ-Transaktionsnummer',
  'solvency.referenceNumber': 'ZG-Referenznummer',
  'solvency.totalPrice': 'Total price',
  'solvency.totalProductPrice': 'Total product price',
  'solvency.totalSavings': 'Total savings',
  'solvency.type': 'Type',
  'solvency.createdAt': 'Created at',
  'solvency.shippingCost': 'Shipping cost',
  'solvency.shippingMethod': 'Shipping method',
  'solvency.method': 'Method',
  'solvency.answerText': 'Answer text',
  'solvency.checkedAt': 'Checked at',
  'solvency.searchOrder': 'Search by order number, product',
  'solvency.responseValidationCode': 'Response validation code',
  'solvency.consumerCreditLimit1': 'Consumer Credit Limit 1',
  'solvency.consumerCreditLimitExt': 'Consumer Credit Limit Ext',
  'solvency.consumerDunningLevel': 'Consumer Dunning Level',
  'solvency.consumerCreditAmount1': 'Consumer Credit Amount 1',
  'solvency.consumerOpenAmount': 'Consumer Open Amount',
  'solvency.name': 'Name',
  'solvency.accountHolder': 'Account Holder',
  'solvency.iban': 'IBAN',
  'solvency.dueDay':'Due Day',
  'solvency.installmentCount':'Installment Count',
  'solvency.installment':'Installment',
  'solvency.finalInstallment':'final Installment',
  'solvency.total':'Total',
  'solvency.totalInterest':'Total Interest',
  'solvency.usesPrefilledIban':'Uses Prefilled Iban',

  // boniversum result attributes
  'solvency.responseId': 'Kunden Transaktion-ID',
  'solvency.creditLimitCode': 'Kreditlimitcode',
  'solvency.creditLimitAmount': 'Kreditlimitbetrag',
  'solvency.creditLimitExtendable':'Kreditlimit erweiterbar',
  'solvency.creditLimitComment': 'Kreditlimit Kommentar',
  'solvency.lastScoringDate': 'Datum der letzten Wertung',

  'paymentSelection.invoice': 'Rechnung',
  'paymentSelection.paypal': 'PayPal',
  'paymentSelection.directDebit': 'Lastschrift',
  'paymentSelection.installments': 'Ratenzahlung',
  'paymentSelection.creditCard': 'Kreditkarte',
  'paymentSelection.installments.chooseInstallmentsPlan': 'Bitte Ratenplan auswählen',
  'paymentSelection.installments.chooseInstallmentsPlan.subtitle': 'In welchen Raten möchten Sie zahlen? Falls Sie einen Gutschein einlösen wollen, wählen Sie bitte eine andere Zahlungsart.',
  'paymentSelection.installments.installmentPlan.title': '{installmentCount} Monatsraten',
  'paymentSelection.installments.installmentPlan.modalTitle': '{installmentCount} Monatsraten je ca. {monthlyRate}',
  'paymentSelection.installments.installmentPlan.installments': '{installmentRange} Monatsrate zu',
  'paymentSelection.installments.installmentPlan.continue': 'Weiter mit {installmentCount} Monatsraten',
  'paymentSelection.installments.installmentPlan.finalInstallment': '{finalInstallmentNumber} Monatsrate',
  'paymentSelection.installments.installmentPlan.total': 'Gesamtbetrag',
  'paymentSelection.installments.installmentPlan.interestRate': 'Effektiver Jahreszins {interestRate}%',
  'paymentSelection.installments.installmentPlan.totalInterest': 'Zinsen',
  'paymentSelection.installments.dueDay': 'Fälligkeit',
  'paymentSelection.installments.dueDay.title': 'Bitte Fälligkeit auswählen',
  'paymentSelection.installments.dueDay.subtitle': 'Wann möchten Sie die Rate zahlen?',
  'paymentSelection.installments.dueDay.first': 'Am 1. des Monats',
  'paymentSelection.installments.dueDay.fifteenth': 'Am 15. des Monats',

  'bankAccountForm.bankingInfo': 'Bankverbindung',
  'bankAccountForm.installment.bankingInfo': 'Zahlungsdetails',
  'bankAccountForm.iban': 'IBAN',
  'bankAccountForm.account-holder': 'Kontoinhaber',
  'bankAccountForm.bank-name': 'Kreditinstitut (wird berechnet)',
  'bankAccountForm.ibanPrefillConsent':
    'Ich möchte, dass diese Bankverbindung bei meiner nächsten Bestellung vorausgefüllt wird.',
  'prefilled.bank-name': 'Kreditinstitut',
  'prefilled.iban.ending': 'endet auf',

  // Order Confirmation
  'orderConfirmation.confirmationBody':
    'Wir haben Ihnen soeben {numberAndTypeOfMails} an {email} gesendet.',
  'orderConfirmation.emailSummary.oneMailWithDetails':
    '<b>eine Bestellbestätigung</b> mit allen Details',
  'orderConfirmation.emailSummary.twoMails': '<b>zwei</b> E-Mails',
  'orderConfirmation.emailSummary.threeMails': '<b>drei</b> E-Mails',
  'orderConfirmation.emailSummary.fourMails': '<b>vier</b> E-Mails',
  'orderConfirmation.emailSummary.stockReminder.stockReminderConfirmation':
    'Eine Bestellbestätigung für Ihr Wunschprodukt zu Ihrem persönlichen Preis',
  'orderConfirmation.emailSummary.stockReminder.otherItemsConfirmation':
    'Eine Bestellbestätigung für Ihren restlichen Warenkorb',
  'orderConfirmation.emailSummary.stockReminder.oneDelivery':
    'Sie erhalten möglichst alle Artikel von heute in einer Lieferung.',
  'orderConfirmation.emailSummary.orderConfirmationEmail':
    'Eine Bestellbestätigung mit allen Details',
  'orderConfirmation.emailSummary.accountActivationEmail':
    'Eine E-Mail, über die Sie ein Passwort für Ihr HSE Kundenkonto erstellen können',
  'orderConfirmation.emailSummary.confirmNewsletter':
    'Eine E-Mail, über die Sie Ihre Newsletter-Anmeldung bestätigen können',
  'orderConfirmation.id': 'Auftragsnummer',
  'orderConfirmation.SAPOrderNumber': 'SAP Order Number',
  'orderConfirmation.product.articleNr': 'Bestellnr. ',
  'orderConfirmation.product.color': 'Farbe:',
  'orderConfirmation.product.size': 'Größe:',
  'orderConfirmation.product.alloy': 'Legierung:',
  'orderConfirmation.product.taste': 'Geschmacksrichtung:',
  'orderConfirmation.product.motiv': 'Motiv:',
  'orderConfirmation.product.aroma': 'Duftrichtung:',
  'orderConfirmation.product.amount': 'Menge:',
  'orderConfirmation.product.stone': 'Stein:',
  'orderConfirmation.product.others': 'Variante:',
  'orderConfirmation.productPrice': 'Zwischensumme',
  'orderConfirmation.deliveryCost': 'Versandkosten',
  'orderConfirmation.voucherAmount': 'Gutscheinwert',
  'orderConfirmation.totalInterest': 'Zinsen',
  'orderConfirmation.totalSum': 'Gesamtbetrag',
  'orderConfirmation.savings': 'Sie sparen insgesamt: {savings}',
  'orderConfirmation.addShippingFree':
    '<a>Versandkostenfreies Produkt</a> zur Bestellung hinzufügen und noch {amount} sparen.',
  'orderConfirmation.taxes': 'Inkl. MwSt.',
  'orderConfirmation.paymentMethod': 'Zahlungsart',
  'orderConfirmation.paymentMethod.installments': 'Ratenzahlung',
  'orderConfirmation.paymentMethod.invoice': 'Rechnung',
  'orderConfirmation.paymentMethod.invoice.details':
    'Bitte zahlen Sie innerhalb von 14 Tagen nach Erhalt der Ware. Einen vorgedruckten Überweisungsschein finden Sie bei ihrer Rechnung.',
  'orderConfirmation.paymentMethod.paypal': 'PayPal',
  'orderConfirmation.paymentMethod.paypal.details':
    'Wir leiten Sie nun zu PayPal weiter. Anschließend werden Sie wieder zu HSE zurückgeleitet, um Ihre Bestellung abzuschließen.',
  'orderConfirmation.paymentMethod.directDebit': 'Lastschrift',
  'orderConfirmation.paymentMethod.directDebit.details':
    'Mit der Angabe Ihrer Bankverbindung ermächtigen Sie HSE, fällige Zahlungen per Lastschrift von Ihrem Bankkonto einzuziehen. Die Lastschrift findet <b>frühestens 14 Tage</b> nach dem Versand der Ware statt.',
  'orderConfirmation.paymentMethod.creditCard.details':
    'Die Bezahlung mit Kreditkarte ist derzeit bei uns nicht möglich. Sie können jedoch über <b>PayPal</b> per Kreditkarte zahlen.',
  'orderConfirmation.approximateDeliveryTimeframe': 'Lieferung in 3 - 5 Werktagen',
  'orderConfirmation.deliveryAddress': 'Lieferadresse',
  'orderConfirmation.billingAddress': 'Rechnungsadresse',
  'orderConfirmation.billingAndDelivery.same': 'Lieferadresse ist identisch mit Rechnungsadresse.',
  'orderConfirmation.deliveryAddress.theSameAsBillingAddress': 'Identisch mit Rechnungsadresse.',
  'orderConfirmation.coronaHintTitle': 'Aktueller Hinweis wg. Covid-19:',
  'orderConfirmation.coronaHintDetails': 'Bitte beachten Sie: Nachnahmesendungen werden derzeit direkt in die Postfiliale gebracht und müssen vom Empfänger oder einer durch Vollmacht beauftragten Person dort abgeholt werden.',
  'orderConfirmation.product.totalAmount': 'Gesamtpreis:',
  'orderConfirmation.employeeDiscount': 'Rabatt',
};
