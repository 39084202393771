import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  deleteBasketSuccess,
  getBasket,
  setSubscribableOrderLineItemsDetails,
  toggleReklaFlag
} from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { changeSalesChannel } from 'src/redux/order/salesChannelSlice';
import { voucherSelector } from 'src/redux/order/selectors/voucherSelectors';
import { resetVoucherState, setShowDeletedVoucherHint } from 'src/redux/order/voucherSlice';
import { OrderErrorResponseTypes } from 'src/types/offer/OrderErrorResponseTypes';
import { OrderItem } from 'src/types/offer/OrderItem';
import { ProductDetails } from 'src/types/product/product';
import Log from 'src/utils/log';

import { deleteCurrentBasket } from './deleteCurrentBasket';
import { getLinkedSubscriptionsForOrderItems } from './utils/getOrderItemsLinkedProducts';
import {
  checkAndResetPayment,
  getOfferAndStoreBasket,
  OfferResponseResult,
} from '../offer/getOffer';
import { deliveryTimesSaga } from '../product/deliveryTimesSaga';


export function* onGetBasketSaga() {
  try {
    yield call(checkAndResetVoucher);
    yield call(checkAndResetPayment);

    const offerResult: OfferResponseResult | undefined = yield call(getOfferAndStoreBasket);

    let offerResponse = offerResult?.response;
    if(offerResult?.errorTitle === OrderErrorResponseTypes.paymentError) {
      yield call(checkAndResetPayment);

      const secondOfferCalculationRetry: OfferResponseResult | undefined = yield call(getOfferAndStoreBasket);
      offerResponse = secondOfferCalculationRetry?.response;
    } else if (offerResult?.errorTitle === OrderErrorResponseTypes.voucherError) {
      const secondOfferCalculationRetry: OfferResponseResult | undefined = yield call(getOfferAndStoreBasket);
      offerResponse = secondOfferCalculationRetry?.response;
    }

    if(offerResponse) {
      const { offer } = offerResponse;

      //extract productIds and variantIds from basket items
      const productIDs: string[] = [];
      const productSkus: string[] = [];
      offer.items.forEach(product => {
        productIDs.push(product.baseProductNo);
        productSkus.push(product.variant.sku);
      });
      yield call(deliveryTimesSaga, offer.items, [productIDs, productSkus]);
      
      const basketItemsLinkedSubsctiptionProducts: {[sku: string]: ProductDetails} = yield call(getLinkedSubscriptionsForOrderItems,offerResponse.offer.items);
      yield put(setSubscribableOrderLineItemsDetails(basketItemsLinkedSubsctiptionProducts));
    }
    return offerResponse;
  } catch (error) {
    Log.error(`Get basket failed, error: ${JSON.stringify(error)}`);
  }
  return;
}

function* checkAndResetVoucher() {
  const voucherErrorDetails = yield select(voucherSelector.getVoucherErrorDetail);

  yield put(setShowDeletedVoucherHint(false));
  if (voucherErrorDetails){
    yield put(resetVoucherState());
    yield put(setShowDeletedVoucherHint(true));
  }
}

function* checkAndClearRelateBasket(){

  const isRekla = yield select(orderEntrySelector.isReklaOrder);
  const orderLineItems: OrderItem[] = yield select(orderEntrySelector.getOrderLineItems);

  if(isRekla && (orderLineItems.length > 1 || !!orderLineItems.find(it => it.quantity > 1))){
    yield call(deleteCurrentBasket);
    yield put(deleteBasketSuccess());
  }
}

function* onToggleReklaFlag(){
  yield call(checkAndClearRelateBasket);
  yield call(onGetBasketSaga);
}

export default function *onGetBasketWatcher() {
  yield takeEvery(getBasket.type, onGetBasketSaga);
  yield takeEvery(changeSalesChannel.type, onGetBasketSaga);
  yield takeEvery(toggleReklaFlag.type, onToggleReklaFlag);
}
