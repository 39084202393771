import cancelProductWatcher from 'src/sagas/editOrder/cancelProduct';
import changePaymentWatcher from 'src/sagas/editOrder/changePayment';
import changeServiceVoucherWatcher from 'src/sagas/editOrder/changeServiceVoucher';
import handleUpdatedEditedOfferWatcher from 'src/sagas/editOrder/handleUpdatedEditedOfferSaga';
import onCloseInstallmentFactoringModalWatcher from 'src/sagas/editOrder/onCloseInstallmentFactoringModal';
import redeemVoucherWatcher from 'src/sagas/editOrder/redeemVoucher';
import removeProductWatcher from 'src/sagas/editOrder/removeProduct';
import removeVoucherWatcher from 'src/sagas/editOrder/removeVoucher';
import switchDeliveryAddressWatcher from 'src/sagas/editOrder/switchDeliveryAddress';
import updateProductWatcher from 'src/sagas/editOrder/updateProduct';
import updateProductPriceDateWatcher from 'src/sagas/editOrder/updateProductPriceDate';
import handleVoucherSessionChangeWatcher from 'src/sagas/voucher/handleVoucherSessionChangeSaga';

import addNewProductWatcher from './addNewProduct';
import clearOrderWatcher from './clearOrder';
import emptyEditedOrderWatcher from './emptyEditedOrder';
import initEditOrderWatcher from './initEditOrder';
import onEditOrderProductSearchWatcher from './onEditOrderProductSearch';
import submitEditedOrderWatcher from './submitEditedOrder';
import updateWeeTakeBackWatcher from './updateWeeTakeBack';
import upgradeProductSaga from './upgradeProduct';


export default [
  initEditOrderWatcher,
  emptyEditedOrderWatcher,
  submitEditedOrderWatcher,
  addNewProductWatcher,
  onEditOrderProductSearchWatcher,
  updateProductWatcher,
  clearOrderWatcher,
  cancelProductWatcher,
  removeProductWatcher,
  switchDeliveryAddressWatcher,
  changePaymentWatcher,
  redeemVoucherWatcher,
  removeVoucherWatcher,
  upgradeProductSaga,
  changeServiceVoucherWatcher,
  updateWeeTakeBackWatcher,
  updateProductPriceDateWatcher,
  handleVoucherSessionChangeWatcher,
  onCloseInstallmentFactoringModalWatcher,
  handleUpdatedEditedOfferWatcher,
];
