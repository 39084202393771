import { BasketItemRequest } from 'src/types/offer/BasketItemRequest';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';


export function fromSKU(sku: string): BasketItemRequest {
  return {
    baseProductNo: toBaseProductNumber(sku),
    quantity: 1,
    sku,
  };
}
