import { AxiosInstance } from 'axios';

import { getHttpClient } from 'src/api/httpClient/HttpClient';
import { localeHeaderInterceptor } from 'src/api/httpClient/interceptors/localeHeaderInterceptor';
import { refreshTokenInterceptor } from 'src/api/httpClient/interceptors/refreshTokenInterceptor';
import { xAuthorizationHeaderInterceptor } from 'src/api/httpClient/interceptors/xAuthorizationHeaderInterceptor';
import { Endpoints } from 'src/constants/endpoints';
import { EditedOrderHistoryResponse } from 'src/editedorder/types/EditedOrderHistoryResponse';


export interface ApiEditedOrder {
  client: AxiosInstance;
  getHistoryByExternalOrderId: (externalOrderId: string) => Promise<EditedOrderHistoryResponse>;
}

export function getApiEditedOrderHistory(baseUrl: string): ApiEditedOrder {
  const axiosInstance = getHttpClient({
    baseUrl,
    transform: {
      camelize: true,
    },
    headers: { 'Content-Type': 'application/json' },
    interceptors: {
      response: refreshTokenInterceptor,
      request: [
        localeHeaderInterceptor,
        xAuthorizationHeaderInterceptor,
      ],
    },
  });

  return {
    client: axiosInstance,
    getHistoryByExternalOrderId: async (externalOrderId: string) => {
      const response = await axiosInstance.get<EditedOrderHistoryResponse>(`${ Endpoints.editOrder }/history/${externalOrderId}`);
      return response.data;
    },
  };
}
