import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  CancelWaitlistItem,
  cancelWaitlistItem,
  cancelWaitlistItemError,
  cancelWaitlistItemInProgress,
  cancelWaitlistItemSuccess
} from 'src/redux/customer/waitlistHistorySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* cancelWaitlistItemSaga(action: CancelWaitlistItem) {
  try {
    yield put(cancelWaitlistItemInProgress());

    const cancellationWaitlistItemRequest = action.payload.cancellationWaitlistItemRequest;

    const apiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    yield call(apiCallCenter.cancelWaitlistItem, cancellationWaitlistItemRequest);

    yield put(cancelWaitlistItemSuccess(cancellationWaitlistItemRequest));

  } catch (err) {
    const errorCode = (err && err.response && err.response.status) || 400;
    yield call(
      logErrorAndShowNotification,
      `Couldn't cancel waitlist items, body ${JSON.stringify(action.payload.cancellationWaitlistItemRequest)}`,
      'Hoppla... Der Artikel auf der Warteliste konnte nicht storniert werden. Bitte versuchen Sie es erneut',
      err,
    );
    yield put(cancelWaitlistItemError(errorCode));
  }

}

export default function* cancelWaitlistItemWatcher() {
  yield takeLatest(cancelWaitlistItem.type, cancelWaitlistItemSaga);
}
