import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import {
  UpdateOrder,
  updateOrder,
  updateOrderFailed,
  updateOrderSuccess,
} from 'src/OrderDisplay/redux/orderDisplaySlice';
import { showErrorNotification, showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';


function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function* updateOrderSaga(action: UpdateOrder) {
  try {
    const apiOrder = yield getContext(SagaContextItem.apiOrder);
    const orderResponse = yield call(apiOrder.updateOrder, action.payload);
    yield put(updateOrderSuccess(orderResponse));
    yield put(showSuccessNotification('notification.edit.success'));
    scrollToTop();
  } catch (err) {
    yield put(
      logEvent({
        level: LOG_LEVEL.WARN,
        message: `Couldn't update order: ${action.payload.id}`,
        err: err,
      }),
    );

    yield put(updateOrderFailed());
    yield put(showErrorNotification('notification.operation.failed'));
    scrollToTop();
  }
}

export default function* updateOrderWatcher() {
  yield takeLatest(updateOrder.type, updateOrderSaga);
}
