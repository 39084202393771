import { takeLatest } from 'redux-saga/effects';

import { resubmitEditedOrderDone } from 'src/editedorder/reducers/EditedOrderReducers';


function reloadPageSaga() {
  window.location.reload();
}

export default function* reloadPageWatcher() {
  yield takeLatest(resubmitEditedOrderDone.type, reloadPageSaga);
}
