import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { newOrderSuccess } from 'src/redux/order/newOrderSlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { createWaitlistItemSuccess } from 'src/redux/product/createWaitlistItemSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';


export function* setPreferredPaymentSaga() {
  try {
    const existingCustomer = yield select(customerInfoSelectors.isExistingCustomer);
    const currentPreferredPayment = yield select(customerInfoSelectors.getPreferredPayment);
    const selectedPreferredPayment = yield select(customerInfoSelectors.getSelectedPreferredPayment);
    if(!existingCustomer || !selectedPreferredPayment || selectedPreferredPayment === currentPreferredPayment) {
      return;
    }

    const customerId = yield select(customerInfoSelectors.getCustomerId);
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);

    if (selectedPreferredPayment === PaymentMethod.directDebit) {
      const bankInfo = yield select(orderEntrySelector.getCustomerBankingDetailsState);
      if(!bankInfo.isExisted) {
        yield call(
          apiCustomer.createOrReplaceBankAccount,
          customerId,
          { accountHolder: bankInfo.accountHolder, iban: bankInfo.iban },
        );
      }
    }

    yield call(apiCustomer.updatePreferredPaymentMethod, customerId, selectedPreferredPayment);
  } catch (err) {
    yield put(logErrorEvent({ message: 'Could not set preferred payment', err }));
  }
}

export default function* setPreferredPaymentMethodWatcher() {
  yield takeLatest(newOrderSuccess.type, setPreferredPaymentSaga);
  yield takeLatest(createWaitlistItemSuccess.type, setPreferredPaymentSaga);
}
