export default {

  // Cancellation modal

  'subscription.cancellation.title': 'Cancel loyalty subscription',
  'subscription.cancellation.selectReason': 'Select a cancellation reason',
  'subscription.cancellation.question': 'Why do you want to cancel the subscription?',
  'subscription.cancellation.submitCancellation': 'Cancel subscription',

  'subscription.cancellation.reason.ANIMAL_DIED': 'Animal died',
  'subscription.cancellation.reason.INCOMPATIBILITY': 'Intolerance',
  'subscription.cancellation.reason.COLLECTION': 'Collection',
  'subscription.cancellation.reason.QUANTITY_BECAME_TOO_MUCH': 'Amount has become too much',
  'subscription.cancellation.reason.CUSTOMER_DIDNT_WANT_SUBSCRIPTION': 'Customer didn\'t want a subscription at all',
  'subscription.cancellation.reason.PRODUCT_REGULARLY_OFFERED_CHEAPER': 'Product regularly offered at a lower price',
  'subscription.cancellation.reason.NO_MORE_NEED': 'No longer needed',
  'subscription.cancellation.reason.NO_INDICATION_OF_REASON': 'No reason given',
  'subscription.cancellation.reason.DOUBLE_SUBSCRIPTION': 'Double subscription',
  'subscription.cancellation.reason.WRONG_SUBSCRIPTION': 'Wrong subscription article',
  'subscription.cancellation.reason.ORDER_RESTRICTION': 'Order Restriction',
  'subscription.cancellation.reason.NO_LONGER_ON_OFFER': 'No longer on offer',
  'subscription.cancellation.reason.HSE_DECISION': 'HSE decision',
  'subscription.cancellation.reason.SUBSCRIPTION_EXPIRED': 'Subscription expired',
  'subscription.cancellation.reason.VARIANT_CHANGE': 'Variant change',
  'subscription.cancellation.reason.PRICE_INCREASE': 'Price increase',

  //Subscription history
  'subscription.history.title': 'Treue-Abo',
  'subscription.history.header.rejection.reason': 'Absagegrund',
  'subscription.history.header.item.description': 'Artikelbezeichnung',
  'subscription.history.header.price': 'Einzelpreis',
  'subscription.history.header.next_delivery': 'Nächste Lieferung',
  'subscription.history.header.product_id': 'Liefer BNR',
  'subscription.history.header.contract_no': 'Vertrags-Nr.',
  'subscription.history': 'Historie',
  'subscription.history.edit': 'Bearbeiten',
  'subscription.history.cancel': 'Stornieren',

  'subscription.history.status.ACTIVE': 'Aktiv',
  'subscription.history.status.CANCELED': 'Gekündigt',
  'subscription.history.status.WARNING': 'Warnung',

  'subscription.history.delivery.failed': 'Lieferung der Bestellung fehlgeschlagen',

  //edit subscription
  'subscription.history.confirmationTitle': 'Bestätigung Treue-Abo',
  'subscription.history.rejection.reason': 'Fehlermeldung',

  'subscription.history.orderNumber': 'Auftragsnummer',
  'subscription.history.orderQuantity': 'Bestellmenge',
  'subscription.history.unitPrice': 'Einzelpreis',
  'subscription.history.nextOrder': 'Termin nächste',
  'subscription.history.rotation': 'Turnus',
  'subscription.history.startIn': 'Termin nächste / vorh. Anlage',
};
