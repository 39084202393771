import { put } from 'redux-saga/effects';

import { openModal } from 'src/redux/app/appSlice';
import { setBlockedCustomer, setCustomerOrderRestriction } from 'src/redux/customer/customerInfoSlice';
import { fetchCustomerByEmailSuccess, fetchDuplicatedCustomers } from 'src/redux/customer/customerSuggestionsSlice';
import { Customer } from 'src/types/customer/customer';
import { OrderRestriction } from 'src/types/customer/OrderRestriction';
import { Modals } from 'src/types/Modals';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';


export function* handleBlockedCustomer(customerResponse: Customer) {
  if (customerResponse?.orderRestriction === OrderRestriction.departedEmployee) {
    yield put(fetchCustomerByEmailSuccess(customerResponse));
  }
  if (customerResponse?.orderRestriction === OrderRestriction.duplicate) {
    yield put(fetchDuplicatedCustomers(customerResponse));
  }
  yield put(setBlockedCustomer(reformatBirthdayInResponse(customerResponse)));
  yield put(openModal(Modals.customerBlocked));

  if (customerResponse?.orderRestriction) {
    yield put(setCustomerOrderRestriction(customerResponse.orderRestriction));
  }
}
