import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  delay,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import {
  resubmitEditedOrder,
  resubmitEditedOrderDone,
  resubmitEditedOrderFailed,
} from 'src/editedorder/reducers/EditedOrderReducers';
import { showErrorNotification, showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';


function* resubmitEditedOrderSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    yield call(apiEditOrder.submitOrder, action.payload);
    yield put(showSuccessNotification('Resubmission success'));
    yield delay(5000);
    yield put(resubmitEditedOrderDone());
  } catch (error) {
    yield put(resubmitEditedOrderFailed());
    yield put(showErrorNotification(error.response?.data?.detail));
  }
}

export default function* resubmitEditedOrderWatcher() {
  yield takeLatest(resubmitEditedOrder.type, resubmitEditedOrderSaga);
}
