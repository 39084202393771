export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_DISPLAY_DATE_FORMAT_SHORT = 'DD.MM.YY';
export const DEFAULT_SERVICES_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DISPLAY_DATETIME_FORMAT = 'DD.MM.YYYY, HH:mm';
export const DEFAULT_DISPLAY_TIME_FORMAT = 'HH:mm';
export const DISPLAY_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const NOTIFICATION_DISPLAY_DATE_FORMAT = 'DD. MMM. YYYY';
export const DEFAULT_TIMEZONE = 'Europe/Berlin';
export const BERLIN_TIMEZONE = '(Berlin)';

export const DATE_TIME_FORMAT = {
  en: 'DD/MM/YYYY HH:mm',
  de: 'DD.MM.YYYY HH:mm',
};

export const DATE_FORMAT = {
  en: 'DD/MM/YYYY',
  de: 'DD.MM.YYYY',
};
