import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { camelizeKeys } from 'humps';
import queryString from 'query-string';

import { TokenResponse } from 'src/api/authTypes';
import Config from 'src/config';
import { LocalStorageKeys } from 'src/constants/localStorageKeys';
import { login } from 'src/routes/externalRedirects';

import { X_AUTHORITZATION_HEADER, getAuthHeader } from './xAuthorizationHeaderInterceptor';
import { ResponseInterceptor } from '../HttpClient';


export const refreshTokenInterceptor: ResponseInterceptor = {
  onFulfilled : (response: AxiosResponse) => response,
  onRejected: (err: AxiosError) => refreshInvalidTokens(err),
};

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _isUnauthorizedRequestAfterRefreshedToken: boolean;
}

export function refreshInvalidTokens(error: AxiosError) {
  try {
    const originalRequest = error.config as CustomAxiosRequestConfig;

    if (originalRequest._isUnauthorizedRequestAfterRefreshedToken) {
      login();
      return;
    }

    if (error && error.response && error.response.status === 401) {
      const refreshToken = window.localStorage.getItem(
        LocalStorageKeys.RefreshToken,
      );

      if (!refreshToken) {
        login();
        return Promise.reject();
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${Config.env.cognito.url}/oauth2/token`,
            queryString.stringify({
              grant_type: 'refresh_token',
              client_id: Config.env.cognito.clientId,
              refresh_token: refreshToken,
            }),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          )
          .then((response: AxiosResponse<object>) => {
            const data = camelizeKeys(response.data) as TokenResponse;
            localStorage.setItem(LocalStorageKeys.IdToken, data.idToken);
            originalRequest.headers = {
              ...originalRequest.headers,
              [X_AUTHORITZATION_HEADER]: getAuthHeader(),
            };
            originalRequest._isUnauthorizedRequestAfterRefreshedToken = true;
            resolve(axios.request({ ...originalRequest }));
          })
          .catch(err => {
            // Handle failed refresh_token case
            login();
            reject(err);
          });
      });
    }

    return Promise.reject(error);
  } catch (err) {
    const alarmBody = {
      'username': 'Wilis-View alarm',
      'blocks': [
        { 'type': 'section', 'text': { 'type': 'mrkdwn', 'text': `Could not refresh token: ${JSON.stringify(err)}` } },
      ],
    };
    axios.post(Config.env.slackChannelAlarmsWebhook, JSON.stringify(alarmBody));
  }
}
