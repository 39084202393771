
const UPGRADE_PRODUCTS_SESSION_KEY = 'upgradeProducts';

function removeUpgradesCrossSellsFromSession() {
  localStorage.removeItem(UPGRADE_PRODUCTS_SESSION_KEY);
}

// TODO: move more utils here

export {
  removeUpgradesCrossSellsFromSession,
};
