import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { PaymentOption } from 'src/types/offer/PaymentOption';


const isPaymentMethodAvailable = (
  paymentMethod: PaymentMethod | undefined,
  availablePaymentMethods: PaymentOption[],
  disableInstallment?: boolean,
  customerAvailablePaymentMethods?: PaymentOption[],
): boolean => {
  const availableMethods = disableInstallment
    ? availablePaymentMethods.filter((i) => i.method !== PaymentMethod.installments )
    : availablePaymentMethods;

  if (customerAvailablePaymentMethods) {
    return  availableMethods.some(availableMethod => availableMethod.method === paymentMethod && availableMethod.enabled)
      && customerAvailablePaymentMethods.some(availableMethod => availableMethod.method === paymentMethod && availableMethod.enabled);
  }

  return availableMethods.some(availableMethod => availableMethod.method === paymentMethod && availableMethod.enabled);
};

export function allPaymentMethodsDisabled(paymentMethodsOptions: PaymentOption[]): boolean {
  return paymentMethodsOptions.filter(option => option.enabled).length === 0;
}

export default isPaymentMethodAvailable;
