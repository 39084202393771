export default {
  'payment.bankInfo': 'Bitte Bankverbindung eingeben',
  // Validation
  'payment.iban.required': 'Bitte überprüfen Sie das IBAN-Feld',
  'payment.iban.notValid': 'IBAN nicht gültig',
  'payment.iban.noRecognized': 'IBAN nicht erkannt',
  'payment.bankName.required': 'Bitte überprüfen Sie den Banknamen',
  'payment.accountHolder.required': 'Bitte überprüfen Sie den Kontoinhaber',
  'payment.iban': 'IBAN',
  'payment.bankName': 'Kreditinstitut (wird berechnet)',
  'payment.accountHolder': 'Kontoinhaber',
  'payment.submit': 'Hinzufügen',
  'INVOICE': 'Rechnung',
  'DIRECT_DEBIT': 'Lastschrift',
  'INSTALLMENTS': 'Ratenzahlung',
  'INSTALLMENTS_FACTORING': 'Ratenkauf',
  'RATENKAUF': 'Ratenkauf',
  'CASH_ON_DELIVERY': 'Nachnahme',
  'CREDIT_CARD': 'Kreditkarte',
  'payment.accountHolder.minCharct': 'Der Kontoinhaber muss aus mehr als 1 Zeichen bestehen.',
  'paymentSelection.installments.chooseInstallmentsPlan':
    'Bitte Ratenplan auswählen',
  'paymentSelection.installments.chooseInstallmentsPlan.subtitle':
    'In welchen Raten möchten Sie zahlen? Falls Sie einen Gutschein einlösen wollen, wählen Sie bitte eine andere Zahlungsart.',

  'paymentSelection.installments.installmentPlan.modalTitle':
    '{installmentCount} Monatsraten je ca. {monthlyRate}',
  'paymentSelection.installments.installmentPlan.installments':
    '{installmentRange} Monatsrate zu',
  'paymentSelection.installments.installmentPlan.continue':
    'Weiter mit {installmentCount} Monatsraten',
  'paymentSelection.installments.installmentPlan.finalInstallment':
    '{finalInstallmentNumber} Monatsrate',
  'paymentSelection.installments.installmentPlan.total': 'Gesamtbetrag',
  'paymentSelection.installments.installmentPlan.interestRate':
    'Effektiver Jahreszins {interestRate}%',
  'paymentSelection.installments.installmentPlan.totalInterest': 'Zinsen',
  'paymentSelection.installments.dueDay': 'Fälligkeit',
  'paymentSelection.installments.dueDay.title': 'Bitte Fälligkeit auswählen',
  'paymentSelection.installments.dueDay.subtitle':
    'Wann möchten Sie die Rate zahlen?',
  'paymentSelection.installments.dueDay.first': 'Am 1. des Monats',
  'paymentSelection.installments.dueDay.fifteenth': 'Am 15. des Monats',
  'paymentSelection.continue': 'Weiter',
  'paymentSelection.installments.bankAccount': 'Bitte Bankverbindung eingeben',
  'paymentSelection.installments.bankName': 'Kreditinstitut (wird berechnet)',
  'prefilled.change': 'Ändern',
  'payment.chooseAsPreferred': 'Als bevorzugte Zahlungsart festlegen',
  'payment.preferred': 'Bevorzugte Zahlungsart',
  'paymentSelection.installments.chooseInstallmentsFactoringPlan.subtitle': 'In welchen Raten möchten Sie zahlen?',
  'paymentSelection.installments.installmentFactoryPlan.interestRate': 'Effektivzins {interestRate}%',
  'paymentSelection.installments.installmentFactoryPlan.nominalInterestRate': 'Nominalzins {nominalInterestRate}%',
  'paymentSelection.installments.installmentFactoryPlan.fundingAmount': 'Finanzierungsbetrag',
  'paymentSelection.installments.installmentFactoryPlan.installments': '{installmentRange} Monatsrate ',
  'paymentSelection.installments.installmentFactoryPlan.installmentsDates': '({startDate} - {endDate})',
  'paymentSelection.installments.installmentFactoryPlan.finalInstallment': '{finalInstallmentNumber} Monatsrate ',
  'paymentSelection.installments.installmentFactoryPlan.finalInstallmentDate': '({lastDate})',
  'paymentSelection.installments.chooseInstallmentFactoryPlan.subtitle': 'In welchen Raten möchten Sie zahlen?',
  'paymentSelection.installments.campagneType.label': 'Wählen Sie die Art der Kampagne',
  'paymentSelection.installments.zeroPercent.label': '0% Finanzierung',
  'paymentSelection.installments.installmentFactoryPlan.conditions.headline': 'Unbedingt vorlesen',
  'paymentSelection.installments.installmentFactoryPlan.conditions.body.newCustomer': `
    Beim Ratenkauf arbeiten wir mit der payolution GmbH und der Bank Frick & Co. AG zusammen.
    Die Ratenkaufbedingungen und Datenschutzbedingungen finden Sie in unserem Online-Shop unter ‚Ratenkauf‘.
  `,
  'paymentSelection.installments.installmentFactoryPlan.conditions.body.existingCustomer': `Alle Bedingungen und Datenschutzhinweise finden Sie in unserem Online-Shop unter ‚Ratenkauf‘.
  `
};
