import { createSelector } from '@reduxjs/toolkit';

import Config from 'src/config';
import { RootStateType } from 'src/constants/types';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { SelectionState } from 'src/subscriptionProductManagement/utils/subscriptionProductManagementUtils';
import { SubscriptionItemStatusType } from 'src/types/subscription/SubscriptionHistoryResponse';
import { SubscriptionProductResponse } from 'src/types/subscription/SubscriptionsOverviewResponse';
import { createSelectors } from 'src/utils/state';
import { authorizedRole } from 'src/utils/validators/isAuthorized';

import { EditDeliveryDatesState } from '../subscriptionManagementSlice';


const { getAgentUsername, getAgentRoles } = appSelectors;


export const subscriptionManagementSelectors = createSelectors({
  getCurrentView(state: RootStateType) {
    return state.subscriptionManagement.currentView;
  },
  getSubscriptionsOverview(state: RootStateType) {
    return state.subscriptionManagement.data ?? [];
  },
  getSubscriptionsOverviewFetchFilters(state: RootStateType) {
    return state.subscriptionManagement.filters;
  },
  getSubscriptionsTotal(state: RootStateType) {
    if (state.subscriptionManagement.selectAll) {
      return state.subscriptionManagement.totalSubscriptions - state.subscriptionManagement.excludedSubscriptions.length;
    }
    return (
      state.subscriptionManagement.totalSubscriptions ??
      state.subscriptionManagement.data?.length ??
      0
    );
  },
  getOffset(state: RootStateType) {
    return state.subscriptionManagement.offset;
  },
  gettotalSubscriptions(state: RootStateType) {
    return state.subscriptionManagement.totalSubscriptions;
  },
  getLoadingStatus(state: RootStateType) {
    return state.subscriptionManagement.loading;
  },
  getCopyCustomerInfoLoading(state: RootStateType) {
    return state.subscriptionManagement.copyCustomerInfoLoading;
  },
  getLoadingMoreStatus(state: RootStateType) {
    return state.subscriptionManagement.isLoadingMore;
  },
  getHasMore(state: RootStateType) {
    return state.subscriptionManagement.hasMore;
  },
  getSelectedSubscriptions(state: RootStateType) {
    return state.subscriptionManagement.selectedSubscriptions;
  },
  getExcludedSubscriptions(state: RootStateType) {
    return state.subscriptionManagement.excludedSubscriptions;
  },
  getSubscriptionsSelectionState(state: RootStateType): SelectionState {
    const selectedLength = state.subscriptionManagement.selectedSubscriptions.length;
    if (
      selectedLength ===
        state.subscriptionManagement.data?.filter(
          it => it.status.type !== SubscriptionItemStatusType.CANCELED,
        ).length &&
      selectedLength !== 0
    ) {
      return SelectionState.ALL;
    } else if (selectedLength > 0) {
      return SelectionState.SOME;
    }
    return SelectionState.NONE;
  },
  getDeliveryDatesState(state: RootStateType): EditDeliveryDatesState {
    return state.subscriptionManagement.editDeliveryDates;
  },
  getCurrentSubscriptionOverViewView(state: RootStateType) {
    return state.subscriptionManagement.subscriptionOverviewView;
  },
  getCurrentSubscriptionDetails(state: RootStateType) {
    return state.subscriptionManagement.subscriptionDetails;
  },
  getCurrentSubscriptionChangesHistoryState(state: RootStateType) {
    return state.subscriptionManagement.changesHistory;
  },
  getCurrentSubscriptionId(state: RootStateType) {
    return state.subscriptionManagement.subscriptionDetails?.id;
  },
  getCancelSubscriptionItemId(state: RootStateType) {
    return state.subscriptionManagement.cancelSubscriptionState.subscriptionsItemsIds;
  },
  getCancelSubscriptionMode(state: RootStateType) {
    return state.subscriptionManagement.cancelSubscriptionState.mode;
  },
  getCancelSubscriptionReason(state: RootStateType) {
    return state.subscriptionManagement.cancelSubscriptionState.subscriptionCancellationReason;
  },
  getSearchKeyword: (state: RootStateType) => state.subscriptionManagement.filters.keywords,
  areAllSubscriptionsSelected: (state: RootStateType) => state.subscriptionManagement.selectAll,
  getAllSelectedSubscriptionsCount: (state: RootStateType) => {
    return state.subscriptionManagement.totalSubscriptions - state.subscriptionManagement.excludedSubscriptions.length;
  },
  getCustomEventState: (state: RootStateType) => state.subscriptionManagement.customEvent,
  canAccessToReports: createSelector([getAgentUsername, getAgentRoles], (username, cognitoRoles) => {
    const eligibleUsers = Config.env.subscriptionProductManagerUsers;
    const isEligibleUser = eligibleUsers.length === 0 || eligibleUsers.includes(username ?? '');
    const isSubscriptionManagementAgent = authorizedRole('accessSubscriptionReports', cognitoRoles);

    return isSubscriptionManagementAgent || isEligibleUser;
  }),
  canEditProductNumber: createSelector([getAgentUsername], (username) => {
    const eligibleUsers = Config.env.usersAllowedToEditProductNumber;
    return eligibleUsers.length === 0 || eligibleUsers.includes(username ?? '');
  }),
  getEditMultipleSubscriptionNumbersLoadingState(state: RootStateType) {
    return state.subscriptionManagement.editMultipleSubscriptionNumbersLoading;
  },
});

export const getSelectedSubscriptionsCustomers = createSelector(
  subscriptionManagementSelectors.getSelectedSubscriptions,
  subscriptionManagementSelectors.getSubscriptionsOverview,
  (selectedSubscriptionIds: string[], subscriptions: SubscriptionProductResponse[]) => {
    const selectedSubscriptions = subscriptions.filter((it: SubscriptionProductResponse) => selectedSubscriptionIds.includes(it.id));
    return selectedSubscriptions.map((it: SubscriptionProductResponse) => it.details.customer);
  }
);
