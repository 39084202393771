import { camelizeKeys } from 'humps';

import isJsonString from 'src/utils/validators/isJsonString';


export function camelizeKeysTransformer(data: object) {
  return typeof data === 'object'
    ? camelizeKeys(data)
    : isJsonString(data)
      ? camelizeKeys(JSON.parse(data))
      : data;
}
