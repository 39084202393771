
import uniqBy from 'lodash/uniqBy';

import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';
import isNumber from 'src/utils/validators/isNumber';


export default function formatSKUs(SKUs: string[]) {
  const BPNs = uniqBy(SKUs.map(sku => {
    try {
      return toBaseProductNumber(sku);
    } catch (err) {
      console.error(err);
      return sku;
    }
  }), (sku: string) =>  sku);

  return BPNs.filter(sku => sku.length === 6 && isNumber(sku));
}
