import { BillingAddress } from 'src/types/customer/address';
import { AddressValidationRequest } from 'src/types/customer/AddressValidationRequest';
import { Salutation } from 'src/types/customer/Salutation';


const serializeAddress = (address: BillingAddress): AddressValidationRequest => {
  return {
    salutation: Salutation.mister,
    firstName: '',
    lastName: '',
    street: address.street,
    streetNumber: address.streetNumber.toString(),
    zipCode: address.zipCode.toString(),
    city: address.city,
    countryCode: address.countryCode,
  };
};

export default serializeAddress;
