import {
  call,
  getContext,
  put,
} from 'redux-saga/effects';

import { ApiBasket } from 'src/api/apiBasket';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { setBasketId } from 'src/redux/order/orderEntrySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { UpdateItemRequest } from 'src/types/offer/BasketItemRequest';
import {
  addBasketId,
  getBasketId,
  removeBasketId,
} from 'src/utils/basketId';


export enum UpdateBasketItemStatus {
  SUCCESS,
  RESERVATION_FAILURE,
  BASKET_NOT_FOUND,
  ITEM_NOT_FOUND,
  INVALID_PRICE_DATE,
  FAILED
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* updateBasketItem(itemId: string, updateItemRequest: UpdateItemRequest): any {
  const apiBasket: ApiBasket = yield getContext(SagaContextItem.apiBasket);
  const currentBasketId = getBasketId();
  try {
    if(!currentBasketId) {
      return UpdateBasketItemStatus.BASKET_NOT_FOUND;
    }
    
    const basket = yield call(apiBasket.updateItem, itemId, currentBasketId, updateItemRequest);

    if(basket.id !== currentBasketId) {
      addBasketId(basket.id);
      yield put(setBasketId(basket.id));
    }

    return UpdateBasketItemStatus.SUCCESS;
  } catch (basketApiError) {
    const basketErrorResponse = basketApiError.response?.data;
    const errorMessage = basketErrorResponse?.detail ?? basketApiError?.message;
    yield put(showErrorNotification(errorMessage));

    switch (basketErrorResponse?.type) {
    case 'StockReservationFailure': return UpdateBasketItemStatus.RESERVATION_FAILURE;
    case 'ProductNotFound': return UpdateBasketItemStatus.ITEM_NOT_FOUND;
    case 'InvalidPriceDate': return UpdateBasketItemStatus.INVALID_PRICE_DATE;
    case 'BasketNotFound':
      removeBasketId();
      return UpdateBasketItemStatus.BASKET_NOT_FOUND;
    default: return UpdateBasketItemStatus.FAILED;
    }
  }
}
