import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const subscriptionHistorySelector = createSelectors({
  getSubscriptionHistoryItems(state: RootStateType) {
    return state.customer.subscriptionHistory.data?.subscriptions ?? [];
  },
  getSubscriptionHistoryLoadingStatus(state: RootStateType) {
    return state.customer.subscriptionHistory.loading;
  },
  getSubscriptionReasonOfCancellation(state: RootStateType) {
    return state.customer.subscriptionHistory.subscriptionItemCancellationReason;
  },
  getSubscriptionCancellationItemId(state: RootStateType) {
    return state.customer.subscriptionHistory.subscriptionsItemsIds;
  },
  getSubscriptionsSearchCriteria(state: RootStateType) {
    return state.customer.subscriptionHistory.searchCriteria;
  },
  getExternalCustomerId(state: RootStateType) {
    return state.customer.subscriptionHistory.externalCustomerId;
  },
  getSelectedStatus(state: RootStateType) {
    return state.customer.subscriptionHistory.searchCriteria?.status;
  },
});
