type Filter = {
  key: string;
  values: string[];
};

export enum FilterType {
  Hour = 'Stundenangebote',
  Day = 'Tagesangebote',
  Week = 'Wochenangebote',
  Month = 'Monatsangebote',
  Anniversary = 'Jubiläumsangebote',
}

const filterMap: Map<FilterType, Filter> = new Map([
  [
    FilterType.Hour,
    {
      key: 'Salesdrivers',
      values: ['Angebot der Stunde', 'Nur einmal Angebot'],
    },
  ],
  [
    FilterType.Day,
    {
      key: 'Salesdrivers',
      values: [
        'Angebot des Tages',
        'Extra des Tages',
        'Trend des Tages',
        'Wochenend-Extra',
        'Wochenend-Special',
      ],
    },
  ],
  [
    FilterType.Week,
    {
      key: 'Salesdrivers',
      values: ['Joker der Woche', 'Extra der Woche', 'Trend der Woche', 'Angebot der Woche', 'Zusatzangebot', 'Maxi Deal'],
    },
  ],
  [
    FilterType.Month,
    {
      key: 'Salesdrivers',
      values: ['Produkt des Monats', 'Extra des Monats', 'Trend des Monats', 'Angebot des Monats', 'Maxi Deal'],
    },
  ],
  [
    FilterType.Anniversary,
    {
      key: 'Salesdrivers',
      values: ['Jubiläumsangebot'],
    },
  ],
]);

const getFactFinderQuery = (type: FilterType) => {
  const filter = filterMap.get(type) as Filter;
  return filter.values
    .map(value => `&filter=${encodeURI(filter.key)}:${encodeURI(value)}`)
    .join('');
};

export default getFactFinderQuery;
