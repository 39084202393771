import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { subscriptionManagementSelectors } from 'src/subscriptionProductManagement/redux/selectors/subscriptionManagementSelectors';
import { SubscriptionProductResponse } from 'src/types/subscription/SubscriptionsOverviewResponse';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';

import { saveSubscriptionNote, saveSubscriptionNoteSuccess } from '../redux/subscriptionManagementSlice';


export function* saveSubscriptionNoteSaga({ payload }: PayloadAction<string>) {
  try {
    const api: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    const subscription: SubscriptionProductResponse = yield select(subscriptionManagementSelectors.getCurrentSubscriptionDetails);

    yield call(api.editSubscriptionNote, subscription.id, { note: payload });

    yield put(saveSubscriptionNoteSuccess(payload));
    yield put(showSuccessNotification('subscriptionProductManagement.notes.saveNote.successNotificationHint'));
    
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      'oops... failed to save subscription note',
      'hoppla... abonnementnotiz konnte nicht gespeichert werden',
      err,
    );
  }
}

export function* saveSubscriptionNoteWatcher() {
  yield takeLatest(saveSubscriptionNote.type, saveSubscriptionNoteSaga);
}
