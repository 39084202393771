import { CountryCode } from 'src/api/orderTypes';
import { OfferSummaryProps } from 'src/CHOrderEntry/components/OrderSummary/OfferSummary';
import Config from 'src/config';
import { RootStateType } from 'src/constants/types';
import { DeliveryAddressType } from 'src/types/customer/address';
import { Customer } from 'src/types/customer/customer';
import { Salutation } from 'src/types/customer/Salutation';
import { Offer } from 'src/types/offer/Offer';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { Currency } from 'src/types/product/Currency';
import { mockState, orderHistoryMock } from 'src/utils/test-object';


export function buildOrderSummaryInfo(offer?: Offer, editedOffer?: EditedOffer): OfferSummaryProps {
  return editedOffer?.editedOrderId ? {
    currency: editedOffer?.currency || Currency.chf,
    subtotal: editedOffer?.totalProductPrice || 0,
    deliveryPrice: editedOffer?.shippingCost?.amount || 0,
    totalPrice: editedOffer?.totalPrice || 0,
  } : {
    currency: offer?.currency || Currency.chf,
    subtotal: offer?.totalProductPrice || 0,
    deliveryPrice: offer?.shippingCost || 0,
    totalPrice: offer?.totalPrice || 0,
    freeShippingReason: offer?.freeShippingReason,
  };
}

export const chCustomer: Customer = {
  id: Config.env.hardcodedB2BCustomerId,
  firstName: 'MS ab 8/15 umfirmiert MS Direct Group AG',
  lastName: 'Mail Service AG',
  salutation: Salutation.mister,
  dateOfBirth: '08.08.2019',
  deliveryAddresses: [{
    externalAddressId: '11199999',
    salutation: Salutation.mister,
    firstName: 'MS ab 8/15 umfirmiert MS Direct Group AG',
    lastName: 'Mail Service AG',
    street: 'Furstenlandstrasse',
    addressAddition: '1',
    streetNumber: '35',
    zipCode: '9001',
    city: 'St.Galllen',
    countryCode: CountryCode.ch,
    isDefault: true,
    fromInvoiceAddress: true,
    type: DeliveryAddressType.Postal,
  }],
  phoneNumbers: [],
  origin: '',
  externalCustomerId: '11199999',
  billingAddress: {
    street: 'Furstenlandstrasse',
    addressAddition: '1',
    streetNumber: '35',
    zipCode: '9001',
    city: 'St.Galllen',
    countryCode: CountryCode.ch
  },
};

export const mockStateForCHOrderEntry: RootStateType = {
  ...mockState,
  order: {
    ...mockState.order,
    orderEntry: {
      ...mockState.order.orderEntry,
      preGeneratedErpNumber: 16000000001
    }
  },
  customer: {
    ...mockState.customer,
    info: {
      ...mockState.customer.info,
      customerResponse: chCustomer
    }
  },
  chOrderEntry: {
    ...mockState.chOrderEntry,
    ordersHistory: {
      ...mockState.chOrderEntry.ordersHistory,
      searchResult:  { ...orderHistoryMock[0], number: '16000000001' }
    }
  }
};