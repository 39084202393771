export default {
  'address.create.validation.phoneNumber': 'Bitte telefonnummer prüfen',
  'address.create.validation.required.street': 'Bitte Straße prüfen',
  'address.create.validation.required.streetNumber': 'Bitte HAusnummer prüfen',
  'address.create.validation.required.zipCode': 'Bitte Postleitzahl prüfen',
  'address.create.validation.required.city': 'Bitte Ort prüfen',
  'address.create.validation.required.firstName': 'Bitte Vorname prüfen',
  'address.create.validation.required.lastName': 'Bitte Nachname prüfen',
  'address.create.validation.required.packstation': 'Please check the packstation number',
  'address.create.validation.required.postNumber': 'Please check DHL post number',
  'address.create.validation.required.careOf': 'Ungültiger Adresszusatz',
  'address.create.validation.required.addressAddition': 'Ungültiger Hausnummernzusatz',
  'address.new': 'New Address',
  'address.billingAsDelivery': 'To the billing adress',
  'address.deliveryAddress': 'Delivery address {index}',
  'address.packstation': 'Packstation',
  'address.postal': 'Postal',
  'deliveryAddress.billingAddress':'Lieferadresse (Rechnungsadresse): ',
  'deliveryAddress.postalAddress':'Lieferadresse (Postanschrift): ',
  'deliveryAddress.packstationAddress':'Lieferadresse (Packstation): ',
  'customer.newDelivery.address': 'Add new delivery address',
  'customer.deliveryAddress.chooseAsPreferred': 'Set as preferred address',
  'customer.deliveryAddress.preferred': 'Preferred address',

  'customer.deliveryAddress.maxAllowedAddressesReached': 'Max allowed delivery addresses reached',
  'address.cantUsePackstation': 'Bulky items will not be delivered to a packing station',
  'address.cantUsePackstationSelectAnotherAddress': 'Bulky items will not be delivered to a packing station. Please select a different delivery address',
  'address.cantDeliverToThisAddressSelectAnotherAddress': 'Delivery to this address is not possible, please chose a diffrent one',
  'address.packstationPostNumberInvalid': 'Packstation fehlerhaft. Bitte neu erfassen.',

  'address.kat1.title': 'Bestellung nicht möglich',
  'address.kat1.title.delivery': 'Bestellung an diese Lieferadresse nicht möglich',
  'address.kat1.cantUseAddressDE': '“Eine Bestellung an diese Adresse ist nicht möglich. Bitte wenden Sie sich an die Service-Kollegen, unter der Rufnummer 0800 23 414 74.”',
  'address.kat1.cantUseAddressAT': '“Eine Bestellung an diese Adresse ist nicht möglich. Bitte wenden Sie sich an die Service-Kollegen, unter der Rufnummer 0800 23 41 47.”',
  'address.kat1.reason.invoice': '“Das sehe ich hier nicht, aber die Kollegen in der Service-Hotline helfen Ihnen gerne weiter.”',
  'address.kat1.cantUseDeliveryAddress': '“Eine Bestellung an diese Lieferadresse ist nicht möglich. Bitte Rechnungsadresse verwenden.”',
  'address.kat1.reason.deliveryDE': '“Das sehe ich hier nicht. Bitte wenden Sie sich bei Fragen an die Kollegen der Service-Hotline, unter der Rufnummer 0800 23 414 74. Diese sind von 8 bis 21 Uhr erreichbar.”',
  'address.kat1.reason.deliveryAT': '“Das sehe ich hier nicht. Bitte wenden Sie sich bei Fragen an die Kollegen der Service-Hotline, unter der Rufnummer 0800 23 41 47. Diese sind von 8 bis 21 Uhr erreichbar.”',
  'address.kat1.useInvoiceAddress': 'Mit Rechnungsadresse fortfahren',
  'address.kat1.editOrderHint': 'Delivery to this address is not possible',
  'address.checkService.isDown': 'Address verification temporarily offline.',
  'address.processWhenCheckService.isDown': 'Proceed without exam.',

  'address.suggest.modalTitle': 'Bestätige die Adresse',
  'address.suggest.selectAddressText': 'Bitte die gewünschte Adresse auswählen',
  'address.suggest.confirmAddressText': 'Ist das die gewünschte Adresse?',
  'address.suggest.useSuggestion': 'Vorschlag verwenden',
  'address.suggest.keepEnteredAddress': 'Eingegebene Adresse behalten',
  'address.suggest.continue': 'Weiter',
};
