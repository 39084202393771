// eslint-disable-next-line
import { put } from 'redux-saga/effects';
import { showErrorNotification } from 'src/redux/app/appSlice';
import { searchError } from 'src/redux/order/orderEntrySlice';

// eslint-disable-next-line
export function* showNotificationAndLogError(error: any) {
  const errorMessage = error.response ? error.response.data?.detail : error?.message;
  yield put(showErrorNotification(errorMessage));
  yield put(searchError(error));
}
