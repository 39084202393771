export default {
  'inflightOrder.INVALID_BASE_PRODUCT_NUMBER_SELECTION': 'Ungültige Bestellnummer',
  'inflightOrder.request.INVALID_BASE_PRODUCT_NUMBER_SELECTION': 'Bitte gleichen Sie die Bestellnummer ab.',

  'inflightOrder.INVALID_PRODUCT_VARIANT_SELECTION': 'Variante nicht erkannt',
  'inflightOrder.request.INVALID_PRODUCT_VARIANT_SELECTION': 'Bitte gleichen Sie die Bestellnummer ab.',

  'inflightOrder.INVALID_CROSS_SELLING': 'Variante nicht erkannt',
  'inflightOrder.request.INVALID_CROSS_SELLING': 'Bitte gleichen Sie die Bestellnummer ab.',

  'inflightOrder.INVALID_AUTHENTICATION': 'Customer data does not match',
  'inflightOrder.request.INVALID_AUTHENTICATION': 'Please look for the right customer account.',

  'inflightOrder.INVALID_VOUCHER_SELECTION': 'Gutscheineinlösung nicht möglich',
  'inflightOrder.request.INVALID_VOUCHER_SELECTION': 'Prüfen Sie, ob der Gutschein im Kundenkonto hinterlegt ist, sowie die Gültigkeit und den Mindestbestellwert des Gutscheins.',

  'inflightOrder.INVALID_ADDRESS_SELECTION': 'Lieferadresse prüfen/ändern',
  'inflightOrder.request.INVALID_ADDRESS_SELECTION': 'Bitte klären Sie die gewünschte Lieferadresse ab.',

  'inflightOrder.INVALID_PAYMENT_METHOD_SELECTION': 'Zahlungsart ändern',
  'inflightOrder.request.INVALID_PAYMENT_METHOD_SELECTION': 'Bitte klären Sie die gewünschte Zahlungsart ab.',

  'inflightOrder.INVALID_ORDER': 'Bestellung abschließen',
  'inflightOrder.request.INVALID_ORDER': 'Bitte Bestellung abschließen. (ggf. sichere Zahlungsart anbieten)',

  'inflightOrder.WEEE_TAKEBACK_HANDOVER': 'Collection of old electrical equipment',
  'inflightOrder.request.WEEE_TAKEBACK_HANDOVER_1': 'Please request a phone number or email address.',
  'inflightOrder.request.WEEE_TAKEBACK_HANDOVER_2': 'Please match phone number with customer.',
  'inflightOrder.request.WEEE_TAKEBACK_HANDOVER_3': 'Please match e-mail address with customer.',
  
  'inflightOrder.BASKET_NOT_FOUND': 'Technischer Fehler Warenkorb',
  'inflightOrder.request.BASKET_NOT_FOUND': 'Bitte Bestellung ggf. neu erfassen.',

  'inflightOrder.submit': 'Weiter',
  'inflightOrder.submitGoToVariantSelection': 'Continue to the variant selection',
};