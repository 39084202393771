import { ProductDetails } from 'src/types/product/product';


export interface TopDeals {
  daily: ProductDetails[];
  weekly: ProductDetails[];
  monthly: ProductDetails[];
}

export interface TvOffers {
  hse24: ProductDetails[];
  hse24_extra: ProductDetails[];
  hse24_trend: ProductDetails[];
}

export interface OffersState {
  loading: boolean;
  topDeals: TopDeals;
  tvOffers: TvOffers;
  error?: boolean;
  showOffers: boolean;
  allOfferItems?: ProductDetails[];
  offersType?: string;
}

export const offersInitialState: OffersState = {
  loading: false,
  topDeals: {
    daily: [],
    weekly: [],
    monthly: [],
  },
  tvOffers: {
    hse24: [],
    hse24_extra: [],
    hse24_trend: [],
  },
  showOffers: false,
};
