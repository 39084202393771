import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import {
  ChangeQuantityAction,
  setQuantityFields,
  deleteBasketSuccess,
  deleteBasketItemStatus,
  updateBasketItemStatus,
  changeQuantity,
} from 'src/redux/order/orderEntrySlice';
import { getOrderLineItemById } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { onGetBasketSaga } from 'src/sagas/orderEntry/onGetBasket';
import { showNotificationAndLogError } from 'src/sagas/orderEntry/showNotificationAndLogError';
import { updateBasketItem, UpdateBasketItemStatus } from 'src/sagas/orderEntry/updateBasketItemService';
import { OrderItem } from 'src/types/offer/OrderItem';


export function *onChangeQuantitySaga(action: ChangeQuantityAction) {
  try {
    yield put(updateBasketItemStatus({ id: action.payload.id, status: 'in_progress' }));

    const updateItemStatus = yield call(updateBasketItem, action.payload.id, { quantity: action.payload.quantity });

    if(updateItemStatus === UpdateBasketItemStatus.BASKET_NOT_FOUND) {
      yield put(deleteBasketSuccess());
      return;
    }

    if(updateItemStatus === UpdateBasketItemStatus.RESERVATION_FAILURE) {
      const orderLineItem: OrderItem = yield select(getOrderLineItemById(action.payload.id));
      if(orderLineItem) {
        yield put(setQuantityFields({ sku: orderLineItem.variant.sku, quantity: orderLineItem.quantity }));
      }
      return;
    }

    yield call(onGetBasketSaga);
  } catch (error) {
    yield call(showNotificationAndLogError, error);
  } finally {
    yield put(deleteBasketItemStatus(action.payload.id));
  }
}

export default function *onChangeQuantityWatcher() {
  yield takeLatest(changeQuantity.type, onChangeQuantitySaga);
}
