export enum ServiceVoucher {
  verargerterKd = 'VERARGERTERKD',
  // neukunde24 = 'NEUKUNDE24',
  systemFehler = 'SYSTEMFEHLER',
  umatausch = 'UMTAUSCH',
}

export enum ExtraServiceVoucher {
  noService = 'NO_SERVICE',
}

export function inferServiceVoucherFromString(value?: string): ServiceVoucher | ExtraServiceVoucher | undefined {
  switch (value) {
  case ServiceVoucher.umatausch.toString() : return ServiceVoucher.umatausch;
  case ServiceVoucher.systemFehler.toString() : return ServiceVoucher.systemFehler;
  case ServiceVoucher.verargerterKd.toString() : return ServiceVoucher.verargerterKd;
  default: return ExtraServiceVoucher.noService;
  }
}
