import 'core-js/es';
import './polyfills';
import './config/sentry';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { FeatureFlags } from './config/featureFlags';
import App from './containers/App/App';
import { configureStore } from './store';
import { baseTheme } from './themes/theme';
import './styles/fonts.module.scss';
import './utils/validators/yupSchema';


const history = createBrowserHistory();
const store = configureStore(history);

/**
 * Mock some endpoints like offers and topDeals to facilitate testing on staging
 * Check ./api/mocks/handlers for more details
 */

if (window.hseEnv === 'staging') {
  const { worker } = require('./api/mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
  document.title = `[STAGING] ${document.title}`;
}

ReactDOM.render(
  <ThemeProvider theme={baseTheme}>
    <StylesProvider injectFirst={true}>
      <CssBaseline />
      <Provider store={store}>
        <FeatureFlags>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </FeatureFlags>
      </Provider>
    </StylesProvider>
  </ThemeProvider>,
  document.getElementById('root') as HTMLElement,
);
