import { ApiAddress } from 'src/api/apiAddress';
import { ApiBasket } from 'src/api/apiBasket';
import { ApiCallCenter } from 'src/api/apiCallCenter';
import { ApiCustomer } from 'src/api/apiCustomer';
import { ApiEditOrder } from 'src/api/apiEditOrder';
import { ApiGuestOrder } from 'src/api/apiGuestOrder';
import { ApiNotifications } from 'src/api/apiNotifications';
import { ApiOffer } from 'src/api/apiOffer';
import { ApiOffers } from 'src/api/apiOffers';
import { ApiOrderHistory } from 'src/api/apiOrderHistory';
import { ApiProduct } from 'src/api/apiProduct';
import { ApiSearch } from 'src/api/apiSearch';
import { ApiStockHistory } from 'src/api/apiStockHistory';
import { ApiSubscriptionsManagement } from 'src/api/apiSubscriptionsManagement';
import { ApiUpsells } from 'src/api/apiUpsells';
import { ApiVirtualVoucher } from 'src/api/apiVirtualVoucher';
import { FactFinderService } from 'src/api/ProductSearchService/FactFinderService';
import { ApiEditedOrder } from 'src/editedorder/api/apiEditedOrder';
import { ApiLogging } from 'src/logging/loggingService';

import { ApiLetterPreview } from '../api/apiLetterPreview';


export enum SagaContextItem {
  ProductSearchService = 'ProductSearchService',
  apiBasket = 'apiBasket',
  apiCallCenter = 'apiCallCenter',
  apiCustomer = 'apiCustomer',
  apiOrder = 'apiOrder',
  apiOffer = 'apiOffer',
  apiGuestOrder = 'apiGuestOrder',
  apiAddress = 'apiAddress',
  apiProduct = 'apiProduct',
  apiOrderHistory = 'apiOrderHistory',
  apiStockHistory = 'apiStockHistory',
  apiUpsells = 'apiUpsells',
  apiOffers = 'apiOffers',
  apiVirtualVoucher = 'apiVirtualVoucher',
  apiVoucher = 'apiVoucher',
  apiNotifications = 'apiNotifications',
  apiEditOrder = 'apiEditOrder',
  apiEditedOrder = 'apiEditedOrder',
  apiLogging = 'apiLogging',
  apiSubscriptionsManagement = 'apiSubscriptionsManagement',
  apiPreviewLetter = 'apiPreviewLetter',
  apiSearch = 'apiSearch'
}

export interface ReduxSagaContext {
  ProductSearchService: FactFinderService;
  apiCustomer: ApiCustomer;
  apiBasket: ApiBasket;
  apiCallCenter: ApiCallCenter;
  apiOffer: ApiOffer;
  apiGuestOrder: ApiGuestOrder;
  apiAddress: ApiAddress;
  apiProduct: ApiProduct;
  apiOrderHistory: ApiOrderHistory;
  apiStockHistory: ApiStockHistory;
  apiUpsells: ApiUpsells;
  apiOffers: ApiOffers;
  apiVirtualVoucher: ApiVirtualVoucher;
  apiNotifications: ApiNotifications;
  apiEditOrder: ApiEditOrder;
  apiEditedOrder: ApiEditedOrder;
  apiLogging: ApiLogging;
  apiSubscriptionsManagement: ApiSubscriptionsManagement
  apiPreviewLetter: ApiLetterPreview
  apiSearch: ApiSearch;
}
