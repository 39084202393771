import { CountryCode } from 'src/api/orderTypes';
import { Currency } from 'src/types/product/Currency';
import { DeliveryTimeMessage } from 'src/types/product/DeliveryTime';
import { BasePrice } from 'src/types/product/prices';


export enum DimensionKind {
  color = 'COLOR',
  size = 'SIZE',
  alloy = 'ALLOY',
  taste = 'TASTE',
  motive = 'MOTIV',
  aroma = 'AROMA',
  amount = 'AMOUNT',
  stone = 'STONE',
  others = 'OTHERS'
}

export enum StockLevel {
  soldOut = 'SOLD_OUT',
  almostSoldOut = 'ALMOST_SOLD_OUT',
  available = 'AVAILABLE',
  notSellable = 'NOT_SELLABLE'
}

export interface MediaUri {
  uri: string;
  mediaType: string;
}

export interface Dimension {
  type: DimensionKind;
  value: string;
}

export interface PriceReference {
  value: number;
  saving: number;
}

export interface Price {
  reference?: PriceReference;
  base?: BasePrice;
  value: number;
  currencyCode?: Currency;
  countryCode?: CountryCode;
  valueAfterDiscount?: number;
  label?: string;
  date?: string;
}

export interface Variant {
  dimensions?: Dimension[];
  price: Price;
  sku: string;
  images?: MediaUri[];
  deliveryTime?: DeliveryTimeMessage;
  status?: string;
}
