import { ProductDetails, ProductImpressionsViewItem } from 'src/types/product/product';


window.dataLayer = window.dataLayer || [];
export const unTrackedUpSellProductsImpressions = (products: ProductDetails[]) => {
  const eecProductImpression = window.dataLayer.filter(
    (e: { event: string }) => e.event === 'eecProductImpression',
  );
  const existingUpSellProducts: string[] = [];

  eecProductImpression.map((e: { ecommerce: { impressions: ProductImpressionsViewItem[] } }) => {
    const Ids = e.ecommerce.impressions.map((el: ProductImpressionsViewItem) => {
      return el.id;
    });
    existingUpSellProducts.push(...Ids);
  });

  const missingUpSellsProduct = products.filter(
    (value: ProductDetails) => !existingUpSellProducts.includes(value.baseProductNo),
  );

  return missingUpSellsProduct;
};
