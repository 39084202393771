import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { closeModal, showSuccessNotification } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { subscriptionReportSelectors } from 'src/subscriptionProductManagement/redux/selectors/subscriptionReportSelectors';
import {
  editSubscriptionProductReport,
  editSubscriptionProductReportFailed,
  editSubscriptionProductReportSuccess,
  SelectedSubscriptionProductReport,
} from 'src/subscriptionProductManagement/redux/subscriptionReportSlice';
import { SubscriptionProductReport } from 'src/subscriptionProductManagement/types/response/SubscriptionReportResponse';
import { Modals } from 'src/types/Modals';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* editSubscriptionProductReportSaga({ payload }: PayloadAction<string>) {
  try {
    const api: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    const selectedReport: SelectedSubscriptionProductReport = yield select(subscriptionReportSelectors.getSelectedSubscriptionProductReport);

    yield call(api.editSubscriptionProductReport, selectedReport.id, { note: payload });

    const reportsItems: SubscriptionProductReport[] = yield select(subscriptionReportSelectors.getSubscriptionsReport);

    const refreshedItems = reportsItems.map(item => item.id === selectedReport.id ? { ...item, note: payload } : item);

    yield put(editSubscriptionProductReportSuccess(refreshedItems));
    yield put(closeModal(Modals.editSubscriptionProductReportNote));
    yield put(showSuccessNotification('subscriptionProductManagement.notes.editNote.successNotificationHint'));
  } catch (err) {
    yield put(editSubscriptionProductReportFailed());
    yield call(
      logErrorAndShowNotification,
      'oops... failed to save or edit subscription product note',
      'hoppla... abonnementnotiz konnte nicht gespeichert werden',
      err,
    );
  }
}

export function* editSubscriptionProductReportWatcher() {
  yield takeLatest(editSubscriptionProductReport.type, editSubscriptionProductReportSaga);
}
