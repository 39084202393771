import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { chCustomer } from 'src/CHOrderEntry/utils/chOrderEntryUtils';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { openModal } from 'src/redux/app/appSlice';
import { fetchCustomerSuccess, storeSelectedDeliveryAddressOrUseBilling } from 'src/redux/customer/customerInfoSlice';
import {
  initEditOrder,
  initEditOrderSuccess,
  setEditedOrderDeliveryTimes,
  setBankDetails,
  changeInstallmentDueDay,
} from 'src/redux/editOrder/editOrderSlice';
import { findCustomerByExternalId } from 'src/sagas/customer/fetchCustomerByNumberSaga';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import { OrderItem } from 'src/types/offer/OrderItem';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { EditedOffer, EditedOrder } from 'src/types/orderhistory/EditedOrder';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';
import { getDeliveryTimeMessage } from 'src/utils/getters/getDeliveryTimes';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';

import { getItemsDeliveryTimesSaga } from '../product/getItemsDeliveryTimesSaga';


export function* initEditOrderSaga(action: PayloadAction<string>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    const { originalOrder, editedOffer }: EditedOrder = yield call(apiEditOrder.initEditOrder, action.payload);
    
    const customer: Customer = originalOrder.customerId === chCustomer.id ? chCustomer :
      yield call(findCustomerByExternalId, originalOrder.externalCustomerId!);
    
    const offer = yield call(setDeliveryTimesSaga, editedOffer);

    const editedOrder: EditedOrder = {
      editedOffer: offer,
      originalOrder: {
        ...originalOrder,
        customer,
      },
    };

    yield put(initEditOrderSuccess(editedOrder));
    yield put(fetchCustomerSuccess(reformatBirthdayInResponse(customer)));
    
    const currentAddress = {
      ...offer.deliveryAddress,
      id: offer.deliveryAddress?.id ?? customer?.id
    };
    yield put(storeSelectedDeliveryAddressOrUseBilling(currentAddress));
    if(customer.bankingDetails) {
      yield put(setBankDetails({ bankingDetails: customer.bankingDetails }));
    }

    if(editedOffer.payment?.method === PaymentMethod.installments) {
      yield put(changeInstallmentDueDay(editedOffer.payment.dueDay));
    }

    if(originalOrder.isEditable === undefined) {
      yield put(logEvent({ message: 'originalOrder.isEditable should never be undefined', level: LOG_LEVEL.ERROR }));
    }
    if (originalOrder.isEditable === false) {
      yield put(openModal(Modals.orderNotEditable));
    }

  } catch (error) {
    yield handleFailureState(error,'Couldn\'t init Edit Order actionRequest: ', action.payload);
  }
}


export function* setDeliveryTimesSaga(editedOffer: EditedOffer) {
  //extract productIds and variantIds from basket items
  const productIDs: string[] = [];
  const productSkus: string[] = [];
  editedOffer.items.forEach(product => {
    try {
      productIDs.push(toBaseProductNumber(product?.sku ?? '') ?? product.baseProductNo);
      productSkus.push(product.variant.sku);
    } catch (err) {
      console.error(err);
    }
  });

  const orderItems = yield call(getItemsDeliveryTimesSaga, editedOffer.items as OrderItem[], [productIDs, productSkus]);

  if (orderItems.length > 0) {
    const orderDeliveryTimes = getDeliveryTimeMessage(orderItems);
    yield put(setEditedOrderDeliveryTimes(orderDeliveryTimes));
  }

  return { ...editedOffer, items: orderItems };
}

export default function* initEditOrderWatcher() {
  yield takeLatest(initEditOrder.type, initEditOrderSaga);
}
