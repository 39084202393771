import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal } from 'src/redux/app/appSlice';
import { CheckCustomerByNameAndBirthday, checkCustomerByNameAndBirthday } from 'src/redux/customer/customerInfoSlice';
import {
  fetchCustomerByNameAndBirthdaySuccess,
  setCustomerCreateFormValues,
  setIgnoreCustomerWithExternalId
} from 'src/redux/customer/customerSuggestionsSlice';
import { customerSuggestionsSelector } from 'src/redux/customer/selectors/customerSuggestionsSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { CheckCustomerRequest } from 'src/types/customer/CheckCustomerRequest';
import { Customer } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';
import formatBirthdayToServicesFormat from 'src/utils/formatters/formatBirthdayToServicesFormat';
import stringify from 'src/utils/stringify';


export function* checkCustomerByNameAndBirthdaySaga(action: CheckCustomerByNameAndBirthday) {
  yield call(checkCustomer, action.payload);
}

export function* checkCustomer(payload: Customer) {
  try {
    const customerApi: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    const requestPayload = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      dateOfBirth: formatBirthdayToServicesFormat(payload.dateOfBirth),
    } as CheckCustomerRequest;

    let response: Customer[] = yield call(customerApi.fetchCustomersByNameAndDateOfBirth, requestPayload);
    const ignoreCustomerWithExternalId = yield select(customerSuggestionsSelector.getIgnoreCustomerWithExternalId);

    if (ignoreCustomerWithExternalId !== undefined) {
      response = response.filter((value) => value.externalCustomerId !== ignoreCustomerWithExternalId);
      if (response.length === 0){
        return;
      }
    }

    yield put(fetchCustomerByNameAndBirthdaySuccess(response));
    yield put(setCustomerCreateFormValues(payload));
    yield put(openModal(Modals.duplicatedCustomer));
  } catch (err) {
    if(!isNotFoundStatus(err)) {
      yield put(logErrorEvent({ message: `Could not check customer ${stringify(payload)}`, err }));
    }
  } finally {
    yield put(setIgnoreCustomerWithExternalId(undefined));
  }
}

export function* checkCustomerByNameAndBirthdayWatcher() {
  yield takeLatest(checkCustomerByNameAndBirthday.type, checkCustomerByNameAndBirthdaySaga);
}
