import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';


import { ApiSearch } from 'src/api/apiSearch';
import {
  FetchProductSuggestionsResults,
  fetchProductSuggestionsResultsSuccess,
  fetchProductSuggestionsResultsError,
  fetchProductSuggestionsResultsInProgress,
  fetchProductSuggestionsResults,
} from 'src/redux/product/productSuggestionsSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { ProductSuggestParams } from 'src/types/product/SearchAndSuggestionsParams';
import { SearchSuggestionResponse } from 'src/types/product/SearchSuggestionResponse';
import countryLocalStorage from 'src/utils/countryLocalStorage';


export function* ProductSuggestionsSaga(action: FetchProductSuggestionsResults) {
  try {
    const apiSearch: ApiSearch = yield getContext(SagaContextItem.apiSearch);
    const suggestParams: ProductSuggestParams = { query: action.payload.query ?? '', countryCode: countryLocalStorage.get(), size: 100 };
    
    yield put(fetchProductSuggestionsResultsInProgress());
    const data: SearchSuggestionResponse[] = yield call(apiSearch.getSuggestions, suggestParams);
    yield put(fetchProductSuggestionsResultsSuccess(data));
  } catch (err) {
    const errorCode = (err?.response?.status) || 400;
    yield put(fetchProductSuggestionsResultsError(errorCode));
  }
}

export default function* fetchProductSuggestionsWatcher() {
  yield takeLatest(fetchProductSuggestionsResults.type, ProductSuggestionsSaga);
}
