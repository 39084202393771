import {
  createAction,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { CountryCode } from 'src/api/orderTypes';
import { RecommendedAddress } from 'src/types/customer/address';
import { AddressCheckErrorResponse, AddressCheckFailureResult } from 'src/types/customer/AddressCheckErrorResponse';
import { AddressValidationResponse } from 'src/types/customer/AddressValidationResponse';


export interface GetCitiesSuggestionsRequest {
  street?: string;
  streetNumber?: string;
  zipCode: string;
}
export interface AddressSuggestionState {
  loading: boolean;
  addresses?: AddressValidationResponse;
  error?: boolean;
  errorReason?: AddressCheckFailureResult;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  countryCode: CountryCode;
  suggestedCities?: string[];
  loadingSuggestedcities: boolean;
}

export const addressSuggestionsInitialState: AddressSuggestionState = {
  loading: false,
  street: '',
  streetNumber: '',
  zipCode: '',
  city: '',
  countryCode: CountryCode.de,
  loadingSuggestedcities: false,
};

export const getSuggestedCities = createAction<GetCitiesSuggestionsRequest>('addresses/GET_CITIES_SUGGESTIONS');
export type GetSuggestedCities = ReturnType<typeof getSuggestedCities>

const addressSuggestionSlice = createSlice({
  name: 'addressSuggestion',
  initialState: addressSuggestionsInitialState,
  reducers: {
    fetchAddressSuggestionsInProgress(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchAddressSuggestionsSuccess(state, { payload }: PayloadAction<AddressValidationResponse|undefined>) {
      state.loading = false;
      state.error = undefined;
      state.addresses = payload;
    },
    fetchAddressSuggestionsError(state, { payload }: PayloadAction<AddressCheckErrorResponse>) {
      state.loading = false;
      state.error = true;
      state.errorReason= payload.reason;
    },
    emptyAddressSuggestions(state) {
      state.loading = false;
      state.error = undefined;
      state.addresses = undefined;
    },
    changeAddress(state, { payload }: PayloadAction<RecommendedAddress>) {
      state.street = payload.street;
      state.streetNumber = payload.streetNumber;
      state.city = payload.city;
      state.zipCode = payload.zipCode;
    },
    setSuggestedCities(state, { payload }: PayloadAction<string[]>) {
      state.suggestedCities = payload;
      state.loadingSuggestedcities = false;
    }
  },
  extraReducers: builder => {
    builder
      .addCase( getSuggestedCities, state => {
        state.suggestedCities = undefined;
        state.loadingSuggestedcities = true;
      });
  }
},
);

export const {
  fetchAddressSuggestionsInProgress,
  fetchAddressSuggestionsSuccess,
  fetchAddressSuggestionsError,
  emptyAddressSuggestions,
  changeAddress,
  setSuggestedCities,
} = addressSuggestionSlice.actions;

export default addressSuggestionSlice.reducer;
