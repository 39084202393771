import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { MonthlyStockStatus, SubscriptionProductReport } from 'src/subscriptionProductManagement/types/response/SubscriptionReportResponse';

import { SortingMode, SubscriptionReportSortBy } from '../utils/subscriptionProductManagementUtils';


export interface StockDelaisRange {
  size: number;
  offset: number;
}

const STOCK_DELAIS_RANGE_SIZE = 6;

export const defaultSubscriptionReportDelaisRangeFilter: StockDelaisRange = {
  offset: 0,
  size: STOCK_DELAIS_RANGE_SIZE
};

export interface SubscriptionsReportFetchFilters {
  keywords?: string;
  selectedMonth?: string;
  sortBy?: SubscriptionReportSortBy;
  sortingMode?: SortingMode;
  stockDelaisRange: StockDelaisRange
}

export interface PartialSubscriptionsReportState {
  page: number;
  size: number;
  items: SubscriptionProductReport[];
}

export interface FullSubscriptionsReportState {
  page: number;
  size: number;
  currentMonthFloorSales: number;
  previousMonthFloorSales: number;
  floorSalesVariation: number;
  totalActiveSubscriptions: number;
  totalSubscribedProducts: number;
  monthlyStockStatus: MonthlyStockStatus[];
  items: SubscriptionProductReport[];
}


export interface MonthsStockReport {
  currentMonthFloorSales: number;
  previousMonthFloorSales: number;
  floorSalesVariation: number;
  totalActiveSubscriptions: number;
  totalSubscribedProducts: number;
  monthlyStockStatus: MonthlyStockStatus[];
}

export interface SelectedSubscriptionProductReport {
  id: string;
  editReportLoading: boolean,
}

export interface SubscriptionReportState {
  data?: SubscriptionProductReport[];
  monthsStock?: MonthsStockReport;
  offset: number;
  filters: SubscriptionsReportFetchFilters;
  loading: boolean;
  selectedSubscriptionProductReport?: SelectedSubscriptionProductReport;
}

export const subscriptionReportInitialState: SubscriptionReportState = {
  data: [],
  monthsStock: undefined,
  offset: 1,
  loading: true,
  filters : {
    stockDelaisRange : defaultSubscriptionReportDelaisRangeFilter
  }
};

export const fetchSubscriptionsReport = createAction('subscriptionReport/FETCH_SUBSCRIPTIONS_REPORT');
export const fetchInitialSubscriptionProductsReport = createAction('subscriptionReport/FETCH_INITIAL_SUBSCRIPTION_PRODUCTS_REPORT');
export const fetchProductsReportByKeyword = createAction<string>('subscriptionReport/FETCH_SUBSCRIPTION_PRODUCTS_REPORT_BY_KEYWORD');
export const editSubscriptionProductReport = createAction<string>('subscriptionManagement/EDIT_SUBSCRIPTION_PRODUCT_REPORT');

const subscriptionReportSlice = createSlice({
  name: 'subscriptionReport',
  initialState: subscriptionReportInitialState,
  reducers: {
    fetchFullSubscriptionReportSuccess(
      state,
      { payload }: PayloadAction<FullSubscriptionsReportState>,
    ) {
      state.loading = false;
      state.data = payload.items;
      state.offset = payload.page;
      state.monthsStock = {
        currentMonthFloorSales: payload.currentMonthFloorSales,
        previousMonthFloorSales: payload.previousMonthFloorSales,
        floorSalesVariation: payload.floorSalesVariation,
        totalActiveSubscriptions: payload.totalActiveSubscriptions,
        totalSubscribedProducts: payload.totalSubscribedProducts,
        monthlyStockStatus: payload.monthlyStockStatus,
      };
    },
    fetchPartialSubscriptionReportSuccess: (
      state,
      { payload }: PayloadAction<PartialSubscriptionsReportState>,
    ) => {
      state.loading = false;
      state.data = payload.items;
      state.offset = payload.page;
    },
    clearReportSearchKeyword: (state) => {
      state.loading = true;
      state.filters = {
        ...state.filters,
        keywords: undefined,
      };
    },
    fetchSubscriptionsReportError(state) {
      state.loading = false;
    },
    setSubscriptionsReportFetchSelectedMonthFilter(
      state,
      { payload }: PayloadAction<string>,
    ) {
      state.offset = 1;
      state.filters = {
        ...state.filters,
        selectedMonth: payload,
      };
      state.loading = true;
    },
    nextSubscriptionReportStockDelaisRange(state) {
      const { size, offset } = state.filters.stockDelaisRange;
      state.filters = {
        ...state.filters,
        stockDelaisRange: { offset: offset + size, size },
      };
    },
    previousSubscriptionReportStockDelaisRange(state) {
      const { size, offset } = state.filters.stockDelaisRange;
      const newOffset = (offset - size) < 0 ? 0 : offset - size;
      state.filters = {
        ...state.filters,
        stockDelaisRange: { offset: newOffset, size },
      };
    },
    clearSubscriptionsReportSelectedMonthFilter(state) {
      state.offset = 1;
      state.filters = {
        ...state.filters,
        selectedMonth: undefined,
      };
      state.loading = true;
    },
    setSelectedSubscriptionProductReport: (state, { payload }: PayloadAction<string>) => {
      state.selectedSubscriptionProductReport = { id: payload, editReportLoading: false };
    },
    editSubscriptionProductReportSuccess: (state, { payload }: PayloadAction<SubscriptionProductReport[]>) => {
      state.selectedSubscriptionProductReport!.editReportLoading = false;
      state.data = payload;
    },
    editSubscriptionProductReportFailed: (state) => {
      state.selectedSubscriptionProductReport!.editReportLoading = false;
    },
    setSearchKeywordFilter: (state, { payload }: PayloadAction<string>) => {
      state.filters = {
        ...state.filters,
        keywords: payload,
      };
    },
    setSubscriptionReportSortBy: (state, { payload }: PayloadAction<SubscriptionReportSortBy>) => {
      state.offset = 1;
      state.loading = true;
      state.filters = {
        ...state.filters,
        sortingMode : state.filters?.sortingMode === SortingMode.DESC && state.filters.sortBy === payload ? SortingMode.ASC : SortingMode.DESC,
        sortBy : payload
      };
    }
  },
  extraReducers: builder => {
    builder
      .addCase( fetchSubscriptionsReport,state => {
        state.loading = true;
      })
      .addCase( fetchInitialSubscriptionProductsReport,state => {
        state.loading = true;
      })
      .addCase( fetchProductsReportByKeyword,(state, { payload }: PayloadAction<string>) => {
        state.loading = true;
        state.filters = { ...state.filters, keywords: payload.trim() };
      })
      .addCase( editSubscriptionProductReport,(state) => {
      state.selectedSubscriptionProductReport!.editReportLoading = true;
      });
  },
});

export const {
  fetchFullSubscriptionReportSuccess,
  nextSubscriptionReportStockDelaisRange,
  previousSubscriptionReportStockDelaisRange,
  fetchPartialSubscriptionReportSuccess,
  clearReportSearchKeyword,
  fetchSubscriptionsReportError,
  setSubscriptionsReportFetchSelectedMonthFilter,
  clearSubscriptionsReportSelectedMonthFilter,
  setSelectedSubscriptionProductReport,
  editSubscriptionProductReportSuccess,
  editSubscriptionProductReportFailed,
  setSearchKeywordFilter,
  setSubscriptionReportSortBy
} = subscriptionReportSlice.actions;

export const subscriptionReportReducer = subscriptionReportSlice.reducer;
