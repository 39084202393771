import { SearchFilter, SearchParams } from 'src/types/product/product';


const buildFilters = (filters: SearchFilter[]): string => {
  if (!filters.length) {
    return '';
  }

  return filters
    .filter((filter: SearchFilter) => filter.value.length > 0)
    .map((filter: SearchFilter) => {
      const { name, value, substring = false } = filter;
      const filterString = substring ? 'substringFilter=' : 'Filter=';

      return filterString.concat(name, ':', encodeURIComponent(value.trim()));
    })
    .join('&');
};

const getQueryString = (params: SearchParams): string => {
  const { query = '*', filters = [] } = params;

  return [`query=${query}`, buildFilters(filters)].filter(f => f.length > 0).join('&');
};

export default getQueryString;
