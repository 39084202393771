import { PayloadAction } from '@reduxjs/toolkit';
import {
  takeLatest,
  call,
  put,
  select,
  getContext,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { RootStateType } from 'src/constants/types';
import { logErrorEvent } from 'src/logging/loggingActions';
import { validateIBAN, validateIBANSuccess } from 'src/redux/editOrder/editOrderSlice';
import {
  validateIBANFailure,
  validateIBANInProgress,
  validateIBAN as validateIBANAction,
  validateIBANSuccess as validateIBANSuccessPayment,
} from 'src/redux/payment/paymentSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { isBadRequestStatus } from 'src/utils/errorStatusChecks';

import { getOfferIfOrderLineItemsExist } from '../offer/getOffer';


export function* validateIBANSaga() {
  try {
    const state: RootStateType = yield select();
    let iban = state.payment.bankingInfo.iban;
    yield put(validateIBANInProgress());
    iban = iban.toUpperCase().replace(/\s/g, '');
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    const data = yield call(apiCustomer.validateIBAN, iban);
    yield put(validateIBANSuccessPayment(data.bankDetails.name));
    yield call(getOfferIfOrderLineItemsExist);
  } catch (err) {
    if(!isBadRequestStatus(err)) {
      yield put(logErrorEvent({ message: 'Could not validate IBAN', err }));
    }
    if (err.response !== undefined) {
      const error = err.response.data.detail;
      yield put(validateIBANFailure(error));
    }
  }
}

export function* validateBankIBANSaga(action: PayloadAction<string>) {
  let iban = action.payload;
  try {
    iban = iban.toUpperCase().replace(/\s/g, '');
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    const data = yield call(apiCustomer.validateIBAN, iban);
    yield put(validateIBANSuccess({ bankName: data.bankDetails.name, iban }));
  } catch (err) {
    if(!isBadRequestStatus(err)) {
      yield put(logErrorEvent({ message: 'Could not validate IBAN', err }));
    }
    yield put(validateIBANSuccess({ bankName: '', ibanError: err.response.data.detail, iban }));
  }
}

export default function* validateIBANWatcher() {
  yield takeLatest(validateIBANAction.type, validateIBANSaga);
  yield takeLatest(validateIBAN.type, validateBankIBANSaga);
}
