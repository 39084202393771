export default {
  'offers.allOffers' : 'Weitere Produkte',
  'offers.allBroadcasts' : 'Alle Sendungen',
  'offers.refreshOffers' : 'Produkte aktualisieren',
  'topDeals.daily' : ' Heutige Angebote',
  'topDeals.weekly' : 'Angebote der Woche',
  'topDeals.monthly' : 'Angebote des Monats',
  'tvOffers.hse24' : 'HSE',
  'tvOffers.hse24_extra' : 'HSE EXTRA',
  'tvOffers.hse24_trend' : 'HSE TREND',
};
