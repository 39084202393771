import {
  call,
  getContext,
  put,
  takeLatest,
  select
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { waitlistHistorySelector } from 'src/redux/customer/selectors/waitlistHistorySelectors';
import {
  fetchWaitlistHistory,
  FetchWaitlistHistory,
  fetchWaitlistHistoryError,
  fetchWaitlistHistorySuccess,
  setWaitlistHistoryFilters,
  WaitlistHistoryFilters,
} from 'src/redux/customer/waitlistHistorySlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { formatDate } from 'src/types/orderhistory/OrdersOverviewHistoryRequest';
import { WaitlistItemsListRequest } from 'src/types/waitlist/CreateWaitlistItemRequest';
import { WaitlistHistoryItemsResponse } from 'src/types/waitlist/WaitlistHistoryResponse';

import { checkAndLoadCustomer } from '../utils/checkAndLoadCustomer';


export function* fetchWaitlistHistorySaga(action: FetchWaitlistHistory) {
  try {
    const { offset } = yield select(waitlistHistorySelector.getInfiniteScrollState);
    const externalCustomerId = yield select(waitlistHistorySelector.getExternalCustomerId);
    const filters: WaitlistHistoryFilters = yield select(waitlistHistorySelector.getWaitlistHistoryFilters);
    
    const apiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    const response: WaitlistHistoryItemsResponse = yield call(
      apiCallCenter.getCustomerWaitlistHistory,
      buildWaitlistHistoryItemsRequest(
        externalCustomerId, 
        filters, 
        offset, 
        action.payload.size ?? 10
      ));

    const { size, total } = response;

    const waitlistItems = (offset !== 1)
      ? (yield select(waitlistHistorySelector.getWaitlistHistoryItems)).concat(response.items)
      : response.items;

    yield put(fetchWaitlistHistorySuccess({ items: waitlistItems, offset: offset, size: size, total: total }));
    yield call(checkAndLoadCustomer, externalCustomerId);
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not fetch waitlist history for cusomer ${action.payload}`, err }));
    const errorCode = (err && err.response && err.response.status) || 400;
    yield put(fetchWaitlistHistoryError(errorCode));
  }
}

export default function* fetchWaitlistHistoryWatcher() {
  yield takeLatest(fetchWaitlistHistory.type, fetchWaitlistHistorySaga);
  yield takeLatest(setWaitlistHistoryFilters.type, fetchWaitlistHistorySaga);
}

export const buildWaitlistHistoryItemsRequest = (
  externalCustomerId: string,
  filters: WaitlistHistoryFilters,
  offset: number,
  size: number,
): WaitlistItemsListRequest => {
  return {
    externalCustomerId,
    offset,
    size: size,
    keywords: filters.keywords,
    searchFrom: filters.searchFrom ? formatDate(filters.searchFrom) : undefined,
    searchTo: filters.searchTo ? formatDate(filters.searchTo) : undefined,
  };
};
