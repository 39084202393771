import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Order } from 'src/api/orderTypes';


export interface RecreateOrderState {
  loading: boolean;
  originalOrder?: Order;
  error?: RecreateOrderError;
  errors: RecreateOrderError[];
  recreateOrderIdSearchValue: string;
}

export enum FailedRecreateOrderStep {
  LOAD_BASKET,
  LOAD_CUSTOMER,
  SET_DELIVERY_ADDRESS,
  SET_SERVICE_VOUCHER,
  REDEEM_VOUCHER,
  SET_PAYMENT,
}

export interface RecreateOrderError {
  step?: FailedRecreateOrderStep;
  title?: string;
  detail: string;
  key?: string;
}

export const recreateOrderInitialState: RecreateOrderState = {
  loading: false,
  errors: [],
  recreateOrderIdSearchValue: '',
};

export const checkAndFetchOriginalOrder = createAction<string | null>('recreateOrder/checkAndFetchOriginalOrder');
export const loadOriginalOrder = createAction<Order>('recreateOrder/loadOriginalOrder');

const recreateOrderSlice = createSlice({
  name: 'recreateOrder',
  initialState: recreateOrderInitialState,
  reducers: {
    fetchOriginalOrderSuccess(state, { payload }: PayloadAction<Order>) {
      state.loading = false;
      state.originalOrder = payload;
    },
    fetchOriginalOrderFailure(state, { payload }: PayloadAction<RecreateOrderError>) {
      state.loading = false;
      state.error = payload;
    },
    addOriginalOrderErrors(state, { payload }: PayloadAction<RecreateOrderError>) {
      state.errors.push(payload);
    },
    resetOriginalOrder() {
      return recreateOrderInitialState;
    },
    loadOriginalOrderSuccess(state) {
      state.loading = false;
    },
    loadOriginalOrderFailed(state, { payload }: PayloadAction<RecreateOrderError>) {
      state.loading = false;
      state.error = payload;
    },
    setRecreateOrderIdSearchValue(state, { payload }: PayloadAction<string>) {
      state.recreateOrderIdSearchValue = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase( checkAndFetchOriginalOrder,(state) => {
        state.loading = true;
      })
      .addCase( loadOriginalOrder,(state) => {
        state.loading = true;
      });
  },
});

export const {
  fetchOriginalOrderSuccess,
  fetchOriginalOrderFailure,
  resetOriginalOrder,
  addOriginalOrderErrors,
  loadOriginalOrderSuccess,
  loadOriginalOrderFailed,
  setRecreateOrderIdSearchValue,
} = recreateOrderSlice.actions;

export default recreateOrderSlice.reducer;
