import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  takeLatest,
  getContext,
  put,
} from 'redux-saga/effects';

import { ApiEditOrder } from 'src/api/apiEditOrder';
import { removeProduct, removeProductSuccess } from 'src/redux/editOrder/editOrderSlice';
import { handleFailureState } from 'src/sagas/editOrder/handelFailureState';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { EditedOffer } from 'src/types/orderhistory/EditedOrder';
import { EditOrderActionRequestWithOrderId } from 'src/types/orderhistory/EditOrderActionRequest';

import { setDeliveryTimesSaga } from './initEditOrder';


export function* removeProductSaga(action: PayloadAction<EditOrderActionRequestWithOrderId>) {
  try {
    const apiEditOrder: ApiEditOrder = yield getContext(SagaContextItem.apiEditOrder);
    const response: EditedOffer = yield call(apiEditOrder.removePosition, action.payload);

    const offer = yield call(setDeliveryTimesSaga, response);

    yield put(removeProductSuccess(offer));
  } catch (error) {
    yield handleFailureState(error, 'Couldn\'t remove Product on edit order actionRequest: ',action.payload.actionRequest);
  }
}

export default function* removeProductWatcher() {
  yield takeLatest(removeProduct.type, removeProductSaga);
}
