import { RootStateType } from 'src/constants/types';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { createSelectors } from 'src/utils/state';


export const paymentSelector = createSelectors({
  getPayment(state: RootStateType) {
    return state.payment;
  },
  getPaymentWarnings(state: RootStateType) {
    return state.payment.warnings;
  },
  getPaymentLoading(state: RootStateType) {
    return state.payment.loading;
  },
  getSelectedPaymentMethod(state: RootStateType): PaymentMethod | undefined {
    return state.payment.selectedPaymentMethod;
  },
  getInstallmentsCurrentInstallmentPlan(state: RootStateType) {
    return state.payment.installments.currentInstallmentPlan;
  },
  getInstallmentsModalStep(state: RootStateType) {
    return state.payment.installments.modalStep;
  },
  getInstallmentsFactoringInstallmentPlans(state: RootStateType) {
    return state.payment.installmentsFactoring.installmentPlans;
  },
  getInstallmentsFactoringConsent(state: RootStateType) {
    return state.payment.installmentsFactoring.conditionsConsent;
  },
  getInstallmentsFactoringCurrentInstallmentPlan(state: RootStateType) {
    return state.payment.installmentsFactoring.currentInstallmentPlan;
  },
  getInstallmentsInstallmentPlans(state: RootStateType) {
    return state.payment.installments.installmentPlans;
  },
  getInstallmentsFactoringModalStep(state: RootStateType) {
    return state.payment.installmentsFactoring.modalStep;
  },
  getPreferredPaymentMethod(state: RootStateType) {
    return state.payment.preferredPaymentMethod;
  },
  getPreferredPaymentLoading(state: RootStateType) {
    return state.payment.preferredPaymentLoading;
  },
  getInstallments(state: RootStateType) {
    return state.payment.installments;
  },
  getInstallmentsDueDate(state: RootStateType) {
    return state.payment.installments.dueDay;
  },
  getInstallmentsChangedDueDay(state: RootStateType) {
    return state.payment.installments.changedDueDay;
  },
  getAvailablePaymentMethods(state: RootStateType) {
    return state.payment.availablePaymentMethods;
  },
  getInstallmentsFactoringModalMode(state: RootStateType) {
    return state.payment.installmentsFactoring.installmentFactoringModalMode;
  },
  isReklaZeroPercentFinancing(state: RootStateType) {
    return state.payment.reklaZeroPercentFinancing;
  },
});
