import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const customerPinSelector = createSelectors({
  getCurrentPinChallengeResult(state: RootStateType) {
    return state.customer.pinVerification.currentPinValue?.challengeResult;
  },
  getCustomerPinVerificationLoading(state: RootStateType) {
    return state.customer.pinVerification.loading;
  },
  getShouldClearPinInputStatus(state: RootStateType) {
    return state.customer.pinVerification.shouldClearPinInput;
  },
  getRequestNewCustomerPinStatus(state: RootStateType) {
    return state.customer.pinVerification.requestNewCustomerPinStatus;
  },
  getAttemptsCount(state: RootStateType) {
    return state.customer.pinVerification.attemptsCount;
  },
  getFormPinValue(state: RootStateType) {
    return state.customer.pinVerification.formPinValue;
  },
  getFormSkipReason(state: RootStateType) {
    return state.customer.pinVerification.formSkipReason;
  },
  getDisableSkipOptionForever(state: RootStateType) {
    return state.customer.pinVerification.disableSkipOptionForever;
  },
  getNextStep(state: RootStateType) {
    return state.customer.pinVerification.nextStep;
  },
  getPreLoadedCustomer(state: RootStateType) {
    return state.customer.pinVerification.loadedCustomer;
  },
});
