import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { openModal } from 'src/redux/app/appSlice';
import { initializeCreateSubscription } from 'src/redux/product/createSubscriptionSlice';
import { Modals } from 'src/types/Modals';
import Log from 'src/utils/log';


export function* initializeCreateSubscriptionSaga() {
  try {
    yield put(openModal(Modals.subscription));
  } catch (err) {
    yield call(Log.error, err);
  }
}

export function* initializeCreateSubscriptionWatcher() {
  yield takeLatest(initializeCreateSubscription.type, initializeCreateSubscriptionSaga);
}
