import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import Config from 'src/config';
import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal, showSuccessNotification } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import {
  addCustomerPrivacy,
  AddCustomerPrivacy,
  addCustomerPrivacySuccess,
  createCustomerError,
} from 'src/redux/customer/customerInfoSlice';
import { DEFAULT_PAYMENTS, setAvailablePaymentMethods } from 'src/redux/payment/paymentSlice';
import { getOfferIfOrderLineItemsExist } from 'src/sagas/offer/getOffer';
import { Modals } from 'src/types/Modals';
import stringify from 'src/utils/stringify';


export function* addCustomerPrivacySaga(action: AddCustomerPrivacy) {
  // todo this lines blow will be removed after enabling this feature to every call center agent
  const agentInfo = yield select(appSelectors.getAgentInfo);
  const allowedUsers = Config.env.userAllowedToUseReceptionSurvey;
  const superUser = allowedUsers.length === 0 || allowedUsers.find(v => agentInfo?.username?.startsWith(v)) !== undefined;

  if (superUser) {
    yield put(addCustomerPrivacySuccess(action.payload));
    yield put(openModal(Modals.receptionSurvey));
  } else {
    try {
      yield put(addCustomerPrivacySuccess(action.payload));
      yield put(showSuccessNotification('customer.createEdit.createSuccess'));
      yield put(setAvailablePaymentMethods(DEFAULT_PAYMENTS));
      yield call(getOfferIfOrderLineItemsExist);
    } catch (err) {
      yield put(logErrorEvent({ message: `Could not add customer privacy ${stringify(action.payload)}`, err }));
      const errorCode = (err?.response?.status) || 400;
      yield put(createCustomerError(errorCode));
    }
  }
}

export function* addCustomerPrivacyWatcher() {
  yield takeLatest(addCustomerPrivacy.type, addCustomerPrivacySaga);
}
