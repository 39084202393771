import { RootStateType } from 'src/constants/types';
import { createSelectors } from 'src/utils/state';


export const subscriptionReportSelectors = createSelectors({
  getSubscriptionsReport(state: RootStateType) {
    return state.subscriptionReport.data ?? [];
  },
  getSubscriptionsReportLoading(state: RootStateType) {
    return state.subscriptionReport.loading;
  },
  getMonthsStock(state: RootStateType) {
    return state.subscriptionReport.monthsStock;
  },
  getSubscriptionsReportFilters(state: RootStateType) {
    return state.subscriptionReport.filters;
  },
  getOffset(state: RootStateType) {
    return state.subscriptionReport.offset;
  },
  getSubscriptionsReportSelectedMonthFilter(state: RootStateType) {
    return state.subscriptionReport.filters?.selectedMonth;
  },
  getSelectedSubscriptionProductReport: (state: RootStateType) => state.subscriptionReport.data?.find(
    value => value.id === state.subscriptionReport.selectedSubscriptionProductReport?.id,
  ),
  getSelectedSubscriptionReportLoading: (state: RootStateType) => state.subscriptionReport.selectedSubscriptionProductReport?.editReportLoading,
});
