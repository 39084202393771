import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Customer, PinChallenge } from 'src/types/customer/customer';
import { SkipCustomerPinChallengeRequest, VerifyCustomerPinChallengeRequest } from 'src/types/customer/VerifyCustomerPinChallengeRequest';
import { Modals } from 'src/types/Modals';


export interface CustomerPinState {
  loading: boolean;
  previousPinValue?: PinVerificationResultDetail;
  currentPinValue?: PinVerificationResultDetail;
  pinChallengeType?: PinChallenge
  loadedCustomer?: Customer;

  externalCustomerId?: string;
  shouldClearPinInput: boolean;
  requestNewCustomerPinStatus: RequestNewCustomerPinStatus;
  attemptsCount?: number;

  formPinValue: string;
  formSkipReason?: string;
  disableSkipOptionForever?: boolean;

  nextStep?: Modals;
}

export enum RequestNewCustomerPinStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NONE = 'NONE'
}


export enum PinChallengeVerificationResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  FAILED_WITH_MAX_ATTEMPTS_REACHED = 'FAILED_WITH_MAX_ATTEMPTS_REACHED'
}

export interface PinVerificationResultDetail {
  value: string,
  challengeResult: PinChallengeVerificationResult
}

export const customerPinInitialState: CustomerPinState = {
  loading: false,
  shouldClearPinInput: false,
  requestNewCustomerPinStatus: RequestNewCustomerPinStatus.NONE,
  formPinValue: '',
};

export const authenticateCustomer = createAction('customerPin/authenticateCustomer');
export const checkCustomerPinAction = createAction<VerifyCustomerPinChallengeRequest>('customerPin/checkCustomerPin');
export const skipPinChallengeAction = createAction<SkipCustomerPinChallengeRequest>('customerPin/skipPinChallenge');
export const requestNewPhonePinAction = createAction<string>('customerPin/requestNewPhonePin');

export type CheckCustomerPinActionType = ReturnType<typeof checkCustomerPinAction>;
export type SkipPinChallengeActionType = ReturnType<typeof skipPinChallengeAction>;
export type RequestNewPhonePinActionType = ReturnType<typeof requestNewPhonePinAction>;

const customerPinSlice = createSlice({
  initialState: customerPinInitialState,
  name: 'CustomerPin',
  reducers: {
    setCurrentPinValue(state, { payload }: PayloadAction<PinVerificationResultDetail>) {
      state.currentPinValue = payload;
    },
    setPinChallengeType(state, { payload }: PayloadAction<PinChallenge>) {
      state.pinChallengeType = payload;
    },
    checkCustomerPinSuccessful(state, { payload }: PayloadAction<PinVerificationResultDetail>) {
      state.loading = false;
      state.currentPinValue = payload;
    },
    checkCustomerPinUnsuccessful(state, { payload }: PayloadAction<{result: PinVerificationResultDetail, attemptsCount?: number}>) {
      state.loading = false;
      state.previousPinValue = payload.result;
      state.attemptsCount = payload.attemptsCount;
      state.currentPinValue = undefined;
    },
    skipPinChallengeSuccess(state) {
      state.loading = false;
    },
    requestNewPhonePinSuccess(state) {
      state.loading = false;
      state.requestNewCustomerPinStatus = RequestNewCustomerPinStatus.SUCCESS;
    },
    resetCustomerPinState(state, { payload }: PayloadAction<Customer | undefined>) {
      return {
        ...customerPinInitialState,
        loadedCustomer: payload,
      };
    },
    requestNewPhonePinFailed(state) {
      state.loading = false;
      state.requestNewCustomerPinStatus = RequestNewCustomerPinStatus.FAILED;
    },
    skipPinChallengeFailed(state) {
      state.loading = false;
    },
    checkCustomerPinFailed(state) {
      state.loading = false;
    },
    setShouldClearPinInputStatus(state, { payload }: PayloadAction<boolean>) {
      if (state.currentPinValue?.challengeResult === PinChallengeVerificationResult.FAILED_WITH_MAX_ATTEMPTS_REACHED) {
        state.shouldClearPinInput = false;
      } else {
        state.shouldClearPinInput = payload;
        if(payload) {
          state.formPinValue = '';
        }
      }
    },
    setRequestNewCustomerPinStatus(state, { payload }: PayloadAction<RequestNewCustomerPinStatus>) {
      state.requestNewCustomerPinStatus = payload;
    },
    setFormPinValue(state, { payload }: PayloadAction<string>) {
      state.formPinValue = payload;
    },
    setFormSkipReason(state, { payload }: PayloadAction<string | undefined>) {
      state.formSkipReason = payload;
    },
    setDisableSkipOptionForever(state) {
      state.disableSkipOptionForever = true;
    },
    setPinCheckModalNextStep(state, { payload }: PayloadAction<undefined | Modals>) {
      state.nextStep = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase( checkCustomerPinAction,(state) => {
        state.loading = true;
      })
      .addCase( skipPinChallengeAction,(state) => {
        state.loading = true;
      })
      .addCase( requestNewPhonePinAction,(state) => {
        state.loading = true;
      });
  },
});

export const {
  setCurrentPinValue,
  checkCustomerPinSuccessful,
  checkCustomerPinUnsuccessful,
  skipPinChallengeSuccess,
  requestNewPhonePinSuccess,
  setPinChallengeType,
  requestNewPhonePinFailed,
  skipPinChallengeFailed,
  checkCustomerPinFailed,
  setShouldClearPinInputStatus,
  resetCustomerPinState,
  setRequestNewCustomerPinStatus,
  setFormPinValue,
  setFormSkipReason,
  setDisableSkipOptionForever,
  setPinCheckModalNextStep,
} = customerPinSlice.actions;
export default customerPinSlice.reducer;
