import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import {
  CancelRejectedOrder,
  cancelRejectedOrder,
  cancelRejectedOrderFailed,
  cancelRejectedOrderSuccess,
} from 'src/OrderDisplay/redux/orderDisplaySlice';
import { closeModal } from 'src/redux/app/appSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Modals } from 'src/types/Modals';


export function* cancelRejectedOrderSaga(action: CancelRejectedOrder) {
  try {
    const apiOrder = yield getContext(SagaContextItem.apiOrder);
    const { statusHistory } = yield call(apiOrder.cancelRejectedOrder, action.payload);
    yield put(cancelRejectedOrderSuccess(statusHistory));
    yield put(closeModal(Modals.cancelRejectedOrder));
  } catch (err) {
    yield put(
      logEvent({
        level: LOG_LEVEL.WARN,
        message: `Couldn't cancel rejected order: ${action.payload}`,
        err: err,
      }),
    );

    yield put(cancelRejectedOrderFailed());
  }
}

export default function* cancelRejectedOrderWatcher() {
  yield takeLatest(cancelRejectedOrder.type, cancelRejectedOrderSaga);
}
